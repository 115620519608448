import {
  bedManagementConstants,
  housingConstants,
} from '@/modules/Housing/lib';

const { initialSeasonPeriod } = housingConstants;
const {
  TEAM_NAME,
  COMPLEX_NAME,
  AVAILABILITY_DATE_FROM_FILTER_NAME,
  AVAILABILITY_DATE_TO_FILTER_NAME,
  ONLY_COUCHES_FILTER_NAME,
} = bedManagementConstants;

export const getUnBeddedSummaries = (response) => {
  const total = response?.meta?.total ?? 0;
  const lastPage = response?.links.reduce((maxPage, link) => {
    const page = parseInt(new URL(link?.href).searchParams.get('page'));

    return page > maxPage ? page : maxPage;
  }, 1);
  const currentPageLink = response?.links.find((link) => link?.meta.active === 'true');
  const currentPage = currentPageLink ? parseInt(new URL(currentPageLink.href).searchParams.get('page')) : 1;
  const unBeddedSummaries = response?.data?.map((summary) => {
    const {
      id,
      name,
      bed_allotment_type,
      email,
      phone_number,
      team_id,
      team_name,
    } = summary?.attributes ?? {};

    const { label: type_of_room, number_of_rooms } =
      summary?.attributes?.apartment_status ?? {};

    return {
      id,
      name,
      bed_allotment_type,
      email,
      phone_number,
      type_of_room,
      number_of_rooms,
      team_id,
      team_name,
    };
  });

  return { items: unBeddedSummaries, total, lastPage, page: currentPage };
};

export const prepareDataForGetUnBeddedSummaries = (data) => {
  const {
    [TEAM_NAME]: team_ids,
    [COMPLEX_NAME]: complex_ids,
    search_query,
    bed_allotment_type,
    season_period,
    page,
  } = data;

  return {
    ...(team_ids && { team_ids }),
    ...(complex_ids && { complex_ids }),
    ...(search_query && { search_query }),
    ...(bed_allotment_type && { bed_allotment_type }),
    ...(season_period && { season_period }),
    ...(page && { page }),
  };
};

export const getBedManagement = (response) => {
  const total = response?.meta?.total ?? 0;
  const bedManagementData = response?.data?.map((bed) => {
    return bed?.attributes ?? {};
  });

  return { items: bedManagementData, total };
};

export const prepareDataForGetBedManagement = (data) => {
  const {
    complex_ids,
    team_ids,
    search_query,
    page,
    season_period,
    recruiting_season_id,
    apartment_types,
    number_of_rooms,
    number_of_available_sleeping_spots,
    [AVAILABILITY_DATE_FROM_FILTER_NAME]: unit_start,
    [AVAILABILITY_DATE_TO_FILTER_NAME]: unit_end,
    [ONLY_COUCHES_FILTER_NAME]: only_couches,
  } = data;

  return {
    page,
    ...(season_period
      ? { season_period }
      : { season_period: 'regular_season' }),
    ...(complex_ids && { complex_ids }),
    ...(team_ids && { team_ids }),
    ...(search_query && { search_query }),
    ...(recruiting_season_id && { recruiting_season_id }),
    ...(apartment_types?.length && { apartment_types }),
    ...(number_of_rooms && { number_of_rooms }),
    ...(unit_start && { unit_start }),
    ...(unit_end && { unit_end }),
    ...(only_couches && { only_couches }),
    ...(number_of_available_sleeping_spots && {
      number_of_available_sleeping_spots,
    }),
  };
};

export const prepareDataForUnassignRep = (data) => {
  const {
    user_type: bed_allotment_type,
    user_id: resident_id,
    season_period,
  } = data;

  return {
    resident_id,
    bed_allotment_type,
    ...(season_period
      ? { season_period }
      : { season_period: initialSeasonPeriod }),
  };
};

export const prepareDataForAssignRep = (data) => {
  const {
    bed_allotment_type,
    resident_id,
    assignment_type,
    room_id,
    bed_number,
    recruiting_season_id,
    season_period,
    force_assignment,
  } = data;

  return {
    force_assignment: !!force_assignment,
    resident_id,
    bed_allotment_type,
    assignment_type,
    ...(room_id && { room_id }),
    ...(bed_number && { bed_number }),
    recruiting_season_id,
    ...(season_period
      ? { season_period }
      : { season_period: initialSeasonPeriod }),
  };
};
