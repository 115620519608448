import { useRef, useEffect } from 'react';
export var useDraggable = function (isLoading) {
    var elementRef = useRef(null);
    var isDragging = useRef(false);
    var startX = useRef(0);
    var scrollLeft = useRef(0);
    var startY = useRef(0);
    var scrollTop = useRef(0);
    var handleMouseDown = function (event) {
        if (elementRef.current) {
            isDragging.current = true;
            startX.current = event.pageX - elementRef.current.offsetLeft;
            startY.current = event.pageY - elementRef.current.offsetTop;
            scrollLeft.current = elementRef.current.scrollLeft;
            scrollTop.current = elementRef.current.scrollTop;
        }
    };
    var handleMouseLeave = function () {
        isDragging.current = false;
    };
    var handleMouseUp = function (event) {
        isDragging.current = false;
    };
    var handleMouseMove = function (event) {
        if (!isDragging.current || !elementRef.current) {
            return;
        }
        var x = event.pageX - elementRef.current.offsetLeft;
        var y = event.pageY - elementRef.current.offsetTop;
        var walkX = (x - startX.current) * 2;
        var walkY = (y - startY.current) * 2;
        elementRef.current.scrollLeft = scrollLeft.current - walkX;
        elementRef.current.scrollTop = scrollTop.current - walkY;
    };
    var handleClick = function (event) {
        if (elementRef.current) {
            if (Math.abs(event.pageX - startX.current - elementRef.current.offsetLeft) > 5 ||
                Math.abs(event.pageY - startY.current - elementRef.current.offsetTop) > 5) {
                event.stopPropagation();
            }
        }
    };
    useEffect(function () {
        if (!isLoading && elementRef.current) {
            var currentElementRef_1 = elementRef.current;
            currentElementRef_1.addEventListener('mousedown', handleMouseDown);
            currentElementRef_1.addEventListener('mouseleave', handleMouseLeave);
            currentElementRef_1.addEventListener('click', handleClick);
            currentElementRef_1.addEventListener('mouseup', handleMouseUp);
            currentElementRef_1.addEventListener('mousemove', handleMouseMove);
            return function () {
                if (currentElementRef_1) {
                    currentElementRef_1.removeEventListener('mousedown', handleMouseDown);
                    currentElementRef_1.removeEventListener('mouseleave', handleMouseLeave);
                    currentElementRef_1.removeEventListener('click', handleClick);
                    currentElementRef_1.removeEventListener('mouseup', handleMouseUp);
                    currentElementRef_1.removeEventListener('mousemove', handleMouseMove);
                }
            };
        }
    }, [isLoading]);
    return { elementRef: elementRef };
};
