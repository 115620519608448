import { Table } from '@/components';
import { getHeadRows } from './utils';

const PayTable = () => {
  const paymentRows = [
    [
      {
        value: 'CV < $700.00',
        align: 'left',
      },
      {
        value: '$75',
        align: 'right',
      },
    ],
    [
      {
        value: 'CV $700.00 - $899.99',
        align: 'left',
      },
      {
        value: '$100',
        align: 'right',
      },
    ],
    [
      {
        value: 'CV $900.00 - $999.99',
        align: 'left',
      },
      {
        value: '$125',
        align: 'right',
      },
    ],
    [
      {
        value: 'CV $1000.00 - $1199.99',
        align: 'left',
      },
      {
        value: '$150',
        align: 'right',
      },
    ],
    [
      {
        value: 'CV > $1200.00',
        align: 'left',
      },
      {
        value: '$175',
        align: 'right',
      },
    ],
  ];

  return (
    <Table
      thead={{
        rows: getHeadRows(),
      }}
      tbody={{
        rows: paymentRows,
      }}
    />
  );
};

PayTable.propTypes = {};

export default PayTable;
