// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nJ4O4bJCOXom8P4PDvYo{margin-top:-20px;margin-left:-20px;object-fit:cover;border-radius:50%;width:40px;height:40px;object-position:50% 50%}.nJ4O4bJCOXom8P4PDvYo svg{border-radius:50%}.L1Hog8fYghFCybKP25yT{margin-top:-20px;margin-left:-20px;object-fit:cover;border-radius:50%;width:40px;height:40px;object-position:50% 50%}`, "",{"version":3,"sources":["webpack://./src/components/Map/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,uBAAA,CACA,0BACE,iBAAA,CAIJ,sBACE,gBAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,uBAAA","sourcesContent":[".avatar {\n  margin-top: -20px;\n  margin-left: -20px;\n  object-fit: cover;\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n  object-position: 50% 50%;\n  svg {\n    border-radius: 50%;\n  }\n}\n\n.vehicle {\n  margin-top: -20px;\n  margin-left: -20px;\n  object-fit: cover;\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n  object-position: 50% 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `nJ4O4bJCOXom8P4PDvYo`,
	"vehicle": `L1Hog8fYghFCybKP25yT`
};
export default ___CSS_LOADER_EXPORT___;
