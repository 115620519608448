import { createAsyncAction } from '@/redux/root';
import { nameSpace } from './nameSpace';
import { settingsReducer } from './settings-reducer';
import { settingsWatcher } from './settings-saga';
import { settingsSelector } from './settings-selector';

export const customGroupTypeNameSpace = `${nameSpace}/custom-group-types`;

export const updateCustomGroupTypeAsync = createAsyncAction(
  `${customGroupTypeNameSpace}/UPDATE_CUSTOM_GROUP_TYPE`,
);

export const createCustomGroupTypeAsync = createAsyncAction(
  `${customGroupTypeNameSpace}/CREATE_CUSTOM_GROUP_TYPE`,
);

export const removeCustomGroupTypeAsync = createAsyncAction(
  `${customGroupTypeNameSpace}/REMOVE_CUSTOM_GROUP_TYPE`,
);

export const requestCustomGroupTypesAsync = createAsyncAction(
  `${customGroupTypeNameSpace}/REQUEST_CUSTOM_GROUP_TYPE`,
);

const NAME = 'customGroupType';

export const customGroupTypesReducer = {
  [NAME]: settingsReducer(customGroupTypeNameSpace, {
    updateAsync: updateCustomGroupTypeAsync,
    createAsync: createCustomGroupTypeAsync,
    removeAsync: removeCustomGroupTypeAsync,
    requestsAsync: requestCustomGroupTypesAsync,
  }),
};

export function* customGroupTypeWatcher() {
  yield settingsWatcher('custom-group-types', {
    removeAsync: removeCustomGroupTypeAsync,
    updateAsync: updateCustomGroupTypeAsync,
    createAsync: createCustomGroupTypeAsync,
    requestsAsync: requestCustomGroupTypesAsync,
  });
}

export const customGroupTypesSelector = settingsSelector(NAME);
