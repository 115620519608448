var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AptiveIcon } from '@aptive-env/storybook';
import Popover from 'components/shared/Popover';
import { format, parse, addDays } from 'date-fns';
import { useMemo, useState } from 'react';
import { fetchCalendarEvents } from 'services/calendar';
import { getCalendarEventTypeColors } from 'utils/color';
import { convert24HourTo12Hour } from 'utils/convertHour';
var WEEK_DAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
var MONTHS = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];
// Function to get the number of days in a month
var getDaysInMonth = function (month, year) {
    return new Date(year, month + 1, 0).getDate();
};
// Function to get the day of the week for the first day of the month
var getFirstDayOfMonth = function (month, year) {
    return new Date(year, month, 1).getDay();
};
var Month = function (_a) {
    var month = _a.month, officeId = _a.officeId, year = _a.year, eventTypes = _a.eventTypes;
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth();
    var currentDay = currentDate.getDate();
    var daysInMonth = getDaysInMonth(month, year);
    var firstDayOfMonth = getFirstDayOfMonth(month, year);
    // Generate an array of days in the current month including days from the previous and next months
    var days = [];
    var daysInPreviousMonth = getDaysInMonth(month - 1 < 0 ? 11 : month - 1, month - 1 < 0 ? year - 1 : year);
    // const daysInNextMonth = getDaysInMonth(month + 1 > 11 ? 0 : month + 1, month + 1 > 11 ? year + 1 : year)
    var previousMonthStart = daysInPreviousMonth - firstDayOfMonth + 1;
    // Add days from the previous month
    for (var i = previousMonthStart; i <= daysInPreviousMonth; i++) {
        days.push({ day: i, month: month - 1 < 0 ? 11 : month - 1, year: month - 1 < 0 ? year - 1 : year, isCurrentMonth: false, isCurrentDay: false });
    }
    // Add days from the current month
    for (var i = 1; i <= daysInMonth; i++) {
        days.push({ day: i, month: month, year: year, isCurrentMonth: true, isCurrentDay: currentMonth === month && currentDay === i && currentYear === year });
    }
    // Add days from the next month
    var nextMonthDay = 1;
    while (days.length % 14 !== 0) {
        days.push({ day: nextMonthDay, month: month + 1 > 11 ? 0 : month + 1, year: month + 1 > 11 ? year + 1 : year, isCurrentMonth: false, isCurrentDay: false });
        nextMonthDay++;
    }
    var _b = useState(null), popoverData = _b[0], setPopoverData = _b[1];
    var _c = useState([]), dayEvents = _c[0], setDayEvents = _c[1];
    var handleViewDayEvents = function (day) { return __awaiter(void 0, void 0, void 0, function () {
        var startDate, startDateStr, endDateStr, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setPopoverData({
                        day: day,
                        isLoading: true
                    });
                    setDayEvents([]);
                    startDate = parse("".concat(day.year, "-").concat((day.month + 1).toString().padStart(2, '0'), "-").concat(day.day.toString().padStart(2, '0')), 'yyyy-MM-dd', new Date());
                    startDateStr = format(startDate, 'yyyy-MM-dd');
                    endDateStr = format(addDays(startDate, 1), 'yyyy-MM-dd');
                    return [4 /*yield*/, fetchCalendarEvents({
                            office_id: officeId,
                            start_date: startDateStr,
                            end_date: endDateStr,
                            per_page: 100
                        })];
                case 1:
                    response = _a.sent();
                    setPopoverData({
                        day: day,
                        isLoading: false,
                    });
                    setDayEvents(response.result.events[startDateStr] || []);
                    return [2 /*return*/];
            }
        });
    }); };
    var filteredDayEvents = useMemo(function () {
        var _a;
        return (_a = dayEvents
            .filter(function (event) { return eventTypes.map(function (type) { return type.id; }).includes(event.event_type); })
            .sort(function (eventA, eventB) { return eventA.start_at.localeCompare(eventB.start_at); })) !== null && _a !== void 0 ? _a : [];
    }, [dayEvents, eventTypes]);
    var getEventModalPosition = function (month) {
        if ([0, 1, 4, 5].includes(month))
            return 'right-top';
        if ([2, 3, 6, 7].includes(month))
            return 'left-top';
        if ([8, 9].includes(month))
            return 'right-bottom';
        if ([10, 11].includes(month))
            return 'left-bottom';
    };
    var isPastEvent = function (eventDate, eventStartAt) {
        var currentDate = new Date();
        var currentTime = "".concat(currentDate.getHours().toString().padStart(2, '0'), ":").concat(currentDate.getMinutes().toString().padStart(2, '0'), ":").concat(currentDate.getSeconds().toString().padStart(2, '0'));
        if (eventDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)) {
            return true;
        }
        return eventStartAt < currentTime;
    };
    return (_jsxs("div", __assign({ className: "border-b border-r border-gray-100 p-4" }, { children: [_jsx("div", __assign({ className: "text-center text-base text-gray-900 my-3" }, { children: MONTHS[month] })), _jsxs("div", __assign({ className: "grid grid-cols-7 my-3" }, { children: [WEEK_DAYS.map(function (weekDay, weekDayIndex) { return (_jsx("div", __assign({ className: "flex items-center justify-center w-[38px] h-[16px] text-sm text-gray-500 mb-2" }, { children: weekDay }), weekDayIndex)); }), days.map(function (day, dayIndex) {
                        var _a, _b, _c;
                        return (_jsx(Popover, __assign({ open: ((_a = popoverData === null || popoverData === void 0 ? void 0 : popoverData.day) === null || _a === void 0 ? void 0 : _a.day) === day.day && ((_b = popoverData === null || popoverData === void 0 ? void 0 : popoverData.day) === null || _b === void 0 ? void 0 : _b.month) === day.month && ((_c = popoverData === null || popoverData === void 0 ? void 0 : popoverData.day) === null || _c === void 0 ? void 0 : _c.year) === day.year, eventModalPosition: getEventModalPosition(month), content: _jsx("div", __assign({ className: "p-3" }, { children: (popoverData === null || popoverData === void 0 ? void 0 : popoverData.isLoading) ? (_jsx("div", __assign({ className: "text-sm text-aro-gray" }, { children: "Loading" }))) : (_jsxs("div", { children: [_jsxs("div", __assign({ className: "w-8 m-auto" }, { children: [_jsx("div", __assign({ className: "text-xs font-medium text-center uppercase" }, { children: format(parse("".concat(day.year, "-").concat((day.month + 1).toString().padStart(2, '0'), "-").concat(day.day.toString().padStart(2, '0')), 'yyyy-MM-dd', new Date()), 'EEE') })), _jsx("div", __assign({ className: "bg-[#007AFF] rounded-lg text-white text-center" }, { children: day.day }))] })), _jsx("div", __assign({ className: "absolute right-1 top-3" }, { children: _jsx(AptiveIcon, { icon: "x", className: "w-[16px] h-[16px] stroke-[#3D3D3D] fill-none cursor-pointer", onClick: function () { return setPopoverData(null); } }) })), (filteredDayEvents.length > 0) ? (_jsx("ul", __assign({ className: "mt-3" }, { children: filteredDayEvents.map(function (event) {
                                                if (event.is_canceled === 'false')
                                                    return (_jsxs("li", __assign({ className: "flex mt-2" }, { children: [_jsx("div", { className: "w-[12px] h-[12px] rounded-[50%] mt-[2px]", style: { background: getCalendarEventTypeColors(event.event_type).dark } }), _jsx("div", __assign({ className: "ml-2" }, { children: _jsx("div", __assign({ className: "flex" }, { children: _jsxs("div", __assign({ className: "text-[11px] text-[#3D3D3D] whitespace-nowrap\n                                      ".concat(isPastEvent(parse("".concat(day.year, "-").concat((day.month + 1).toString().padStart(2, '0'), "-").concat(day.day.toString().padStart(2, '0')), 'yyyy-MM-dd', new Date()), event.start_at) ? 'line-through' : '', "\n                                    ") }, { children: [convert24HourTo12Hour(event.start_at), " ", _jsx("span", __assign({ className: "font-bold" }, { children: event.title }))] })) })) }))] }), event.id));
                                                else
                                                    return _jsx(_Fragment, {});
                                            }) }))) : (_jsx("div", __assign({ className: "whitespace-nowrap mt-3 text-sm text-aro-gray" }, { children: "No events" })))] })) })), onClickOutside: function () {
                                setPopoverData(null);
                            } }, { children: _jsx("div", __assign({ className: "flex items-center justify-center text-sm border-r border-b border-gray-100 h-[38px] hover:bg-gray-200 cursor-pointer ".concat(day.isCurrentMonth ? 'text-gray-900' : 'text-gray-500'), onClick: function () {
                                    handleViewDayEvents(day);
                                } }, { children: day.isCurrentDay ? (_jsx("div", __assign({ className: "bg-[#007AFF] text-white w-full h-[32px] rounded-lg flex items-center justify-center" }, { children: day.day }))) : (_jsx("div", { children: day.day })) })) }), dayIndex));
                    })] }))] })));
};
var CalendarYearView = function (_a) {
    var officeId = _a.officeId, year = _a.year, eventTypes = _a.eventTypes;
    return (_jsx("div", __assign({ className: "border-[#CBCBCB80] flex-auto overflow-auto" }, { children: _jsx("div", __assign({ className: "grid grid-cols-4" }, { children: Array.from({ length: 12 }, function (_, i) { return i + 1; }).map(function (_, monthIndex) { return (_jsx(Month, { officeId: officeId, month: monthIndex, year: year, eventTypes: eventTypes }, monthIndex)); }) })) })));
};
export default CalendarYearView;
