import { createConsoleLogger, ConfigCatProvider, LogLevel } from 'configcat-react';
import PropTypes from 'prop-types';

const ConfigCatWrapper = ({ children }) => {
  const logger = createConsoleLogger(LogLevel.Off);

  return (
    <ConfigCatProvider sdkKey={process.env.REACT_APP_CONFIG_CAT_SDK_KEY} options={{ logger, pollIntervalSeconds: 300 }}>
      {children}
    </ConfigCatProvider>
  );
};

ConfigCatWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConfigCatWrapper;
