import { createReducer } from '@/redux/root';
import {
  editHistoryNameSpace,
  filterEditHistoriesAsync,
  requestHistoryOptionsAsync,
} from './';
import { NAME } from './selectors';

// eg. 'App\Models\PlanBuilder\AreaPlan' => 'Area Plan'
export const modelFormat = (model) => {
  if (!model) {
    return '';
  }
  // split the string by '\'
  const parts = model.split('\\');

  // get the last part of the string and separate the words by space
  return parts[parts.length - 1].replace(/([A-Z])/g, ' $1').trim();
};

// mass_update -> Mass Update
export const actionFormat = (action) => {
  if (!action) {
    return '';
  }

  return action.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
};

const FilterEditHistories = ({ current_page, data, per_page, total }) => {
  return {
    currentPage: current_page,
    perPage: per_page,
    total,
    data,
  };
};

const optionMap = (options, format) => {
  return [
    {
      value: '',
      name: 'All',
    },
    ...options
      .filter((option) => option !== null)
      .sort()
      .map((option) => ({
        value: option,
        name: format(option),
      })),
  ];
};

export const userTypes = [
  {
    value: '',
    name: 'All',
  },
  {
    value: 'App\\Models\\AlteraProduction\\EcosureUser',
    name: 'User',
  },
  {
    value: 'App\\Models\\User',
    name: 'API',
  },
];

const Options = ({ model_types, actions }) => {
  return {
    model_types: optionMap(model_types, modelFormat),
    actions: optionMap(actions, actionFormat),
    editor_types: userTypes,
  };
};

const editHistoriesInitialState = {
  editHistories: FilterEditHistories({
    current_page: 1,
    data: [],
    per_page: 10,
    total: 0,
  }),
  options: Options({
    model_types: [],
    actions: [],
    editor_types: [],
  }),
};

export const editHistoriesReducer = {
  [NAME]: createReducer(editHistoryNameSpace, editHistoriesInitialState, {
    [filterEditHistoriesAsync.success]: ({ state, action: { payload } }) => {
      state.editHistories = FilterEditHistories(payload);
    },
    [requestHistoryOptionsAsync.success]: ({ state, action: { payload } }) => {
      state.options = Options(payload);
    },
  }),
};
