import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DropdownButton } from '@/modules/Housing/components/common';

const SeasonFilter = ({
  options,
  setRecruitingSeasonId,
}) => {
  const [selectedSeason, setSelectedSeason] = useState('');
  const [seasonOptions, setSeasonOptions] = useState([]);

  useEffect(() => {
    options.map((option) => {
      if (option.is_current) {
        setSelectedSeason(option.label);
        setRecruitingSeasonId(option.value);
      }
    });
  }, [options, setRecruitingSeasonId]);

  useEffect(() => {
    const tempOptions = options.map((option) => {
      return {
        ...option,
        name: option.label,
        onClick: () => setSelectedSeason(option.value),
      };
    });
    setSeasonOptions(tempOptions);
  }, [options]);

  const onFilterChange = (option) => {
    setRecruitingSeasonId(option.value);
    setSelectedSeason(option.name);
  };

  return (
    <DropdownButton
      options={seasonOptions}
      label={selectedSeason}
      onChange={onFilterChange}
    />
  );
};

SeasonFilter.propTypes = {
  options: PropTypes.array,
  setRecruitingSeasonId: PropTypes.func,
};

export default SeasonFilter;
