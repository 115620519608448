var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ChecklistForm from './ChecklistForm';
import { Modal } from '@aptive-env/storybook';
var ChecklistModal = function (_a) {
    var isOpen = _a.isOpen, checklist = _a.checklist, checklistAttachments = _a.checklistAttachments, states = _a.states, onCancel = _a.onCancel, onSubmit = _a.onSubmit;
    return (_jsx(Modal, __assign({ open: isOpen, onClickOutside: onCancel }, { children: _jsx(ChecklistForm, { checklist: checklist, checklistAttachments: checklistAttachments, states: states, onCancel: onCancel, onSubmit: onSubmit }) })));
};
export default ChecklistModal;
