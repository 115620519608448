import { CustomFormElement } from '@/components/common/Form';
import useSearchParamFilter from './useSearchParamFilter';
import { useSelector } from 'react-redux';
import { plansSelector } from '@/modules/AdminTools/redux/planBuilder/plans';
import { useMemo } from 'react';

const toOptions = (arr) =>
  arr.map(({ id, name }) => ({
    name: `${id} - ${name}`,
    value: id,
  }));

const PlanFilter = () => {
  const { value, setSearchValue } = useSearchParamFilter('plan_id');
  const plans = useSelector(plansSelector);

  const planOptions = useMemo(
    () => [{ name: 'All', value: '' }, ...toOptions(plans)],
    [plans]
  );

  return (
    <div className="flex flex-row space-x-4">
      <div className="w-1/2">
        <label htmlFor="plan_id">Related Plan</label>
        <CustomFormElement
          type="select"
          selectOptions={planOptions}
          value={value}
          onChange={setSearchValue}
          id="plan_id_select"
        />
      </div>
      <div className="w-1/2">
        <label htmlFor="plan_id">Related Plan Id</label>
        <CustomFormElement
          type="text"
          value={value}
          onChange={setSearchValue}
          id="plan_id"
        />
      </div>
    </div>
  );
};

export default PlanFilter;
