// TODO: Refactor functionality added by these two commits:
//       bb0977659eb2fe9c389ad3958297fd843cd7fa21
//       3dcf6ebaf4b62e5d4b59a1f95dba140e76287d17
//       Remove changes made in these two commits from the TableBody.
//       It aimed to be a component that provides structure only rather than behavior!

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { normalizeRows, Props, TableRow, TableCell } from '.';
import { useState } from 'react';

const TableBody = ({
  rows,
  data,
  onSelect,
  classNames,
  hasHoverAction,
  isStickyHeader,
  ...props
}) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const handleMouseEnter = (index) => {
    if (hasHoverAction) {
      setHoveredRowIndex(index);
    }
  };

  const handleMouseLeave = () => {
    if (hasHoverAction) {
      setHoveredRowIndex(null);
    }
  };

  return (
    <tbody {...new Props(props).withClassName('divide-y', isStickyHeader ? 'overflow-y-auto' : '')}>
      {normalizeRows(rows).map(({ cells, isHeaderRow, ...props }, index) => (
        <TableRow
          key={uuidv4()}
          {...(
            new Props(props)
              .withClassName('text-gray-600 text-sm')
              .withClassName(isHeaderRow ? '' : classNames)
          )}
          onClick={onSelect ? () => onSelect(data?.[index]) : undefined}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          {cells.map(({ ...props }) => (
            <TableCell
              key={uuidv4()}
              {...new Props(props).withClassName(isStickyHeader ? 'border-b' : '')}
              isHovered={hoveredRowIndex === index}
            />
          ))}
        </TableRow>
      ))}
    </tbody>
  );
};

TableBody.propTypes = {
  isStickyHeader: PropTypes.bool,
  data: PropTypes.array,
  onSelect: PropTypes.func,
  classNames: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  ).isRequired,
  hasHoverAction: PropTypes.bool,
};

export default TableBody;
