import { RecruiterProgressTableHeader } from './RecruiterProgressTableHeader';
import { RecruiterProgressTableBody } from './RecruiterProgressTableBody';

const RecruitProgressTable = ({ offset, limit, recruitersData }) => {
  return (
    <>
      {recruitersData?.length > 0 ? (
        <div className="my-4 overflow-x-auto rounded md:h-40vh">
          <div className="w-full flex flex-col max-h-[900px] align-middle">
            <div className="relative w-full border-b border-gray-200 shadow">
              <table className="h-full divide-y divide-gray-200 relative w-full border-separate border-spacing-0">
                <RecruiterProgressTableHeader />
                {recruitersData?.length > 0 && (
                  <RecruiterProgressTableBody
                    data={recruitersData}
                    offset={offset}
                    limit={limit}
                  />
                )}
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center w-full bg-white">
          <span className="py-6">Data not found</span>
        </div>
      )}
    </>
  );
};

export default RecruitProgressTable;
