export const apartmentSetupConstants = {
  COMPLEX_TYPE_NAME: 'type',
  COMPLEX_NAME: 'name',
  DEALER_NAME: 'dealer_id',
  EMAIL_NAME: 'email',
  PHONE_NAME: 'phone',
  STREET_ADDRESS_NAME: 'street',
  CITY_NAME: 'city',
  STATE_NAME: 'state',
  ZIP_NAME: 'zip',
  CONTACT_PERSON_NAME : 'contact',
  TEAMS_NAME: 'team_ids',
  COMPLEXES_NAME: 'complex_ids',
  PARTNERSHIP_NAME: 'partnership_id',
  PARTNERSHIPS_NAME: 'partnership_ids',

  COMPLEX_TYPE_LABEL: 'Complex Type',
  COMPLEX_LABEL: 'Complex Name',
  DEALER_LABEL: 'Dealer',
  EMAIL_LABEL: 'Email Address',
  PHONE_LABEL: 'Phone Number',
  STREET_ADDRESS_LABEL: 'Street Address',
  CITY_LABEL: 'City',
  STATE_LABEL: 'State',
  ZIP_LABEL: 'Zip',
  CONTACT_PERSON_LABEL: 'Contact Person',
  TEAMS_LABEL: 'Teams',
  PARTNERSHIP_LABEL: 'Partnership',

  APARTMENT_SEARCH_NAME: 'apartmentSearch',
  VIEW_BY_COMPLEX: 'View by Complex',
  VIEW_BY_TEAM: 'View by Team',
};
