import { createAsyncAction } from '@/redux/root';

import { nameSpace } from '../nameSpace';

export const areaPlansNameSpace = `${nameSpace}/areaPlans`;

export const requestAreaPlansAsync = createAsyncAction(
  `${areaPlansNameSpace}/REQUEST_AREA_PLANS`,
);

export const updateAreaPlanAsync = createAsyncAction(
  `${areaPlansNameSpace}/UPDATE_AREA_PLAN`,
);

export const createAreaPlanAsync = createAsyncAction(
  `${areaPlansNameSpace}/CREATE_AREA_PLAN`,
);

export const removeAreaPlanAsync = createAsyncAction(
  `${areaPlansNameSpace}/REMOVE_AREA_PLAN`,
);
