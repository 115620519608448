import PlanSelect from './PlanSelect';
import EditPlan from './EditPlan';
import UseToPlanPrice from './UseToPlanPrice';
import EditUseToPlan from './EditUseToPlan';
import { planUpgradePathConstants } from '@/modules/AdminTools/lib/constants';

const {
  UPGRADE_TO,
  UPGRADE_TO_LABEL,

  UPGRADE_FROM,
  UPGRADE_FROM_LABEL,

  LOW_MONTHLY_MIN_DISCOUNT,
  LOW_MONTHLY_MIN_DISCOUNT_LABEL,

  HIGH_MONTHLY_MIN_DISCOUNT,
  HIGH_MONTHLY_MIN_DISCOUNT_LABEL,

  LOW_RECURRING_MIN_DISCOUNT,
  LOW_RECURRING_MIN_DISCOUNT_LABEL,

  HIGH_RECURRING_MIN_DISCOUNT,
  HIGH_RECURRING_MIN_DISCOUNT_LABEL,

  SHOULD_COMPARE_TO_PLAN_MIN_PRICE,
  SHOULD_COMPARE_TO_PLAN_MIN_PRICE_LABEL,
} = planUpgradePathConstants;

export const fields = [
  {
    label: <div className="min-w-64">{UPGRADE_FROM_LABEL}</div>,
    name: UPGRADE_FROM,
    CreateRender: () => (
      <PlanSelect
        planId={UPGRADE_FROM}
        other={UPGRADE_TO}
        label={UPGRADE_FROM_LABEL}
      />
    ),
    Edit: EditPlan,
    other: UPGRADE_TO,
  },
  {
    label: <div className="min-w-64">{UPGRADE_TO_LABEL}</div>,
    name: UPGRADE_TO,
    CreateRender: () => (
      <PlanSelect
        planId={UPGRADE_TO}
        other={UPGRADE_FROM}
        label={UPGRADE_TO_LABEL}
      />
    ),
    Edit: EditPlan,
    other: UPGRADE_FROM,
  },
  {
    label: LOW_MONTHLY_MIN_DISCOUNT_LABEL,
    name: LOW_MONTHLY_MIN_DISCOUNT,
    type: 'number',
    step: 0.01,
    required: true,
    min: 0,
  },
  {
    label: HIGH_MONTHLY_MIN_DISCOUNT_LABEL,
    name: HIGH_MONTHLY_MIN_DISCOUNT,
    type: 'number',
    step: 0.01,
    required: true,
    min: 0,
  },
  {
    label: LOW_RECURRING_MIN_DISCOUNT_LABEL,
    name: LOW_RECURRING_MIN_DISCOUNT,
    type: 'number',
    step: 0.01,
    required: true,
    min: 0,
  },
  {
    label: HIGH_RECURRING_MIN_DISCOUNT_LABEL,
    name: HIGH_RECURRING_MIN_DISCOUNT,
    type: 'number',
    step: 0.01,
    required: true,
    min: 0,
  },
  {
    label: SHOULD_COMPARE_TO_PLAN_MIN_PRICE_LABEL,
    name: SHOULD_COMPARE_TO_PLAN_MIN_PRICE,
    CreateRender: UseToPlanPrice,
    Edit: EditUseToPlan,
    sort: false,
  },
];
