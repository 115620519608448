var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { iconMap } from '.';
var Icon = function (_a) {
    var icon = _a.icon, _b = _a.id, id = _b === void 0 ? '' : _b, _c = _a.title, title = _c === void 0 ? '' : _c, _d = _a.className, className = _d === void 0 ? '' : _d, onClick = _a.onClick;
    var classes = classNames(className, 'flex items-center');
    return (_jsxs("span", __assign({ "data-for": id, className: classes, onClick: onClick }, { children: [_jsx("title", { children: title }), iconMap[icon]] })));
};
export default Icon;
