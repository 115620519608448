import { createAction, createAsyncAction } from '@/redux/root';

import { nameSpace } from '../nameSpace';

export const bedManagementNameSpace = `${nameSpace}/bedManagement`;

export const requestUnBeddedSummariesAsync = createAsyncAction(
  `${nameSpace}/GET_UN_BEDDED_SUMMARIES`,
);

export const resetUnBeddedSummariesAction = createAction(
  `${nameSpace}/RESET_UN_BEDDED_SUMMARIES`,
);

export const requestBedManagementAsync = createAsyncAction(
  `${nameSpace}/GET_BED_MANAGEMENT`,
);

export const unassignRepAsync = createAsyncAction(`${nameSpace}/UNASSIGN_REP`);

export const assignRepAsync = createAsyncAction(`${nameSpace}/ASSIGN_REP`);

export const exportBedManagementAsync = createAsyncAction(`${nameSpace}/EXPORT_BED_MANAGEMENT`);

export const requestBedManagementStatisticAsync = createAsyncAction(
  `${nameSpace}/GET_BED_MANAGEMENT_STATISTIC`
);
