var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Loader } from 'components/common';
import { mergeClassName } from 'lib';
import { forwardRef, useCallback, useMemo } from 'react';
import { components, default as BaseSelect } from 'react-select';
var Control = function (props) {
    var isFocused = props.isFocused, children = props.children, selectProps = props.selectProps, rest = __rest(props, ["isFocused", "children", "selectProps"]);
    var hasError = selectProps.hasError;
    var className = mergeClassName('bg-white border rounded-md text-sm', { 'ring-1': isFocused }, { 'text-gray-500': !hasError }, { 'text-red-300': hasError }, { 'border-gray-300': !isFocused && !hasError }, { 'border-red-300': !isFocused && hasError }, { 'ring-primary-300 border-primary-300': isFocused && !hasError }, { 'ring-red-500 border-red-500': isFocused && hasError });
    return (_jsx(components.Control, __assign({}, rest, { isFocused: isFocused, className: className, selectProps: selectProps }, { children: children })));
};
var Input = function (props) {
    return _jsx(components.Input, __assign({}, props, { inputClassName: "focus:ring-0" }));
};
var LoadingIndicator = function () { return _jsx(Loader, {}); };
var IndicatorSeparator = function () { return null; };
var Menu = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (_jsx(components.Menu, __assign({}, props, { className: "border border-gray-300 text-sm text-gray-600" }, { children: children })));
};
var MenuList = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (_jsx(components.MenuList, __assign({}, props, { className: "divide-y divide-gray-100" }, { children: children })));
};
var Option = function (props) {
    var isSelected = props.isSelected, isFocused = props.isFocused, children = props.children, rest = __rest(props, ["isSelected", "isFocused", "children"]);
    var className = mergeClassName('hover:bg-gray-50', { 'bg-gray-50': isSelected }, { 'text-primary-300': isFocused || isSelected });
    return (_jsx(components.Option, __assign({}, rest, { className: className, isSelected: isSelected, isFocused: isFocused }, { children: children })));
};
var Select = function (_a) {
    var inputId = _a.inputId, name = _a.name, value = _a.value, options = _a.options, defaultOption = _a.defaultOption, onChange = _a.onChange, isLoading = _a.isLoading, className = _a.className, styles = _a.styles, isMulti = _a.isMulti, closeMenuOnSelect = _a.closeMenuOnSelect, required = _a.required, hasError = _a.hasError, onInputChange = _a.onInputChange, filterOption = _a.filterOption, defaultInputValue = _a.defaultInputValue, placeholder = _a.placeholder, disabled = _a.disabled, ref = _a.ref;
    var onChangeWrapper = useCallback(function (newValue) {
        if (Array.isArray(newValue)) {
            newValue = newValue.map(function (_a) {
                var value = _a.value;
                return value;
            });
        }
        else {
            newValue = newValue === null || newValue === void 0 ? void 0 : newValue.value;
        }
        onChange({
            target: {
                name: name,
                value: newValue,
            },
        });
    }, [name, onChange]);
    var defaultStyles = useMemo(function () { return ({
        control: function (provided) { return (__assign(__assign({}, provided), { '&:hover': null, borderColor: null, borderRadius: null, transition: null })); },
        valueContainer: function (provided) { return (__assign(__assign({}, provided), { padding: '2px 12px' })); },
        singleValue: function (provided) { return (__assign(__assign({}, provided), { marginLeft: null, marginRight: null, color: null })); },
        input: function (provided) { return (__assign(__assign({}, provided), { margin: null, color: null })); },
        dropdownIndicator: function (provided) { return (__assign(__assign({}, provided), { ':hover': null, color: null })); },
        menu: function (provided) { return (__assign(__assign({}, provided), { overflow: 'hidden', borderRadius: '8px', boxShadow: null, marginTop: '4px', marginBottom: '4px' })); },
        menuList: function (provided) { return (__assign(__assign({}, provided), { maxHeight: 4 + 7 * 41 + 4 })); },
        menuPortal: function (provided) { return (__assign(__assign({}, provided), { zIndex: 9999 })); },
        option: function (provided) { return (__assign(__assign({}, provided), { ':active': null, padding: '10px 16px', backgroundColor: null, color: null, cursor: 'pointer' })); },
        loadingMessage: function (provided) { return (__assign(__assign({}, provided), { padding: '10px 16px', color: null })); },
        noOptionsMessage: function (provided) { return (__assign(__assign({}, provided), { padding: '10px 16px', color: null })); },
    }); }, []);
    var baseValue = useMemo(function () {
        if (isMulti) {
            return options.filter(function (option) { return value === null || value === void 0 ? void 0 : value.includes(option.value); });
        }
        return options.find(function (option) { return option.value === value; }) || defaultOption;
    }, [defaultOption, isMulti, options, value]);
    return (_jsx(BaseSelect, __assign({ inputId: inputId, name: name, value: baseValue, options: options, onChange: onChangeWrapper, isLoading: isLoading, className: className, styles: __assign(__assign({}, defaultStyles), styles), components: {
            Control: Control,
            Input: Input,
            LoadingIndicator: LoadingIndicator,
            IndicatorSeparator: IndicatorSeparator,
            Menu: Menu,
            MenuList: MenuList,
            Option: Option,
        }, menuPortalTarget: document.body, isMulti: isMulti, closeMenuOnSelect: closeMenuOnSelect, required: required, placeholder: placeholder, isDisabled: disabled, onInputChange: onInputChange, filterOption: filterOption }, (defaultInputValue && { defaultInputValue: defaultInputValue }), (ref && { ref: ref }), { menuPosition: 'fixed' })));
};
Select.defaultProps = {
    isLoading: false,
};
export default forwardRef(function (props, ref) { return _jsx(Select, __assign({}, props, { ref: ref })); });
