var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
var CustomNumber = function (_a) {
    var id = _a.id, name = _a.name, disabled = _a.disabled, formValue = _a.formValue, value = _a.value, hasError = _a.hasError, min = _a.min, max = _a.max, className = _a.className, onChange = _a.onChange, register = _a.register, childOrientation = _a.childOrientation, ariaDescribedBy = _a.ariaDescribedBy, placeholder = _a.placeholder, autoComplete = _a.autoComplete, baseClasses = _a.baseClasses, children = _a.children, onBlur = _a.onBlur, step = _a.step, required = _a.required;
    var classes = useMemo(function () {
        return classNames(className, 'shadow-sm block w-full sm:text-sm rounded-md [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield]', "".concat(hasError && baseClasses
            ? baseClasses === null || baseClasses === void 0 ? void 0 : baseClasses.errorClasses
            : baseClasses === null || baseClasses === void 0 ? void 0 : baseClasses.standardClasses));
    }, [hasError, className, baseClasses]);
    var handleWheel = function (event) {
        event.target.blur();
        setTimeout(function () { return event.target.focus(); }, 0);
    };
    return (_jsxs("div", __assign({ className: "flex" }, { children: [childOrientation === 'left' ? children : null, _jsx("input", __assign({}, (register && __assign({}, register(name))), { id: id, min: min, max: max, name: name, type: 'number', value: register ? formValue : value, className: classes }, (onChange && __assign({ onChange: onChange })), { disabled: disabled, placeholder: placeholder, "aria-describedby": ariaDescribedBy, "aria-invalid": hasError, autoComplete: autoComplete }, (onBlur && { onBlur: onBlur }), { step: step || 0.01, required: required, onWheel: handleWheel })), childOrientation === 'right' ? children : null] })));
};
export default CustomNumber;
