/**
 * Converts 12 hour string to 24 hour string.
 * @param time12h "HH:mm AM" or "HH:mm PM" (for example "09:20 PM")
 * @returns "HH:mm:ss" (for example "21:20:00")
 */
export function convert12HourTo24Hour(time12h) {
    // Extract the hours, minutes, and AM/PM from the input string
    var _a = time12h.split(' '), time = _a[0], period = _a[1];
    var _b = time.split(':'), hours = _b[0], minutes = _b[1];
    // Convert hours to 24-hour format
    var hours24 = parseInt(hours, 10);
    if (period.toUpperCase() === 'PM' && hours24 < 12) {
        hours24 += 12;
    }
    else if (period.toUpperCase() === 'AM' && hours24 === 12) {
        hours24 = 0;
    }
    // Format the result as "HH:mm:ss"
    return "".concat(hours24.toString().padStart(2, '0'), ":").concat(minutes.padStart(2, '0'), ":00");
}
/**
 * Converts 24 hour string to 12 hour string.
 * @param time24h "HH:mm:ss" (for example "21:20:00")
 * @returns "HH:mm AM/PM" (for example "09:20 PM")
 */
export function convert24HourTo12Hour(time24h) {
    // Extract the hours, minutes from the input string
    var _a = time24h.split(':'), hours = _a[0], minutes = _a[1];
    // Determine "AM" | "PM" & hours12
    var suffix = hours >= '12' ? 'PM' : 'AM';
    var hours12 = Number(hours) % 12 || 12;
    // Format the result as "HH:mm AM/PM"
    return "".concat(hours12.toString().padStart(2, '0'), ":").concat(minutes, " ").concat(suffix);
}
