import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import Fields from './Fields';
import Controls from './Controls';

const Form = ({ request, defaultValues }) => {
  const dispatch = useDispatch();

  const methods = useForm({ defaultValues });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => dispatch(request(data)))}>
        <div className="flex flex-row">
          <Fields />
          <div className="flex-grow" />
          <Controls />
        </div>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  defaultValues: PropTypes.any.isRequired,
  request: PropTypes.func.isRequired,
};

export default Form;
