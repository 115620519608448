import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

import Labeled from '@/modules/AdminTools/components/Labeled';
import { useGroupPlanName } from '../NameContext';
import AddPricing from './AddPricing';
import PricingsTable from './PricingsTable';

const Pricings = ({ LevelsHeader, required = true }) => {
  const groupPlanName = useGroupPlanName();
  let name = 'pricings';
  if (groupPlanName) {
    name = `${groupPlanName}.${name}`;
  }

  const pricings = useFieldArray({ name });

  const append = () => {
    const pricing = {
      plan_pricing_level_id: '',
    };

    pricings.append(pricing);
  };

  const removeConfirm = (index) => {
    if (window.confirm('Are you sure?')) {
      pricings.remove(index);
    }
  };

  return (
    <Labeled label={<LevelsHeader />}>
      <PricingsTable
        pricings={pricings.fields}
        remove={removeConfirm}
        required={required}
      />
      <AddPricing {...{ append }} />
    </Labeled>
  );
};

Pricings.propTypes = {
  LevelsHeader: PropTypes.elementType.isRequired,
  required: PropTypes.bool,
};

export default Pricings;
