import { createSelector } from 'reselect';

export const NAME = 'editHistories';

export const filterEditHistoriesSelector = createSelector(
  (state) => state[NAME],
  (editHistories) => editHistories.editHistories
);

export const historyOptionsSelector = createSelector(
  (state) => state[NAME],
  (editHistories) => editHistories.options
);
