import * as yup from 'yup';
import { onboardingConstants } from '@/lib/constants';
import {
  REQUIRED,
  MIN_2_CHARS,
  MAX_255_CHARS,
  PHONE_REGEX,
  PHONE_VALIDATION_MESSAGE,
} from '@/lib/validations';

const {
  REFERENCE_NAME,
  REFERENCE_RELATION,
  REFERENCE_PHONE_NUMBER,
} = onboardingConstants;

export const validationSchema = yup.object().shape({
  [REFERENCE_NAME]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
      return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
    })
    .matches(/.{2,}/, {
      excludeEmptyString: true,
      message: MIN_2_CHARS,
    })
    .max(255, MAX_255_CHARS)
    .nullable(),
  [REFERENCE_RELATION]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
      return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
    })
    .matches(/.{2,}/, {
      excludeEmptyString: true,
      message: MIN_2_CHARS,
    })
    .max(255, MAX_255_CHARS)
    .nullable(),
  [REFERENCE_PHONE_NUMBER]: yup
    .string()
    .matches(PHONE_REGEX, {
      excludeEmptyString: true,
      message: PHONE_VALIDATION_MESSAGE,
    })
    .required(REQUIRED)
    .nullable(),
});
