import { createReducer } from '@/redux/root';
import {
  groupPlansNameSpace,
  requestGroupPlansAsync,
} from './actions';
import { NAME } from './selectors';

export const groupPlansReducer = {
  [NAME]: createReducer(
    groupPlansNameSpace,
    {
      groupPlans: new Map(),
      groupPlansArray: [],
    },
    {
      [requestGroupPlansAsync.success]: ({ state, action: { payload } }) => {
        const groupPlans = new Map();

        if (Array.isArray(payload)) {
          payload.forEach((groupPlan) => groupPlans.set(groupPlan.id, groupPlan));
          state.groupPlansArray = payload;
        }

        state.groupPlans = groupPlans;
      },
    },
  ),
};
