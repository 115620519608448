import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import GroupPlansOverride from '../GroupPlansOverride';
import { planSelector } from '@/modules/AdminTools/redux/planBuilder/plans';
import { customGroupTypesSelector } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/custom-group-types';
import { useSearchParams } from 'react-router-dom';
import CustomGroups from './CustomGroups';
import { customGroupsSelector } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/custom-groups';

const CustomGroupOverride = () => {
  const customGroupTypes = useSelector(customGroupTypesSelector);
  const customGroups = useSelector(customGroupsSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const customGroupType = useMemo(() => {
    const groupType = searchParams.get('custom_group_type');

    if (groupType) {
      return groupType;
    }
    if (customGroupTypes.length > 0) {
      return customGroupTypes[0].name;
    }

    return null;
  }, [customGroupTypes, searchParams]);

  const setCustomGroupType = (groupType) => {
    if (groupType === null) {
      searchParams.delete('custom_group_type');
      setSearchParams(searchParams);

      return;
    }
    searchParams.set('custom_group_type', groupType);
    setSearchParams(searchParams);
  };

  const groupedGroups = useMemo(() => {
    if (!customGroups || !customGroupTypes) {
      return [];
    }

    const groupTypesById = customGroupTypes.reduce((acc, groupType) => {
      acc[groupType.id] = groupType.name;

      return acc;
    }, {} );

    return customGroups.reduce((acc, group) => {
      const groupType = groupTypesById[group.custom_group_type_id];
      if (!acc[groupType]) {
        acc[groupType] = [];
      }
      acc[groupType].push(group);

      return acc;
    }, {} );
  }, [customGroups, customGroupTypes]);

  const selectedGroups = useMemo(() => {
    if (!groupedGroups || !customGroupType) {
      return [];
    }

    return groupedGroups[customGroupType] || [];
  }, [groupedGroups, customGroupType]);

  const groupOptions = useMemo(() => {
    return selectedGroups.map(({ id, identifiers }) => ({
      label: `${id}: ${identifiers}`,
      value: id,
    }));
  }, [selectedGroups]);

  const customGroupId = useMemo(() => selectedGroups.map(({ id }) => id), [selectedGroups]);

  const plan = useSelector(planSelector);
  const groupPlans = useMemo(
    () => plan.group_plans.filter((groupPlan) => {
      return groupPlan.group_type === 'custom' && customGroupId.includes(groupPlan.group_id);
    }),
    [plan, customGroupId],
  );

  return (
    <>
      <CustomGroups {...{ customGroupType, setCustomGroupType, customGroupTypes }} />
      <GroupPlansOverride
        groupIdName={`${customGroupType}_group_id`}
        groupType="custom"
        groupOptions={groupOptions}
        groupPlans={groupPlans}
      />
    </>
  );
};

export default CustomGroupOverride;
