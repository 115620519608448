import { useSelector } from 'react-redux';

import FilterRequestDataTable from '@/modules/AdminTools/components/DataTable/FilterRequestDataTable';
import {
  actionFormat,
  filterEditHistoriesSelector,
  modelFormat,
} from '@/modules/AdminTools/redux/editHistory';
import { formatDateDisplay } from '@/lib/utils';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import PlanIdsValue from './Filters/PlanIdsValue';
import UserTypeValue from './Filters/UserTypeValue';
import UserNameValue from './Filters/UserNameValue';

const initialTable = [
  {
    label: 'Created',
    name: 'created_at',
    render: ({ created_at }) => formatDateDisplay(created_at),
  },
  {
    label: 'User Name',
    name: 'user_name',
    render: UserNameValue,
    sort: false,
  },
  {
    label: 'User Type',
    name: 'editor_type',
    render: UserTypeValue,
  },
  {
    label: 'User ID',
    name: 'editor_id',
  },
  {
    label: 'Object Type',
    name: 'model_type',
    format: modelFormat,
  },
  {
    label: 'Object ID',
    name: 'model_id',
  },
  {
    label: 'Action',
    name: 'action',
    format: actionFormat,
  },
  {
    label: 'Changes',
    name: 'changes',
    sort: false,
  },
];

const plans = {
  label: 'Plans',
  name: 'plan_ids',
  render: PlanIdsValue,
  sort: false,
};

const EditHistoriesTable = () => {
  const filterEditHistories = useSelector(filterEditHistoriesSelector);
  const [searchParams] = useSearchParams();

  const planId = searchParams.get('plan_id');
  const table = useMemo(() => {
    const newTable = [...initialTable];
    if (planId === null || planId === undefined || planId === '') {
      newTable.push(plans);
    }

    return newTable;
  }, [planId]);

  return <FilterRequestDataTable {...{ ...filterEditHistories, table }} />;
};

export default EditHistoriesTable;
