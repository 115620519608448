var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var PaperClipSVG = function (props) { return (_jsx("svg", __assign({ width: "21", height: "20", viewBox: "0 0 21 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { d: "M12.8573 5.83333L7.3691 11.3215C6.71822 11.9724 6.71822 13.0276 7.3691 13.6785C8.01997 14.3294 9.07525 14.3294 9.72612 13.6785L15.0713 8.19036C16.373 6.88861 16.373 4.77806 15.0713 3.47631C13.7695 2.17456 11.659 2.17456 10.3573 3.47631L5.01207 8.96447C3.05945 10.9171 3.05945 14.0829 5.01207 16.0355C6.9647 17.9882 10.1305 17.9882 12.0831 16.0355L17.2976 10.8333", stroke: "#9CA3AF", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }))); };
