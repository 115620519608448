import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatNumber } from '@/lib/utils';
import { Loader } from '@/components/common';

const BedManagementStatistic = ({
  index,
  label,
  color,
  leftStatistic,
  rightStatistic,
  isLoading,
  percentage,
}) => {
  const leftStatisticClasses = classNames({
    'font-medium': Number.isFinite(rightStatistic),
    'text-aptivelime': color === 'aptivelime',
    'text-black': !color,
  });

  return (
    <div
      key={index}
      className={classNames(
        'flex items-center p-4 bg-white rounded border border-gray-200 sm:text-sm',
        isLoading ? 'justify-center' : 'justify-between',
      )}
    >
      {isLoading ?
        <Loader /> :
        <>
          <span className="font-bold text-gray-700 text-sm lg:text-base">{label}</span>
          <span className="font-normal lg:text-xl">
            <span className={leftStatisticClasses}>
              {`${formatNumber(leftStatistic)}${percentage ? '%' : ''}`}
            </span>
            {Number.isFinite(rightStatistic) ? <span className='black'>{` / ${formatNumber(rightStatistic)}`}</span> : null}
          </span>
        </>
      }
    </div>
  );
};

BedManagementStatistic.propTypes = {
  label: PropTypes.string,
  leftStatistic: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  rightStatistic: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  percentage: PropTypes.bool,
};

export default BedManagementStatistic;
