import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import GroupPlansOverride from './GroupPlansOverride';
import { planSelector } from '@/modules/AdminTools/redux/planBuilder/plans';

const GroupTypeOverride = ({ groupIdName, groupType, groupOptions }) => {
  const plan = useSelector(planSelector);
  const groupPlans = useMemo(
    () => plan.group_plans.filter((groupPlan) => groupPlan.group_type === groupType),
    [plan, groupType],
  );

  return (
    <GroupPlansOverride {...{ groupIdName, groupType, groupOptions, groupPlans }} />
  );
};

GroupTypeOverride.propTypes = {
  groupIdName: PropTypes.string,
  groupType: PropTypes.string,
  groupOptions: PropTypes.array,
};

export default GroupTypeOverride;
