import PropTypes from 'prop-types';

import { NameContext } from './NameContext';
import Pricings from './Pricings';
// We're not implementing PercentageThreshold as of today for GroupPlan.
// import PercentageThreshold from './PercentageThreshold';
import BillingFrequencies from './BillingFrequencies';
import Services from './ProductPricings/Services';
import Addons from './ProductPricings/Addons';

const GroupPlan = ({ name, LevelsHeader, ServicesHeader, AddonsHeader }) => {
  return (
    <NameContext.Provider value={name}>
      <div className="flex flex-row space-x-4">
        {/* <PercentageThreshold /> */}
        <BillingFrequencies />
      </div>
      <hr className="mt-4" />
      <Pricings {...{ LevelsHeader }} />
      <hr className="mt-4" />
      <Services {...{ ServicesHeader }} />
      <hr className="mt-4" />
      <Addons {...{ AddonsHeader }} />
    </NameContext.Provider>
  );
};

GroupPlan.propTypes = {
  AddonsHeader: PropTypes.elementType.isRequired,
  LevelsHeader: PropTypes.elementType.isRequired,
  ServicesHeader: PropTypes.elementType.isRequired,
  name: PropTypes.string,
};

export default GroupPlan;
