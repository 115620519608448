import PropTypes from 'prop-types';
import { memo } from 'react';

const StepHeader = ({ title }) => {
  return (
    <div className="flex flex-col px-6 py-6 border-b border-gray-200">
      <h1 className="font-bold text-xl">{title}</h1>
    </div>
  );
};

StepHeader.propTypes = {
  title: PropTypes.string,
};

export default memo(StepHeader);
