import { trim } from 'lodash-es';

export const validateStringLength = (value, limit, limitType) => {
  let isValid = true;

  if (limitType === 'min') {
    if (value.length && trim(value).length < limit) {
      isValid = false;
    }
  } else if (limitType === 'max') {
    if (value.length && trim(value).length > limit) {
      isValid = false;
    }
  }

  return isValid;
};
