var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var TimePicker = React.forwardRef(function (params, ref) {
    var value = params.value, className = params.className, onChange = params.onChange, options = params.options, props = __rest(params, ["value", "className", "onChange", "options"]);
    return (_jsx("div", __assign({ ref: ref, className: className }, props, { children: options.map(function (time, index) { return (_jsx("div", __assign({ className: "h-[30px] px-[10px] flex items-center text-xs cursor-pointer hover:bg-[#0000000D] ".concat(time === value ? 'bg-[#0000000D]' : ''), onClick: function () { return onChange === null || onChange === void 0 ? void 0 : onChange(time); } }, { children: time }), index)); }) })));
});
export default TimePicker;
