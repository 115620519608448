export const housingConstants = {
  HOUSING: 'Housing',
  NO_DATA_TO_DISPLAY: 'No data to display',
  APARTMENT_SETUP_FEATURE_FLAG_NAME: 'isApartmentSetupEnabled',
  BED_MANAGEMENT_FEATURE_FLAG_NAME: 'isBedManagementEnabled',
  APARTMENT_EXPORT_FEATURE_FLAG_NAME: 'isApartmentExportEnabled',
  BED_MANAGEMENT_EXPORT_FEATURE_FLAG_NAME: 'isBedManagementExportEnabled',
  HOUSING_PROFILE_FEATURE_FLAG_NAME: 'isHousingProfileEnabled',
  BED_MANAGEMENT_STATISTIC_FEATURE_FLAG_NAME: 'isBedManagementStatisticEnabled',
  initialPage: 1,
  initialPageSize: 10,
  initialSearchText: '',
  seasonOptions: [
    { label: 'Regular season', value: 'regular_season' },
    { label: 'Pre season', value: 'pre_season' },
    { label: 'Post season', value: 'post_season' },
  ],
  initialSeasonPeriod: 'regular_season',
  FILTER_ACTION_NEEDED: 'Please filter by team or complex to view results.',

  FILTER_TYPE_LIST: 'list',
  FILTER_TYPE_DATE_RANGE: 'date_range',
  FILTER_TYPE_MULTI_LIST: 'multi_list',
  FILTER_TYPE_SEARCH: 'search',
};
