import EditCustomGroup from './EditCustomGroup';

const table = [
  {
    label: 'Name',
    sortBy: 'name',
    render: ({ customGroup }) => <EditCustomGroup {...{ customGroup }} />,
  },
  {
    label: 'Priority',
    sortBy: 'priority',
    render: ({ customGroup }) => customGroup.priority,
  },
];

export default table;
