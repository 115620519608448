import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { CustomFormElement } from '@/components';
import { useGroupPlanName } from '../../NameContext';

const Price = ({ field, index, required }) => {
  const groupPlanName = useGroupPlanName();
  let name = `pricings[${index}].${field}`;
  if (groupPlanName) {
    name = `${groupPlanName}.${name}`;
  }

  const { register } = useFormContext();

  const planErrors = useSelector((state) => state?.errors?.errors?.updatePlan);

  let priceErrorName = `pricings.${index}.${field}`;
  if (groupPlanName) {
    priceErrorName = `${groupPlanName}.${name}`;
  }
  const priceErrors =
    planErrors?.[priceErrorName];

  return (
    <div className="w-24">
      <CustomFormElement
        type="number"
        label={''}
        name={name}
        id={name}
        register={register}
        required={required}
        error={priceErrors}
        min={0}
      />
    </div>
  );
};

Price.propTypes = {
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  required: PropTypes.bool,
};

export default Price;
