import PropTypes from 'prop-types';

const IconButton = ({
  icon: Icon,
  value,
  onClick,
  disabled,
}) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={`h-10 relative items-center px-2 rounded-md border border-gray-300 bg-white text-sm font-medium ${disabled ? 'text-gray-200 cursor-default' : 'text-gray-500 hover:bg-gray-50'}`}
  >
    <span className="sr-only">{value}</span>
    <Icon className="h-5 w-5" aria-hidden="true" />
  </button>
);

IconButton.propTypes = {
  icon: PropTypes.elementType,
  value: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default IconButton;
