import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Loader, SideDrawerWrapper, WizardProfileModal } from '@/components';
import { PersonalInfo } from './ProfileTabs/PersonalInfo';
import { HousingInfo } from './ProfileTabs/HousingInfo';
import { LicensingInfo } from './ProfileTabs/LicensingInfo';
import { dashboardConstants } from '@/lib';
import { bedManagementConstants, housingConstants } from '@/modules/Housing/lib/constants';
import {
  repsLoadingSelector,
} from '@/redux/loading';
import {
  requestRepAsContactAsync,
} from '@/redux/reps';
import {
  repProfileSelector,
  clearRepProfileStateAction,
} from '@/redux/onboarding';
import { removeAllErrorsAction } from '@/redux/errors';
import { FormProvider, useForm } from 'react-hook-form';
import {
  personalInfoValidationSchema,
  housingInfoValidationSchema,
  licensingInfoValidationSchema,
} from '@/modules/Housing/modules/BedManagement/forms/ValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  updateRepByIdAsync,
} from '@/redux/reps';
import { updateRepErrorsSelector } from '@/redux/errors';
import { ConfirmationModal } from '@/components';
import { isAdminSelector } from '@/redux/auth';
import ProfileHeader from './ProfileHeader';
import { bedManagementProfileDataAdapter } from '@/modules/Housing/modules/BedManagement/lib';

const {
  PERSONAL_INFO,
  LICENSING,
} = dashboardConstants;
const { HOUSING } = housingConstants;

const {
  QUIT_PROFILE_CONFIRMATION_BUTTON,
  RETURN_TO_PROFILE_BUTTON,

  QUIT_PROFILE_CONFIRMATION_TITLE,
  QUIT_PROFILE_CONFIRMATION_MESSAGE,
} = bedManagementConstants;

const Profile = (
  {
    onClose,
    isOpen,
    selectedRepUserId,
    rep,
    requestRep,
    repIsLoading,
    removeErrors,
    updateRep,
    isAdmin,
    clearRep,
    recruitingSeasonId,
  },
) => {
  const [selectedTab, setSelectedTab] = useState(PERSONAL_INFO);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [fullProfileOpen, setFullProfileOpen] = useState(false);

  const onConfirmationQuit = () => {
    setConfirmationOpen(false);
    onProfileClose();
  };

  const onConfirmationBack = () => {
    setConfirmationOpen(false);
  };

  const getValidationSchema = (selectedTab) => {
    switch (selectedTab) {
      case PERSONAL_INFO:
        return personalInfoValidationSchema;
      case HOUSING:
        return housingInfoValidationSchema;
      case LICENSING:
        return licensingInfoValidationSchema;
      default:
        return personalInfoValidationSchema;
    }
  };

  useEffect(() => {
    if (selectedRepUserId && isOpen) {
      requestRep({ userId: selectedRepUserId });
    }
  }, [
    selectedRepUserId,
    requestRep,
    isOpen,
  ]);

  const methods = useForm({
    defaultValues: {
      ...rep,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(getValidationSchema(selectedTab)),
    context: { isAdmin: true },
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    reset,
  } = methods;

  const handleSubmitForm = useCallback(() => {
    const sendingData = bedManagementProfileDataAdapter(getValues(), selectedRepUserId);
    updateRep({
      data: { ...sendingData },
      successCallback: onProfileClose,
    });
  }, [selectedRepUserId, updateRep, getValues]);

  const handleResetForm = useCallback(() => {
    reset({
      ...rep,
    });
  }, [
    reset,
    rep,
  ]);

  useEffect(() => {
    handleResetForm();
  }, [rep, handleResetForm]);

  const onChangeHandler = useCallback((event) => {
    const { name, value, } = event.target;

    setValue(name, value, { shouldValidate: true });

    setIsChanged(true);
  }, [setValue]);

  const onProfileClose = useCallback((withReload = true) => {
    onClose(withReload);
    setSelectedTab(PERSONAL_INFO);
    setIsChanged(false);
    handleResetForm();
    clearRep();
  }, [onClose, setSelectedTab, setIsChanged]);

  const renderTab = useCallback((tab) => {
    switch (tab) {
      case PERSONAL_INFO:
        return <PersonalInfo
          onChangeHandler={onChangeHandler}
        />;
      case HOUSING:
        return <HousingInfo
          onChangeHandler={onChangeHandler}
          errors={errors}
        />;
      case LICENSING:
        return <LicensingInfo
          onChangeHandler={onChangeHandler}
          userId={selectedRepUserId}
        />;
      default:
        return <PersonalInfo
          onChangeHandler={onChangeHandler}
        />;
    }
  }, [rep, onChangeHandler]);

  const updateSelectedTab = async (selectedTab) => {
    const isValid = await trigger();
    if (isValid) {
      setSelectedTab(selectedTab);
      removeErrors();
    }
  };

  const onCancelClick = () => {
    if (isChanged) {
      setConfirmationOpen(true);
    } else {
      onProfileClose();
    }
  };

  const tabItems = [
    PERSONAL_INFO,
    HOUSING,
    LICENSING,
  ];

  const renderTabButtons = () => {
    return (
      <div className="border-b border-gray-200 pt-4">
        <div className="px-6">
          <nav className="flex -mb-px space-x-6">
            {
              tabItems.map((item, index) => {
                return (
                  <button
                    key={index}
                    onClick={() => updateSelectedTab(item)}
                    className={`${selectedTab === item
                      ? 'border-aptivegreen text-black font-bold border-b-4'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 font-medium border-b-2'
                      } whitespace-nowrap pb-4 px-1 text-sm`}
                  >
                    {item}
                  </button>
                );
              })
            }
          </nav>
        </div>
      </div>
    );
  }

  const openFullProfile = () => {
    setFullProfileOpen(true);
    onProfileClose(false);
  }

  const onFullProfileClose = () => {
    onProfileClose();
    setFullProfileOpen(false);
  }

  return (
    <>
      <SideDrawerWrapper isOpened={isOpen} onCloseModal={onCancelClick} large>
        <ProfileHeader
          rep={rep}
          repIsLoading={repIsLoading}
          openFullProfile={openFullProfile}
        />
        {renderTabButtons()}
        <div className="flex-1 px-6 pt-5 pb-24 border-b border-gray-200 sm:px-6">
          <FormProvider {...methods}>
            <form noValidate className="w-full" onSubmit={handleSubmit(handleSubmitForm)}>
              {
                rep && repIsLoading ? <Loader /> : (<>
                  {renderTab(selectedTab)}
                  <div className="max-w-xl w-screen h-20 bg-white absolute right-0 bottom-0 border border-t border-gray-200">
                    <div className="sm:col-span-6 flex justify-end absolute right-2 bottom-2">
                      <button
                        onClick={onCancelClick}
                        type="button"
                        className="border border-gray-200 inline-flex justify-center mr-2 px-4 py-2 text-base bg-white font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen-500 sm:w-40 sm:text-sm"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={!isAdmin}
                        type="submit"
                        className="border border-gray-200 inline-flex justify-center mr-2 px-4 py-2 text-white bg-blue-600 font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen-500 sm:w-40 sm:text-sm"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </>)
              }
            </form>
          </FormProvider>
        </div>
        <ConfirmationModal
          isOpened={confirmationOpen}
          modalWidth="max-w-[592px] w-full"
          onCancel={onConfirmationQuit}
          onAction={onConfirmationBack}
          title={QUIT_PROFILE_CONFIRMATION_TITLE}
          message={QUIT_PROFILE_CONFIRMATION_MESSAGE}
          cancelLabel={QUIT_PROFILE_CONFIRMATION_BUTTON}
          confirmLabel={RETURN_TO_PROFILE_BUTTON}
          confirmButtonColor="aptiveblue"
        />
      </SideDrawerWrapper>
      <WizardProfileModal
        isOpen={fullProfileOpen}
        onClose={onFullProfileClose}
        isPersonalWizard={false}
        userId={selectedRepUserId}
        recruitingSeasonId={recruitingSeasonId}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    repIsLoading: repsLoadingSelector(state),
    rep: repProfileSelector(state),
    formErrors: updateRepErrorsSelector(state),
    isAdmin: isAdminSelector(state),
  };
};

const mapDispatchToProps = {
  requestRep: requestRepAsContactAsync.request,
  removeErrors: removeAllErrorsAction,
  updateRep: updateRepByIdAsync.request,
  clearRep: clearRepProfileStateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
