export const bedsHistoryConstants = {
  USER_TYPE_FILTER__LABEL: 'User type',
  USER_TYPE_FILTER__NAME: 'user_type',
  TEAM_FILTER__LABEL: 'Team',
  TEAM_FILTER__NAME: 'team_ids',
  SEASON_FILTER__LABEL: 'Season',
  SEASON_FILTER__NAME: 'season_period',
  COMPLEX_FILTER__LABEL: 'Complex',
  COMPLEX_FILTER__NAME: 'complex_ids',
  UNIT_FILTER__LABEL: 'Unit',
  UNIT_FILTER__NAME: 'apartment_ids',
  BED_TYPE_FILTER__LABEL: 'Bed type',
  BED_TYPE_FILTER__NAME: 'bed_type',
  MOVE_TYPE_FILTER__LABEL: 'Move type',
  MOVE_TYPE_FILTER__NAME: 'move_type',
  DATE_RANGE_FILTER__LABEL: 'Date range',
  VIEW_BY_USER: 'View by User',
  DATE_FROM_FILTER_NAME: 'date_from',
  DATE_TO_FILTER_NAME: 'date_to',
  DEFAULT_FILTER_LABEL: 'default',
};
