import { mergeClassName } from '@/lib';

/**
 * @deprecated No alternative
 */
export default class Props {

  constructor(props) {
    Object.keys(props).forEach((key) => {
      this[key] = props[key];
    });
  }

  withClassName(...classes) {
    return this.withProp('className', mergeClassName(...classes, this.className), true);
  }

  withProp(name, value, override = false) {
    this[name] = override ? value : this[name] || value;

    return this;
  }

}
