import { Table } from '@/components';
import {
  getComplexHeadRows,
  parseComplexRows,
} from '@/modules/Housing/modules/ApartmentSetup/lib';
import { apartmentSetupConstants } from '@/modules/Housing/lib';
import { SearchBar, FilterBadge } from '@/components/common';
import { useDraggable } from '@/modules/Housing/hooks';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@/modules/Housing/routing/apartmentSetup';
import { resetSelectedApartmentAction } from '@/modules/Housing/redux/apartment';
import { connect } from 'react-redux';
import { parseLabel } from '@/modules/Housing/modules/BedsHistory/lib';

const { APARTMENT_SEARCH_NAME, VIEW_BY_TEAM } =
  apartmentSetupConstants;

const ComplexesTable = ({
  // Own Props
  initialPage,
  pageSize,
  setPageSize,
  selectedPage,
  onPageChange,
  teamsStatistics,
  teamsStatisticsTotal,
  complexesStatistics,
  complexesStatisticsTotal,
  viewOption,
  isLoading,
  onEditClick,
  searchValue,
  setSearchValue,
  viewFilters,
  setViewFilters,
  selectedFilters,
  setSelectedFilters,
  onAddApartmentClick,
}) => {
  const { tableRef } = useDraggable(isLoading);

  const navigate = useNavigate();

  const { data: tableData, rows: tableRows } = parseComplexRows(
    viewOption === VIEW_BY_TEAM ? teamsStatistics : complexesStatistics,
    viewOption,
    onEditClick,
    onAddApartmentClick,
  );

  const handleSearchClick = ({ searchText }) => {
    setSearchValue(searchText);
  };

  const onDeleteViewFilter = (index) => {
    setViewFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
  };

  const onDeleteFilter = (filterName) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      delete newFilters[filterName];
      return newFilters;
    });
  };

  const handleClickTableRow = (value) => {
    if (value) {
      navigate(`${PATH}/${viewOption === VIEW_BY_TEAM ? 'team' : 'complex'}/${value.id}`);
    }
  };

  return (
    <>
      {!isLoading && (
        <div className="mt-4 border border-solid border-gray-300 rounded-t-lg p-2 border-b-0 bg-white self-stretch flex items-center gap-2">
          <SearchBar
            searchText={searchValue}
            inputName={APARTMENT_SEARCH_NAME}
            onSearchClick={handleSearchClick}
          />
          <div className="flex gap-2 justify-center items-center">
            {viewFilters.length > 0 &&
              viewFilters.map((filter, index) => (
                <FilterBadge
                  key={index}
                  text={`${filter.type.label}: ${filter.value[0].label} ${
                    filter.value.length > 1 ? '...' : ''
                  } `}
                  className="bg-blue-50 text-blue-600"
                  onDelete={() => onDeleteViewFilter(index)}
                />
              ))}
              {Object.keys(selectedFilters).length > 0 &&
              Object.keys(selectedFilters).map(
                (key, index) =>
                  selectedFilters[key] && (
                    <FilterBadge
                      key={index}
                      className="bg-blue-50 text-blue-600"
                      text={parseLabel(selectedFilters[key], key)}
                      onDelete={() => onDeleteFilter(key)}
                    />
                  )
              )}
          </div>
        </div>
      )}
      <Table
        ref={tableRef}
        loading={isLoading}
        thead={{ rows: getComplexHeadRows(viewOption) }}
        tbody={{
          rows: tableRows,
          hasHoverAction: true,
          classNames: 'hover:bg-gray-50 cursor-pointer',
          onSelect: handleClickTableRow,
          data: tableData,
        }}
        paginator={{
          pageSize,
          setPageSize,
          onPageChange,
          selectedPage,
          initialPage,
          rowCount:
            viewOption === VIEW_BY_TEAM
              ? teamsStatisticsTotal
              : complexesStatisticsTotal,
        }}
        wrapper={{
          className: 'overflow-y-visible rounded-t-none',
        }}
      />
    </>
  );
};

ComplexesTable.propTypes = {
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  selectedPage: PropTypes.number,
  complexes: PropTypes.array,
  complexesTotal: PropTypes.number,
  getComplexes: PropTypes.func,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
};

const mapDispatchToProps = {
  resetSelected: resetSelectedApartmentAction,
};

export default connect(null, mapDispatchToProps)(ComplexesTable);
