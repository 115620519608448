import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation, useRoutes, useSearchParams } from 'react-router-dom';
import routes from './routing/routes';
import { dashboardConstants } from '@/lib/constants';
import { isAuthenticatedSelector, userSelector, selectConfigCatUser } from '@/redux/auth';
import { requestNotificationsAsync } from '@/redux/notifications';
import { useRefreshToken } from '@/hooks';
import { useConfigCatClient } from 'configcat-react';
import { DataDogRum } from '@/components/common/DataDog';

const {
  SPT_MODULE_FEATURE_FLAG_NAME,
  ARO_MODULE_FEATURE_FLAG_NAME,
  ARO_PLANNING_PAGE_FEATURE_FLAG_NAME,
  LICENSING_MODULE_FEATURE_FLAG_NAME,
} = dashboardConstants;

export const App = ({ isAuthenticated, user, getNotifications }) => {
  useRefreshToken(isAuthenticated);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const redirectUrl = searchParams.get('redirectUrl');
  const configCatClient = useConfigCatClient();
  const configCatUser = useSelector(selectConfigCatUser);
  const [features, setFeatures] = useState({});

  const routing = useRoutes(routes(isAuthenticated, user, redirectUrl, features));

  useEffect(() => {
    if (isAuthenticated) {
      getNotifications();
    }
  }, [getNotifications, isAuthenticated, location.pathname]);

  useEffect(() => {
    configCatClient.setDefaultUser(configCatUser);
    configCatClient
      .getAllValuesAsync()
      .then((values) => {
        setFeatures(
          values
            .filter(
              ({ settingKey }) => [
                SPT_MODULE_FEATURE_FLAG_NAME,
                ARO_MODULE_FEATURE_FLAG_NAME,
                ARO_PLANNING_PAGE_FEATURE_FLAG_NAME,
                LICENSING_MODULE_FEATURE_FLAG_NAME,
              ].includes(settingKey),
            )
            .reduce(
              (acc, { settingKey, settingValue }) => {
                acc[settingKey] = Boolean(settingValue);

                return acc;
              },
              {},
            ),
        );
      });
  }, [
    configCatClient,
    configCatUser,
  ]);

  return (
    <>
      {routing}
      <DataDogRum />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  getNotifications: requestNotificationsAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
