import { createSelector } from 'reselect';

export const repsSlice = (state) => state?.reps;

export const myTreeSelector = createSelector(
  repsSlice,
  (state) => state?.myTree || [],
);

export const recruitersSelector = createSelector(
  repsSlice,
  (state) => state?.recruiters || [],
);

export const downlineSelector = createSelector(
  repsSlice,
  (state) => state?.downline || [],
);

export const repsWorkdayTasksSelector = createSelector(
  [
    repsSlice,
    (_state, userId) => userId,
  ],
  (state, userId) => state?.[`user_id_${userId}`]?.workdayTasks || [],
);

export const myTreePaginationFilteredCountSelector = createSelector(
  recruitersSelector,
  (state) => state?.total || 0,
);

export const recruitersDataSelector = createSelector(
  recruitersSelector,
  (state) => state?.data || [],
);

export const repSelector = createSelector(
  [
    repsSlice,
    (_state, repId) => repId,
  ],
  (state, repId) => state[repId] || {},
);

export const attachmentsSelector = createSelector(
  [
    repsSlice,
    (_state, userId) => userId,
  ],
  (state, userId) => state?.[userId ? `user_id_${userId}` : 'self_user']?.attachments,
);

export const recruitingSeasonSelector = createSelector(
  repsSlice,
  (state) => state?.recruitingSeason || null,
);

export const experienceOptionsSelector = createSelector(
  repsSlice,
  (state) => state?.experienceOptions || [],
);

export const experienceOptionSelector = createSelector(
  experienceOptionsSelector,
  (_state, id) => id,
  (state, id) => state.find((experience) => experience.id === id),
);

export const apartmentStatusesSelector = createSelector(
  repsSlice,
  (state) => state?.apartmentStatuses || [],
);

export const repsWorkdayTasksUpdateErrorSelector = createSelector(
  [
    repsSlice,
    (_state, userId) => userId,
  ],
  (state, userId) => state?.[`user_id_${userId}`]?.workdayTasksErrors,
);

export const attachmentsErrorSelector = createSelector(
  [
    repsSlice,
    (_state, userId) => userId,
  ],
  (state, userId) => state?.[userId ? `user_id_${userId}` : 'self_user']?.attachmentsErrors,
);

export const repStatusesSelector = createSelector(
  repsSlice,
  (state) => state?.repStatuses || [],
);

export const selectSalesPrograms = createSelector(
  repsSlice,
  (state) => state?.salesPrograms || [],
);
