import PropTypes from 'prop-types';
import { createSelector } from 'reselect';

import {
  createCustomGroupAsync,
  customGroupsSelector,
  removeCustomGroupAsync,
  updateCustomGroupAsync,
} from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/custom-groups';
import EditDataTable from '@/modules/AdminTools/components/EditDataTable';

const fields = [
  {
    label: 'identifiers',
    name: 'identifiers',
    type: 'text',
    required: true,
    sort: false,
  },
];

const sortOrders = ['id'];

const defaultValues = {
  'identifiers': '',
};

const getSearchField = ({ id }) => id;

const CustomGroups = ({ custom_group_type_id }) => {
  const selector = createSelector(
    customGroupsSelector,
    (customGroups) => customGroups.filter((customGroup) => (customGroup.custom_group_type_id === custom_group_type_id)),
  );

  const transformData = (data) => {
    const identifiers = data.identifiers.split(',');

    return {
      ...data,
      identifiers,
      custom_group_type_id,
    };
  };

  const updateAsync = {
    request: (data) => updateCustomGroupAsync.request(transformData(data)),
  };
  const createAsync = {
    request: (data) => createCustomGroupAsync.request(transformData(data)),
  };

  return (
    <EditDataTable
      {...{
        sortOrders,
        getSearchField,
        defaultValues,
        fields,
      }}
      updateAsync={updateAsync}
      removeAsync={removeCustomGroupAsync}
      createAsync={createAsync}
      selector={selector}
      searching={false}
    />
  );
};

CustomGroups.propTypes = {
  custom_group_type_id: PropTypes.number.isRequired,
};

export default CustomGroups;
