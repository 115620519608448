import { createReducer } from '@/redux/root';
import {
  bedManagementNameSpace,
  requestUnBeddedSummariesAsync,
  requestBedManagementAsync,
  exportBedManagementAsync,
  resetUnBeddedSummariesAction,
  requestBedManagementStatisticAsync,
} from './actions';
import { s2ab } from '@/lib/utils';
import { saveAs } from 'file-saver';

const initialState = {
  unBeddedSummaries: [],
  unBeddedTotal: 0,
  unBeddedLastPage: 1,
  bedManagementData: [],
  bedManagementTotal: 0,
  bedManagementStatistic: {},
};

const downloadBedManagementFile = (payload) => {
  const fileData = payload?.data?.attributes;
  const blob = new Blob([s2ab(atob(fileData?.file))], { type: '' });
  saveAs(blob, fileData?.file_name);
};

export const bedManagementReducer = createReducer(
  bedManagementNameSpace,
  initialState,
  {
    [requestUnBeddedSummariesAsync.success]: ({ state, action }) => {
      const { items, total, page, lastPage } = action.payload;
      state.unBeddedSummaries = page === 1
        ? items
        : state.unBeddedSummaries.concat(items);
      state.unBeddedTotal = total;
      state.unBeddedLastPage = lastPage;
    },
    [resetUnBeddedSummariesAction]: ({ state }) => {
      state.unBeddedSummaries = [];
      state.unBeddedTotal = 0;
      state.unBeddedLastPage = 1;
    },
    [requestBedManagementAsync.success]: ({ state, action }) => {
      const { items, total } = action.payload;
      state.bedManagementData = items;
      state.bedManagementTotal = total;
    },
    [exportBedManagementAsync.success]: ({ action: { payload } }) => {
      downloadBedManagementFile(payload);
    },

    [requestBedManagementStatisticAsync.success]: ({ state, action: { payload } }) => {
      const statistic = payload?.data?.attributes;
      state.bedManagementStatistic = {
        repsNeedingHousing: statistic?.pending_reps_count,
        totalReps: statistic?.total_reps_count,
        serviceProsNeedingHousing: statistic?.pending_service_pros_count,
        totalServicePros: statistic?.total_service_pros,
        availableRooms: statistic?.available_rooms_count,
        totalRooms: statistic?.total_rooms_count,
      };
    },
  },
);
