import { Colors } from 'constants/colors';
export function getCalendarEventTypeColors(eventTypeId) {
    if (eventTypeId === 'meeting') {
        return {
            dark: Colors.ARO_BLUE,
            light: Colors.ARO_LIGHT_BLUE,
        };
    }
    if (eventTypeId === 'office-event') {
        return {
            dark: Colors.ARO_RED,
            light: Colors.ARO_LIGHT_RED,
        };
    }
    if (eventTypeId === 'team-building') {
        return {
            dark: Colors.ARO_PURPLE,
            light: Colors.ARO_LIGHT_PURPLE,
        };
    }
    if (eventTypeId === 'training-event') {
        return {
            dark: Colors.ARO_GREEN,
            light: Colors.ARO_LIGHT_GREEN,
        };
    }
    if (eventTypeId === 'vehicle-maintenance') {
        return {
            dark: Colors.ARO_ORANGE,
            light: Colors.ARO_LIGHT_ORANGE,
        };
    }
    if (eventTypeId === 'other') {
        return {
            dark: Colors.ARO_PINK,
            light: Colors.ARO_LIGHT_PINK,
        };
    }
    return {
        dark: Colors.ARO_GRAY,
        light: Colors.ARO_LIGHT_GRAY,
    };
}
