var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Home, Route, Vehicle, ServicePros, RoutePolygons } from 'icons';
import styles from './index.module.scss';
export var MapLayersToggleButtons = function (_a) {
    var routeLayers = _a.routeLayers, onToggleRouteLayer = _a.onToggleRouteLayer;
    var options = [
        {
            key: 'homes',
            title: 'Homes',
            description: 'View the homes that are on the different routes',
        },
        {
            key: 'routes',
            title: 'Routes',
            description: 'View the actual driving route created by ARO',
        },
        {
            key: 'service-vehicles',
            title: 'Vehicles',
            description: 'Live location view of the Service Vehicle',
        },
        {
            key: 'service-pro',
            title: 'Service Pros',
            description: 'Overlay the Service Pro avatar on the route polygon',
        },
        {
            key: 'route-polygon',
            title: 'Routes Polygon',
            description: 'View the outside boundary of a route',
        },
    ];
    var isOptionSelected = useCallback(function (option) {
        return routeLayers.includes(option.key);
    }, [routeLayers]);
    return (_jsx("div", __assign({ className: "flex gap-3" }, { children: options.map(function (option) { return (_jsxs("button", __assign({ className: "h-[32px] flex items-center px-3 rounded-full cursor-pointer shadow-[0_1px_2px_0_#0000004D] ".concat(isOptionSelected(option) ? 'bg-blue-50' : 'bg-white'), onClick: function () { return onToggleRouteLayer(option.key); } }, { children: [_jsxs("div", __assign({ className: "mr-1 ".concat(isOptionSelected(option) ? styles.svg_path_stroke_black : styles.svg_path_stroke_white) }, { children: [option.key === 'homes' && _jsx(Home, {}), option.key === 'routes' && _jsx(Route, {}), option.key === 'service-vehicles' && _jsx(Vehicle, {}), option.key === 'service-pro' && _jsx(ServicePros, {}), option.key === 'route-polygon' && _jsx(RoutePolygons, {})] })), _jsx("div", __assign({ className: "text-[15px] font-light whitespace-nowrap ".concat(isOptionSelected(option) ? 'text-[#007AFF]' : 'text-gray-600') }, { children: option.title }))] }), option.key)); }) })));
};
