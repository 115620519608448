var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useEffect, useMemo } from 'react';
import FilePreview from './FilePreview';
import CustomErrorMessage from '../CustomErrorMessage';
import DropZone from './DropZone';
var CustomFile = function (_a) {
    var id = _a.id, attachmentLabel = _a.attachmentLabel, attachmentDescription = _a.attachmentDescription, acceptedFileTypes = _a.acceptedFileTypes, maxFileSize = _a.maxFileSize, maxFileCount = _a.maxFileCount, defaultFiles = _a.defaultFiles, onChange = _a.onChange, onPreviewFile = _a.onPreviewFile, onDownloadFile = _a.onDownloadFile;
    var _b = useState(defaultFiles !== null && defaultFiles !== void 0 ? defaultFiles : []), files = _b[0], setFiles = _b[1];
    var _c = useState(false), isDragging = _c[0], setIsDragging = _c[1];
    var _d = useState(false), fileTypeErrorMessage = _d[0], setFileTypeErrorMessage = _d[1];
    var _e = useState(false), fileSizeErrorMessage = _e[0], setFileSizeErrorMessage = _e[1];
    var remainingFileCount = useMemo(function () { return maxFileCount - files.length; }, [files.length, maxFileCount]);
    var handleDragEnter = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    }, []);
    var handleDragLeave = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    }, []);
    var handleDragOver = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    }, []);
    var handleDrop = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
        setFileTypeErrorMessage(false);
        setFileSizeErrorMessage(false);
        if (event.dataTransfer.files) {
            var files_1 = Array.from(event.dataTransfer.files).slice(0, remainingFileCount).filter(function (file) {
                if (!acceptedFileTypes.includes(file.type)) {
                    setFileTypeErrorMessage(true);
                    return false;
                }
                if (file.size > maxFileSize) {
                    setFileSizeErrorMessage(true);
                    return false;
                }
                return true;
            });
            setFiles(function (prevFiles) { return __spreadArray(__spreadArray([], prevFiles, true), files_1, true); });
        }
    }, [acceptedFileTypes, maxFileSize, remainingFileCount]);
    var handleFileUpload = function (uploadedFiles) {
        setFileTypeErrorMessage(false);
        setFileSizeErrorMessage(false);
        var files = Array.from(uploadedFiles).slice(0, remainingFileCount).filter(function (file) {
            if (!acceptedFileTypes.includes(file.type)) {
                setFileTypeErrorMessage(true);
                return false;
            }
            if (file.size > maxFileSize) {
                setFileSizeErrorMessage(true);
                return false;
            }
            return true;
        });
        setFiles(function (prevFiles) { return __spreadArray(__spreadArray([], prevFiles, true), files, true); });
    };
    var handleRemoveFile = function (file) {
        if (file.id) {
            setFiles(function (prevFiles) { return prevFiles.filter(function (prevFile) { return prevFile.id !== file.id; }); });
        }
        else {
            setFiles(function (prevFiles) { return prevFiles.filter(function (prevFile) { return prevFile.name !== file.name; }); });
        }
    };
    useEffect(function () {
        if (onChange) {
            onChange({
                target: {
                    name: id,
                    value: files
                }
            });
        }
    }, [files, id, onChange]);
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'flex flex-col gap-4' }, { children: [attachmentLabel && _jsx("div", __assign({ className: 'text-base font-semibold leading-5' }, { children: attachmentLabel })), files.length !== maxFileCount && (_jsxs(_Fragment, { children: [attachmentDescription && _jsx("div", __assign({ className: 'text-sm text-gray-500' }, { children: attachmentDescription })), _jsx(DropZone, { isDragging: isDragging, handleDragEnter: handleDragEnter, handleDragLeave: handleDragLeave, handleDragOver: handleDragOver, handleDrop: handleDrop, handleFileUpload: handleFileUpload, acceptedFileTypes: acceptedFileTypes, maxFileSize: maxFileSize }), fileTypeErrorMessage && (_jsx(CustomErrorMessage, { text: 'Unsupported file type. Please try again.', isicon: true })), fileSizeErrorMessage && (_jsx(CustomErrorMessage, { text: 'Unsupported file size. Please try again.', isicon: true }))] }))] })), files.length > 0 && (_jsx(FilePreview, { files: files, onRemove: handleRemoveFile, onPreview: onPreviewFile, onDownload: onDownloadFile }))] }));
};
export default CustomFile;
