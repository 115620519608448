export var ROUTING_COLORS = [
    {
        priority: 1,
        primary: '#66C2A4',
        secondary: '#111928'
    },
    {
        priority: 2,
        primary: '#8C96C6',
        secondary: '#111928'
    },
    {
        priority: 3,
        primary: '#74A9CF',
        secondary: '#111928'
    },
    {
        priority: 4,
        primary: '#FC8D59',
        secondary: '#111928'
    },
    {
        priority: 5,
        primary: '#78C679',
        secondary: '#111928'
    },
    {
        priority: 6,
        primary: '#DF65B0',
        secondary: '#111928'
    },
    {
        priority: 7,
        primary: '#7BCCC4',
        secondary: '#111928'
    },
    {
        priority: 8,
        primary: '#FE9929',
        secondary: '#111928'
    },
    {
        priority: 9,
        primary: '#41AE76',
        secondary: '#111928'
    },
    {
        priority: 10,
        primary: '#8C6BB1',
        secondary: '#111928'
    },
    {
        priority: 11,
        primary: '#3690C0',
        secondary: '#111928'
    },
    {
        priority: 12,
        primary: '#EF6548',
        secondary: '#111928'
    },
    {
        priority: 13,
        primary: '#41AB5D',
        secondary: '#111928'
    },
    {
        priority: 14,
        primary: '#E7298A',
        secondary: '#111928'
    },
    {
        priority: 15,
        primary: '#4EB3D3',
        secondary: '#111928'
    },
    {
        priority: 16,
        primary: '#EC7014',
        secondary: '#111928'
    },
    {
        priority: 17,
        primary: '#238B45',
        secondary: '#111928'
    },
    {
        priority: 18,
        primary: '#88419D',
        secondary: '#111928'
    },
    {
        priority: 19,
        primary: '#0570B0',
        secondary: '#111928'
    },
    {
        priority: 20,
        primary: '#D7301F',
        secondary: '#111928'
    },
    {
        priority: 21,
        primary: '#238443',
        secondary: '#111928'
    },
    {
        priority: 22,
        primary: '#CE1256',
        secondary: '#111928'
    },
    {
        priority: 23,
        primary: '#2B8CBE',
        secondary: '#111928'
    },
    {
        priority: 24,
        primary: '#CC4C02',
        secondary: '#111928'
    },
    {
        priority: 25,
        primary: '#005824',
        secondary: '#FFFFFF'
    },
    {
        priority: 26,
        primary: '#6E016B',
        secondary: '#FFFFFF'
    },
    {
        priority: 27,
        primary: '#034E7B',
        secondary: '#FFFFFF'
    },
    {
        priority: 28,
        primary: '#990000',
        secondary: '#FFFFFF'
    },
    {
        priority: 29,
        primary: '#005A32',
        secondary: '#FFFFFF'
    },
    {
        priority: 30,
        primary: '#91003F',
        secondary: '#FFFFFF'
    },
    {
        priority: 31,
        primary: '#08589E',
        secondary: '#FFFFFF'
    },
    {
        priority: 32,
        primary: '#8C2D04',
        secondary: '#FFFFFF'
    },
];
