var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from '@aptive-env/storybook';
import { ChevronLeft } from 'icons';
import ServiceCard from 'components/ServiceCard';
import RouteEventTimeline from 'components/RouteEventTimeline';
var RouteDetails = function (_a) {
    var _b, _c, _d, _e;
    var name = _a.name, route = _a.route, isStarted = _a.isStarted, onClose = _a.onClose;
    var handleClickBack = function () {
        onClose();
    };
    return (_jsx("div", __assign({ className: "h-full flex flex-col gap-4" }, { children: _jsxs(Card, __assign({ className: "h-[calc(100%-34px)] min-w-[375px] bg-ui-snow mx-[15px] my-[17px] rounded-lg shadow-med flex flex-col overflow-hidden" }, { children: [_jsxs("div", __assign({ className: "flex-none h-[56px] bg-[#F9FAFB] flex items-center w-full px-4" }, { children: [_jsx("button", __assign({ className: "cursor-pointer", onClick: handleClickBack }, { children: _jsx(ChevronLeft, {}) })), _jsx("div", __assign({ className: "w-full text-center" }, { children: name }))] })), _jsxs("div", __assign({ className: "h-[calc(100%-56px)] flex flex-col" }, { children: [_jsx(ServiceCard, { index: 0, isStarted: isStarted, isUsedInRouteDetails: true, avatar: route.avatar, name: route.service_pro_name, statistics: route.statistics, actualStatistics: route.actual_stats, completionPercentage: (_c = (_b = route.actual_stats) === null || _b === void 0 ? void 0 : _b.completion_percentage) !== null && _c !== void 0 ? _c : 0, isAtRisk: (_e = (_d = route.actual_stats) === null || _d === void 0 ? void 0 : _d.at_risk) !== null && _e !== void 0 ? _e : false, isInitiallyExpanded: true }), _jsx(RouteEventTimeline, { route: route })] }))] })) })));
};
export default RouteDetails;
