import React from 'react';
import InfoBox from './InfoBox';
import { connect } from 'react-redux';
import {
  bedManagementStatisticSelector,
} from '@/modules/Housing/redux/bedManagement';
import { isBedManagementStatisticLoadingSelector } from '@/modules/Housing/redux/loading';

const BedManagementStats = ({
  bedManagementStatistic,
  isBedManagementStatisticLoading,
}) => {
  return (
    <div className="grid grid-cols-3 gap-2 mb-4">
      <InfoBox
        label="Reps needing housing"
        leftStatistic={bedManagementStatistic?.repsNeedingHousing}
        rightStatistic={bedManagementStatistic?.totalReps}
        color="aptivelime"
        isLoading={isBedManagementStatisticLoading}
      />

      <InfoBox
        label="% Reps needing housing"
        leftStatistic={
          bedManagementStatistic?.repsNeedingHousing && bedManagementStatistic?.totalReps
            ? (bedManagementStatistic.repsNeedingHousing / bedManagementStatistic.totalReps) * 100
            : 0
        }
        percentage={true}
        isLoading={isBedManagementStatisticLoading}
      />

      <InfoBox
        label="Total Rooms available"
        leftStatistic={bedManagementStatistic?.availableRooms}
        rightStatistic={bedManagementStatistic?.totalRooms}
        isLoading={isBedManagementStatisticLoading}
      />

      <InfoBox
        label="SPs needing housing"
        leftStatistic={bedManagementStatistic?.serviceProsNeedingHousing}
        rightStatistic={bedManagementStatistic?.totalServicePros}
        color="aptivelime"
        isLoading={isBedManagementStatisticLoading}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  bedManagementStatistic: bedManagementStatisticSelector(state),
  isBedManagementStatisticLoading: isBedManagementStatisticLoadingSelector(state),
});

export default connect(mapStateToProps)(BedManagementStats);
