var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
export var EventDescriptionInput = function (_a) {
    var _b, _c;
    var eventData = _a.eventData, setEventData = _a.setEventData, errors = _a.errors, setErrors = _a.setErrors;
    var handleChangeDescription = function (evt) {
        setEventData(function (prevData) { return (__assign(__assign({}, prevData), { description: evt.target.value })); });
        setErrors(__assign(__assign({}, errors), { description: [] }));
    };
    return (_jsxs("div", { children: [_jsx("p", __assign({ className: "font-inter text-sm font-normal leading-4 text-dark-gray-100" }, { children: "Description" })), _jsxs("div", __assign({ className: 'w-full border border-gray-200 rounded-lg mt-1 overflow-hidden' }, { children: [_jsx("textarea", { className: classNames('w-full h-[80px] resize-y rounded-lg text-sm leading-4 placeholder-[#A9A9A9] text-[#111827] px-3 py-2 focus-visible:outline-none border-none focus:border-none focus:ring-0', ((_b = errors.description) === null || _b === void 0 ? void 0 : _b.length) ? 'text-red-600 border-red-500' : ''), placeholder: "Enter event description here", value: eventData.description, onChange: handleChangeDescription }), (_c = errors.description) === null || _c === void 0 ? void 0 : _c.map(function (error, index) { return (_jsx("p", __assign({ className: "mb-1 text-xs text-red-600 dark:text-red-500" }, { children: error }), index)); })] }))] }));
};
