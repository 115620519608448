var _a;
import axios from 'axios';
import Cookies from 'js-cookie';
import { generateApiUrlByVersion } from './custom';
var baseURL = generateApiUrlByVersion(1);
var authToken = (_a = Cookies.get('tokenPayload')) !== null && _a !== void 0 ? _a : process.env.REACT_APP_ARO_API_AUTH_TOKEN;
var aroAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authToken,
    }
});
aroAPI.interceptors.response.use(function (response) { return response; }, function (error) {
    var _a;
    if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401)
        window.location.reload();
    throw error;
});
export default aroAPI;
