// TODO: SPT:REMOVE

// This implementation is taken from react-leaflet-freedraw
// https://github.com/elangobharathi/react-leaflet-freedraw

// TODO: The `./leaflet-freedraw` is a custom build of `leaflet-freedraw` with the fixed error that occurs when unmounting Map component.
//       This fix is taken from https://www.npmjs.com/package/leaflet-freedraw-fix
//       The original `leaflet-freedraw` is removed from the project and replaced with the custom build.
//       See https://github.com/Wildhoney/Leaflet.FreeDraw/compare/v2.13.3...btafel:Leaflet.FreeDraw:v2.13.4#diff-eced47e4d5f6d5ef68a2fd3d8afbb160db13f8b7131335222049339669a8f291 for details.
import LeafletFreedraw from './leaflet-freedraw';
import { createLayerComponent } from '@react-leaflet/core';
import './freedraw.css'

function createLeafletElement(props, context) {
  const instance = new LeafletFreedraw({ ...props });
  return { instance, context: { ...context, overlayContainer: instance } };
}

function updateLeafletElement(instance, props, prevProps) {
  if (props.mode !== prevProps.mode) {
    instance.mode(props.mode);
  }
}

const Freedraw = createLayerComponent(
  createLeafletElement,
  updateLeafletElement
);

export default Freedraw;
