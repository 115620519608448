import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import table from './table';
import {
  customGroupTypesSelector,
  removeCustomGroupTypeAsync,
} from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/custom-group-types';
import SortingDataTable from '@/modules/AdminTools/components/DataTable/SortingDataTable';

const sortOrders = ['name', 'priority'];

const CustomGroupTable = () => {
  const dispatch = useDispatch();

  const categories = useSelector(customGroupTypesSelector);

  const remove = useCallback(
    (id) => {
      if (!window.confirm('Are you sure?')) {
        return;
      }

      dispatch(removeCustomGroupTypeAsync.request({ id }));
    },
    [dispatch],
  );

  const getSearchField = ({ name }) => name;

  const rowMap = useCallback(
    (customGroup) => ({
      customGroup,
      remove: () => remove(customGroup.id),
    }),
    [remove],
  );

  return (
    <SortingDataTable
      data={categories}
      {...{ rowMap, table, sortOrders, getSearchField }}
    />
  );
};

export default CustomGroupTable;
