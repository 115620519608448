import PropTypes from 'prop-types';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomButton } from '@/components';
import { Select } from '@/components/common';
import { planSelector } from '@/modules/AdminTools/redux/planBuilder/plans';
import { createGroupPlanAsync } from '@/modules/AdminTools/redux/planBuilder/group-plans';

const GroupOptions = ({ setGroupId, groupPlans, groupType, groupOptions }) => {
  const dispatch = useDispatch();
  const updatePlanDefaultValues = useSelector(planSelector);
  const defaultAreaPlan = updatePlanDefaultValues.default_area_plan;

  const [newGroupId, setNewGroupId] = useState(null);

  const filteredGroupOptions = useMemo(() => {
    const groupIds = groupPlans.map(({ group_id }) => group_id);

    return groupOptions.filter(({ value }) => !groupIds.includes(value));
  }, [groupOptions, groupPlans]);

  useEffect(() => {
    if (filteredGroupOptions.length > 0) {
      setNewGroupId(filteredGroupOptions[0].value);
    }
  }, [filteredGroupOptions]);

  const createNewGroupPlan = useCallback(
    () => {
      if (!newGroupId) {
        return;
      }
      const newGroupPlan = {
        ...defaultAreaPlan,
        pricings: defaultAreaPlan.area_plan_pricings,
        group_type: groupType,
        group_id: newGroupId,
      };

      dispatch(createGroupPlanAsync.request( { groupPlan: newGroupPlan } ));
      setGroupId(newGroupId);
    },
    [defaultAreaPlan, dispatch, setGroupId, groupType, newGroupId],
  );

  return (
    <div className="flex flex-col mt-4">
      <div>
        Select a group to add
      </div>
      <div className="w-64">
        <Select
          name={'groupNames'}
          onChange={({ target: { value } }) => setNewGroupId(value)}
          value={newGroupId}
          options={filteredGroupOptions}
        />
      </div>
      <div>
        <CustomButton onClick={createNewGroupPlan}>
          Add
        </CustomButton>
      </div>
    </div>
  );
};

GroupOptions.propTypes = {
  setGroupId: PropTypes.func.isRequired,
  groupPlans: PropTypes.array,
  groupType: PropTypes.string,
  groupOptions: PropTypes.array,
};

export default GroupOptions;
