var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AptiveIcon, Button } from '@aptive-env/storybook';
export var PlanningFooterSection = function () {
    var handleClickRunRouteOptimization = function () {
        alert('Not implemented yet');
    };
    return (_jsxs("div", __assign({ className: "flex-none flex gap-2 w-full h-[84px] border border-l-0 border-r-0 border-b-0 border-t-1 border-gray-200 items-center px-6" }, { children: [_jsxs("div", __assign({ className: "flex items-start gap-2" }, { children: [_jsx(AptiveIcon, { className: "w-[24px] h-[24px] stroke-[#4B5563] fill-none", icon: "clock" }), _jsxs("div", { children: [_jsx("div", __assign({ className: "text-xs text-gray-700 leading-4" }, { children: _jsxs("span", __assign({ className: "font-medium" }, { children: ["Last optimized ", _jsx("span", __assign({ className: "font-bold" }, { children: "today at 5:00 AM." }))] })) })), _jsx("div", __assign({ className: "text-xs text-gray-700 leading-4" }, { children: _jsxs("span", __assign({ className: "font-medium" }, { children: ["Next optimization scheduled for ", _jsx("span", __assign({ className: "font-bold" }, { children: "today at 12:00 PM." }))] })) }))] })] })), _jsx(Button, __assign({ size: "default", variant: "default", className: "ml-auto", onClick: handleClickRunRouteOptimization }, { children: "Run Route Optimization" }))] })));
};
