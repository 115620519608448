import { CustomFormElement } from '@/components/common/Form';
import useSearchParamFilter from './useSearchParamFilter';
import { useSelector } from 'react-redux';
import { historyOptionsSelector } from '@/modules/AdminTools/redux/editHistory';

const UserFilter = () => {
  const userType = useSearchParamFilter('editor_type');
  const userId = useSearchParamFilter('editor_id');
  const userName = useSearchParamFilter('user_name');

  const { editor_types: userTypes } = useSelector(historyOptionsSelector);

  return (
    <div className="flex flex-row space-x-4">
      <div className="w-1/3">
        <label htmlFor="user_name">User Name</label>
        <CustomFormElement
          type="text"
          value={userName.value}
          onChange={userName.setSearchValue}
          id="user_name"
        />
      </div>
      <div className="w-1/3">
        <label htmlFor="editor_type">User Type</label>
        <CustomFormElement
          type="select"
          selectOptions={userTypes}
          value={userType.value}
          onChange={userType.setSearchValue}
          id="editor_type"
        />
      </div>
      <div className="w-1/3">
        <label htmlFor="editor_id">User Id</label>
        <CustomFormElement
          type="text"
          value={userId.value}
          onChange={userId.setSearchValue}
          id="editor_id"
        />
      </div>
    </div>
  );
};

export default UserFilter;
