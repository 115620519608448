var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var BreaksAndLunch = function () {
    return (_jsxs("div", __assign({ className: "p-6" }, { children: [_jsx("h2", __assign({ className: "text-base font-semibold text-slate-600 mb-4" }, { children: "Breaks and lunch" })), _jsx("div", { className: "h-px w-full bg-gray-200 -mt-1" }), _jsxs("div", __assign({ className: "grid grid-cols-4 gap-4 text-gray-600 text-base font-normal mt-4" }, { children: [_jsx("p", { children: "Eligible breaks:" }), _jsx("span", __assign({ className: "justify-self-end" }, { children: "10:00 PM" })), _jsx("span", __assign({ className: "justify-self-end" }, { children: "10:15 PM" })), _jsx("span", __assign({ className: "justify-self-center" }, { children: ".25" }))] })), _jsxs("div", __assign({ className: "grid grid-cols-4 gap-4 text-gray-600 text-base font-normal mt-4" }, { children: [_jsx("span", {}), _jsx("span", __assign({ className: "justify-self-end" }, { children: "3:00 PM" })), _jsx("span", __assign({ className: "justify-self-end" }, { children: "3:15 PM" })), _jsx("span", __assign({ className: "justify-self-center" }, { children: ".25" }))] })), _jsxs("div", __assign({ className: "grid grid-cols-4 gap-4 text-gray-600 text-base font-normal mt-4" }, { children: [_jsx("p", { children: "Lunch break:" }), _jsx("span", __assign({ className: "justify-self-end" }, { children: "3:00 PM" })), _jsx("span", __assign({ className: "justify-self-end" }, { children: "3:15 PM" })), _jsx("span", __assign({ className: "justify-self-center" }, { children: ".25" }))] })), _jsxs("div", __assign({ className: "flex flex-col gap-[9px] text-sm\ttext-gray-600 mt-5" }, { children: [_jsx("p", __assign({ className: "font-semibold" }, { children: "Utah Work Laws Summary*" })), _jsx("p", { children: "Total work hours: Up to 8 hours/day" }), _jsx("p", { children: "Eligible Breaks: 15 minutes for every 4 consecutive hours worked" }), _jsx("p", { children: "Lunch Hour: At least 1 hour after 5 hours of work" })] }))] })));
};
export default BreaksAndLunch;
