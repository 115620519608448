var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { AptiveIcon, Dropdown, DropdownItem, Modal, } from '@aptive-env/storybook';
import { format, getDay } from 'date-fns';
import classNames from 'classnames';
import CustomRecurrence from 'components/CustomRecurrence';
import { eventConstants, EVENT_EVERY_WEEKDAY, EVENT_NOT_REPEATING, EVENT_REPEATED_CUSTOM, EVENT_MONTHLY, EVENT_WEEKLY, } from 'constants/index';
var eventRepeatTypes = eventConstants.eventRepeatTypes, monthWeeks = eventConstants.monthWeeks, weekDates = eventConstants.weekDates, weekDays = eventConstants.weekDays, mapRepeatTypeToInterval = eventConstants.mapRepeatTypeToInterval;
export var EventRepeatSelect = function (_a) {
    var isOpen = _a.isOpen, eventData = _a.eventData, setEventData = _a.setEventData, _b = _a.disabled, disabled = _b === void 0 ? false : _b, event = _a.event;
    var _c = useState(false), isRepeatDropdownOpen = _c[0], setIsRepeatDropdownOpen = _c[1];
    var _d = useState(EVENT_NOT_REPEATING), selectedRepeatType = _d[0], setSelectedRepeatType = _d[1];
    var _e = useState(false), isCustomRecurrenceSelected = _e[0], setIsCustomRecurrenceSelected = _e[1];
    var _f = useState(false), isCustomDone = _f[0], setIsCustomDone = _f[1];
    var _g = useState({
        week_days: [''],
        end_after: 'never',
        occurrences: 1,
        repeat_every: 1,
        interval: 'daily',
        end_date: null,
        week_num: undefined,
    }), customEventTime = _g[0], setCustomEventTime = _g[1];
    useEffect(function () {
        if (selectedRepeatType !== EVENT_REPEATED_CUSTOM) {
            setEventData(function (prevEventData) { return (__assign(__assign({}, prevEventData), { interval: mapRepeatTypeToInterval[selectedRepeatType] })); });
            if (selectedRepeatType === EVENT_EVERY_WEEKDAY) {
                setEventData(function (prevEventData) { return (__assign(__assign({}, prevEventData), { weekDays: weekDays })); });
            }
            if (selectedRepeatType === EVENT_WEEKLY) {
                setEventData(function (prevEventData) { return (__assign(__assign({}, prevEventData), { weekDays: [weekDates[getDay(eventData.selectedDate)]] })); });
            }
        }
    }, [selectedRepeatType, setEventData, eventData.selectedDate]);
    useEffect(function () {
        if (isCustomDone) {
            setEventData(function (prevEventData) { return (__assign(__assign(__assign(__assign(__assign(__assign({}, prevEventData), { endAfter: customEventTime.end_after, interval: customEventTime.interval, repeatEvery: customEventTime.repeat_every }), (customEventTime.end_date && { endDate: customEventTime.end_date })), (customEventTime.week_num && { weekNum: customEventTime.week_num })), (customEventTime.occurrences && { occurrences: customEventTime.occurrences })), (customEventTime.week_days.length && { weekDays: customEventTime.week_days }))); });
        }
    }, [isCustomDone, setEventData, customEventTime]);
    var toggleRepeatDropdown = function (event) {
        event.stopPropagation();
        setIsRepeatDropdownOpen(function (prev) { return !prev; });
    };
    function handleRepeatDropdown(repeatType) {
        if (repeatType === EVENT_REPEATED_CUSTOM) {
            setIsCustomRecurrenceSelected(true);
        }
        setSelectedRepeatType(repeatType);
        setIsRepeatDropdownOpen(false);
        setIsCustomDone(false);
    }
    var handleCloseCustomRecurrence = function () {
        setIsCustomRecurrenceSelected(false);
        setSelectedRepeatType(EVENT_NOT_REPEATING);
    };
    var getCustomRepeatDetail = function () {
        var _a;
        var eventTimeParams = disabled ? __assign(__assign({}, event), { week_days: (_a = event.week_days) === null || _a === void 0 ? void 0 : _a.split(',') }) : customEventTime;
        var repeatDetail = '';
        if (eventTimeParams.repeat_every > 1)
            repeatDetail += "Every ".concat(eventTimeParams.repeat_every, " ");
        switch (eventTimeParams.interval) {
            case 'once':
                repeatDetail += EVENT_NOT_REPEATING;
                break;
            case 'monthly-on-day':
                repeatDetail += EVENT_MONTHLY;
                break;
            case 'daily':
                repeatDetail += eventTimeParams.repeat_every > 1 ? 'days' : 'Daily';
                break;
            case 'weekly':
                repeatDetail += eventTimeParams.repeat_every > 1 ? 'weeks on ' : 'Weekly on ';
                eventTimeParams.week_days.forEach(function (week_day) {
                    repeatDetail += week_day.charAt(0).toUpperCase() + week_day.slice(1);
                    repeatDetail +=
                        week_day ===
                            eventTimeParams.week_days[eventTimeParams.week_days.length - 1]
                            ? ''
                            : ', ';
                });
                break;
            case 'monthly':
                repeatDetail += eventTimeParams.repeat_every > 1 ? 'months on ' : 'Monthly on ';
                if (eventTimeParams.week_num === undefined)
                    repeatDetail += "day ".concat(format(eventData.selectedDate, 'd'));
                else if (eventTimeParams.week_num === -1)
                    repeatDetail += "the last ".concat(weekDates[getDay(eventData.selectedDate)].charAt(0).toUpperCase() +
                        weekDates[getDay(eventData.selectedDate)].slice(1));
                else
                    repeatDetail += "the ".concat(monthWeeks[eventTimeParams.week_num] +
                        ' ' +
                        weekDates[getDay(eventData.selectedDate)].charAt(0).toUpperCase() +
                        weekDates[getDay(eventData.selectedDate)].slice(1));
                break;
            case 'yearly':
                repeatDetail += eventTimeParams.repeat_every > 1 ? 'years on ' : 'Annually on ';
                repeatDetail += "".concat(format(eventData.selectedDate, 'MMMM d'));
                break;
        }
        if (eventTimeParams.end_date) {
            var endDate = new Date("".concat(eventTimeParams.end_date, "T00:00:00"));
            repeatDetail += " until ".concat(format(endDate, 'MMMM d, yyyy'));
        }
        if (eventTimeParams.occurrences)
            repeatDetail += ", ".concat(eventTimeParams.occurrences, " times");
        return repeatDetail;
    };
    return (_jsxs("div", { children: [_jsx("p", __assign({ className: "font-inter text-sm font-normal leading-4 text-dark-gray-100" }, { children: "Repeat" })), _jsxs("div", __assign({ className: "relative z-[5] mt-1" }, { children: [_jsxs("div", __assign({ className: classNames('w-full h-[38px] border rounded-lg flex items-center justify-between px-3', disabled ? 'text-gray-400' : 'text-dark-gray-100 cursor-pointer'), onClick: disabled ? function () { } : toggleRepeatDropdown }, { children: [_jsx("p", __assign({ className: "font-inter text-sm font-normal leading-4" }, { children: selectedRepeatType === EVENT_REPEATED_CUSTOM || disabled
                                    ? getCustomRepeatDetail()
                                    : selectedRepeatType })), _jsx(AptiveIcon, { icon: "chevronDown", className: "w-4 h-4 stroke-[#3D3D3D] fill-none cursor-pointer" })] })), _jsx(Dropdown, __assign({ className: "!min-w-[262px] w-full", isOpen: isRepeatDropdownOpen, onClose: function () { return setIsRepeatDropdownOpen(false); } }, { children: eventRepeatTypes.map(function (repeatType, index) { return (_jsx(DropdownItem, __assign({ selected: selectedRepeatType === repeatType, onClick: function () { return handleRepeatDropdown(repeatType); } }, { children: repeatType }), index)); }) }))] })), isCustomRecurrenceSelected && (_jsx(Modal, __assign({ open: isOpen, className: "w-[443px] min-h-[600px] flex flex-col", onClickOutside: handleCloseCustomRecurrence }, { children: _jsx(CustomRecurrence, { onClose: function () { return setIsCustomRecurrenceSelected(false); }, onCancel: handleCloseCustomRecurrence, data: customEventTime, setData: setCustomEventTime, pickedDate: eventData.selectedDate, setDone: setIsCustomDone }) })))] }));
};
