export var ChecklistRequirementsNames = {
    registration_in_person: 'Registration in person',
    wet_signature: 'Wet signature',
    digital_signature: 'Digital signature',
    background_check: 'Background Check',
    fingerprints: 'Fingerprints',
    identification_requirements: 'Identification requirements',
    photo_requirements: 'Headshot/photo requirement',
    bond: 'Bond',
    certificate_of_insurance: 'Certificate of Insurance',
    articles_of_incorporation: 'Articles of Incorporation',
    business_license: 'Business License (additional to individual)',
    certificate_of_authority: 'Certificate of Authority',
    letter_of_authorization: 'Letter of Authorization',
    personal_references: 'References (personal)',
    local_references: 'References (local)',
    professional_references: 'References (professional)',
    sales_tax_id: 'Sales Tax ID # or certificate',
    vehicle_registration: 'Vehicle Registration',
    vehicle_insurance: 'Vehicle Insurance',
    additional_appointments_required: 'Additional appointments required',
};
