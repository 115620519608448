var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from '@aptive-env/storybook';
import LicenseForm from './LicenseForm';
var LicenseModal = function (_a) {
    var isOpen = _a.isOpen, license = _a.license, salesRepresentative = _a.salesRepresentative, setSalesRepresentativeLicenses = _a.setSalesRepresentativeLicenses, onClose = _a.onClose, states = _a.states, selectedLicenseAttachments = _a.selectedLicenseAttachments;
    return (_jsx(Modal, __assign({ open: isOpen, onClickOutside: onClose }, { children: _jsx(LicenseForm, { license: license, salesRepresentative: salesRepresentative, setSalesRepresentativeLicenses: setSalesRepresentativeLicenses, selectedLicenseAttachments: selectedLicenseAttachments, onClose: onClose, states: states }) })));
};
export default LicenseModal;
