import {
  Ledger,
} from '../';

const LedgerArchived = () => {
  return (
    <Ledger archived={true} />
  );
};

export default LedgerArchived;
