var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from '@aptive-env/storybook';
import LicenseApplicationForm from './LicenseApplicationForm';
var LicenseApplicationModal = function (_a) {
    var isOpen = _a.isOpen, details = _a.details, attachments = _a.attachments, checklistAttachments = _a.checklistAttachments, onClose = _a.onClose, onSubmit = _a.onSubmit;
    return (_jsx(Modal, __assign({ open: isOpen, onClickOutside: onClose }, { children: _jsx(LicenseApplicationForm, { details: details, attachments: attachments, checklistAttachments: checklistAttachments, onCancel: onClose, onSubmit: onSubmit }) })));
};
export default LicenseApplicationModal;
