import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import GroupPlan from '../../GroupPlan';
import Headers from './Headers';
import { updatingPlanLoadingSelector } from '@/redux/loading';
import FormControls from '../../FormControls';
import { updateGroupPlanAsync } from '@/modules/AdminTools/redux/planBuilder/group-plans';

const GroupPlanUpdate = ({ groupPlan }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const planErrors = useSelector((state) => state?.errors?.errors?.updatePlan);

  const isUpdating = useSelector(updatingPlanLoadingSelector);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const methods = useForm({ defaultValues: groupPlan });

  const successfulUpdate = useMemo(
    () => isSubmitted && !isUpdating && !planErrors,
    [isUpdating, planErrors, isSubmitted],
  );

  useEffect(() => {
    if (successfulUpdate) {
      methods.reset(groupPlan);
    }
  }, [navigate, successfulUpdate, groupPlan, methods]);

  useEffect(() => {
    if (planErrors && isSubmitted) {
      setIsSubmitted(false);
    }
  }, [setIsSubmitted, planErrors, isSubmitted]);

  const handleSubmit = (data) => {
    if (isUpdating) {
      return;
    }

    dispatch(updateGroupPlanAsync.request( { groupPlan: data } ));

    setIsSubmitted(true);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <div className="mt-4">
          <FormControls submitText="Update Group Override" />
          <GroupPlan {...Headers} />
        </div>
      </form>
    </FormProvider>
  );
};

GroupPlanUpdate.propTypes = {
  groupPlan: PropTypes.object.isRequired,
};

export default GroupPlanUpdate;
