import { planUpgradePathConstants } from '@/modules/AdminTools/lib/constants';
import Boolean from '@/modules/AdminTools/components/Form/Boolean';

const {
  SHOULD_COMPARE_TO_PLAN_MIN_PRICE,
  SHOULD_COMPARE_TO_PLAN_MIN_PRICE_LABEL,
} = planUpgradePathConstants;

const UseToPlanPrice = () => {
  return <Boolean name={SHOULD_COMPARE_TO_PLAN_MIN_PRICE} label={SHOULD_COMPARE_TO_PLAN_MIN_PRICE_LABEL} />;
};

export default UseToPlanPrice;
