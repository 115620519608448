var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState, useContext } from 'react';
import { AptiveIcon, Tab, Tabs, Modal } from '@aptive-env/storybook';
import { GoogleMap, Marker, Polygon } from '@react-google-maps/api';
import { ROUTING_COLORS } from 'constants/map';
var containerStyle = {
    width: '100%',
    height: '455px',
};
function classNames() {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(Boolean).join(' ');
}
export var OfficeModal = function (params) {
    var open = params.open, className = params.className, selectedOffice = params.selectedOffice, regions = params.regions, center = params.center, onSelectOffice = params.onSelectOffice, onClose = params.onClose, appContext = params.appContext;
    var _a = useContext(appContext), isGoogleMapAPILoaded = _a.isGoogleMapAPILoaded, googleMapAPILoadError = _a.googleMapAPILoadError;
    var _b = useState(0), checkedTab = _b[0], setCheckedTab = _b[1];
    var handleSelectOffice = useCallback(function (office) {
        onSelectOffice(office);
    }, [onSelectOffice]);
    var handleLoadError = function (error) {
        console.error('Error loading Google Maps:', error);
    };
    if (googleMapAPILoadError) {
        handleLoadError(googleMapAPILoadError);
        return _jsx("div", { children: "Error loading Google Maps!" });
    }
    var handleCloseModal = function () {
        onClose();
    };
    var handleChangeTab = function (val) {
        setCheckedTab(val);
    };
    var generatePolygonOption = function (routeIndex) {
        var polygonOption = {
            fillOpacity: 0.24,
            fillColor: ROUTING_COLORS[routeIndex % ROUTING_COLORS.length].primary,
            strokeColor: ROUTING_COLORS[routeIndex % ROUTING_COLORS.length].primary,
            strokeWeight: 2,
        };
        return polygonOption;
    };
    var generateCircleSymbol = function (routeIndex) {
        var circleSymbol = {
            path: 'M-2.3 0a2.3 2.3 0 1 0 4.6 0a2.3 2.3 0 1 0 -4.6 0',
            strokeColor: '#000',
            fillColor: ROUTING_COLORS[routeIndex % ROUTING_COLORS.length].primary,
            fillOpacity: 1,
            strokeWidth: 1,
            strokeOpacity: 1,
            scale: 2,
        };
        return circleSymbol;
    };
    return (_jsxs(Modal, __assign({ open: open, className: className, onClickOutside: handleCloseModal }, { children: [_jsxs("div", __assign({ className: "px-6 pt-6 pb-4 flex justify-between" }, { children: [_jsxs("div", __assign({ className: "flex items-center gap-2" }, { children: [_jsx(AptiveIcon, { className: "w-[24px] h-[24px] stroke-gray-600 fill-none", icon: "globeAlt" }), _jsx("div", __assign({ className: "text-base font-semibold leading-4 text-gray-600" }, { children: "Select an Office" }))] })), _jsx("div", { children: _jsx(AptiveIcon, { className: "w-[24px] h-[24px] stroke-gray-600 fill-none cursor-pointer", icon: "x", onClick: handleCloseModal }) })] })), _jsxs("div", __assign({ className: "relative px-4" }, { children: [_jsx("div", { className: "absolute border-b-2 bottom-0 w-[calc(100%-32px)]" }), _jsxs(Tabs, __assign({ className: "space-x-2 px-4", onChange: handleChangeTab, value: checkedTab }, { children: [_jsx(Tab, __assign({ className: "border-b-[2px] w-[57px] pb-[13px] z-10", value: 0 }, { children: "List" })), _jsx(Tab, __assign({ className: "border-b-[2px] w-[57px] pb-[13px] z-10", value: 1 }, { children: "Map" }))] }))] })), _jsxs("div", __assign({ className: "p-6 flex gap-6 flex-wrap" }, { children: [checkedTab === 0 &&
                        (regions === null || regions === void 0 ? void 0 : regions.map(function (region, index) { return (_jsxs("div", __assign({ className: "w-1/6 flex flex-col gap-2" }, { children: [_jsx("div", __assign({ className: "text-sm font-semibold leading-4 text-[#101722] cursor-context-menu" }, { children: region.name })), region.offices.map(function (office, cIndex) { return (_jsx("div", __assign({ className: classNames('text-sm	font-normal leading-4 cursor-pointer', office.name === selectedOffice.name ? 'text-[#101722]' : 'text-[#5E6774]'), onClick: function () { return handleSelectOffice(office); } }, { children: office.name }), 'country_' + cIndex)); })] }), index)); })), checkedTab === 1 && isGoogleMapAPILoaded &&
                        _jsx(GoogleMap, __assign({ zoom: 4, center: center, mapContainerStyle: containerStyle }, { children: regions === null || regions === void 0 ? void 0 : regions.map(function (region, index) { return (_jsxs("div", { children: [region.offices.map(function (office, officeIndex) { return (_jsx(Marker, { icon: generateCircleSymbol(index), title: office.name, position: office.location, onClick: function () { return handleSelectOffice(office); } }, officeIndex)); }), _jsx(Polygon, { visible: true, options: generatePolygonOption(index), paths: region.boundary })] }, index)); }) }))] }))] })));
};
