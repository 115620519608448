// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EObEjaT02GSFYIZNaK5T{width:12px;aspect-ratio:1;border-radius:50%;background:#1b64f2;animation:_KpJxwslLKFj4rYv0tqj 1s infinite linear;position:relative}.EObEjaT02GSFYIZNaK5T:before{content:"";position:absolute;inset:0;border-radius:inherit;box-shadow:0 0 0 0 #a1c1fc;animation:inherit}@keyframes _KpJxwslLKFj4rYv0tqj{100%{box-shadow:0 0 0 10px rgba(0,0,0,0)}}`, "",{"version":3,"sources":["webpack://./src/components/RouteCurrentAnimatedIcon/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,cAAA,CACA,iBAAA,CACA,kBAAA,CACA,iDAAA,CACA,iBAAA,CAEA,6BACE,UAAA,CACA,iBAAA,CACA,OAAA,CACA,qBAAA,CACA,0BAAA,CACA,iBAAA,CAIJ,gCACE,KACE,mCAAA,CAAA","sourcesContent":[".animation {\n  width: 12px;\n  aspect-ratio: 1;\n  border-radius: 50%;\n  background: #1b64f2;\n  animation: l2 1s infinite linear;\n  position: relative;\n\n  &:before {\n    content: \"\";\n    position: absolute;\n    inset: 0;\n    border-radius: inherit;\n    box-shadow: 0 0 0 0 #a1c1fc;\n    animation: inherit;\n  }\n}\n\n@keyframes l2 {\n  100% {\n    box-shadow: 0 0 0 10px #0000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animation": `EObEjaT02GSFYIZNaK5T`,
	"l2": `_KpJxwslLKFj4rYv0tqj`
};
export default ___CSS_LOADER_EXPORT___;
