import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import {
  onboardingConstants,
} from '@/lib';
import { CustomFormElement, DriverLicenseNumber, SsnNumber } from '@/components/common';
import { connect } from 'react-redux';
import { updateRepErrorsSelector } from '@/redux/errors';
import { addFsExcludeClass } from '@/lib/utils';
import { requestSsnAsync } from '@/redux/reps';
import { selectLoadingSsn } from '@/redux/loading';
import { isAdminSelector } from "@/redux/auth";
import {
  repProfileSelector,
} from '@/redux/onboarding';
import { useMemo } from 'react';
import { defaultSelectOption } from '@/lib/configs';
import { selectStates } from '@/redux/addresses';

const {
  DRIVER_LICENSE_NUMBER,
  DRIVER_LICENSE_NUMBER_LABEL,
  DRIVER_LICENSE_EXPIRATION_DATE,
  DRIVER_LICENSE_EXPIRATION_DATE_LABEL,
  DRIVER_LICENSE_STATE_ISSUED,
  DRIVER_LICENSE_STATE_ISSUED_LABEL,
  SOCIAL_SECURITY_NUMBER,
  SOCIAL_SECURITY_NUMBER_LABEL,
  LIMITED_LIABILITY_COMPANY,
  LIMITED_LIABILITY_COMPANY_LABEL,
  EMPLOYER_IDENTIFICATION_NUMBER,
  EMPLOYER_IDENTIFICATION_NUMBER_LABEL,
  SOLICITOR_PERMIT_NUMBER,
  SOLICITOR_PERMIT_NUMBER_LABEL,
  SOLICITOR_EXPIRATION_DATE,
  SOLICITOR_EXPIRATION_DATE_LABEL,
  DRIVER_LICENSE_COUNTRY_ISSUED,
} = onboardingConstants;

const LicensingInfo = ({
  // Own Props
  onChangeHandler,
  userId,

  rep,
  getSsn,
  isSsnLoading,
  isAdmin,
  states,
}) => {
  const { register, formState: { errors } } = useFormContext();

  const onCopySsnClick = () => {
    if (document.hasFocus()) {
      // Call the function to get the SSN and handle the success callback
      getSsn({ userId, successCallback: (ssn) => {
        navigator.clipboard.writeText(ssn).then(() => {});
      }});
    }
  }

  const driverLicenseIssuedStates = useMemo(() => {
    const driverLicenseCountry = rep?.[DRIVER_LICENSE_COUNTRY_ISSUED];
    const countryStates = states?.[driverLicenseCountry] ?? [];

    return [...defaultSelectOption, ...countryStates];
  }, [states]);

  return (
    <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
      <DriverLicenseNumber
        colSpan={3}
        id={DRIVER_LICENSE_NUMBER}
        name={DRIVER_LICENSE_NUMBER}
        label={DRIVER_LICENSE_NUMBER_LABEL}
        onChange={onChangeHandler}
        register={register}
        error={errors?.[DRIVER_LICENSE_NUMBER]}
        className={addFsExcludeClass()}
        disabled={!isAdmin}
      />
      <CustomFormElement
        colSpan={3}
        id={DRIVER_LICENSE_EXPIRATION_DATE}
        name={DRIVER_LICENSE_EXPIRATION_DATE}
        label={DRIVER_LICENSE_EXPIRATION_DATE_LABEL}
        type="date"
        showYearDropdown
        onChange={onChangeHandler}
        register={register}
        error={errors?.[DRIVER_LICENSE_EXPIRATION_DATE]}
        disabled={!isAdmin}
      />
      <CustomFormElement
        colSpan={3}
        id={DRIVER_LICENSE_STATE_ISSUED}
        name={DRIVER_LICENSE_STATE_ISSUED}
        label={DRIVER_LICENSE_STATE_ISSUED_LABEL}
        type="select"
        selectOptions={driverLicenseIssuedStates}
        onChange={onChangeHandler}
        register={register}
        error={errors?.[DRIVER_LICENSE_STATE_ISSUED]}
        className={addFsExcludeClass()}
        disabled={!isAdmin}
      />
      <SsnNumber
        colSpan={3}
        id={SOCIAL_SECURITY_NUMBER}
        name={SOCIAL_SECURITY_NUMBER}
        label={SOCIAL_SECURITY_NUMBER_LABEL}
        register={register}
        error={errors?.ssnNumber}
        isDirty={false}
        isCopyLoading={isSsnLoading}
        {...(rep?.[SOCIAL_SECURITY_NUMBER] && { onCopyClick: onCopySsnClick })}
        className={addFsExcludeClass()}
        disabled={true}
      />
      <CustomFormElement
        colSpan={3}
        id={LIMITED_LIABILITY_COMPANY}
        name={LIMITED_LIABILITY_COMPANY}
        label={LIMITED_LIABILITY_COMPANY_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[LIMITED_LIABILITY_COMPANY]}
        className={addFsExcludeClass()}
        disabled={!isAdmin}
      />
      <CustomFormElement
        colSpan={3}
        id={EMPLOYER_IDENTIFICATION_NUMBER}
        name={EMPLOYER_IDENTIFICATION_NUMBER}
        label={EMPLOYER_IDENTIFICATION_NUMBER_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[EMPLOYER_IDENTIFICATION_NUMBER]}
        className={addFsExcludeClass()}
        disabled={!isAdmin}
      />
      <CustomFormElement
        colSpan={3}
        id={SOLICITOR_PERMIT_NUMBER}
        name={SOLICITOR_PERMIT_NUMBER}
        label={SOLICITOR_PERMIT_NUMBER_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[SOLICITOR_PERMIT_NUMBER]}
        className={addFsExcludeClass()}
        disabled={!isAdmin}
      />
      <CustomFormElement
        colSpan={3}
        id={SOLICITOR_EXPIRATION_DATE}
        name={SOLICITOR_EXPIRATION_DATE}
        label={SOLICITOR_EXPIRATION_DATE_LABEL}
        type="date"
        showYearDropdown
        onChange={onChangeHandler}
        register={register}
        error={errors?.[SOLICITOR_EXPIRATION_DATE]}
        disabled={!isAdmin}
      />
    </div>
  );
};

LicensingInfo.propTypes = {
  rep: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  getSsn: PropTypes.func.isRequired,
  isSsnLoading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  formErrors: updateRepErrorsSelector(state),
  isSsnLoading: selectLoadingSsn(state),
  isAdmin: isAdminSelector(state),
  rep: repProfileSelector(state),
  states: selectStates(state),
});

const mapDispatchToProps = {
  getSsn: requestSsnAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(LicensingInfo);
