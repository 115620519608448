import { mergeClassName } from '@/lib/utils';
import PropTypes from 'prop-types';

const LinkedinIcon = ({ className }) => {
  return (
    <svg className={mergeClassName('w-6 h-6', className)} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4491 20.4495H16.8931V14.8805C16.8931 13.5525 16.8694 11.843 15.0436 11.843C13.1915 11.843 12.9081 13.2899 12.9081 14.7838V20.4492H9.35204V8.99694H12.7658V10.562H12.8136C13.5097 9.3718 14.804 8.66093 16.1819 8.71208C19.7861 8.71208 20.4506 11.0828 20.4506 14.167L20.4491 20.4495ZM5.33963 7.4315C4.19992 7.43171 3.27584 6.50794 3.27564 5.36821C3.27544 4.22848 4.19918 3.30438 5.33889 3.30417C6.4786 3.30397 7.40268 4.22774 7.40288 5.36747C7.40298 5.91478 7.18566 6.43973 6.79872 6.82681C6.41179 7.21389 5.88694 7.4314 5.33963 7.4315ZM7.11766 20.4495H3.5579V8.99694H7.11766V20.4495ZM22.222 0.00178957H1.77099C0.804431 -0.00911825 0.0117963 0.765131 0 1.7317V22.268C0.0113924 23.235 0.803963 24.01 1.77099 23.9999H22.222C23.1909 24.0119 23.9866 23.2369 24 22.268V1.73022C23.9862 0.761739 23.1904 -0.0124652 22.222 0.000152051" />
    </svg>
  );
};

LinkedinIcon.propTypes = {
  className: PropTypes.string,
};

export default LinkedinIcon;
