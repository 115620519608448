// TODO: Refactor functionality added by this commit:
//       bb0977659eb2fe9c389ad3958297fd843cd7fa21
//       Remove changes made in this commit from the TableCell.
//       It aimed to be a component that provides structure only rather than behavior!

import PropTypes from 'prop-types';
import { Props } from '.';

const TableCell = ({
  value,
  hoverValue,
  isHovered,
  type: Tag = 'td',
  align = 'left',
  valign = 'middle',
  ...props
}) => (
  <Tag
    {...new Props(props)
      // Because Tailwind can't handle dynamic values,
      // we have to list all possible `text-*` and `align-*` classes here.
      // The final value will override them.
      .withClassName([
        'px-4 py-4',
        `text-left text-center text-right text-${align}`,
        `align-top align-middle align-bottom align-${valign}`,
      ])}
  >
    {hoverValue ? (isHovered ? hoverValue : value) : value}
  </Tag>
);

TableCell.propTypes = {
  value: PropTypes.any,
  hoverValue: PropTypes.any,
  isHovered: PropTypes.bool,
  type: PropTypes.oneOf(['td', 'th']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  valign: PropTypes.oneOf(['top', 'middle', 'bottom']),
};

export default TableCell;
