import { useCallback, useEffect, useMemo } from 'react';
import { CustomFormElement } from '.';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Icon, Loader } from '@/components/common';

const SsnNumber = (props) => {
  const mask = useMemo(() => (
    /**
     * Don't restrict first (or any other) character's range, for example /[0-8]/ instead of /\d/.
     * It may cause caret jump to the position of the next suitable character (in this case 9)
     * on attempt to insert the restricted character (9).
     *
     * Example,
     * Given: 123-45-6789
     * Do: select the first character: [1]12-45-6789. Try to insert 9 which is restricted for the first character.
     * Result: caret jumps to the next 9's position, i.e. 234-56-789[]_
     */
    [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  ), []);
  const { setValue } = useFormContext();

  const { isDirty, name, onCopyClick, isCopyLoading } = props;

  const handleFocus = useCallback(() => {
    setValue(name, '', { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  }, [setValue, name]);

  useEffect(() => {
    if (isDirty) {
      // Not clean, but need to refocus on the form since it changes inputs from mask to text and vice versa, i.e. the `setFocus` is not working in this case.
      document.getElementById(name).focus();
    }
  }, [isDirty, name]);

  return isDirty
    ? (
      <CustomFormElement
        {...props}
        type="masked"
        mask={mask}
      />
    ) : (
      <CustomFormElement
        {...props}
        type="text"
        onFocus={handleFocus}
        {...(onCopyClick && { children : (
          <div className="absolute right-0 px-3 py-2">
            {isCopyLoading ?
              <Loader className="pt-1" /> : (
                <Icon className="w-5 h-5 relative text-gray-600 font-thin cursor-pointer"
                  icon="copyIcon"
                  onClick={() => onCopyClick()}
                />
              )}
          </div>
        ) })}
        childOrientation="right"
      />
    );
};

SsnNumber.propTypes = {
  isDirty: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onCopyClick: PropTypes.func,
  isCopyLoading: PropTypes.bool,
};

export default SsnNumber;
