import { useMemo, useState, useRef } from 'react';
import classNames from 'classnames';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { XIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { TooltipText } from '@/components/common';
import { v4 as uuidv4 } from 'uuid';

const CustomInputSelect = ({
  id,
  name,
  type,
  disabled,
  formValue,
  value,
  hasError,
  max,
  className,
  onChange,
  register,
  childOrientation,
  ariaDescribedBy,
  placeholder,
  autoComplete,
  baseClasses,
  children,
  onBlur,
  required,
  onKeyDown,
  onCrossClick,
  autoDropdownClickAction,
  autoDropdownOptions,
  readOnly,
}) => {
  const [autoDropdownVisible, setAutoDropdownVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleClickOutsideDropdownButton = () => {
    setAutoDropdownVisible(false);
    setInputValue('');
  };

  const ref = useRef();

  useOnClickOutside(ref, handleClickOutsideDropdownButton);

  const handleAutoDropdownClick = (option) => {
    setAutoDropdownVisible(false);
    autoDropdownClickAction(option);
    setInputValue('');
  };

  const handleOnChange = (event) => {
    if (autoDropdownOptions && autoDropdownOptions.length) {
      setAutoDropdownVisible(true);
      setInputValue(event.target.value);
    } else {
      onChange(event);
    }
  };

  const filteredOptions = autoDropdownOptions?.filter(
    (option) => option.label.toLowerCase().includes(inputValue.toLowerCase()),
  );

  const classes = useMemo(() => {
    const base =
      type !== 'checkbox'
        ? baseClasses?.[hasError ? 'errorClasses' : 'standardClasses'] || ''
        : '';

    return classNames(
      'border-gray-300 shadow-sm block w-full sm:text-sm rounded-md border-r-0 rounded-r-none',
      base,
      className,
      { 'text-gray-400': disabled },
    );
  }, [type, baseClasses, hasError, className, disabled]);

  return (
    <div ref={ref} className="flex relative">
      {childOrientation === 'left' ? children : null}
      <TooltipText
        id={uuidv4()}
        text={
          <input
            {...(register && { ...register(name) })}
            id={id}
            max={max}
            name={name}
            type={type}
            value={autoDropdownClickAction ? inputValue : register ? formValue : value}
            className={classes}
            onChange={autoDropdownClickAction ? handleOnChange : onChange}
            disabled={disabled}
            placeholder={placeholder}
            aria-describedby={ariaDescribedBy}
            aria-invalid={hasError}
            autoComplete={autoComplete}
            {...(onBlur && { onBlur })}
            required={required || false}
            onKeyDown={onKeyDown}
            readOnly={readOnly}
          />
        }
        message={value}
      />
      {childOrientation === 'right' ? children : null}
      <div
        className="border-gray-300 border-l-0 rounded-l-none shadow-sm flex justify-end items-center cursor-pointer border rounded-md"
        onClick={onCrossClick}
      >
        {value
          ? <XIcon className="w-6 h-6 text-gray-400 hover:text-primary-300" />
          : <div className="w-6 h-6" />
        }
      </div>
      {autoDropdownOptions &&
        autoDropdownOptions.length > 0 &&
        autoDropdownVisible && (
        <div className="absolute w-56 top-10 right-0 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleAutoDropdownClick(option)}
            >
              {option.label}
            </div>
          ))}
          {filteredOptions.length === 0 && (
            <div className="p-2 text-gray-400">No options available</div>
          )}
        </div>
      )}
    </div>
  );
};

CustomInputSelect.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  formValue: PropTypes.string,
  value: PropTypes.string,
  hasError: PropTypes.bool,
  max: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func,
  register: PropTypes.func,
  childOrientation: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  baseClasses: PropTypes.object,
  children: PropTypes.node,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onHoverClick: PropTypes.func,
  onCrossClick: PropTypes.func,
  autoDropdownClickAction: PropTypes.func,
  autoDropdownOptions: PropTypes.array,
  readOnly: PropTypes.bool,
};

/**
 * Warning!
 *
 * Don't use `React.memo()` in order to optimize the form component in conjunction with React Hook Form.
 *
 * The `reset()` function from the `useForm()` won't reset the value of the DOM input element during the form reset process.
 * It instead assumes that the component will be re-rendered and a correct value will be set during DOM input element registration.
 */
export default CustomInputSelect;
