// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NEKy1pXZSh_tEVuxg7_M path{stroke:#000}.mnxqHl9Ym_rMoPhlVTlo{object-fit:cover;border-radius:50%;width:40px;height:40px;object-position:50% 50%}.mnxqHl9Ym_rMoPhlVTlo svg{border-radius:50%}`, "",{"version":3,"sources":["webpack://./src/components/ServiceCard/index.module.scss"],"names":[],"mappings":"AACE,2BACE,WAAA,CAIJ,sBACE,gBAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,uBAAA,CACA,0BACE,iBAAA","sourcesContent":[".svg {\n  path {\n    stroke: #000000;\n  }\n}\n\n.avatar {\n  object-fit: cover;\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n  object-position: 50% 50%;\n  svg {\n    border-radius: 50%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svg": `NEKy1pXZSh_tEVuxg7_M`,
	"avatar": `mnxqHl9Ym_rMoPhlVTlo`
};
export default ___CSS_LOADER_EXPORT___;
