import { useSelector } from 'react-redux';

import CustomGroupHeader from './CustomGroupHeader';
import CustomGroupTable from './CustomGroupTable';
import { customGroupTypesSelector } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/custom-group-types';

const CustomGroup = () => {
  const customGroupTypes = useSelector(customGroupTypesSelector);

  const showForm = customGroupTypes.length > 0;

  return (
    <div>
      <CustomGroupHeader />
      {showForm ? <CustomGroupTable /> : <div>No Custom Groups</div>}
    </div>
  );
};

export default CustomGroup;
