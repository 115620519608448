var _a;
export var EVENT_NOT_REPEATING = 'Does not repeat';
export var EVENT_DAILY = 'Daily';
export var EVENT_WEEKLY = 'Weekly';
export var EVENT_MONTHLY = 'Monthly';
export var EVENT_SERVICE_ANNUALLY = 'Service Annually';
export var EVENT_EVERY_WEEKDAY = 'Every weekday (Monday to Friday)';
export var EVENT_REPEATED_CUSTOM = 'Custom...';
export var eventConstants = {
    EVENT_EDIT_TYPE: 'Edit Event',
    EVENT_NEW_TYPE: 'New Event',
    EVENT_OVERRIDE_TYPE: 'Override Event',
    EVENT_INITIAL_TITLE: 'Untitled',
    EVENT_INITIAL_START_TIME: '08:00 AM',
    EVENT_INITIAL_END_TIME: '09:00 AM',
    timeOptions: [
        '12:00 AM',
        '12:30 AM',
        '01:00 AM',
        '01:30 AM',
        '02:00 AM',
        '02:30 AM',
        '03:00 AM',
        '03:30 AM',
        '04:00 AM',
        '04:30 AM',
        '05:00 AM',
        '05:30 AM',
        '06:00 AM',
        '06:30 AM',
        '07:00 AM',
        '07:30 AM',
        '08:00 AM',
        '08:30 AM',
        '09:00 AM',
        '09:30 AM',
        '10:00 AM',
        '10:30 AM',
        '11:00 AM',
        '11:30 AM',
        '12:00 PM',
        '12:30 PM',
        '01:00 PM',
        '01:30 PM',
        '02:00 PM',
        '02:30 PM',
        '03:00 PM',
        '03:30 PM',
        '04:00 PM',
        '04:30 PM',
        '05:00 PM',
        '05:30 PM',
        '06:00 PM',
        '06:30 PM',
        '07:00 PM',
        '07:30 PM',
        '08:00 PM',
        '08:30 PM',
        '09:00 PM',
        '09:30 PM',
        '10:00 PM',
        '10:30 PM',
        '11:00 PM',
        '11:30 PM',
    ],
    eventRepeatTypes: [
        EVENT_NOT_REPEATING,
        EVENT_DAILY,
        EVENT_WEEKLY,
        EVENT_MONTHLY,
        EVENT_SERVICE_ANNUALLY,
        EVENT_EVERY_WEEKDAY,
        'Custom...',
    ],
    mapRepeatTypeToInterval: (_a = {},
        _a[EVENT_NOT_REPEATING] = 'once',
        _a[EVENT_DAILY] = 'daily',
        _a[EVENT_WEEKLY] = 'weekly',
        _a[EVENT_MONTHLY] = 'monthly-on-day',
        _a[EVENT_SERVICE_ANNUALLY] = 'yearly',
        _a[EVENT_EVERY_WEEKDAY] = 'weekly',
        _a),
    mapIntervalToRepeatType: {
        once: EVENT_NOT_REPEATING,
        daily: EVENT_DAILY,
        weekly: EVENT_WEEKLY,
        monthly: EVENT_MONTHLY,
        yearly: EVENT_SERVICE_ANNUALLY,
    },
    monthWeeks: ['first', 'second', 'third', 'fourth', 'last'],
    weekDates: [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
    ],
    weekDays: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
    ],
};
