import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { soTeamsSortedSelector } from '@/redux/sales-operations';
import GroupTypeOverride from './GroupTypeOverride';

const TeamOverride = () => {
  const teams = useSelector(soTeamsSortedSelector);

  const groupOptions = useMemo(() => {
    if (!teams) {
      return [];
    }

    return teams.map(({ id, name }) => ({
      label: `${id}: ${name}`,
      value: id,
    }));
  }, [teams]);

  return (
    <GroupTypeOverride groupIdName="team_id" groupType="team" groupOptions={groupOptions} />
  );
};

export default TeamOverride;
