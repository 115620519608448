var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Button, Dropdown } from '@aptive-env/storybook';
import { useOnClickOutside } from 'hooks';
import { AptiveIcon } from '@aptive-env/storybook';
var CustomSelect = function (_a) {
    var id = _a.id, formValue = _a.formValue, value = _a.value, register = _a.register, watch = _a.watch, name = _a.name, className = _a.className, onChange = _a.onChange, selectOptions = _a.selectOptions, ariaDescribedBy = _a.ariaDescribedBy, hasError = _a.hasError, baseClasses = _a.baseClasses, disabled = _a.disabled, onBlur = _a.onBlur, _b = _a.dropdownPosition, dropdownPosition = _b === void 0 ? 'right' : _b, searchable = _a.searchable;
    var _c = useState(false), isDropdownOpen = _c[0], setIsDropdownOpen = _c[1];
    var _d = useState(selectOptions.find(function (option) { return option.value === formValue; }) || null), selectOption = _d[0], setSelectOption = _d[1];
    var _e = useState(''), searchQuery = _e[0], setSearchQuery = _e[1];
    var ref = useRef(null);
    var classes = useMemo(function () { return classNames(className, 'shadow-sm block w-full sm:text-sm rounded-md disabled:opacity-100 disabled:select-text cursor-default flex justify-between', "".concat(hasError ? baseClasses === null || baseClasses === void 0 ? void 0 : baseClasses.errorClasses : baseClasses === null || baseClasses === void 0 ? void 0 : baseClasses.standardClasses), { 'text-gray-400': disabled }); }, [baseClasses === null || baseClasses === void 0 ? void 0 : baseClasses.errorClasses, baseClasses === null || baseClasses === void 0 ? void 0 : baseClasses.standardClasses, className, disabled, hasError]);
    var handleOnClickOutside = function () {
        setIsDropdownOpen(false);
    };
    useOnClickOutside(ref, handleOnClickOutside);
    var handleChange = function (value) {
        var selected = selectOptions.find(function (option) { return option.value === value; }) || null;
        setSelectOption(selected);
        onChange && onChange({ target: { name: name, value: value } });
        setIsDropdownOpen(false);
    };
    useEffect(function () {
        if (watch) {
            var selected = selectOptions.find(function (option) { return option.value === watch(name); }) || null;
            setSelectOption(selected);
        }
    }, [watch, name, selectOptions]);
    return (_jsxs("div", __assign({ className: isDropdownOpen ? 'relative' : '', ref: ref }, { children: [_jsxs(Button, __assign({ "aria-haspopup": "true", "aria-label": "Dropdown button", icon: "chevronDown", position: "right", isFilled: false, variant: "neutral", className: classes, onClick: function () { return setIsDropdownOpen(function (prev) { return !prev; }); }, disabled: disabled, onBlur: onBlur }, { children: [(selectOption === null || selectOption === void 0 ? void 0 : selectOption.icon) ? (_jsxs("div", __assign({ className: "flex gap-4 items-center" }, { children: [_jsx(AptiveIcon, { icon: selectOption.icon, className: selectOption.iconClassName, isFilled: true }), _jsx("span", { children: selectOption.name })] }))) : ((selectOption === null || selectOption === void 0 ? void 0 : selectOption.name) || _jsx("span", __assign({ className: "text-gray-400 font-normal" }, { children: "Select" }))), _jsx("input", __assign({ id: id, type: "hidden", name: name, value: register ? formValue : value }, (register && __assign({}, register(name))), { "aria-describedby": ariaDescribedBy, onChange: onChange }))] })), _jsxs(Dropdown, __assign({ className: classNames('w-full z-10 max-h-[250px] overflow-y-auto no-scrollbar', { 'right-0': dropdownPosition === 'left' }), isOpen: isDropdownOpen, onClose: function () { } }, { children: [searchable && (_jsxs("div", __assign({ className: 'grid grid-cols-6 items-center p-2' }, { children: [_jsx(AptiveIcon, { className: "sm:col-span-1 w-4 h-4 ml-2 stroke-[#9CA3AF] fill-none", icon: 'search' }), _jsx("input", { type: "text", placeholder: "Search", className: "sm:col-span-5 w-full h-[32px] rounded border-gray-200 border p-2 text-sm focus:outline-none focus:ring-1 focus:ring-primary focus:ring-opacity-50", value: searchQuery, onChange: function (e) { return setSearchQuery(e.target.value); } })] }))), selectOptions &&
                        selectOptions.filter(function (option) { var _a; return (_a = option.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchQuery.toLowerCase()); }).map(function (_a, index) {
                            var label = _a.name, value = _a.value, icon = _a.icon, iconClassName = _a.iconClassName;
                            return (_jsx("div", __assign({ onClick: function () { return handleChange(value); }, className: classNames('pl-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-700 text-sm') }, { children: icon ? _jsxs("div", __assign({ className: 'flex gap-4 items-center' }, { children: [_jsx(AptiveIcon, { icon: icon, className: iconClassName, isFilled: true }), _jsx("span", { children: label })] })) : label }), index));
                        })] }))] })));
};
export default CustomSelect;
