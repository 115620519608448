import { CustomFormElement } from '@/components';
import { useFormContext } from 'react-hook-form';

const Priority = () => {
  const { register } = useFormContext();

  return (
    <CustomFormElement
      {...{ register }}
      type="number"
      name="priority"
      label="Priority"
      step={1}
      min={0}
      max={999999}
      required
    />
  );
};

export default Priority;
