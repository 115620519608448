import { CustomFormElement } from '@/components/common/Form';
import useSearchParamFilter from './useSearchParamFilter';
import { historyOptionsSelector } from '@/modules/AdminTools/redux/editHistory';
import { useSelector } from 'react-redux';

const ModelFilter = () => {
  const modelType = useSearchParamFilter('model_type');
  const modelId = useSearchParamFilter('model_id');

  const { model_types: modelTypes } = useSelector(historyOptionsSelector);

  return (
    <div className="flex flex-row space-x-4">
      <div className="w-1/2">
        <label htmlFor="model_type">Object Type</label>
        <CustomFormElement
          type="select"
          selectOptions={modelTypes}
          value={modelType.value}
          onChange={modelType.setSearchValue}
          id="model_type"
        />
      </div>
      <div className="w-1/2">
        <label htmlFor="model_id">Object Id</label>
        <CustomFormElement
          type="text"
          value={modelId.value}
          onChange={modelId.setSearchValue}
          id="model_id"
        />
      </div>
    </div>
  );
};

export default ModelFilter;
