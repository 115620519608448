var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AptiveIcon, Button, Modal } from '@aptive-env/storybook';
import { eventValidationConstants } from 'constants/index';
var EVENT_TITLE_MISSING_WARNING_TITLE = eventValidationConstants.EVENT_TITLE_MISSING_WARNING_TITLE, EVENT_PAST_DATE_WARNING_TITLE = eventValidationConstants.EVENT_PAST_DATE_WARNING_TITLE;
export var EventDataWarningModal = function (_a) {
    var open = _a.open, data = _a.data, onClickGoBack = _a.onClickGoBack, onClickSave = _a.onClickSave;
    var handleClickSave = function () {
        if (data.title === EVENT_TITLE_MISSING_WARNING_TITLE || data.title === EVENT_PAST_DATE_WARNING_TITLE)
            onClickSave(data.title);
        else
            onClickSave();
    };
    return (_jsx(Modal, __assign({ open: open, className: "w-[500px]" }, { children: _jsxs("div", __assign({ className: "p-5" }, { children: [_jsxs("div", __assign({ className: "flex gap-3" }, { children: [_jsx("div", __assign({ className: "bg-[#fce8e8] w-[40px] h-[40px] rounded-[50%] flex-none flex items-center justify-center" }, { children: _jsx(AptiveIcon, { className: "w-[24px] h-[24px] stroke-[#c81e1d] fill-none", icon: "exclamation" }) })), _jsxs("div", { children: [_jsx("div", __assign({ className: "text-xl font-light" }, { children: data.title })), _jsx("div", __assign({ className: "text-xs text-aro-gray mt-2" }, { children: data.content }))] })] })), _jsxs("div", __assign({ className: "flex justify-end mt-3" }, { children: [_jsx(Button, __assign({ size: "small", variant: "neutral", onClick: onClickGoBack }, { children: "Go back" })), _jsx(Button, __assign({ size: "small", variant: "danger", className: "ml-2", onClick: handleClickSave }, { children: "Save" }))] }))] })) })));
};
