import { productManagerConstants } from '@/modules/AdminTools/lib/constants';
import Header from '@/modules/AdminTools/components/Header';
import ServiceCategoryForm from './ServiceCategoryForm';
import { CustomButton } from '@/components';
import { createProductCategoryAsync } from '@/modules/AdminTools/redux/productManager/product-categories';

const defaultValues = {
  [productManagerConstants.NAME]: '',
  [productManagerConstants.ORDER]: 0,
  [productManagerConstants.PRODUCT_SUB_CATEGORIES]: [],
};
const { SERVICE_CATEGORIES } = productManagerConstants;

const ServiceCategoryHeader = () => (
  <Header title={SERVICE_CATEGORIES}>
    <ServiceCategoryForm
      defaultValues={defaultValues}
      request={createProductCategoryAsync.request}
    >
      <CustomButton color={'green'}>Create Service Category</CustomButton>
    </ServiceCategoryForm>
  </Header>
);

export default ServiceCategoryHeader;
