import { useRef, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
var MapAdvancedMarker = function (_a) {
    var map = _a.map, position = _a.position, title = _a.title, zIndex = _a.zIndex, content = _a.content;
    var markerRef = useRef();
    useEffect(function () {
        var options = {
            map: map,
            position: position,
            zIndex: zIndex,
            title: title,
        };
        if (content) {
            var pinOptions = {
                background: content.background,
                borderColor: content.borderColor,
                glyphColor: content.glyphColor,
                scale: content.scale
            };
            if (content.glyph) {
                var markup = renderToStaticMarkup(content.glyph);
                var glyph = document.createElement('div');
                glyph.innerHTML = markup;
                pinOptions.glyph = glyph;
            }
            var pin = new google.maps.marker.PinElement(pinOptions);
            options.content = pin.element;
        }
        var marker = new google.maps.marker.AdvancedMarkerElement(options);
        markerRef.current = marker;
        return function () {
            if (markerRef.current) {
                markerRef.current.map = null;
            }
        };
    }, [map, position, zIndex, title, content]);
    return null;
};
export default MapAdvancedMarker;
