import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

const FilterValue = ({ field, value, format }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchValue = () => {
    searchParams.set(field, value);
    setSearchParams(searchParams);
  };


  return (
    <div className="text-primary-300 cursor-pointer" onClick={setSearchValue}>
      {!format && value}
      {format && format(value)}
    </div>
  );
};

FilterValue.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.any,
  format: PropTypes.func,
};

export default FilterValue;
