import PropTypes from 'prop-types';

import GroupPlanUpdate from './GroupPlanUpdate';

const GroupPlans = ({ groupId, groupPlans }) => {
  return (
    <>
      {groupPlans.map((groupPlan, index) => (
        <div key={index} className={groupPlan.group_id === +groupId ? '' : 'hidden'}>
          <GroupPlanUpdate groupPlan={groupPlan} />
        </div>
      ))}
    </>
  );
};

GroupPlans.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groupPlans: PropTypes.array,
};

export default GroupPlans;
