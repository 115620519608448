export var Colors;
(function (Colors) {
    Colors["ARO_BLUE"] = "#0EA5E9";
    Colors["ARO_RED"] = "#f43f5d";
    Colors["ARO_PURPLE"] = "#8b5cf5";
    Colors["ARO_GREEN"] = "#0fba81";
    Colors["ARO_ORANGE"] = "#f49e0c";
    Colors["ARO_PINK"] = "#ef5da8";
    Colors["ARO_GRAY"] = "#808080";
    Colors["ARO_LIGHT_BLUE"] = "#daf3fc";
    Colors["ARO_LIGHT_RED"] = "#fee2e7";
    Colors["ARO_LIGHT_PURPLE"] = "#eee7fe";
    Colors["ARO_LIGHT_GREEN"] = "#dbf5ec";
    Colors["ARO_LIGHT_ORANGE"] = "#fef2dc";
    Colors["ARO_LIGHT_PINK"] = "#fde7f2";
    Colors["ARO_LIGHT_GRAY"] = "#transparent";
})(Colors || (Colors = {}));
