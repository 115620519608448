import PropTypes from 'prop-types';
import CustomGroupForm from '../CustomGroupForm';
import { updateCustomGroupTypeAsync } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/custom-group-types';

const EditCustomGroup = ({ customGroup }) => {
  return (
    <CustomGroupForm
      defaultValues={customGroup}
      request={updateCustomGroupTypeAsync.request}
    >
      <div className="text-primary-300 cursor-pointer">{customGroup.name}</div>
    </CustomGroupForm>
  );
};

EditCustomGroup.propTypes = {
  customGroup: PropTypes.object.isRequired,
};

export default EditCustomGroup;
