import { onboardingConstants } from '@/lib/constants';

const {
  FIRST_NAME,
  LAST_NAME,
  FULL_NAME,
  DATE_OF_BIRTH,
  ADDRESS_CITY,
  ADDRESS_STATE,
  ADDRESS_ZIP,
  EXPERIENCE,
  ADDRESS_COUNTRY,
  HOUSING_TYPE,
  NUM_OF_ROOMS,
  ROOMMATE_REQUEST,
  TENTATIVE_KNOCKING_START_DATE,
  TENTATIVE_KNOCKING_END_DATE,
  ACTUAL_START_DATE,
  ACTUAL_END_DATE,
  RENT_TIER,
  OPT_IN,
  PRESEASON_HOUSING_TYPE,
  PRESEASON_NUM_OF_ROOMS,
  PRESEASON_TENTATIVE_KNOCKING_START_DATE,
  PRESEASON_TENTATIVE_KNOCKING_END_DATE,
  PRESEASON_ACTUAL_START_DATE,
  PRESEASON_ACTUAL_END_DATE,
  POSTSEASON_HOUSING_TYPE,
  POSTSEASON_NUM_OF_ROOMS,
  POSTSEASON_TENTATIVE_KNOCKING_START_DATE,
  POSTSEASON_TENTATIVE_KNOCKING_END_DATE,
  POSTSEASON_ACTUAL_START_DATE,
  POSTSEASON_ACTUAL_END_DATE,
  DRIVER_LICENSE_NUMBER,
  DRIVER_LICENSE_EXPIRATION_DATE,
  DRIVER_LICENSE_STATE_ISSUED,
  LIMITED_LIABILITY_COMPANY,
  EMPLOYER_IDENTIFICATION_NUMBER,
  SOLICITOR_PERMIT_NUMBER,
  SOLICITOR_EXPIRATION_DATE,
} = onboardingConstants;

const isTrue = (value) => [true, 'true', 1, '1', 'Yes', 'yes'].includes(value);

export const bedManagementProfileDataAdapter = (data, userId) => {
  const allowedEmptyFields = [
    ROOMMATE_REQUEST,
  ];

  const newData = {};
  const keys = [
    { 'first_name': FIRST_NAME },
    { 'last_name': LAST_NAME },
    { 'preferred_name': FULL_NAME },
    { 'dob': DATE_OF_BIRTH },
    { 'permanent_city': ADDRESS_CITY },
    { 'permanent_country': ADDRESS_COUNTRY },
    { 'permanent_state': ADDRESS_STATE },
    { 'permanent_zip': ADDRESS_ZIP },
    { 'experience': EXPERIENCE },
    { 'apartment_status_id': HOUSING_TYPE },
    { 'no_of_rooms': NUM_OF_ROOMS },
    { 'roommate_request': ROOMMATE_REQUEST },
    { 'opt_in': OPT_IN },
    { 'rent_tier': RENT_TIER },
    { 'start_date': TENTATIVE_KNOCKING_START_DATE },
    { 'end_date': TENTATIVE_KNOCKING_END_DATE },
    { 'actual_start_date': ACTUAL_START_DATE },
    { 'actual_end_date': ACTUAL_END_DATE },
    {
      'pre_season': [
        { 'apartment_status_id': PRESEASON_HOUSING_TYPE },
        { 'number_of_rooms': PRESEASON_NUM_OF_ROOMS },
        { 'start_date': PRESEASON_TENTATIVE_KNOCKING_START_DATE },
        { 'end_date': PRESEASON_TENTATIVE_KNOCKING_END_DATE },
        { 'actual_start_date': PRESEASON_ACTUAL_START_DATE },
        { 'actual_end_date': PRESEASON_ACTUAL_END_DATE },
      ]
    },
    {
      'post_season': [
        { 'apartment_status_id': POSTSEASON_HOUSING_TYPE },
        { 'number_of_rooms': POSTSEASON_NUM_OF_ROOMS },
        { 'start_date': POSTSEASON_TENTATIVE_KNOCKING_START_DATE },
        { 'end_date': POSTSEASON_TENTATIVE_KNOCKING_END_DATE },
        { 'actual_start_date': POSTSEASON_ACTUAL_START_DATE },
        { 'actual_end_date': POSTSEASON_ACTUAL_END_DATE },
      ]
    },
    { 'drivers_license_number': DRIVER_LICENSE_NUMBER },
    { 'drivers_license_expiration_date': DRIVER_LICENSE_EXPIRATION_DATE },
    { 'state_issued': DRIVER_LICENSE_STATE_ISSUED },
    { 'employer_identification_number': EMPLOYER_IDENTIFICATION_NUMBER },
    { 'limited_liability_company': LIMITED_LIABILITY_COMPANY },
    { 'solicitor_expiration_date': SOLICITOR_EXPIRATION_DATE },
    { 'solicitor_permit_number': SOLICITOR_PERMIT_NUMBER },
  ];

  keys.forEach(item => {
    const [key, value] = Object.entries(item)[0];

    switch (true) {
      case allowedEmptyFields.includes(value) && data[value]?.length === 0:
        newData[key] = data[value];
        break;
      case Array.isArray(value):
        value.forEach(subItem => {
          const [subKey, subValue] = Object.entries(subItem)[0];
          if (data[subValue]) {
            if (newData[key] === undefined) {
              newData[key] = {};
            }

            newData[key][subKey] = data[subValue];
          }
        });
        break;
      case value === OPT_IN:
        newData[key] = isTrue(data[value]);
        break;
      case data[value]?.length === 0 || data[value] === null:
        break;
      default:
        newData[key] = data[value];
        break;
    }
  });

  newData['userId'] = userId;

  return newData;
}
