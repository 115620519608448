import { createSelector } from 'reselect';

const bedManagementObjectSelector = (state) => state.bedManagement;

export const unBeddedLastPageSelector = createSelector(
  bedManagementObjectSelector,
  (state) => state?.unBeddedLastPage,
);

export const unBeddedSummariesSelector = createSelector(
  bedManagementObjectSelector,
  (state) => state?.unBeddedSummaries,
);

export const bedManagementDataSelector = createSelector(
  bedManagementObjectSelector,
  (state) => state?.bedManagementData,
);

export const bedManagementDataTotalSelector = createSelector(
  bedManagementObjectSelector,
  (state) => state?.bedManagementTotal,
);

export const bedManagementStatisticSelector = createSelector(
  bedManagementObjectSelector,
  (state) => state?.bedManagementStatistic
);
