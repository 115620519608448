export var defaultSelectOption = [
    { value: '', name: '-- Select --' },
];
export var prioritySelectionOption = [
    { value: 'high', name: 'High', icon: 'chevronUp', iconClassName: 'w-[16px] h-[16px] fill-[#F05252] stroke-none' },
    { value: 'medium', name: 'Medium', icon: 'menuAlt4', iconClassName: 'w-[16px] h-[16px] fill-[#F59E0B] stroke-none' },
    { value: 'low', name: 'Low', icon: 'chevronDown', iconClassName: 'w-[16px] h-[16px] fill-[#0EA5E9] stroke-none' }
];
export var submissionTypeSelectionOption = [
    { value: 'email', name: 'Email' },
    { value: 'online', name: 'Online' },
    { value: 'in-person', name: 'In person' },
];
