import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { normalizeRows, Props, TableRow, TableCell } from '.';

const TableFoot = ({
  rows,
  ...props
}) => (
  <tfoot {...(new Props(props).withClassName('divide-y'))}>
    {normalizeRows(rows).map(({ cells, ...props }) => (
      <TableRow
        key={uuidv4()}
        {...(new Props(props).withClassName('bg-gray-100 text-gray-600 text-xs'))}
      >
        {cells.map(({ ...props }) => (
          <TableCell
            key={uuidv4()}
            {...(new Props(props))}
          />
        ))}
      </TableRow>
    ))}
  </tfoot>
);

TableFoot.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default TableFoot;
