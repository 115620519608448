import { CalendarIcon, UserGroupIcon } from '@heroicons/react/outline';
import person from '../../../../../../assets/person.png';
import { Loader } from '@/components';
import { addFsExcludeClass } from '@/lib/utils';
import { selectStates } from '@/redux/addresses';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  formatDate,
} from '@/lib';

const ProfileHeader = (
  {
    rep,
    repIsLoading,
    openFullProfile,
    states,
  },
) => {
  const [stateName, setStateName] = useState('');

  useEffect(() => {
    if (states) {
      const fullState = states[parseInt(rep?.currentAddressCountry)]?.find(item => item.value === rep.currentAddressState);
      setStateName(fullState?.name);
    }
  }, [
    states,
    setStateName,
    rep,
  ]);

  const age = (dob) => {
    const today = new Date();
    const birthday = new Date(dob);

    return today.getFullYear() - birthday.getFullYear();
  };

  return (
    <div className="px-4 py-6 sm:px-6 bg-gray-100">
      <div className="flex items-start justify-between">
        <div className="flex items-center mb-4 space-x-4 lg:space-x-6">
          <img
            className={addFsExcludeClass('w-16 h-16 rounded-full lg:w-20 lg:h-20')}
            src={rep.profileImg ? rep.profileImg : person}
            alt=""
          />
          <div className="space-y-1 text-lg font-medium leading-6 ">
            {
              repIsLoading ? <Loader /> :
                <>
                  <h3 className={addFsExcludeClass()}>{rep && `${rep?.firstName} ${rep?.lastName}`}</h3>
                  <ul className="list-disc flex flex-row space-x-8 text-xs text-gray-400">
                    <li className="list-none">{rep && `${rep?.status}`}</li>
                    <li className="">{rep && `Age ${age(rep?.dob)}`}</li>
                    <li className="">{rep && `${rep?.currentAddressCity}, ${stateName}`}</li>
                  </ul>
                </>
            }
          </div>
        </div>
        <div
          className="flex items-center mt-6 space-x-4 lg:space-x-6 text-sm cursor-pointer"
          onClick={openFullProfile}
        >
          View full profile
        </div>
      </div>
      <div className="flex space-x-4 items-start">
        {
          repIsLoading ? <Loader /> :
            <>
              <div className="flex text-gray-400">
                <CalendarIcon className="w-6 h-6 text-gray-500 mr-2" />
                <p>{rep && `Joined ${formatDate(rep?.userCreatedAt, 'MMMM YYYY')}`}</p>
              </div>
              <div className="flex text-gray-400">
                <UserGroupIcon className="w-6 h-6 text-gray-500 mr-2" />
                <p>{rep && `${rep?.team}`}</p>
              </div>
            </>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  states: selectStates(state),
});

export default connect(mapStateToProps)(ProfileHeader);
