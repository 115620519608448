var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { addDays, endOfWeek, format, isToday, startOfWeek } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import EventCard from 'components/EventCard';
import EventDetailModal from 'components/EventDetailModal';
import { fetchCalendarEvents, fetchCalendarEventParticipants } from 'services/calendar';
import { generateTimeArray } from 'utils/generateTimeArray';
import { groupOverlappingEvents } from 'utils/groupOverlappingEvents';
var HEADER_HEIGHT = 64;
var TIME_BLOCK_HEIGHT = 36;
var CalendarWeekView = function (_a) {
    var officeId = _a.officeId, currentDate = _a.currentDate, eventTypes = _a.eventTypes, onEventSuccess = _a.onEventSuccess;
    var _b = useState([]), weekDays = _b[0], setWeekDays = _b[1];
    var timeArrays = generateTimeArray(false);
    var _c = useState({}), mapOfGroupedOverlappingEvents = _c[0], setMapOfGroupedOverlappingEvents = _c[1];
    var _d = useState(false), isEventDetailOpened = _d[0], setIsEventDetailOpened = _d[1];
    var _e = useState(false), isEventDetailLoading = _e[0], setIsEventDetailLoading = _e[1];
    var _f = useState(null), selectedEvent = _f[0], setSelectedEvent = _f[1];
    var _g = useState(null), selectedEventDate = _g[0], setSelectedEventDate = _g[1];
    var _h = useState([]), selectedEventParticipants = _h[0], setSelectedEventParticipants = _h[1];
    useEffect(function () {
        fetchEvents();
        // eslint-disable-next-line
    }, [currentDate, officeId]);
    var fetchEvents = function () {
        var firstDayOfWeek = startOfWeek(currentDate);
        var lastDayOfWeek = endOfWeek(currentDate);
        var tempWeekDays = [firstDayOfWeek];
        for (var i = 1; i < 7; i++)
            tempWeekDays.push(addDays(tempWeekDays[i - 1], 1));
        setWeekDays(tempWeekDays);
        fetchCalendarEvents({
            office_id: officeId,
            start_date: format(firstDayOfWeek, 'yyyy-MM-dd'),
            end_date: format(addDays(lastDayOfWeek, 1), 'yyyy-MM-dd'),
        })
            .then(function (response) {
            var mapOfEvents = response.result.events;
            var tempMapOfGroupedOverlappingEvents = {};
            Object.keys(mapOfEvents).forEach(function (key) {
                var events = mapOfEvents[key];
                events.forEach(function (event) {
                    event.is_hidden = false;
                });
                tempMapOfGroupedOverlappingEvents[key] = groupOverlappingEvents(events);
            });
            setMapOfGroupedOverlappingEvents(tempMapOfGroupedOverlappingEvents);
        })
            .catch(function (e) {
            console.log(e);
        });
    };
    var handleClickCard = function (event, day) {
        setSelectedEvent(event);
        setSelectedEventDate(day);
        setIsEventDetailOpened(true);
        setIsEventDetailLoading(true);
        setSelectedEventParticipants([]);
        fetchCalendarEventParticipants(event.id)
            .then(function (response) {
            setSelectedEventParticipants(response);
            setIsEventDetailLoading(false);
        });
    };
    var handleHideShowEvent = function (eventId, eventDate, isAll, isHidden) {
        var tempMapOfGroupedOverlappingEvents = __assign({}, mapOfGroupedOverlappingEvents);
        for (var _i = 0, _a = Object.keys(tempMapOfGroupedOverlappingEvents); _i < _a.length; _i++) {
            var date = _a[_i];
            var tempGroupedOverlappingEvents = tempMapOfGroupedOverlappingEvents[date];
            for (var _b = 0, tempGroupedOverlappingEvents_1 = tempGroupedOverlappingEvents; _b < tempGroupedOverlappingEvents_1.length; _b++) {
                var tempGroupedOverlappingEvent = tempGroupedOverlappingEvents_1[_b];
                for (var _c = 0, tempGroupedOverlappingEvent_1 = tempGroupedOverlappingEvent; _c < tempGroupedOverlappingEvent_1.length; _c++) {
                    var event_1 = tempGroupedOverlappingEvent_1[_c];
                    if (isAll && event_1.id === eventId) {
                        event_1.is_hidden = isHidden;
                        break;
                    }
                    if (!isAll && event_1.id === eventId && format(eventDate, 'yyyy-MM-dd') === date) {
                        event_1.is_hidden = isHidden;
                        break;
                    }
                }
            }
        }
        setMapOfGroupedOverlappingEvents(tempMapOfGroupedOverlappingEvents);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex flex-col flex-auto overflow-hidden" }, { children: [_jsxs("div", __assign({ className: "relative flex flex-none overflow-y-scroll" }, { children: [_jsx("div", { className: "flex-none w-[64px] h-[64px] p-2 border-b border-r border-[#CBCBCB80]" }), weekDays.map(function (day, dayIndex) { return (_jsxs("div", __assign({ className: 'flex flex-col w-full p-2 border-r border-b border-[#E5E7EB]', style: {
                                    height: HEADER_HEIGHT
                                } }, { children: [_jsx("div", __assign({ className: "text-sm font-normal uppercase text-text-default m-auto" }, { children: format(day, 'ccc') })), isToday(day) ? (_jsx("div", __assign({ className: "w-[32px] h-[32px] flex items-center justify-center m-auto bg-[#007AFF] rounded-lg" }, { children: _jsx("div", __assign({ className: "text-base font-bold text-white" }, { children: format(day, 'dd') })) }))) : (_jsx("div", __assign({ className: "w-[32px] h-[32px] flex items-center justify-center m-auto" }, { children: _jsx("div", __assign({ className: "text-base font-bold text-text-default" }, { children: format(day, 'dd') })) })))] }), dayIndex)); })] })), _jsxs("div", __assign({ className: "flex flex-auto overflow-auto rounded-br-[8px] rounded-bl-[8px] border-t border-[#CBCBCB80] bg-white" }, { children: [_jsx("div", __assign({ className: 'relative flex flex-col w-[64px] flex-none' }, { children: timeArrays.map(function (time, timeIndex) { return (_jsx("div", __assign({ className: 'flex justify-center text-sm text-text-default font-normal border-r border-[#CBCBCB80] flex-none', style: {
                                        height: TIME_BLOCK_HEIGHT * 2
                                    } }, { children: time !== '12 AM' ? (_jsx("div", __assign({ className: "mt-[-11px]" }, { children: time }))) : (_jsx("div", {})) }), timeIndex)); }) })), weekDays.map(function (day) {
                                var _a;
                                return (_jsxs("div", __assign({ className: 'relative flex flex-1 flex-col' }, { children: [__spreadArray(__spreadArray([], timeArrays, true), timeArrays, true).map(function (_, timeIndex) { return (_jsx("div", { className: "flex items-center flex-none justify-center text-sm text-text-default font-normal border-r border-[#CBCBCB80] ".concat(timeIndex < __spreadArray(__spreadArray([], timeArrays, true), timeArrays, true).length - 1 ? 'border-b' : ''), style: { height: TIME_BLOCK_HEIGHT } }, timeIndex)); }), (_a = mapOfGroupedOverlappingEvents[format(day, 'yyyy-MM-dd')]) === null || _a === void 0 ? void 0 : _a.map(function (groupedOverlappingEvent) {
                                            // eslint-disable-next-line array-callback-return
                                            return groupedOverlappingEvent.map(function (event, eventIndex) {
                                                if (!event.is_hidden && event.is_canceled === 'false') {
                                                    return (_jsx(EventCard, { event: event, view: "week", groupTotal: groupedOverlappingEvent.length, groupIndex: eventIndex, selectedTypes: eventTypes, onClick: function () { return handleClickCard(event, day); } }, "".concat(event.id, ":").concat(uuidv4())));
                                                }
                                            });
                                        })] }), format(day, 'MM/dd/yyyy')));
                            })] }))] })), selectedEvent && selectedEventDate && (_jsx(EventDetailModal, { event: selectedEvent, eventDate: selectedEventDate, participants: selectedEventParticipants, loading: isEventDetailLoading, open: isEventDetailOpened, onDelete: fetchEvents, onHideShow: handleHideShowEvent, onSuccess: onEventSuccess, onClose: function () { return setIsEventDetailOpened(false); } }))] }));
};
export default CalendarWeekView;
