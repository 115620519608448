import AreaPlan from '../AreaPlan';
import Headers from './Headers';

const DefaultAreaPlan = () => {
  return (
    <AreaPlan name={'default_area_plan'} {...Headers} />
  );
};

export default DefaultAreaPlan;
