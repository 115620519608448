var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, AptiveIcon, Dropdown, DropdownItem } from '@aptive-env/storybook';
import CalendarSearch from 'components/CalendarSearch';
import MultiEventSelect from 'components/shared/MultiEventSelect';
import CalendarYearView from 'components/CalendarYearView';
import CalendarWeekView from 'components/CalendarWeekView';
import CalendarDayView from 'components/CalendarDayView';
import CalendarMonthView from 'components/CalendarMonthView';
var VIEWS = [
    {
        value: 'day',
        label: 'Day view'
    },
    {
        value: 'week',
        label: 'Week view'
    },
    {
        value: 'month',
        label: 'Month view'
    },
    {
        value: 'year',
        label: 'Year view'
    },
];
var OfficeCalendar = function (_a) {
    var officeId = _a.officeId, onClickAddNewEvent = _a.onClickAddNewEvent, onSuccess = _a.onSuccess, eventTypes = _a.eventTypes, activeDate = _a.activeDate, viewMode = _a.viewMode, setViewMode = _a.setViewMode;
    var _b = useState(eventTypes), selectedTypes = _b[0], setSelectedTypes = _b[1];
    var _c = useState(VIEWS[viewMode]), view = _c[0], setView = _c[1];
    var _d = useState(false), isViewDropdownOpen = _d[0], setIsViewDropdownOpen = _d[1];
    useEffect(function () {
        setSelectedTypes(eventTypes);
    }, [eventTypes]);
    useEffect(function () {
        setView(VIEWS[viewMode]);
    }, [viewMode]);
    return (_jsx("div", __assign({ className: "grow p-3 flex flex-col" }, { children: _jsxs("div", __assign({ className: "flex flex-col shadow-[0px_3px_8px_0px_rgba(87,102,117,0.06),_0px_6px_12px_0px_rgba(106,115,129,0.16)] rounded-lg overflow-auto" }, { children: [_jsxs("div", __assign({ className: "flex justify-between p-4 bg-[#f9fafb] z-[1]" }, { children: [_jsxs("div", __assign({ className: "flex items-center gap-5" }, { children: [_jsx(MultiEventSelect, { options: eventTypes, value: selectedTypes, onChange: function (value) { return setSelectedTypes(value); } }), _jsx(CalendarSearch, { officeId: officeId, onEventSuccess: onSuccess }, officeId)] })), _jsxs("div", __assign({ className: "flex items-center gap-5" }, { children: [_jsxs("div", __assign({ className: "relative" }, { children: [_jsx(Button, __assign({ variant: "neutral", icon: "chevronDown", position: "right", className: "h-9 bg-white", onClick: function (event) {
                                                event.stopPropagation();
                                                setIsViewDropdownOpen(function (prev) { return !prev; });
                                            } }, { children: view.label })), _jsx(Dropdown, __assign({ isOpen: isViewDropdownOpen, onClose: function () {
                                                setIsViewDropdownOpen(false);
                                            }, className: "bg-white" }, { children: VIEWS.map(function (item, index) { return (_jsx(DropdownItem, __assign({ onClick: function () {
                                                    setView(item);
                                                    setViewMode(index);
                                                    setIsViewDropdownOpen(false);
                                                } }, { children: item.label }), item.value)); }) }))] })), _jsxs(Button, __assign({ className: "h-[36px] !p-[8px]", onClick: onClickAddNewEvent }, { children: [_jsx(AptiveIcon, { className: "w-[20px] h-[20px] fill-none", icon: "plusCircle" }), _jsx("span", __assign({ className: "pl-[4px]" }, { children: "Add new event" }))] }))] }))] })), view.value === 'day' && (_jsx(CalendarDayView, { currentDate: activeDate, eventTypes: selectedTypes, officeId: officeId, onEventSuccess: onSuccess })), view.value === 'week' && (_jsx(CalendarWeekView, { currentDate: activeDate, eventTypes: selectedTypes, officeId: officeId, onEventSuccess: onSuccess })), view.value === 'month' && (_jsx(CalendarMonthView, { officeId: officeId, activeDate: activeDate, eventTypes: selectedTypes, onEventSuccess: onSuccess })), view.value === 'year' && (_jsx(CalendarYearView, { officeId: officeId, year: activeDate.getFullYear(), eventTypes: selectedTypes }))] })) })));
};
export default OfficeCalendar;
