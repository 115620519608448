import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { planSelector } from '@/modules/AdminTools/redux/planBuilder/plans';
import AreaPlanUpdate from './AreaPlanUpdate';

const AreaPlans = ({ areaId }) => {
  const updatePlanDefaultValues = useSelector(planSelector);
  const areaPlans = updatePlanDefaultValues.area_plans;

  return (
    <>
      {areaPlans.map((areaPlan, index) => (
        <div key={index} className={areaPlan.area_id === +areaId ? '' : 'hidden'}>
          <AreaPlanUpdate
            areaPlan={areaPlan}
          />
        </div>
      ))}
    </>
  );
};

AreaPlans.propTypes = {
  areaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AreaPlans;
