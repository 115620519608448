import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '@/modules/AdminTools/api/planBuilder';
import { createRequestSaga } from '@/redux/helpers';
import {
  requestGroupPlansAsync,
  updateGroupPlanAsync,
  createGroupPlanAsync,
  removeGroupPlanAsync,
} from './actions';
import {
  createPlanBuilderGroupPlan,
  removePlanBuilderGroupPlan,
  updatePlanBuilderGroupPlan,
} from '../plans';

function* requestGroupPlansSaga() {
  const response = yield call(Api.getGroupPlans);

  yield put(requestGroupPlansAsync.success(response));
}

function* updateGroupPlanSaga({ payload: { groupPlan } }) {
  const response = yield call(Api.updateGroupPlan(groupPlan.id), groupPlan);

  yield put(updatePlanBuilderGroupPlan(response));
}

function* createGroupPlanSaga({ payload: { groupPlan } }) {
  const response = yield call(Api.createGroupPlan, groupPlan);

  yield put(createPlanBuilderGroupPlan(response));
}

function* removeGroupPlanSaga({ payload: { groupPlan } }) {
  yield call(Api.removeGroupPlan(groupPlan.id));

  yield put(removePlanBuilderGroupPlan(groupPlan));
}

export function* groupPlansWatcher() {
  yield takeLatest(
    requestGroupPlansAsync.request.type,
    createRequestSaga(requestGroupPlansSaga, {
      keyNew: 'groupPlans',
      errKey: 'groupPlans',
      write: false,
    }),
  );

  yield takeLatest(
    updateGroupPlanAsync.request.type,
    createRequestSaga(updateGroupPlanSaga, {
      keyNew: 'groupPlan',
      errKey: 'groupPlan',
      write: false,
    }),
  );

  yield takeLatest(
    createGroupPlanAsync.request.type,
    createRequestSaga(createGroupPlanSaga, {
      keyNew: 'groupPlan',
      errKey: 'groupPlan',
      write: false,
    }),
  );

  yield takeLatest(
    removeGroupPlanAsync.request.type,
    createRequestSaga(removeGroupPlanSaga, {
      keyNew: 'groupPlan',
      errKey: 'groupPlan',
      write: false,
    }),
  );
}
