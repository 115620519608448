var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
var Popover = function (_a) {
    var open = _a.open, children = _a.children, content = _a.content, position = _a.position, _b = _a.className, className = _b === void 0 ? '' : _b, onClickOutside = _a.onClickOutside;
    var ref = useRef(null);
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return function () {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [
        onClickOutside
    ]);
    return (_jsxs("div", __assign({ className: open ? 'relative' : '', ref: ref }, { children: [children, open && (_jsx("div", __assign({ className: "bg-white rounded absolute z-10 ".concat(className, " ").concat(position === 'top' ? 'bottom-[100%] mb-2' : position === 'bottom' ? 'mt-2' : position === 'right' ? 'mt-2 left-0' : position === 'left' ? 'mt-2 right-0' : ''), style: { boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' } }, { children: content })))] })));
};
export default Popover;
