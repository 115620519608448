import { useSelector } from 'react-redux';

import { CustomFormElement } from '@/components/common/Form';
import useSearchParamFilter from './useSearchParamFilter';
import { historyOptionsSelector } from '@/modules/AdminTools/redux/editHistory';

const ActionFilter = () => {
  const { value, setSearchValue } = useSearchParamFilter('action');
  const { actions } = useSelector(historyOptionsSelector);

  return (
    <div>
      <label htmlFor="actions">Action</label>
      <CustomFormElement
        type="select"
        selectOptions={actions}
        value={value}
        onChange={setSearchValue}
        id="actions"
      />
    </div>
  );
};

export default ActionFilter;
