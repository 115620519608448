import { jsx as _jsx } from "react/jsx-runtime";
import { Navbars, Navbar as Item } from '@aptive-env/storybook';
var items = [
    { title: 'Personal Info', value: 1 },
    { title: 'Schedule', value: 2 },
    { title: 'Licensing', value: 3 },
    { title: 'Notes', value: 4 },
];
var Navbar = function (_a) {
    var setSelectedItem = _a.setSelectedItem;
    var handleClick = function (value) {
        setSelectedItem(value);
    };
    return (_jsx("div", { children: _jsx(Navbars, { children: items.map(function (item) { return (_jsx(Item, { title: item.title, onClick: function () { return handleClick(item.value); } }, item.value)); }) }) }));
};
export default Navbar;
