import { Loader, Paginator } from '@/components';
import PropTypes from 'prop-types';
import { forwardRef, isValidElement } from 'react';
import { Props, TableHead, TableBody, TableFoot } from '.';

const Table = forwardRef(({
  loading = false,
  loader = {},
  wrapper = {},
  thead,
  tbody,
  tfoot,
  paginator,
  isStickyHeader = true,
  ...props
}, ref) => (
  loading ? (
    <Loader {...new Props(loader).withClassName('py-16')} />
  ) : (
    <div
      ref={ref}
      {...new Props(wrapper).withClassName('border rounded-lg overflow-auto')}
    >
      <table
        {...new Props(props).withClassName('min-w-full divide-y bg-white')}
      >
        {thead && (isValidElement(thead) ? thead : <TableHead isStickyHeader={isStickyHeader} {...thead} />)}
        {tbody && (isValidElement(tbody) ? tbody : <TableBody isStickyHeader={isStickyHeader} {...tbody} />)}
        {tfoot && (isValidElement(tfoot) ? tfoot : <TableFoot {...tfoot} />)}
      </table>
      {paginator &&
        (isValidElement(paginator) ? (
          paginator
        ) : (
          <Paginator
            {...{ className: 'sticky inset-x-0 bottom-0', ...paginator }}
          />
        ))}
    </div>
  )
));

Table.propTypes = {
  loading: PropTypes.bool,
  loader: PropTypes.object,
  wrapper: PropTypes.object,
  thead: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  tbody: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  tfoot: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  paginator: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  isStickyHeader: PropTypes.bool,
};

export default Table;
