import * as yup from 'yup';
import {
  REQUIRED,
  MIN_5_DIGITS,
  MIN_2_CHARS,
  MAX_11_CHARS,
  MAX_255_CHARS,
  POSTAL_CODE_REGEX,
  KNOCKING_END_DATE_MIN_MESSAGE,
  INVALID_DATE_MESSAGE,
  ACTUAL_END_DATE_MIN_MESSAGE,
  DRIVER_LICENSE_NUMBER_REGEX,
  MAX_20_CHARS,
  EIN_MAX_VALIDATION_MESSAGE,
} from '@/lib/validations';
import moment from 'moment';
import { getMaxDOBDate } from '@/lib/utils';

export const personalInfoValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .matches(/.{2,}/, {
      excludeEmptyString: true,
      message: MIN_2_CHARS,
    })
    .max(255, MAX_255_CHARS),
  lastName: yup.string()
    .checkWhiteSpacesOnly(REQUIRED)
    .matches(/.{2,}/, {
      excludeEmptyString: true,
      message: MIN_2_CHARS,
    })
    .max(255, MAX_255_CHARS),
  fullName: yup.string()
    .checkWhiteSpacesOnly(REQUIRED)
    .matches(/.{2,}/, {
      excludeEmptyString: true,
      message: MIN_2_CHARS,
    })
    .max(255, MAX_255_CHARS),
  dob: yup.string()
    .test(
      'isDOBValid',
      `Date of Birth must be a date before ${getMaxDOBDate().format('MM-DD-YYYY')}`,
      (value) => {
        let isValid = true;

        const maxDOBDate = getMaxDOBDate();
        const ageDiff = maxDOBDate.diff(moment(value));

        if (value && ageDiff < 0) {
          isValid = false;
        }

        return isValid;
      },
    ),
  // Only Admin can edit an experience field and only in edit rep form.
  experience: yup.string().nullable(),
  addressCity: yup.string()
    .checkWhiteSpacesOnly(REQUIRED)
    .matches(/.{2,}/, {
      excludeEmptyString: true,
      message: MIN_2_CHARS,
    })
    .max(255, MAX_255_CHARS),
  addressCountry: yup.string(),
  addressState: yup.string(),
  addressZip: yup.string()
    .matches(POSTAL_CODE_REGEX, {
      excludeEmptyString: true,
      message: MIN_5_DIGITS,
    })
    .max(11, MAX_11_CHARS),
});

export const housingInfoValidationSchema = yup.object().shape({
  needsHousing: yup.string(),
  tentativeKnockingStartDate: yup
    .date()
    .typeError(INVALID_DATE_MESSAGE)
    .when(['$isAdmin'], (isAdmin, schema) => {
        return isAdmin ? schema.nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null)) : schema.required(REQUIRED)
      }
    ),
  tentativeKnockingEndDate: yup
    .date()
    .typeError(INVALID_DATE_MESSAGE)
    .when(['$isAdmin'], (isAdmin, schema) => {
        return isAdmin ? schema.nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null)) : schema.required(REQUIRED)
      }
    )
    .when('tentativeKnockingStartDate', (knockingStartDate, schema) => {
      if (knockingStartDate && knockingStartDate instanceof Date && !isNaN(knockingStartDate)) {
        return schema.min(knockingStartDate, KNOCKING_END_DATE_MIN_MESSAGE);
      }

      return schema;
    }),
  actualStartDate: yup
    .date()
    .nullable()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
  actualEndDate: yup
    .date()
    .typeError(INVALID_DATE_MESSAGE)
    .when('actualStartDate', (actualStartDate, schema) => {
      if (actualStartDate && actualStartDate instanceof Date && !isNaN(actualStartDate)) {
        return schema.min(actualStartDate, ACTUAL_END_DATE_MIN_MESSAGE);
      }

      return schema;
    })
    .nullable()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
  optIn: yup.string(),
  rentTier: yup.string(),
  preseasonHousingType: yup.string(),
  preseasonTentativeKnockingStartDate: yup
    .date()
    .typeError(INVALID_DATE_MESSAGE)
    .when(['$isAdmin'], (isAdmin, schema) => {
        return isAdmin ? schema.nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null)) : schema.required(REQUIRED)
      }
    ),
  preseasonTentativeKnockingEndDate: yup
    .date()
    .typeError(INVALID_DATE_MESSAGE)
    .when(['$isAdmin'], (isAdmin, schema) => {
        return isAdmin ? schema.nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null)) : schema.required(REQUIRED)
      }
    )
    .when('preseasonTentativeKnockingStartDate', (knockingStartDate, schema) => {
      if (knockingStartDate && knockingStartDate instanceof Date && !isNaN(knockingStartDate)) {
        return schema.min(knockingStartDate, KNOCKING_END_DATE_MIN_MESSAGE);
      }

      return schema;
    }),
  preseasonActualStartDate: yup
    .date()
    .nullable()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
  preseasonActualEndDate: yup
    .date()
    .typeError(INVALID_DATE_MESSAGE)
    .when('preseasonActualStartDate', (actualStartDate, schema) => {
      if (actualStartDate && actualStartDate instanceof Date && !isNaN(actualStartDate)) {
        return schema.min(actualStartDate, ACTUAL_END_DATE_MIN_MESSAGE);
      }

      return schema;
    })
    .nullable()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
  postseasonHousingType: yup.string(),
  postseasonTentativeKnockingStartDate: yup
    .date()
    .typeError(INVALID_DATE_MESSAGE)
    .when(['$isAdmin'], (isAdmin, schema) => {
        return isAdmin ? schema.nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null)) : schema.required(REQUIRED)
      }
    ),
  postseasonTentativeKnockingEndDate: yup
    .date()
    .typeError(INVALID_DATE_MESSAGE)
    .when(['$isAdmin'], (isAdmin, schema) => {
        return isAdmin ? schema.nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null)) : schema.required(REQUIRED)
      }
    )
    .when('postseasonTentativeKnockingStartDate', (knockingStartDate, schema) => {
      if (knockingStartDate && knockingStartDate instanceof Date && !isNaN(knockingStartDate)) {
        return schema.min(knockingStartDate, KNOCKING_END_DATE_MIN_MESSAGE);
      }

      return schema;
    }),
  postseasonActualStartDate: yup
    .date()
    .nullable()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
  postseasonActualEndDate: yup
    .date()
    .typeError(INVALID_DATE_MESSAGE)
    .when('postseasonActualStartDate', (actualStartDate, schema) => {
      if (actualStartDate && actualStartDate instanceof Date && !isNaN(actualStartDate)) {
        return schema.min(actualStartDate, ACTUAL_END_DATE_MIN_MESSAGE);
      }

      return schema;
    })
    .nullable()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
});

export const licensingInfoValidationSchema = yup.object().shape({
  driverLicenseNumber: yup.string()
    .matches(DRIVER_LICENSE_NUMBER_REGEX, {
      excludeEmptyString: true,
      message: MIN_2_CHARS,
    })
    .max(20, MAX_20_CHARS),
  driverLicenseExpirationDate: yup.string(),
  driverLicenseStateIssued: yup.string(),
  limitedLiabilityCompany: yup.string(),
  employerIdentificationNumber: yup.string()
    .matches(/^\d+$/, {
      message: EIN_MAX_VALIDATION_MESSAGE,
      excludeEmptyString: true
    })
    .length(9, EIN_MAX_VALIDATION_MESSAGE),
  solicitorPermitNumber: yup.string(),
  salesStateIssued: yup.string(),
  solicitorExpirationDate: yup.string(),
});
