var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { mergeClassName } from 'lib/utils';
import { AptiveIcon } from '@aptive-env/storybook';
var CustomInput = function (_a) {
    var id = _a.id, name = _a.name, type = _a.type, disabled = _a.disabled, formValue = _a.formValue, value = _a.value, hasError = _a.hasError, max = _a.max, className = _a.className, onChange = _a.onChange, register = _a.register, childOrientation = _a.childOrientation, ariaDescribedBy = _a.ariaDescribedBy, placeholder = _a.placeholder, autoComplete = _a.autoComplete, baseClasses = _a.baseClasses, children = _a.children, onBlur = _a.onBlur, required = _a.required, onKeyDown = _a.onKeyDown, mark = _a.mark, hasCopyButton = _a.hasCopyButton;
    var classes = useMemo(function () {
        var base = type !== 'checkbox'
            ? (baseClasses === null || baseClasses === void 0 ? void 0 : baseClasses[hasError ? 'errorClasses' : 'standardClasses']) || ''
            : '';
        return mergeClassName('shadow-sm block w-full sm:text-sm rounded-md', base, className, { 'text-gray-400': disabled });
    }, [type, baseClasses, hasError, className, disabled]);
    var handleCopy = function (event) {
        event.stopPropagation();
        var input = document.getElementById(id);
        if (input) {
            input.select();
            document.execCommand('copy');
        }
    };
    return (_jsxs("div", __assign({ className: "flex ".concat(mark || hasCopyButton ? 'relative' : '') }, { children: [childOrientation === 'left' ? children : null, mark && _jsx("span", __assign({ className: "absolute inset-y-0 left-0 flex items-center pl-3 text-xs" }, { children: mark })), _jsx("input", __assign({}, (register && __assign({}, register(name))), { id: id, max: max, name: name, type: type, value: register ? formValue : value, className: classes }, (onChange && { onChange: onChange }), { disabled: disabled, placeholder: placeholder, "aria-describedby": ariaDescribedBy, "aria-invalid": hasError, autoComplete: autoComplete }, (onBlur && { onBlur: onBlur }), { required: required || false, onKeyDown: onKeyDown }, (!register && type === 'checkbox' && { checked: !!value }))), hasCopyButton && (_jsx("div", __assign({ className: "absolute right-3 top-2 bg-white" }, { children: _jsx(AptiveIcon, { className: "w-[20px] h-[20px] stroke-[#9CA3AF] fill-none hover-gray-700 cursor-pointer hover:opacity-[0.7]", icon: "documentDuplicate", onClick: handleCopy }) }))), childOrientation === 'right' ? children : null] })));
};
export default CustomInput;
