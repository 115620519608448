var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { addDays, format } from 'date-fns';
import EventCard from 'components/EventCard';
import EventDetailModal from 'components/EventDetailModal';
import { fetchCalendarEvents, fetchCalendarEventParticipants } from 'services/calendar';
import { generateTimeArray } from 'utils/generateTimeArray';
import { groupOverlappingEvents } from 'utils/groupOverlappingEvents';
var HEADER_HEIGHT = 64;
var TIME_BLOCK_HEIGHT = 36;
var CalendarDayView = function (_a) {
    var officeId = _a.officeId, currentDate = _a.currentDate, eventTypes = _a.eventTypes, onEventSuccess = _a.onEventSuccess;
    var timeArrays = generateTimeArray(false);
    var _b = useState([]), groupedOverlappingEvents = _b[0], setGroupedOverlappingEvents = _b[1];
    var _c = useState(false), isEventDetailOpened = _c[0], setIsEventDetailOpened = _c[1];
    var _d = useState(false), isEventDetailLoading = _d[0], setIsEventDetailLoading = _d[1];
    var _e = useState(null), selectedEvent = _e[0], setSelectedEvent = _e[1];
    var _f = useState([]), selectedEventParticipants = _f[0], setSelectedEventParticipants = _f[1];
    useEffect(function () {
        fetchEvents();
        // eslint-disable-next-line
    }, [currentDate, officeId]);
    var fetchEvents = function () {
        fetchCalendarEvents({
            office_id: officeId,
            start_date: format(currentDate, 'yyyy-MM-dd'),
            end_date: format(addDays(currentDate, 1), 'yyyy-MM-dd'),
        })
            .then(function (response) {
            var mapOfEvents = response.result.events;
            var events = mapOfEvents[format(currentDate, 'yyyy-MM-dd')] || [];
            events.forEach(function (event) {
                event.is_hidden = false;
            });
            setGroupedOverlappingEvents(groupOverlappingEvents(events));
        })
            .catch(function (e) {
            console.log(e);
        });
    };
    var handleClickCard = function (event) {
        setSelectedEvent(event);
        setIsEventDetailOpened(true);
        setIsEventDetailLoading(true);
        setSelectedEventParticipants([]);
        fetchCalendarEventParticipants(event.id)
            .then(function (response) {
            setSelectedEventParticipants(response);
            setIsEventDetailLoading(false);
        });
    };
    var handleHideShowEvent = function (eventId, eventDate, isAll, isHidden) {
        var tempGroupedOverlappingEvents = __spreadArray([], groupedOverlappingEvents, true);
        for (var _i = 0, tempGroupedOverlappingEvents_1 = tempGroupedOverlappingEvents; _i < tempGroupedOverlappingEvents_1.length; _i++) {
            var tempGroupedOverlappingEvent = tempGroupedOverlappingEvents_1[_i];
            for (var _a = 0, tempGroupedOverlappingEvent_1 = tempGroupedOverlappingEvent; _a < tempGroupedOverlappingEvent_1.length; _a++) {
                var event_1 = tempGroupedOverlappingEvent_1[_a];
                if (event_1.id === eventId) {
                    event_1.is_hidden = isHidden;
                }
            }
        }
        setGroupedOverlappingEvents(tempGroupedOverlappingEvents);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex flex-col flex-auto overflow-hidden" }, { children: [_jsxs("div", __assign({ className: "relative flex flex-none overflow-y-scroll" }, { children: [_jsx("div", { className: "flex-none w-[64px] h-[64px] p-2 border-b border-r border-[#CBCBCB80]" }), _jsxs("div", __assign({ className: 'flex flex-col w-full p-2 border-r border-b border-[#E5E7EB]', style: {
                                    height: HEADER_HEIGHT
                                } }, { children: [_jsx("div", __assign({ className: "text-sm font-normal uppercase text-text-default m-auto" }, { children: format(currentDate, 'ccc') })), _jsx("div", __assign({ className: "w-[32px] h-[32px] flex items-center justify-center m-auto" }, { children: _jsx("div", __assign({ className: "text-base font-bold text-text-default" }, { children: format(currentDate, 'dd') })) }))] }))] })), _jsxs("div", __assign({ className: "flex flex-auto overflow-auto rounded-br-[8px] rounded-bl-[8px] border-t border-[#CBCBCB80] bg-white" }, { children: [_jsx("div", __assign({ className: 'relative flex flex-col w-[64px] flex-none' }, { children: timeArrays.map(function (time, timeIndex) { return (_jsx("div", __assign({ className: 'flex justify-center text-sm text-text-default font-normal border-r border-[#CBCBCB80] flex-none', style: {
                                        height: TIME_BLOCK_HEIGHT * 2
                                    } }, { children: time !== '12 AM' ? (_jsx("div", __assign({ className: "mt-[-11px]" }, { children: time }))) : (_jsx("div", {})) }), timeIndex)); }) })), _jsxs("div", __assign({ className: 'relative flex flex-1 flex-col' }, { children: [__spreadArray(__spreadArray([], timeArrays, true), timeArrays, true).map(function (_, timeIndex) { return (_jsx("div", { className: "flex items-center flex-none justify-center text-sm text-text-default font-normal border-r border-[#CBCBCB80] ".concat(timeIndex < __spreadArray(__spreadArray([], timeArrays, true), timeArrays, true).length - 1 ? 'border-b' : ''), style: { height: TIME_BLOCK_HEIGHT } }, timeIndex)); }), groupedOverlappingEvents.map(function (groupedOverlappingEvent) {
                                        return groupedOverlappingEvent.map(function (event, eventIndex) {
                                            if (!event.is_hidden && event.is_canceled === 'false') {
                                                return (_jsx(EventCard, { event: event, view: "day", groupTotal: groupedOverlappingEvent.length, groupIndex: eventIndex, selectedTypes: eventTypes, onClick: function () { return handleClickCard(event); } }, event.id));
                                            }
                                            else {
                                                return _jsx(_Fragment, {});
                                            }
                                        });
                                    })] }), format(currentDate, 'MM/dd/yyyy'))] }))] })), selectedEvent && (_jsx(EventDetailModal, { event: selectedEvent, eventDate: currentDate, participants: selectedEventParticipants, loading: isEventDetailLoading, open: isEventDetailOpened, onDelete: fetchEvents, onHideShow: handleHideShowEvent, onSuccess: onEventSuccess, onClose: function () { return setIsEventDetailOpened(false); } }))] }));
};
export default CalendarDayView;
