var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AptiveIcon } from '@aptive-env/storybook';
var ChecklistFormHeader = function (_a) {
    var title = _a.title, onClose = _a.onClose;
    return (_jsxs("div", __assign({ className: "w-full h-[89px] flex items-center justify-between border-b border-gray-200 px-6" }, { children: [_jsx("div", __assign({ className: "text-2xl text-gray-900 font-bold" }, { children: title })), _jsx("div", __assign({ className: "cursor-pointer", onClick: onClose }, { children: _jsx(AptiveIcon, { className: "w-4 h-4 fill-[#9CA3AF] stroke-none", icon: "x", isFilled: true }) }))] })));
};
export default ChecklistFormHeader;
