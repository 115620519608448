var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState } from 'react';
export var CalendarStateContext = createContext({
    eventTypes: [],
    setEventTypes: function () { },
    selectedOffice: {},
    setSelectedOffice: function () { },
    officeEmployees: [],
    setOfficeEmployees: function () { },
    calendarMode: 1,
    setCalendarMode: function () { },
});
export var CalendarStateProvider = function (_a) {
    var children = _a.children;
    var _b = useState([]), eventTypes = _b[0], setEventTypes = _b[1];
    var _c = useState(), selectedOffice = _c[0], setSelectedOffice = _c[1];
    var _d = useState([]), officeEmployees = _d[0], setOfficeEmployees = _d[1];
    var _e = useState(1), calendarMode = _e[0], setCalendarMode = _e[1];
    var calendarContextValue = {
        eventTypes: eventTypes,
        setEventTypes: setEventTypes,
        selectedOffice: selectedOffice,
        setSelectedOffice: setSelectedOffice,
        officeEmployees: officeEmployees,
        setOfficeEmployees: setOfficeEmployees,
        calendarMode: calendarMode,
        setCalendarMode: setCalendarMode
    };
    return (_jsx(CalendarStateContext.Provider, __assign({ value: calendarContextValue }, { children: children })));
};
