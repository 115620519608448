var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CustomButtonGroup } from 'components/common';
var LicenseApplicationFooter = function (_a) {
    var isCancelDisabled = _a.isCancelDisabled, isSaveDisabled = _a.isSaveDisabled, onCancel = _a.onCancel, onSave = _a.onSave;
    return (_jsx("div", __assign({ className: "w-full h-16 flex justify-end items-center border-t border-gray-200 px-6" }, { children: _jsx(CustomButtonGroup, { onCancelClick: onCancel, onSaveClick: onSave, disabledCancel: isCancelDisabled, disabledSave: isSaveDisabled, cancelText: "Cancel", saveText: "Save", buttonClassName: "w-20", wrapperClassName: "flex justify-end w-full", orientation: "right", loading: false, withSubmit: true }) })));
};
export default LicenseApplicationFooter;
