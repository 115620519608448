import PropTypes from 'prop-types';

const FormLabel = ({
  label,
  htmlFor,
  required,
}) => label.length > 0 && (
  <div className="flex text-sm font-medium text-gray-700">
    <label htmlFor={htmlFor} className="flex flex-inline gap-1">
      <div>{label}</div>
      {required && (
        <div className=" text-red-500">*</div>
      )}
    </label>
  </div>
);

FormLabel.propTypes = {
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  required: PropTypes.bool,
};

export default FormLabel;
