import { ConfirmationModal, Table } from '@/components';
import PropTypes from 'prop-types';
import { parseLabel } from '@/modules/Housing/modules/BedsHistory/lib';
import {
  getBedManagementHeadRows,
  parseBedManagementRows,
  validateAssignRep,
  getRoomsForAssignment,
} from '@/modules/Housing/modules/BedManagement/lib';
import { useDraggable } from '@/modules/Housing/hooks';
import {
  unassignRepAsync,
  assignRepAsync,
  unBeddedSummariesSelector,
} from '@/modules/Housing/redux/bedManagement';
import { connect } from 'react-redux';
import { bedManagementConstants } from '@/modules/Housing/lib';
import { addToastsAction } from '@/redux/toasts';
import { toastType, FilterBadge } from '@/components/common';
import { useState, useCallback } from 'react';

const {
  EXPECTED_MARRIED_HOUSING_TYPES,
  BED_ASSIGNMENT_TYPES: {
    BED: BED_ASSIGNMENT_TYPE,
    ROOM: ROOM_ASSIGNMENT_TYPE,
    COUCH: COUCH_ASSIGNMENT_TYPE,
  },
} = bedManagementConstants;

const BedManagementTable = ({
  isLoading,
  selectedFilters,
  setSelectedFilters,
  pageSize,
  setPageSize,
  selectedPage,
  onPageChange,
  initialPage,
  bedData,
  bedDataTotal,
  viewFilters,
  setViewFilters,
  seasonPeriod,
  recruitingSeasonId,
  unassignRep,
  assignRep,
  refreshBedManagementPage,
  unBeddedData,
  showToasts,
  isBedManagementStatisticEnabled,
}) => {
  const { tableRef } = useDraggable(isLoading);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedBedSpot, setSelectedBedSpot] = useState(null);

  const onClickUnassignSalesRep = useCallback((userInfo) => {
    const { complex_id, apartment_id, user_type, user_id } = userInfo;

    unassignRep({
      complexId: complex_id,
      apartmentId: apartment_id,
      user_id,
      user_type,
      season_period: seasonPeriod,
      successCallback: () => refreshBedManagementPage(),
    });
  }, [unassignRep, seasonPeriod, refreshBedManagementPage]);

  const assignSalesRep = useCallback(({
    complex_id,
    apartment_id,
    resident_id,
    bed_allotment_type,
    expected_apartment_type,
    expected_number_of_rooms,
    roomIndex,
    complexIndex,
    apartmentIndex,
    bedType,
    force_assignment = false,
  }) => {
    let assignmentType = bedType, roomIds = [], bedNumber;

    if (EXPECTED_MARRIED_HOUSING_TYPES.includes(expected_apartment_type) && !force_assignment) {
      assignmentType = ROOM_ASSIGNMENT_TYPE;
      roomIds = getRoomsForAssignment(
        bedData,
        expected_number_of_rooms,
        roomIndex,
        complexIndex,
        apartmentIndex,
      );
    }

    // If the assignment is forced, then the assignment type must be BED unless it's Couch
    assignmentType = force_assignment && assignmentType !== COUCH_ASSIGNMENT_TYPE
      ? BED_ASSIGNMENT_TYPE : assignmentType;

    if (assignmentType === BED_ASSIGNMENT_TYPE) {
      roomIds = [
        bedData[complexIndex].apartments[apartmentIndex].rooms[roomIndex].id,
      ];
      bedNumber =
        bedData[complexIndex].apartments[apartmentIndex].rooms[roomIndex].beds
          .length;
    }

    assignRep({
      complexId: complex_id,
      apartmentId: apartment_id,
      assignment_type: assignmentType,
      room_id: roomIds,
      bed_number: bedNumber,
      resident_id,
      bed_allotment_type,
      //recruiting_season_id: recruitingSeasonId,
      season_period: seasonPeriod,
      force_assignment,
      successCallback: () => refreshBedManagementPage(),
    });
  }, [bedData, recruitingSeasonId, seasonPeriod, assignRep, refreshBedManagementPage]);

  const onClickAssignSalesRep = useCallback((params) => {
    const bedValidation = validateAssignRep(bedData, params);

    if (!bedValidation.valid && !bedValidation.canBeForced) {
      showToasts([
        {
          type: toastType.ERROR,
          message: 'Invalid assignment',
          details: bedValidation.message,
        },
      ]);

      return;
    }

    if (!bedValidation.valid && bedValidation.canBeForced) {
      setSelectedBedSpot(params);
      setConfirmationOpen(true);

      return;
    }

    assignSalesRep(params);
  }, [bedData, showToasts, setSelectedBedSpot, setConfirmationOpen, assignSalesRep]);

  const onConfirmationBack = useCallback(() => {
    setSelectedBedSpot(null);
    setConfirmationOpen(false);
  }, [setSelectedBedSpot, setConfirmationOpen]);

  const onConfirmationSubmit = useCallback(() => {
    if (selectedBedSpot) {
      assignSalesRep({
        force_assignment: true,
        ...selectedBedSpot,
      });
    }

    setSelectedBedSpot(null);
    setConfirmationOpen(false);
  }, [selectedBedSpot, assignSalesRep, setSelectedBedSpot, setConfirmationOpen]);

  const bedManagementRows = parseBedManagementRows(
    bedData,
    onClickUnassignSalesRep,
    onClickAssignSalesRep,
    unBeddedData,
  );

  const onDeleteFilter = (filterName) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      delete newFilters[filterName];

      return newFilters;
    });
  };

  const onDeleteViewFilter = (index) => {
    setViewFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
  };

  return (
    <div className="grow">
      {!isLoading && (
        <div className="border border-solid border-gray-300 rounded-t-lg min-h-[56px] px-2 border-b-0 bg-white self-stretch flex items-center gap-2">
          <div className="flex gap-2 justify-center items-center">
            {viewFilters.length > 0 &&
              viewFilters.map((filter, index) => (
                <FilterBadge
                  key={index}
                  text={`${filter.type.label}: ${filter.value[0].label} ${
                    filter.value.length > 1 ? '...' : ''
                  } `}
                  className="bg-blue-50 text-blue-600"
                  onDelete={() => onDeleteViewFilter(index)}
                />
              ))}
            {Object.keys(selectedFilters).length > 0 &&
              Object.keys(selectedFilters).map(
                (key, index) =>
                  selectedFilters[key] && (
                    <FilterBadge
                      key={index}
                      className="bg-blue-50 text-blue-600"
                      text={parseLabel(selectedFilters[key], key)}
                      onDelete={() => onDeleteFilter(key)}
                    />
                  ),
              )}
          </div>
        </div>
      )}
      <Table
        ref={tableRef}
        loading={isLoading}
        className="select-none"
        thead={{
          rows: getBedManagementHeadRows(),
          className: 'sticky top-0 z-10',
        }}
        tbody={{
          rows: bedManagementRows,
        }}
        paginator={{
          pageSize,
          setPageSize,
          onPageChange,
          selectedPage,
          initialPage,
          rowCount: bedDataTotal,
        }}
        wrapper={{
          style: { height: isBedManagementStatisticEnabled ? 'calc(100vh - 410px)' : 'calc(100vh - 260px)' },
          className: 'overflow-y-visible rounded-t-none relative',
        }}
      />
      <ConfirmationModal
        isOpened={confirmationOpen}
        modalWidth="max-w-[592px] w-full"
        onCancel={onConfirmationBack}
        onAction={onConfirmationSubmit}
        title="Invalid assignment"
        message="You are assigning a user to a bed that does not match their preferences. Do you want to proceed?"
        cancelLabel="Cancel"
        confirmLabel="Submit"
      />
    </div>
  );
};

BedManagementTable.propTypes = {
  isLoading: PropTypes.bool,
  selectedFilters: PropTypes.object.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  selectedPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  initialPage: PropTypes.number.isRequired,
  bedData: PropTypes.array.isRequired,
  bedDataTotal: PropTypes.number.isRequired,
  viewFilters: PropTypes.array.isRequired,
  setViewFilters: PropTypes.func.isRequired,
  unassignRep: PropTypes.func.isRequired,
  assignRep: PropTypes.func.isRequired,
  refreshBedManagementPage: PropTypes.func.isRequired,
  unBeddedData: PropTypes.array.isRequired,
  showToasts: PropTypes.func.isRequired,
  seasonPeriod: PropTypes.string.isRequired,
  recruitingSeasonId: PropTypes.number.isRequired,
  isBedManagementStatisticEnabled: PropTypes.bool,
};

const mapDispatchToProps = {
  unassignRep: unassignRepAsync.request,
  assignRep: assignRepAsync.request,
  showToasts: addToastsAction,
};

const mapStateToProps = (state) => ({
  unBeddedData: unBeddedSummariesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(BedManagementTable);
