import FilterValue from '@/modules/AdminTools/components/DataTable/FilterValue';
import PropTypes from 'prop-types';

const UserNameValue = ({ user_name, api_user_name, editor_type }) => {
  if (editor_type === 'App\\Models\\User') {
    return <FilterValue field="user_name" value={api_user_name} />;
  } else {
    return <FilterValue field="user_name" value={user_name} />;
  }
};

UserNameValue.propTypes = {
  user_name: PropTypes.any,
  api_user_name: PropTypes.any,
  editor_type: PropTypes.any,
};

export default UserNameValue;
