var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
function parseTime(time) {
    var _a = time.split(':').map(Number), hours = _a[0], minutes = _a[1], seconds = _a[2];
    return hours * 3600 + minutes * 60 + seconds;
}
export function groupOverlappingEvents(events) {
    // Parse and sort events by start time
    var sortedEvents = events.map(function (event) { return (__assign(__assign({}, event), { startTime: parseTime(event.start_at), endTime: parseTime(event.end_at) })); }).sort(function (a, b) { return a.startTime - b.startTime; });
    var groupedEvents = [];
    var currentGroup = [];
    for (var _i = 0, sortedEvents_1 = sortedEvents; _i < sortedEvents_1.length; _i++) {
        var event_1 = sortedEvents_1[_i];
        if (currentGroup.length === 0) {
            currentGroup.push(event_1);
        }
        else {
            var lastEvent = currentGroup[currentGroup.length - 1];
            if (parseTime(event_1.start_at) <= parseTime(lastEvent.end_at)) {
                currentGroup.push(event_1);
            }
            else {
                groupedEvents.push(currentGroup);
                currentGroup = [event_1];
            }
        }
    }
    if (currentGroup.length > 0) {
        groupedEvents.push(currentGroup);
    }
    return groupedEvents;
}
