import { arrayWithoutEmpty, formatDateDisplay } from '@/lib/utils';
import { housingConstants } from '@/modules/Housing/lib/constants';
import { bedManagementConstants } from '@/modules/Housing/lib';
import { CustomFormElement } from '@/components/common';
import {
  formatDateRequest,
  resolveFilterDates,
} from '@/modules/Housing/components/common/DateRange/utils';

const {
  HOUSING_TYPE_FILTER_LABEL,
  HOUSING_TYPE_FILTER_NAME,
  ROOMS_FILTER_LABEL,
  ROOMS_FILTER_NAME,
  BEDS_FILTER_LABEL,
  BEDS_FILTER_NAME,
  SEASON_FILTER_LABEL,
  SEASON_FILTER_NAME,
  EXPECTED_SINGLE_HOUSING_TYPES,
  EXPECTED_FEMALE_HOUSING_TYPES,
  EXPECTED_MIXED_HOUSING_TYPES,
  SINGLE_HOUSING_TYPE,
  FEMALE_HOUSING_TYPE,
  MIXED_HOUSING_TYPE,
  MARRIED_OFFICE_HOUSING_TYPE,
  MARRIED_HOUSING_TYPE,
  MARRIED_OFFICE_HOUSING_TYPE_ABBREVIATION,
  NOT_IN_APARTMENTS_HOUSING_TYPE,
  OFFICE_APARTMENT_HOUSING_TYPE,
  AVAILABILITY_DATE_RANGE_FILTER_LABEL,
  AVAILABILITY_DATE_FROM_FILTER_NAME,
  AVAILABILITY_DATE_TO_FILTER_NAME,
  ONLY_COUCHES_FILTER_NAME,
  ONLY_COUCHES_FILTER_LABEL,
} = bedManagementConstants;

export const getBedManagementHeadRows = () =>
  arrayWithoutEmpty([
    {
      value: 'COMPLEX',
      align: 'left',
    },
    {
      value: 'HOUSING TYPE',
      align: 'left',
    },
    {
      value: 'UNIT',
      align: 'left',
    },
    {
      value: 'ROOM TYPE',
      align: 'left',
    },
    {
      value: 'BEDS IN ROOM',
      align: 'left',
    },
    {
      value: 'SALES REPS',
      align: 'left',
    },
    {
      value: 'REP STATUS',
      align: 'left',
    },
    {
      value: 'RENT STATUS',
      align: 'left',
    },
    {
      value: 'MOVE IN',
      align: 'left',
    },
    {
      value: 'MOVE OUT',
      align: 'left',
    },
    {
      value: 'NOTES',
      align: 'left',
    },
    {
      value: 'LEASE SIGNED',
      align: 'left',
    },
    {
      value: 'LEASE START',
      align: 'left',
    },
    {
      value: 'LEASE END',
      align: 'left',
    },
    {
      value: 'ELEC ACCT#',
      align: 'left',
    },
    {
      value: 'GAS ACCT#',
      align: 'left',
    },
    {
      value: 'FURNITURE DELIVERY',
      align: 'left',
    },
    {
      value: 'FURNITURE PICKUP',
      align: 'left',
    },
  ]);

export const parseBedManagementRows = (
  bedManagementData,
  onClickUnassignSalesRep,
  onClickAssignSalesRep,
  unBeddedData,
) => {
  if (!bedManagementData || bedManagementData.length === 0) {
    return createNoDataRows();
  }

  let rows = [];

  const unBeddedOptions = unBeddedData?.map((option) => ({
    label: option.name,
    resident_id: option.id,
    bed_allotment_type: option.bed_allotment_type,
    expected_apartment_type: option.type_of_room,
    expected_number_of_rooms: option.number_of_rooms,
  }));

  bedManagementData.forEach((complexData, index) => {
    const complexRows = parseComplexData(complexData, index);
    rows = [...rows, ...complexRows];
  });

  return rows.map((row) => {
    const {
      apartment_row_span,
      room_row_span,
      complex_name,
      apartment_type,
      unit_id,
      room_type,
      lease_start_date,
      lease_end_date,
      lease_signed,
      furniture_delivery_date,
      furniture_pick_up_date,
      electric_account_number,
      gas_account_number,
      number_of_beds,
      sales_reps,
      user_type,
      rep_status,
      apartment_status,
      move_in,
      move_out,
      notes,
      complex_id,
      apartment_id,
      id: user_id,
      complexIndex,
      apartmentIndex,
      roomIndex,
      bedIndex,
    } = row;

    const residentInfo = { complex_id, apartment_id, user_type, user_id };
    const bedType = number_of_beds === 'Couch' ? 'couch' : 'bed';

    const bedSpotInfo = {
      complexIndex,
      apartmentIndex,
      roomIndex,
      bedIndex,
      complex_id,
      apartment_id,
      bedType,
    };

    return [
      row.hasOwnProperty('complex_name') && {
        value: complex_name,
        align: 'left',
        valign: 'top',
        className: 'whitespace-nowrap border-r border-gray-200',
        rowSpan: apartment_row_span,
      },
      row.hasOwnProperty('apartment_type') && {
        value: apartment_type,
        align: 'left',
        valign: 'top',
        className: 'whitespace-nowrap border-r border-gray-200',
        rowSpan: apartment_row_span,
      },
      row.hasOwnProperty('apartment_type') && {
        value: unit_id,
        align: 'left',
        valign: 'top',
        className: 'whitespace-nowrap border-r border-gray-200',
        rowSpan: apartment_row_span,
      },
      row.hasOwnProperty('room_type') && {
        value: room_type,
        align: 'left',
        valign: 'top',
        className: 'whitespace-nowrap border-r border-gray-200',
        rowSpan: apartment_row_span,
      },
      row.hasOwnProperty('number_of_beds') && {
        value:
          number_of_beds === 'Couch'
            ? 'Couch'
            : `${number_of_beds} bed${number_of_beds === 1 ? '' : 's'}`,
        align: 'left',
        valign: 'top',
        className:
          number_of_beds === 'Couch'
            ? 'whitespace-nowrap border-r border-gray-200 bg-gray-50'
            : 'whitespace-nowrap border-r border-gray-200',
        rowSpan: room_row_span,
      },
      {
        value: sales_reps ? (
          <CustomFormElement
            className="p-2 border rounded !w-[300px]"
            type="textSelect"
            id="sales_reps"
            name="sales_reps"
            value={sales_reps}
            readOnly
            onCrossClick={() => onClickUnassignSalesRep(residentInfo)}
            onKeyDown={(event) => {
              if (['Backspace', 'Delete'].includes(event.code)) {
                onClickUnassignSalesRep(residentInfo);
              }
            }}
          />
        ) : (
          <CustomFormElement
            className="p-2 border rounded !w-[300px] focus:outline-none focus:ring-2 focus:ring-aptivegreen focus:border-transparent"
            type="textSelect"
            id="sales_reps"
            name="sales_reps"
            autoComplete="off"
            autoDropdownOptions={unBeddedOptions}
            autoDropdownClickAction={(option) => {
              const params = { ...bedSpotInfo, ...option };
              onClickAssignSalesRep(params);
            }}
          />
        ),
        align: 'left',
        className:
          number_of_beds === 'Couch'
            ? 'whitespace-nowrap border-r border-gray-200 bg-gray-50 px-2 py-1'
            : 'whitespace-nowrap border-r border-gray-200 px-2 py-1',
      },
      {
        value: user_type === 'service-pro' ? 'Service Pro' : rep_status,
        align: 'left',
        className:
          number_of_beds === 'Couch'
            ? 'whitespace-nowrap border-r border-gray-200 bg-gray-50'
            : 'whitespace-nowrap border-r border-gray-200',
      },
      {
        value:
          apartment_status?.label === 'Married'
            ? `Married ${apartment_status?.number_of_rooms} Room${
                apartment_status?.number_of_rooms === 1 ? '' : 's'
              }`
            : apartment_status?.label ?? 'NA',
        align: 'left',
        className:
          number_of_beds === 'Couch'
            ? 'whitespace-nowrap border-r border-gray-200 bg-gray-50'
            : 'whitespace-nowrap border-r border-gray-200',
      },
      {
        value: formatDateDisplay(move_in),
        align: 'left',
        className:
          number_of_beds === 'Couch'
            ? 'whitespace-nowrap border-r border-gray-200 bg-gray-50'
            : 'whitespace-nowrap border-r border-gray-200',
      },
      {
        value: formatDateDisplay(move_out),
        align: 'right',
        className:
          number_of_beds === 'Couch'
            ? 'whitespace-nowrap border-r border-gray-200 bg-gray-50'
            : 'whitespace-nowrap border-r border-gray-200',
      },
      {
        value: notes || 'NA',
        align: 'left',
        className:
          number_of_beds === 'Couch'
            ? 'whitespace-nowrap border-r border-gray-200 bg-gray-50'
            : 'whitespace-nowrap border-r border-gray-200',
      },
      row.hasOwnProperty('lease_signed') && {
        value: lease_signed ? 'Yes' : 'No',
        align: 'right',
        valign: 'top',
        className: 'whitespace-nowrap border-r border-gray-200',
        rowSpan: apartment_row_span,
      },
      row.hasOwnProperty('lease_start_date') && {
        value: formatDateDisplay(lease_start_date),
        align: 'right',
        valign: 'top',
        className: 'whitespace-nowrap border-r border-gray-200',
        rowSpan: apartment_row_span,
      },
      row.hasOwnProperty('lease_end_date') && {
        value: formatDateDisplay(lease_end_date),
        align: 'right',
        valign: 'top',
        className: 'whitespace-nowrap border-r border-gray-200',
        rowSpan: apartment_row_span,
      },
      row.hasOwnProperty('electric_account_number') && {
        value: electric_account_number,
        align: 'right',
        valign: 'top',
        className: 'whitespace-nowrap border-r border-gray-200',
        rowSpan: apartment_row_span,
      },
      row.hasOwnProperty('gas_account_number') && {
        value: gas_account_number,
        align: 'right',
        valign: 'top',
        className: 'whitespace-nowrap border-r border-gray-200',
        rowSpan: apartment_row_span,
      },
      row.hasOwnProperty('furniture_delivery_date') && {
        value: formatDateDisplay(furniture_delivery_date),
        align: 'right',
        valign: 'top',
        className: 'whitespace-nowrap border-r border-gray-200',
        rowSpan: apartment_row_span,
      },
      row.hasOwnProperty('furniture_pick_up_date') && {
        value: formatDateDisplay(furniture_pick_up_date),
        align: 'right',
        valign: 'top',
        className: 'whitespace-nowrap',
        rowSpan: apartment_row_span,
      },
    ].filter((cell) => cell !== false);
  });
};

const createNoDataRows = () => {
  return [
    {
      value: housingConstants.NO_DATA_TO_DISPLAY,
      align: 'center',
      colSpan: Number.MAX_SAFE_INTEGER,
      className: 'py-8 grow',
    },
  ];
};

const parseComplexData = (complexData, complexIndex) => {
  const { name: complex_name, id: complex_id, apartments } = complexData ?? {};
  let complexRows = [];

  if (!apartments || apartments.length === 0) {
    complexRows = [
      createEmptyComplexRow(complex_name, complex_id, complexIndex),
    ];
  } else {
    apartments.forEach((apartment, index) => {
      const apartmentRows = parseApartmentData(
        apartment,
        complex_name,
        complex_id,
        complexIndex,
        index
      );
      complexRows = [...complexRows, ...apartmentRows];
    });
  }

  return complexRows;
};

const createEmptyComplexRow = (complexName, complexId, complexIndex) => {
  return {
    complex_index: complexIndex,
    complex_name: complexName,
    complex_id: complexId,
    complex_row_span: 1,
    apartment_type: '',
    unit_id: '',
    lease_start_date: '',
    lease_end_date: '',
    lease_signed: '',
    furniture_delivery_date: '',
    furniture_pick_up_date: '',
    elec_acct_number: '',
    gas_acct_number: '',
    number_of_beds: '',
    room_type: 'No bedroom unit',
    sales_reps: '',
    rep_status: '',
    apartment_status: { label: 'NA' },
    move_in: '',
    move_out: '',
    notes: 'NA',
  };
};

const parseApartmentData = (
  apartmentData,
  complex_name,
  complex_id,
  complexIndex,
  apartmentIndex
) => {
  const {
    rooms,
    has_couch,
    couch,
    apartment_id,
    notes,
    ...apartmentDetails
  } = apartmentData ?? {};
  let apartmentRows = [];

  if (rooms && rooms.length > 0) {
    rooms.forEach((room, index) => {
      const roomRows = parseRoomData(
        room,
        complex_id,
        apartment_id,
        complexIndex,
        apartmentIndex,
        index
      );
      apartmentRows = [...apartmentRows, ...roomRows];
    });
  }

  if (has_couch && couch) {
    const couchRow = parseBedData(couch, {
      complex_id,
      apartment_id,
      complexIndex,
      apartmentIndex,
      number_of_beds: 'Couch',
    });
    apartmentRows = [...apartmentRows, couchRow];
  }

  apartmentRows[0] = {
    complex_name,
    room_type:
      rooms.length > 0 ? `${rooms.length} bedroom unit` : 'No bedroom unit',
    ...apartmentDetails,
    apartment_row_span: apartmentRows.length,
    ...apartmentRows[0],
  };

  return apartmentRows;
};

const parseRoomData = (
  roomData,
  complex_id,
  apartment_id,
  complexIndex,
  apartmentIndex,
  roomIndex
) => {
  const { beds, number_of_beds, ...roomDetails } = roomData ?? {};
  let roomRows = [];

  if (beds && beds.length > 0) {
    beds.forEach((bed, index) => {
      const bedRow = parseBedData(bed, {
        complex_id,
        apartment_id,
        complexIndex,
        apartmentIndex,
        roomIndex,
        bedIndex: index,
      });
      roomRows = [...roomRows, bedRow];
    });
  }

  roomRows[0] = {
    number_of_beds,
    room_row_span: roomRows.length,
    ...roomDetails,
    ...roomRows[0],
  };

  return roomRows;
};

const parseBedData = (bedData, additionalFields) => {
  const { user_info, ...bedDetails } = bedData ?? {};
  const { name: sales_reps, ...userDetails } = user_info ?? {};
  const row = {
    sales_reps,
    ...userDetails,
    ...bedDetails,
    ...additionalFields,
  };
  return row;
};

const mapFilterLabelToName = {
  [HOUSING_TYPE_FILTER_LABEL]: HOUSING_TYPE_FILTER_NAME,
  [ROOMS_FILTER_LABEL]: ROOMS_FILTER_NAME,
  [BEDS_FILTER_LABEL]: BEDS_FILTER_NAME,
  [SEASON_FILTER_LABEL]: SEASON_FILTER_NAME,
};

const mapFilterLabelToValue = {
  'Pre season': 'pre_season',
  'Regular season': 'regular_season',
  'Post season': 'post_season',
  [SINGLE_HOUSING_TYPE]: 'Single',
  [FEMALE_HOUSING_TYPE]: 'Single Female',
  [MIXED_HOUSING_TYPE]: 'Mixed',
  [MARRIED_HOUSING_TYPE]: 'Married',
  [MARRIED_OFFICE_HOUSING_TYPE]: 'Married-Office',
  [MARRIED_OFFICE_HOUSING_TYPE_ABBREVIATION]: 'M/OA',
  [NOT_IN_APARTMENTS_HOUSING_TYPE]: 'Not in Apartments',
  [OFFICE_APARTMENT_HOUSING_TYPE]: 'Office Apartment',
  '1 bedroom': 1,
  '2 bedrooms': 2,
  '3 bedrooms': 3,
  '4 bedrooms': 4,
  '5 bedrooms': 5,
  '6 bedrooms': 6,
  '7 bedrooms': 7,
  '8 bedrooms': 8,
  '9 bedrooms': 9,
  '10 bedrooms': 10,
  '11 bedrooms': 11,
  '12 bedrooms': 12,
  '13 bedrooms': 13,
  '14 bedrooms': 14,
  '15 bedrooms': 15,
  '1 bed': 1,
  '2 beds': 2,
  '3 beds': 3,
  '4 beds': 4,
};

export const parseFilters = (filters) => {
  return Object.keys(filters).reduce((parsedFilters, key) => {
    switch (key) {
      case AVAILABILITY_DATE_RANGE_FILTER_LABEL:
        const dateRange = resolveFilterDates(filters[key].value);
        const startDate = dateRange[0]
          ? formatDateRequest(dateRange[0])
          : '';
        const endDate = dateRange[1]
          ? formatDateRequest(dateRange[1])
          : '';

        if (startDate) {
          parsedFilters[AVAILABILITY_DATE_FROM_FILTER_NAME] = startDate;
        }

        if (endDate) {
          parsedFilters[AVAILABILITY_DATE_TO_FILTER_NAME] = endDate;
        }

        break;
      case BEDS_FILTER_LABEL:
        if (filters[key].value === ONLY_COUCHES_FILTER_LABEL) {
          parsedFilters[ONLY_COUCHES_FILTER_NAME] = true;
          break;
        }

        parsedFilters[mapFilterLabelToName[key]] = mapFilterLabelToValue[filters[key].value];
        break;
      case HOUSING_TYPE_FILTER_LABEL:
        parsedFilters[mapFilterLabelToName[key]] = [mapFilterLabelToValue[filters[key].value]] ?? [];
        break;
      default:
        parsedFilters[mapFilterLabelToName[key]] = mapFilterLabelToValue[filters[key].value];
    }

    return parsedFilters;
  }, {});
};

export const parseViewFilter = (filter) => {
  if (!filter) {
    return {};
  }

  return {
    [filter.type.value]: filter?.value?.map((value) => value.value),
  };
};

export const isRoomUnassigned = (room) => {
  return room.beds.every((bed) => !bed.user_info);
};

export const getRoomsForAssignment = (
  bedData,
  expectedNumberOfRooms,
  roomIndex,
  complexIndex,
  apartmentIndex,
) => {
  let roomIds = [];
  let roomLeft = expectedNumberOfRooms;

  // First, check the room at roomIndex
  let room = bedData[complexIndex].apartments[apartmentIndex].rooms[roomIndex];
  if (isRoomUnassigned(room) && roomLeft > 0) {
    roomIds.push(room.id);
    roomLeft -= 1;
  }

  // Then, iterate over the remaining rooms
  bedData[complexIndex].apartments[apartmentIndex].rooms.forEach((room, index) => {
    if (index !== roomIndex && isRoomUnassigned(room) && roomLeft > 0) {
      roomIds.push(room.id);
      roomLeft -= 1;
    }
  });

  return roomIds;
}

export const validateAssignRep = (bedData, params) => {
  const {
    expected_apartment_type,
    expected_number_of_rooms,
    complexIndex,
    apartmentIndex,
    roomIndex,
  } = params;
  const housing_type =
    bedData[complexIndex].apartments[apartmentIndex].apartment_type;
  let available_number_of_rooms = 0;
  const room = bedData[complexIndex].apartments[apartmentIndex].rooms[roomIndex];

  bedData[complexIndex].apartments[apartmentIndex].rooms.forEach((room) => {
    if (isRoomUnassigned(room)) {
      available_number_of_rooms += 1;
    }
  });

  switch (housing_type) {
    case SINGLE_HOUSING_TYPE:
      if (EXPECTED_SINGLE_HOUSING_TYPES.includes(expected_apartment_type)) {
        return {
          valid: true,
        };
      }

      if (EXPECTED_FEMALE_HOUSING_TYPES.includes(expected_apartment_type)) {
        return {
          valid: false,
          canBeForced: false,
          message: 'You can not assign Female to a Male apartment.',
        };
      }

      return {
        valid: false,
        canBeForced: true,
      };
    case FEMALE_HOUSING_TYPE:
      if (EXPECTED_FEMALE_HOUSING_TYPES.includes(expected_apartment_type)) {
        return {
          valid: true,
        };
      }

      if (EXPECTED_SINGLE_HOUSING_TYPES.includes(expected_apartment_type)) {
        return {
          valid: false,
          canBeForced: false,
          message: 'You can not assign Male to a Female apartment.',
        };
      }

      return {
        valid: false,
        canBeForced: true,
      };
    case MIXED_HOUSING_TYPE:
      if (EXPECTED_MIXED_HOUSING_TYPES.includes(expected_apartment_type)) {
        return {
          valid: true,
        };
      }

      return {
        valid: false,
        canBeForced: true,
      };
    default:
      if (
        EXPECTED_SINGLE_HOUSING_TYPES.includes(expected_apartment_type)
        || EXPECTED_FEMALE_HOUSING_TYPES.includes(expected_apartment_type)
      ) {
        return {
          valid: false,
          canBeForced: true,
        };
      }

      return {
        valid: room && available_number_of_rooms >= expected_number_of_rooms && isRoomUnassigned(room),
        canBeForced: true,
      };
  }
};
