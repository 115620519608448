const AccessDenied = () => (
  <main className="grow flex">
    <div className="max-w-[1440px] m-auto py-8 px-4 text-center">
      <h1 className="text-3xl mb-2">You are not authorized to access this page.</h1>
      <p>If you believe this is an error, please contact an administrator.</p>
    </div>
  </main>
);

export default AccessDenied;
