import moment from 'moment';
export var formatDate = {
    FORMAT_TIMESTAMP: 'YYYY-MM-DD HH:mm:ss',
    FORMAT_REQUEST: 'YYYY-MM-DD',
    FORMAT_DISPLAY: 'MM-DD-YYYY',
    FORMAT_NOVEL: 'DD MMM YYYY',
    FORMAT_YEAR: 'YYYY',
};
export var momentDate = function (date) { return (moment(date, [
    formatDate.FORMAT_TIMESTAMP,
    formatDate.FORMAT_REQUEST,
    formatDate.FORMAT_DISPLAY,
    formatDate.FORMAT_NOVEL,
    formatDate.FORMAT_YEAR,
])); };
export var formatDateFn = function (date, format) {
    if (format === void 0) { format = formatDate.FORMAT_REQUEST; }
    var result = momentDate(date).format(format);
    return result !== 'Invalid date' ? result : '';
};
export var formatDateDisplay = function (date, format) {
    if (format === void 0) { format = formatDate.FORMAT_DISPLAY; }
    return (formatDateFn(date, format));
};
