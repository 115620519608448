import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { FilterableRecruitTable, Loader, Select } from '@/components';
import { StatsHeader } from '@/modules/dashboard/components';
import {
  requestDownlineAsync,
  downlineSelector,
  requestExperienceOptionsAsync,
  exportLeadsAsync,
} from '@/redux/reps';
import { allLeadsStatsLoadingSelector, downlineLoadingSelector, selectLoadingLeadsExport } from '@/redux/loading';
import { isAdminSelector, userRoleSelector } from '@/redux/auth';
import { allLeadsStatsHeaderDataSelector, contractsFilteredTotalSelector, requestContractStatsAsync } from '@/redux/contracts';
import { dashboardConstants } from '@/lib';
import { getAllLeadsStatsConstants } from '@/lib/constants';
import { dataKeys } from '@/lib/adapters';
import { useSortTable } from '@/hooks';
import { Profile, AddLead } from '../components/AllLeads';
import { useFeatureFlag } from 'configcat-react';
import { Icon } from '@/components';
import { Menu, Transition } from '@headlessui/react';
import { mergeClassName } from '@/lib/utils';

const { RECRUIT } = dataKeys;

const exportOptions = [{
  value: 'csv',
  label: 'Export CSV',
}, {
  value: 'xlsx',
  label: 'Export Excel',
}];

const AllLeads = ({
  // State
  downlineLoading,
  statsLoading,
  filteredTotal,
  statsHeaderData,
  repsDownline,
  role,
  isAdmin,

  // Dispatch
  requestContractStats,
  requestDownline,
  requestExperienceOptions,
  exportLeads,
  isExportLoading,
}) => {
  const statsHeaderConstants = useMemo(() => getAllLeadsStatsConstants(isAdmin), [isAdmin]);

  const [tileSelected, setTileSelected] = useState(statsHeaderConstants[0].name);
  const [filters, setFilters] = useState(statsHeaderConstants[0].filterOptions.filter);
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState(RECRUIT);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [recruitProfileOpen, setRecruitProfileOpen] = useState(false);
  const [addLeadOpen, setAddLeadOpen] = useState(false);
  const [selectedRecruit, setSelectedRecruit] = useState();
  const { getSortParam, sortQueryParams, onSortParamsChange, resetSorting } = useSortTable();

  const { value: isLeadsExportEnabled } = useFeatureFlag(
    dashboardConstants.LEADS_EXPORT_FEATURE_FLAG_NAME,
    false,
  );

  const onSelectRecruit = ({ recruit }) => {
    setSelectedRecruit(recruit);
    setRecruitProfileOpen(true);
  };

  const onProfileClose = () => {
    setRecruitProfileOpen(false);
  };

  const onAddNewClick = () => {
    setAddLeadOpen(true);
  };

  const onExportClick = (format) => {
    exportLeads({
      ...requestDownlineParams,
      format: format,
    });
  };

  const onAddLeadClose = ({ rep }) => {
    setAddLeadOpen(false);

    if (rep) {
      onSelectRecruit({ recruit: { ...rep } });
    }
  };

  const agreementFilterChange = (agreementType) => {
    setFilters({
      statuses: [...agreementType],
    });
  };

  const handleStatsTileClick = useCallback((name, filterOptions) => {
    setFilters(filterOptions.filter);
    setTileSelected(name);
    setSelectedPage(1);
    resetSorting();
  }, [resetSorting]);

  const requestDownlineParams = useMemo(() => ({
    ...(searchText && { search: searchText, search_type: searchType }),
    ...filters,
    ...(sortQueryParams && { sort: sortQueryParams }),
    page: { number: selectedPage, size: pageSize },
  }), [
    searchText,
    searchType,
    filters,
    sortQueryParams,
    selectedPage,
    pageSize,
  ]);

  useEffect(() => {
    requestDownline(requestDownlineParams);
    requestContractStats(requestDownlineParams);
    requestExperienceOptions();
  }, [
    requestContractStats,
    requestDownline,
    requestDownlineParams,
    requestExperienceOptions,
  ]);

  return (
    <div className="px-8">
      <StatsHeader
        constants={statsHeaderConstants}
        data={statsHeaderData}
        selected={tileSelected}
        onTileClick={handleStatsTileClick}
        isDisabled={statsLoading}
      />

      {downlineLoading ? (
        <Loader />
      ) : (
        <div className="mt-5">
          <div className="relative flex justify-end">
            <button
              type="button"
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-aptivegreen hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen"
              onClick={onAddNewClick}
            >
              {dashboardConstants.ADD_LEAD}
            </button>
            {isLeadsExportEnabled && (
              isExportLoading ? (
                <Loader className="items-center ml-2" />
              ) : (
                <Menu>
                  {({ open }) => (
                    <>
                      <Menu.Button className={'ml-2 inline-flex justify-center rounded-md border shadow-sm text-base font-medium focus:outline-none sm:text-sm border-gray-300 bg-white hover:bg-gray-50 p-0 text-gray-400'}>
                        <Icon
                          icon="export"
                          id="export"
                          className="w-12 h-9 px-4 py-2 stroke-gray-400"
                        />
                      </Menu.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          as="ul"
                          className="absolute right-0 top-10 w-56 rounded-lg bg-white shadow-popup z-[1]"
                        >
                          {
                            exportOptions.map((option, index) =>
                              <Menu.Item as="li" key={index}>
                                <button
                                  type="button"
                                  className={mergeClassName(
                                    'w-full py-2.5 px-4 text-sm text-left text-gray-600 hover:bg-gray-50 transition-color duration-200',
                                    { 'border-b': Boolean(exportOptions[index + 1]) },
                                  )}
                                  onClick={() => onExportClick(option.value)}
                                >
                                  {option.label}
                                </button>
                              </Menu.Item>)
                          }
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              )
            )}
          </div>
          <FilterableRecruitTable
            role={role}
            recruits={repsDownline}
            onSelect={onSelectRecruit}
            tileSelected={tileSelected}
            searchText={searchText}
            setSearchText={setSearchText}
            searchType={searchType}
            setSearchType={setSearchType}
            getSortParam={getSortParam}
            onSortChange={onSortParamsChange}
            totalCount={filteredTotal}
            initialPage={1}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onAgreementChange={agreementFilterChange}
            filters={filters?.statuses}
          />
        </div>
      )}

      <Profile
        recruit={selectedRecruit}
        isOpen={recruitProfileOpen}
        onClose={onProfileClose}
      />
      <AddLead isOpen={addLeadOpen} onClose={onAddLeadClose} />
    </div>
  );
};

AllLeads.propTypes = {
  downlineLoading: PropTypes.bool,
  statsLoading: PropTypes.bool,
  // TODO: update type after reworking handleResponseError() at src/redux/helpers/create-request-saga.js
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filteredTotal: PropTypes.number,
  statsHeaderData: PropTypes.exact({
    agreementsSent: PropTypes.number,
    agreementsSigned: PropTypes.number,
    downlineCount: PropTypes.number,
    pendingSignature: PropTypes.number,
  }),
  repsDownline: PropTypes.array,
  role: PropTypes.string,
  isAdmin: PropTypes.bool,
  requestContractStats: PropTypes.func,
  requestDownline: PropTypes.func,
  onAgreementChange: PropTypes.func,
  requestExperienceOptions: PropTypes.func,
  exportLeads: PropTypes.func,
  isExportLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  downlineLoading: downlineLoadingSelector(state),
  statsLoading: allLeadsStatsLoadingSelector(state),
  filteredTotal: contractsFilteredTotalSelector(state),
  statsHeaderData: allLeadsStatsHeaderDataSelector(state),
  repsDownline: downlineSelector(state),
  role: userRoleSelector(state),
  isAdmin: isAdminSelector(state),
  isExportLoading: selectLoadingLeadsExport(state),
});

const mapDispatchToProps = {
  requestContractStats: requestContractStatsAsync.request,
  requestDownline: requestDownlineAsync.request,
  requestExperienceOptions: requestExperienceOptionsAsync.request,
  exportLeads: exportLeadsAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllLeads);
