import SidebarHeader from './SidebarHeader';
import SidebarCard from './SidebarCard';
import { SearchBar, Loader } from '@/components/common';
import { housingConstants } from '@/modules/Housing/lib/constants';
import { CustomFormElement } from '@/components';
import { useDraggable } from '@/modules/Housing/hooks';
import { addFsExcludeClass } from '@/lib/utils';
import classNames from 'classnames';
import {
  defaultSelectOption,
  bedManagementConstants,
} from '@/modules/Housing/lib';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { filteredTeamsSummaries, requestFilteredTeamsSummariesAsync } from '@/modules/Housing/redux/area';
import {
  requestUnBeddedSummariesAsync,
  unBeddedSummariesSelector,
  unBeddedLastPageSelector,
  resetUnBeddedSummariesAction,
} from '@/modules/Housing/redux/bedManagement';
import {
  isBedManagementSidebarLoadingSelector,
  isComplexSummariesLoadingSelector,
  isTeamsSummariesLoadingSelector,
  isUnBeddedSummariesLoadingSelector,
} from '@/modules/Housing/redux/loading';
import PropTypes from 'prop-types';

const {
  TEAM_LABEL,
  TEAM_NAME,
  SEARCH_SPS__NAME,
  SEARCH_REPS__NAME,
  SEARCH_REPS__LABEL,
  SEARCH_SPS__LABEL,
  VIEW_BY_COMPLEX,
  VIEW_BY_TEAM,
} = bedManagementConstants;

const Sidebar = ({
  viewOption,
  viewFilters,
  teams,
  requestUnBeddedSummaries,
  unBeddedSummaries,
  isLoading,
  onSelect,
  selectedSeasonPeriod,
  refreshSidebar,
  requestFilterTeamsSummaries,
  unBeddedLastPage,
  isUnBeddedSummariesLoading,
  resetUnBeddedSummaries,
  setTeamIds,
  setComplexIds,
}) => {
  const defaultPage = 1;
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const [teamId, setTeamsFilter] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('');
  const [selectedBedAllotmentType, setSelectedBedAllotmentType] =
    useState('rep');
  const { tableRef } = useDraggable(isLoading);
  const viewFilterIds = useMemo(() => viewFilters.map((filter) => filter.value), [viewFilters]);

  const complexIds = useMemo(() => {
    if (viewOption === VIEW_BY_COMPLEX) {
      return viewFilterIds;
    }

    if (viewOption === VIEW_BY_TEAM) {
      return [];
    }
  }, [viewOption, viewFilterIds]);

  const teamIds = useMemo(() => {
    if (viewOption === VIEW_BY_TEAM) {
      return viewFilterIds;
    }

    if (viewOption === VIEW_BY_COMPLEX) {
      return teamId ? [teamId] : teams.map((teams) => teams.value);
    }
  }, [teamId, teams, viewOption, viewFilterIds]);

  const resetPagination = useCallback(() => {
    setPage(defaultPage);
    resetUnBeddedSummaries();
  }, [setPage, resetUnBeddedSummaries]);

  useEffect(() => {
    if (viewOption === VIEW_BY_COMPLEX) {
      requestFilterTeamsSummaries({
        complex_ids: viewFilterIds,
        season_period: selectedSeasonPeriod,
      });
    }

    resetPagination();
  }, [
    requestFilterTeamsSummaries,
    resetPagination,
    selectedSeasonPeriod,
    viewFilterIds,
    viewOption,
    refreshSidebar,
  ]);

  useEffect(() => {
    if (isUnBeddedSummariesLoading || page > unBeddedLastPage) {
      return;
    }

    if (viewOption === VIEW_BY_COMPLEX && teams.length === 0) {
      return;
    }

    const payload = {
      bed_allotment_type: selectedBedAllotmentType,
      search_query: selectedSearch,
      season_period: selectedSeasonPeriod,
      page: {
        size: pageSize,
        number: page,
      },
    };

    if (teamIds.length) {
      payload.team_ids = teamIds;
    }
    if (complexIds.length) {
      payload.complex_ids = complexIds;
    }

    requestUnBeddedSummaries(payload);
    setPage(page + 1);
  }, [
    requestUnBeddedSummaries,
    teamIds,
    selectedBedAllotmentType,
    selectedSearch,
    refreshSidebar,
    selectedSeasonPeriod,
    complexIds,
    viewOption,
    teams,
    page,
    unBeddedLastPage,
    isUnBeddedSummariesLoading,
  ]);

  useEffect(() => {
    if (viewOption === VIEW_BY_TEAM) {
      setTeamsFilter(() => '');
    }

    resetPagination();
    setSelectedSearch('');
  }, [viewOption, resetPagination]);

  useEffect(() => {
    setComplexIds(complexIds);
  }, [complexIds, setComplexIds]);

  useEffect(() => {
    setTeamIds(teamIds);
  }, [teamIds, setTeamIds]);

  const parseTeams = useCallback(() => {
    return teams.map((team) => ({
      name: team.label,
      value: team.value,
    }));
  }, [teams]);

  const handleHeaderChange = useCallback(
    (value) => () => {
      setSelectedBedAllotmentType(value);
      setTeamsFilter(() => '');
      setSelectedSearch('');
      resetPagination();
    },
    [resetPagination, setSelectedBedAllotmentType, setTeamsFilter, setSelectedSearch],
  );

  const handleSearchClick = ({ searchText }) => {
    setSelectedSearch(searchText);
    resetPagination();
  };

  const onChangeHandler = useCallback(
    ({ target: { value } }) => {
      setTeamsFilter(() => value);
      resetPagination();
    },
    [setTeamsFilter, resetPagination],
  );

  let unBeddedResidents;
  if (unBeddedSummaries.length === 0 && !isUnBeddedSummariesLoading) {
    unBeddedResidents = housingConstants.NO_DATA_TO_DISPLAY;
  } else {
    unBeddedResidents = unBeddedSummaries.map((summary) => (
      <SidebarCard key={summary.id} {...summary} onSelect={onSelect} />
    ));

    if (isUnBeddedSummariesLoading) {
      unBeddedResidents.push(<Loader key="summary-loader" className="pt-8" />);
    }
  }

  return (
    <div className="w-[330px] h-full border border-gray-200 rounded-lg bg-white">
      <SidebarHeader
        onChange={handleHeaderChange}
        selectedBedAllotmentType={selectedBedAllotmentType}
      />
      <div className="flex flex-col gap-6 p-6 mb-16">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {viewOption === VIEW_BY_COMPLEX && (
              <CustomFormElement
                id={TEAM_NAME}
                name={TEAM_NAME}
                label={TEAM_LABEL}
                colSpan={3}
                type="select"
                selectOptions={[...defaultSelectOption, ...parseTeams()]}
                onChange={onChangeHandler}
                className={addFsExcludeClass()}
                value={teamId}
                isMulti={true}
                disabled={isUnBeddedSummariesLoading}
              />
            )}
            <SearchBar
              searchText={selectedSearch}
              inputName={
                selectedBedAllotmentType === 'rep'
                  ? SEARCH_REPS__NAME
                  : SEARCH_SPS__NAME
              }
              label={
                selectedBedAllotmentType === 'rep'
                  ? SEARCH_REPS__LABEL
                  : SEARCH_SPS__LABEL
              }
              onSearchClick={handleSearchClick}
              labelClassName="text-sm font-medium text-gray-700 mb-0.5"
              inputClassName="shadow-sm block sm:text-sm border-r-0 rounded-md rounded-r-none border-gray-300 focus:border-aptivegreen focus:border-r focus:border-r-aptivegreen focus:outline-none focus:ring-1 focus:ring-aptivegreen flex-grow"
              iconClassName="border-l-0 rounded-l-none shadow-sm border-gray-300"
              disabled={isUnBeddedSummariesLoading}
            />
            <div
              className={classNames(
                'overflow-y-auto divide-y no-scrollbar',
                unBeddedSummaries.length === 0 &&
                'flex justify-center items-center',
              )}
              style={{ height: 'calc(100vh - 530px)' }}
              ref={tableRef}
            >
              {unBeddedResidents}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  teams: filteredTeamsSummaries(state),
  unBeddedSummaries: unBeddedSummariesSelector(state),
  unBeddedLastPage: unBeddedLastPageSelector(state),
  isLoading:
    isBedManagementSidebarLoadingSelector(state) ||
    isTeamsSummariesLoadingSelector(state) ||
    isComplexSummariesLoadingSelector(state),
  isUnBeddedSummariesLoading: isUnBeddedSummariesLoadingSelector(state),
});

const mapDispatchToProps = {
  requestUnBeddedSummaries: requestUnBeddedSummariesAsync.request,
  resetUnBeddedSummaries: resetUnBeddedSummariesAction,
  requestFilterTeamsSummaries: requestFilteredTeamsSummariesAsync.request,
};

Sidebar.propTypes = {
  viewOption: PropTypes.string,
  viewFilters: PropTypes.array,
  requestTeamsSummaries: PropTypes.func,
  teams: PropTypes.array,
  requestUnBeddedSummaries: PropTypes.func,
  unBeddedSummaries: PropTypes.array,
  isLoading: PropTypes.bool,
  selectedSeasonPeriod: PropTypes.string,
  refreshSidebar: PropTypes.bool,
  requestFilterTeamsSummaries: PropTypes.func,
  unBeddedLastPage: PropTypes.number,
  isUnBeddedSummariesLoading: PropTypes.bool,
  resetUnBeddedSummaries: PropTypes.func,
  onSelect: PropTypes.func,
  setTeamIds: PropTypes.func,
  setComplexIds: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
