import { createAction, createAsyncAction } from '@/redux/root';

import { nameSpace } from '../nameSpace';

export const planNameSpace = `${nameSpace}/plans`;

export const requestPlansAsync = createAsyncAction(
  `${planNameSpace}/REQUEST_PLANS`,
);

export const updatePlanAsync = createAsyncAction(
  `${planNameSpace}/UPDATE_PLAN`,
);

export const massUpdateAsync = createAsyncAction(
  `${planNameSpace}/MASS_UPDATE`,
);

export const createPlanAsync = createAsyncAction(
  `${planNameSpace}/CREATE_PLAN`,
);

export const requestPlanAsync = createAsyncAction(
  `${planNameSpace}/REQUEST_PLAN`,
);

export const removePlanAsync = createAsyncAction(
  `${planNameSpace}/REMOVE_PLAN`,
);

export const createPlanBuilderAreaPlan = createAction(
  `${planNameSpace}/CREATE_AREA_PLAN`,
);

export const updatePlanBuilderAreaPlan = createAction(
  `${planNameSpace}/UPDATE_AREA_PLAN`,
);

export const removePlanBuilderAreaPlan = createAction(
  `${planNameSpace}/DELETE_AREA_PLAN`,
);

export const createPlanBuilderGroupPlan = createAction(
  `${planNameSpace}/CREATE_GROUP_PLAN`,
);

export const updatePlanBuilderGroupPlan = createAction(
  `${planNameSpace}/UPDATE_GROUP_PLAN`,
);

export const removePlanBuilderGroupPlan = createAction(
  `${planNameSpace}/DELETE_GROUP_PLAN`,
);

export const requestBillingFrequenciesAsync = createAsyncAction(
  `${planNameSpace}/REQUEST_BILLING_FREQUENCIES`,
);
