import { CustomFormElement } from '@/components';
import { onboardingConstants, onboardingDataValues } from '@/lib/constants';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

const {
  DIRECT_DEPOSIT_BANK_NAME,
  DIRECT_DEPOSIT_ACCOUNT_NAME,
  DIRECT_DEPOSIT_ACCOUNT_TYPE,
  DIRECT_DEPOSIT_ROUTING_NUMBER,
  DIRECT_DEPOSIT_ACCOUNT_NUMBER,
  DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER,
  DIRECT_DEPOSIT_BANK_NAME_LABEL,
  DIRECT_DEPOSIT_ACCOUNT_NAME_LABEL,
  DIRECT_DEPOSIT_ACCOUNT_TYPE_LABEL,
  DIRECT_DEPOSIT_ROUTING_NUMBER_LABEL,
  DIRECT_DEPOSIT_ACCOUNT_NUMBER_LABEL,
  DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER_LABEL,
} = onboardingConstants;

const {
  ACCOUNT_TYPE_CHECKING_VALUE,
  ACCOUNT_TYPE_SAVINGS_VALUE,
} = onboardingDataValues;

const radioOptions = [
  {
    label: 'Checking',
    value: ACCOUNT_TYPE_CHECKING_VALUE,
    id: 'directDepositChecking',
  },
  {
    label: 'Savings',
    value: ACCOUNT_TYPE_SAVINGS_VALUE,
    id: 'directDepositSavings',
  },
];

const DirectDepositFields = ({ canEditField, onChangeHandler }) => {
  const {
    register,
    formState: {
      errors,
      defaultValues,
    },
  } = useFormContext();

  const [accountNumberPlaceholder] = useState(defaultValues._[DIRECT_DEPOSIT_ACCOUNT_NUMBER]);
  const [confirmAccountNumberPlaceholder] = useState(defaultValues._[DIRECT_DEPOSIT_ACCOUNT_NUMBER]);

  return (
    <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
      <CustomFormElement
        colSpan={3}
        id={DIRECT_DEPOSIT_BANK_NAME}
        name={DIRECT_DEPOSIT_BANK_NAME}
        label={DIRECT_DEPOSIT_BANK_NAME_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[DIRECT_DEPOSIT_BANK_NAME]}
        disabled={!canEditField(DIRECT_DEPOSIT_BANK_NAME)}
        required
      />
      <CustomFormElement
        colSpan={3}
        id={DIRECT_DEPOSIT_ACCOUNT_NAME}
        name={DIRECT_DEPOSIT_ACCOUNT_NAME}
        label={DIRECT_DEPOSIT_ACCOUNT_NAME_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[DIRECT_DEPOSIT_ACCOUNT_NAME]}
        disabled={!canEditField(DIRECT_DEPOSIT_ACCOUNT_NAME)}
        required
      />
      <CustomFormElement
        colSpan={3}
        id={DIRECT_DEPOSIT_ACCOUNT_TYPE}
        name={DIRECT_DEPOSIT_ACCOUNT_TYPE}
        label={DIRECT_DEPOSIT_ACCOUNT_TYPE_LABEL}
        type="radio"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[DIRECT_DEPOSIT_ACCOUNT_TYPE]}
        disabled={!canEditField(DIRECT_DEPOSIT_ACCOUNT_TYPE)}
        radioOptions={radioOptions}
        orientation="horizontal"
        required
      />
      <CustomFormElement
        colSpan={3}
        id={DIRECT_DEPOSIT_ROUTING_NUMBER}
        name={DIRECT_DEPOSIT_ROUTING_NUMBER}
        label={DIRECT_DEPOSIT_ROUTING_NUMBER_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[DIRECT_DEPOSIT_ROUTING_NUMBER]}
        disabled={!canEditField(DIRECT_DEPOSIT_ROUTING_NUMBER)}
        required
      />
      <CustomFormElement
        colSpan={3}
        placeholder={accountNumberPlaceholder}
        id={DIRECT_DEPOSIT_ACCOUNT_NUMBER}
        name={DIRECT_DEPOSIT_ACCOUNT_NUMBER}
        label={DIRECT_DEPOSIT_ACCOUNT_NUMBER_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[DIRECT_DEPOSIT_ACCOUNT_NUMBER]}
        disabled={!canEditField(DIRECT_DEPOSIT_ACCOUNT_NUMBER)}
        required
      />
      <CustomFormElement
        colSpan={3}
        placeholder={confirmAccountNumberPlaceholder}
        id={DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER}
        name={DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER}
        label={DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER]}
        disabled={!canEditField(DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER)}
        required
      />
    </div>
  );
};

DirectDepositFields.propTypes = {
  canEditField: PropTypes.func,
  onChangeHandler: PropTypes.func,
};

export default DirectDepositFields;
