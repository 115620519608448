var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EventDetailModal from 'components/EventDetailModal';
import { addMonths, format, getDaysInMonth, startOfMonth, parse, addDays } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchCalendarEventParticipants, fetchCalendarEvents } from 'services/calendar';
import { getCalendarEventTypeColors } from 'utils/color';
import { convert24HourTo12Hour } from 'utils/convertHour';
import { v4 as uuidv4 } from 'uuid';
var CalendarMonthView = function (_a) {
    var officeId = _a.officeId, activeDate = _a.activeDate, eventTypes = _a.eventTypes, onEventSuccess = _a.onEventSuccess;
    var _b = useState({}), events = _b[0], setEvents = _b[1];
    var _c = useState(false), isEventDetailOpened = _c[0], setIsEventDetailOpened = _c[1];
    var _d = useState(false), isEventDetailLoading = _d[0], setIsEventDetailLoading = _d[1];
    var _e = useState(null), selectedEvent = _e[0], setSelectedEvent = _e[1];
    var _f = useState(null), selectedEventDate = _f[0], setSelectedEventDate = _f[1];
    var _g = useState([]), selectedEventParticipants = _g[0], setSelectedEventParticipants = _g[1];
    var items = useMemo(function () {
        var currentYear = activeDate.getFullYear();
        var currentMonth = activeDate.getMonth();
        var daysInMonth = getDaysInMonth(activeDate);
        var daysInPreviousMonth = getDaysInMonth(addMonths(activeDate, -1));
        var previousMonthStart = daysInPreviousMonth - startOfMonth(activeDate).getDay() + 1;
        var items = [];
        // Add days from the previous month
        for (var i = previousMonthStart; i <= daysInPreviousMonth; i++) {
            items.push({ day: i, month: currentMonth - 1 < 0 ? 11 : currentMonth - 1, year: currentMonth - 1 < 0 ? currentYear - 1 : currentYear });
        }
        // Add days from the current month
        for (var i = 1; i <= daysInMonth; i++) {
            items.push({ day: i, month: currentMonth, year: currentYear });
        }
        // Add days from the next month
        var nextMonthDay = 1;
        while (items.length % 7 !== 0) {
            items.push({ day: nextMonthDay, month: currentMonth + 1 > 11 ? 0 : currentMonth + 1, year: currentMonth + 1 > 11 ? currentYear + 1 : currentYear });
            nextMonthDay++;
        }
        return items;
    }, [activeDate]);
    var isCurrentMonth = useCallback(function (item) {
        return item.month === activeDate.getMonth();
    }, [activeDate]);
    var isCurrentDate = function (item) {
        var currentDate = new Date();
        return item.day === currentDate.getDate() && item.month === currentDate.getMonth() && item.year === currentDate.getFullYear();
    };
    var getItemEvents = useCallback(function (item) {
        var _a, _b, _c;
        return (_c = (_b = (_a = events["".concat(item.year, "-").concat((item.month + 1).toString().padStart(2, '0'), "-").concat(item.day.toString().padStart(2, '0'))]) === null || _a === void 0 ? void 0 : _a.filter(function (event) { return eventTypes.map(function (type) { return type.id; }).includes(event.event_type); })) === null || _b === void 0 ? void 0 : _b.sort(function (eventA, eventB) { return eventA.start_at.localeCompare(eventB.start_at); })) !== null && _c !== void 0 ? _c : [];
    }, [events, eventTypes]);
    useEffect(function () {
        fetchEvents();
        // eslint-disable-next-line
    }, [items, officeId]);
    var fetchEvents = function () {
        var startDate = parse("".concat(items[0].year, "-").concat((items[0].month + 1).toString().padStart(2, '0'), "-").concat(items[0].day.toString().padStart(2, '0')), 'yyyy-MM-dd', new Date());
        var startDateStr = format(startDate, 'yyyy-MM-dd');
        var endDateStr = format(addDays(startDate, items.length), 'yyyy-MM-dd');
        fetchCalendarEvents({
            office_id: officeId,
            start_date: startDateStr,
            end_date: endDateStr,
            per_page: items.length,
        })
            .then(function (response) {
            var tempData = {};
            Object.keys(response.result.events).forEach(function (key) {
                tempData[key] = [];
                response.result.events[key].forEach(function (event) {
                    tempData[key].push(__assign(__assign({}, event), { is_hidden: false }));
                });
            });
            setEvents(tempData);
        })
            .catch(function (e) {
            console.log(e);
        });
    };
    var handleClickEvent = function (event, eventDateString) {
        setSelectedEvent(event);
        setSelectedEventDate(parse(eventDateString, 'yyyy-MM-dd', new Date()));
        setIsEventDetailOpened(true);
        setIsEventDetailLoading(true);
        setSelectedEventParticipants([]);
        fetchCalendarEventParticipants(event.id)
            .then(function (response) {
            setSelectedEventParticipants(response);
            setIsEventDetailLoading(false);
        });
    };
    var handleHideShowEvent = function (eventId, eventDate, isAll, isHidden) {
        var tempEvents = __assign({}, events);
        Object.keys(tempEvents).forEach(function (date) {
            tempEvents[date] = tempEvents[date].map(function (event) {
                if (isAll && event.id === eventId) {
                    return __assign(__assign({}, event), { is_hidden: isHidden });
                }
                if (!isAll && event.id === eventId && format(eventDate, 'yyyy-MM-dd') === date) {
                    return __assign(__assign({}, event), { is_hidden: isHidden });
                }
                return event;
            });
        });
        setEvents(tempEvents);
    };
    return (_jsxs("div", __assign({ className: "border-[#CBCBCB80] flex-auto overflow-auto" }, { children: [_jsx("div", __assign({ className: "grid grid-cols-7" }, { children: items.map(function (item, index) { return (_jsxs("div", __assign({ className: "h-[192px] flex flex-col border-r border-b border-gray-100 ".concat(isCurrentMonth(item) ? 'bg-white' : 'bg-gray-50') }, { children: [_jsx("div", __assign({ className: "py-2 text-base text-center ".concat(isCurrentMonth(item) ? 'text-gray-900' : 'text-gray-500') }, { children: (isCurrentDate(item)) ? (_jsx("div", __assign({ className: "bg-[#007AFF] text-white w-[32px] h-[32px] rounded-lg flex items-center justify-center m-auto" }, { children: item.day }))) : (_jsx("div", { children: item.day })) })), _jsx("ul", __assign({ className: "overflow-auto" }, { children: 
                            // eslint-disable-next-line array-callback-return
                            getItemEvents(item).map(function (event) {
                                if (!event.is_hidden && event.is_canceled === 'false')
                                    return (_jsx("li", __assign({ className: "text-sm mt-2 p-1", onClick: function () { return handleClickEvent(event, "".concat(item.year, "-").concat((item.month + 1).toString().padStart(2, '0'), "-").concat(item.day.toString().padStart(2, '0'))); } }, { children: _jsxs("div", __assign({ className: "flex gap-1 p-2 rounded-lg cursor-pointer", style: { background: getCalendarEventTypeColors(event.event_type).light } }, { children: [_jsx("div", { className: "mt-[6px] h-2 w-2 rounded-full flex-none", style: { background: getCalendarEventTypeColors(event.event_type).dark } }), _jsxs("div", { children: [convert24HourTo12Hour(event.start_at), " ", event.title] })] })) }), "".concat(event.id, ":").concat(uuidv4())));
                            }) }))] }), index)); }) })), selectedEvent && selectedEventDate && (_jsx(EventDetailModal, { event: selectedEvent, eventDate: selectedEventDate, participants: selectedEventParticipants, loading: isEventDetailLoading, open: isEventDetailOpened, onDelete: fetchEvents, onHideShow: handleHideShowEvent, onSuccess: onEventSuccess, onClose: function () { return setIsEventDetailOpened(false); } }))] })));
};
export default CalendarMonthView;
