var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
var CustomTextArea = function (_a) {
    var formValue = _a.formValue, id = _a.id, hasError = _a.hasError, rows = _a.rows, onChange = _a.onChange, onBlur = _a.onBlur, required = _a.required, disabled = _a.disabled, className = _a.className, ariaDescribedBy = _a.ariaDescribedBy, name = _a.name, baseClasses = _a.baseClasses, register = _a.register, placeholder = _a.placeholder;
    var errorClasses = baseClasses.errorClasses, standardClasses = baseClasses.standardClasses;
    var classes = classNames(className, 'shadow-sm block w-full sm:text-sm border rounded-md', "".concat(hasError ? errorClasses : standardClasses));
    return (_jsx(_Fragment, { children: _jsx("textarea", __assign({}, (register && __assign({}, register(name))), { value: formValue, id: id, name: name, className: classes, rows: rows, onChange: onChange }, onBlur && { onBlur: onBlur }, { required: required, disabled: disabled, "aria-describedby": ariaDescribedBy, "aria-invalid": hasError, placeholder: placeholder })) }));
};
export default CustomTextArea;
