import { addMonths, subMonths, getDaysInMonth, setDate, getDay, addDays, addWeeks, subDays, eachDayOfInterval, add, isPast, } from 'date-fns';
import { convert12HourTo24Hour } from './convertHour';
import { TZDate } from '@date-fns/tz';
export function changeDateMonth(date, isNextMonth) {
    return addMonths(date, isNextMonth ? 1 : -1);
}
export function changeDateWeek(date, isNextMonth) {
    return addWeeks(date, isNextMonth ? 1 : -1);
}
function getCalendarCells(date) {
    var _a, _b;
    var daysArray = new Array(getDaysInMonth(date)).fill(1);
    var calendarCells = [];
    var prepareCell = function (date, dayNumber) {
        return {
            text: String(dayNumber),
            value: setDate(date, dayNumber),
        };
    };
    daysArray.forEach(function (_, i) {
        calendarCells.push(prepareCell(date, i + 1));
    });
    var unShiftAmount = getDay((_a = calendarCells[0]) === null || _a === void 0 ? void 0 : _a.value);
    var unPushAmount = 7 - getDay((_b = calendarCells[calendarCells.length - 1]) === null || _b === void 0 ? void 0 : _b.value) - 1;
    var lastMonth = subMonths(date, 1);
    for (var i = 0; i < unShiftAmount; i++) {
        calendarCells.unshift(prepareCell(lastMonth, getDaysInMonth(lastMonth) - i));
    }
    var nextMonth = addMonths(date, 1);
    for (var i = 0; i < unPushAmount; i++) {
        calendarCells.push(prepareCell(nextMonth, i + 1));
    }
    return calendarCells;
}
export function getCalendarRows(date) {
    var cells = getCalendarCells(date);
    var rows = [];
    for (var i = 0; i < cells.length; i += 7) {
        rows.push(cells.slice(i, i + 7));
    }
    return rows;
}
export function getActiveRow(date) {
    var unShiftAmount = getDay(date);
    var unPushAmount = 7 - getDay(date) - 1;
    var startDay = subDays(date, unShiftAmount);
    var endDay = addDays(date, unPushAmount);
    var thisWeek = eachDayOfInterval({ start: startDay, end: endDay });
    var rows = thisWeek.map(function (day) {
        return { text: String(getDay(day)), value: day };
    });
    return rows;
}
export var isPastTime = function (date, time12h, timezone) {
    var _a = convert12HourTo24Hour(time12h).split(':'), hours = _a[0], minutes = _a[1];
    var selectedDateTime = add(date, {
        hours: Number(hours),
        minutes: Number(minutes),
    });
    var zonedDateTime = new TZDate(selectedDateTime, timezone);
    return isPast(zonedDateTime);
};
