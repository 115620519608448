import { onboardingConstants } from '@/lib/constants';
import { OnboardingHeader } from '@/modules/recruits/components';
import {
  selectedSelector,
  validateOnboardingFormsCompletion,
  setCurrentStepAction,
  setFeatureFlagAction,
} from '@/redux/onboarding';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { EditProfile, ViewHistory } from '.';
import { selectViewSection, selectViewStep, setReviewDocumentAction } from '@/redux/notifications';
import { useFeatureFlag } from 'configcat-react';

const {
  WIZARD_TYPE_ADMIN,
  WIZARD_TYPE_REGIONAL,
  WIZARD_TYPE_RECRUITER,
  WIZARD_TYPE_RECRUIT,
  PAY_DETAILS_SECTION_FEATURE_FLAG_NAME,
  HOUSING_SECTION_FEATURE_FLAG_NAME,
  RESIDENTIAL_HISTORY_SECTION_FEATURE_FLAG_NAME,
  VEHICLES_SECTION_FEATURE_FLAG_NAME,
  EMPLOYMENT_HISTORY_SECTION_FEATURE_FLAG_NAME,
  REFERENCES_SECTION_FEATURE_FLAG_NAME,
  I9_DOCUMENTS_SECTION_FEATURE_FLAG_NAME,
  DIRECT_DEPOSIT_SECTION_FEATURE_FLAG_NAME,
  APPROVED_PHOTO_WORKDAY_CHECKBOX_FEATURE_FLAG_NAME,
  W9_WORKDAY_CHECKBOX_FEATURE_FLAG_NAME,
  DIRECT_DEPOSIT_WORKDAY_CHECKBOX_FEATURE_FLAG_NAME,
} = onboardingConstants;

const MODES = {
  EDIT_PROFILE: 'edit_profile',
  VIEW_HISTORY: 'view_history',
};

export const ProfileWizardContext = createContext(null);

const ProfileWizard = ({
  // Own Props
  wizardType = WIZARD_TYPE_RECRUIT,
  isPersonalWizard = true,
  isPopup = false,
  userId,
  onUserDeleted,
  recruitingSeasonId,
  cancelToken,

  // State
  rep,
  uploadFileSection,
  setUploadFileSection,
  uploadFileStep,
  setCurrentStep,
  validateOnboardingCompletion,
  setFeatureFlag,
}) => {
  const [mode, setMode] = useState(MODES.EDIT_PROFILE);
  const [section, setSection] = useState(null);
  const [repName, setRepName] = useState('');

  /*
   * Begin: Feature Flags for configuring the Wizard
   *
   * See src/redux/onboarding/reducer.js
   */
  const { value: isPayDetailsSectionEnabled } =
    useFeatureFlag(PAY_DETAILS_SECTION_FEATURE_FLAG_NAME, false);

  const { value: isHousingSectionEnabled } =
    useFeatureFlag(HOUSING_SECTION_FEATURE_FLAG_NAME, false);

  const { value: isResidentialHistorySectionEnabled } =
    useFeatureFlag(RESIDENTIAL_HISTORY_SECTION_FEATURE_FLAG_NAME, false);

  const { value: isVehiclesSectionEnabled } =
    useFeatureFlag(VEHICLES_SECTION_FEATURE_FLAG_NAME, false);

  const { value: isEmploymentHistorySectionEnabled } =
    useFeatureFlag(EMPLOYMENT_HISTORY_SECTION_FEATURE_FLAG_NAME, false);

  const { value: isReferencesSectionEnabled } =
    useFeatureFlag(REFERENCES_SECTION_FEATURE_FLAG_NAME, false);

  const { value: isI9DocumentsSectionEnabled } =
    useFeatureFlag(I9_DOCUMENTS_SECTION_FEATURE_FLAG_NAME, false);

  const { value: isDirectDepositSectionEnabled } =
    useFeatureFlag(DIRECT_DEPOSIT_SECTION_FEATURE_FLAG_NAME, false);

  // Tip: Store wizard-related feature flags in the Redux store, because they need to be used in reducers/selectors :man-shrugging:
  useEffect(() => {
    setFeatureFlag({ name: PAY_DETAILS_SECTION_FEATURE_FLAG_NAME, value: isPayDetailsSectionEnabled });
  }, [setFeatureFlag, isPayDetailsSectionEnabled]);

  useEffect(() => {
    setFeatureFlag({ name: HOUSING_SECTION_FEATURE_FLAG_NAME, value: isHousingSectionEnabled });
  }, [setFeatureFlag, isHousingSectionEnabled]);

  useEffect(() => {
    setFeatureFlag({ name: RESIDENTIAL_HISTORY_SECTION_FEATURE_FLAG_NAME, value: isResidentialHistorySectionEnabled });
  }, [setFeatureFlag, isResidentialHistorySectionEnabled]);

  useEffect(() => {
    setFeatureFlag({ name: VEHICLES_SECTION_FEATURE_FLAG_NAME, value: isVehiclesSectionEnabled });
  }, [setFeatureFlag, isVehiclesSectionEnabled]);

  useEffect(() => {
    setFeatureFlag({ name: EMPLOYMENT_HISTORY_SECTION_FEATURE_FLAG_NAME, value: isEmploymentHistorySectionEnabled });
  }, [setFeatureFlag, isEmploymentHistorySectionEnabled]);

  useEffect(() => {
    setFeatureFlag({ name: REFERENCES_SECTION_FEATURE_FLAG_NAME, value: isReferencesSectionEnabled });
  }, [setFeatureFlag, isReferencesSectionEnabled]);

  useEffect(() => {
    setFeatureFlag({ name: I9_DOCUMENTS_SECTION_FEATURE_FLAG_NAME, value: isI9DocumentsSectionEnabled });
  }, [setFeatureFlag, isI9DocumentsSectionEnabled]);

  useEffect(() => {
    setFeatureFlag({ name: DIRECT_DEPOSIT_SECTION_FEATURE_FLAG_NAME, value: isDirectDepositSectionEnabled });
  }, [setFeatureFlag, isDirectDepositSectionEnabled]);
  /* End: Feature Flags for configuring the Wizard */

  /*
   * Begin: Feature Flags for WorkDay Task checkboxes
   *
   * See src/redux/reps/reducer.js
   */
  const { value: isApprovedPhotoCheckboxEnabled } =
    useFeatureFlag(APPROVED_PHOTO_WORKDAY_CHECKBOX_FEATURE_FLAG_NAME, false);

  const { value: isW9CheckboxEnabled } =
    useFeatureFlag(W9_WORKDAY_CHECKBOX_FEATURE_FLAG_NAME, false);

  const { value: isDirectDepositCheckboxEnabled } =
    useFeatureFlag(DIRECT_DEPOSIT_WORKDAY_CHECKBOX_FEATURE_FLAG_NAME, false);

  // Tip: Store wizard-related feature flags in the Redux store, because they need to be used in reducers/selectors :man-shrugging:
  useEffect(() => {
    setFeatureFlag({ name: APPROVED_PHOTO_WORKDAY_CHECKBOX_FEATURE_FLAG_NAME, value: isApprovedPhotoCheckboxEnabled });
  }, [setFeatureFlag, isApprovedPhotoCheckboxEnabled]);

  useEffect(() => {
    setFeatureFlag({ name: W9_WORKDAY_CHECKBOX_FEATURE_FLAG_NAME, value: isW9CheckboxEnabled });
  }, [setFeatureFlag, isW9CheckboxEnabled]);

  useEffect(() => {
    setFeatureFlag({ name: DIRECT_DEPOSIT_WORKDAY_CHECKBOX_FEATURE_FLAG_NAME, value: isDirectDepositCheckboxEnabled });
  }, [setFeatureFlag, isDirectDepositCheckboxEnabled]);
  /* End: Feature Flags for WorkDay Task checkboxes */

  useEffect(() => {
    if (rep?.userId) {
      validateOnboardingCompletion();
    }
  }, [rep?.userId, validateOnboardingCompletion]);

  useEffect(() => {
    if (rep.firstName && rep.lastName) {
      setRepName(`${rep.firstName} ${rep.lastName}`);
    }
  }, [rep.firstName, rep.lastName, setRepName]);

  useEffect(() => {
    if (uploadFileSection) {
      setSection(uploadFileSection);
      setCurrentStep(uploadFileStep);
    }
  }, [setCurrentStep, uploadFileSection, uploadFileStep]);

  useEffect(() => {
    if (!section) {
      setUploadFileSection(null);
    }
  }, [section, setUploadFileSection]);

  const showEditProfile = () => {
    setMode(MODES.EDIT_PROFILE);
  };

  const showViewHistory = (section) => {
    setMode(MODES.VIEW_HISTORY);
    setSection(section);
  };

  const getView = (mode) => {
    switch (mode) {
      case MODES.VIEW_HISTORY:
        return (
          <ViewHistory />
        );

      default:
        return (
          <EditProfile
            onUserDeleted={onUserDeleted}
            recruitingSeasonId={recruitingSeasonId}
            cancelToken={cancelToken}
          />
        );
    }
  };

  const profileWizardContextValue = useMemo(() => ({
    wizardType,
    isPersonalWizard,
    isPopup,
    userId,
    section,
    setSection,
    repName,
    setRepName,
    showEditProfile,
    showViewHistory,
    recruitingSeasonId,
  }), [
    wizardType,
    isPersonalWizard,
    isPopup,
    userId,
    section,
    repName,
    recruitingSeasonId,
  ]);

  return (
    <div className="flex flex-col w-screen max-w-5xl rounded overflow-hidden bg-white shadow-xl">
      <ProfileWizardContext.Provider value={profileWizardContextValue}>
        <OnboardingHeader />
        {getView(mode)}
      </ProfileWizardContext.Provider>
    </div>
  );
};

ProfileWizard.propTypes = {
  wizardType: PropTypes.oneOf([
    WIZARD_TYPE_ADMIN,
    WIZARD_TYPE_REGIONAL,
    WIZARD_TYPE_RECRUITER,
    WIZARD_TYPE_RECRUIT,
  ]),
  isPersonalWizard: PropTypes.bool,
  isPopup: PropTypes.bool,
  userId: PropTypes.number,
  onUserDeleted: PropTypes.func,
  rep: PropTypes.object,
  uploadFileSection: PropTypes.string,
  uploadFileStep: PropTypes.number,
  setUploadFileSection: PropTypes.func,
  setCurrentStep: PropTypes.func,
  resetCurrentStep: PropTypes.func,
  validateOnboardingCompletion: PropTypes.func,
  recruitingSeasonId: PropTypes.string,
  setFeatureFlag: PropTypes.func,
  cancelToken: PropTypes.object,
};

const mapStateToProps = (state) => ({
  rep: selectedSelector(state),
  uploadFileSection: selectViewSection(state),
  uploadFileStep: selectViewStep(state),
});

const mapDispatchToProps = {
  setUploadFileSection: setReviewDocumentAction,
  setCurrentStep: setCurrentStepAction,
  validateOnboardingCompletion: validateOnboardingFormsCompletion,
  setFeatureFlag: setFeatureFlagAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWizard);
