import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { CustomButton, Select } from '@/components/common';
import { removeGroupPlanAsync } from '@/modules/AdminTools/redux/planBuilder/group-plans';

const GroupPlanOptions = ({ groupId, setGroupId, groupPlans, groupOptions }) => {
  const dispatch = useDispatch();

  const groupPlanRows = useMemo(() => {
    const groupMap = groupOptions?.reduce((map, { label, value }) => {
      map[value] = label;

      return map;
    }, {}) || {};

    return groupPlans.map(({ group_id }) => ({
      label: groupMap[group_id] ?? `Group ID: ${group_id}`,
      value: group_id,
    }));
  }, [groupPlans, groupOptions]);

  const remove = useCallback(() => {
    if (!window.confirm('Are you sure?')) {
      return;
    }
    const groupPlan = groupPlans.find(({ group_id }) => group_id === groupId);
    dispatch(removeGroupPlanAsync.request({ groupPlan }));
    setGroupId(null);
  }, [groupId, groupPlans, dispatch, setGroupId]);

  if (!groupPlans.length) {
    return null;
  }

  return (
    <div className="flex flex-col mt-4">
      <div>
        Select a group override to edit
      </div>
      <div className="w-64">
        <Select
          name={'groupNames'}
          options={groupPlanRows}
          onChange={({ target: { value } }) => setGroupId(value)}
          value={groupId}
        />
        <div>
          <CustomButton onClick={remove}>Remove</CustomButton>
        </div>
      </div>
    </div>
  );
};

GroupPlanOptions.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setGroupId: PropTypes.func.isRequired,
  groupPlans: PropTypes.array,
  groupOptions: PropTypes.array,
};

export default GroupPlanOptions;
