import { startOfWeek, endOfWeek, startOfYear, addWeeks } from 'date-fns';
export function getWeeksOfYear(year) {
    var weeks = [];
    var currentDate = startOfWeek(startOfYear(new Date(year, 0, 1)), { weekStartsOn: 1 }); // Starting from the first Monday
    var weekCount = 1;
    while (currentDate.getFullYear() === year) {
        weeks.push({
            week: weekCount,
            start: currentDate,
            end: endOfWeek(currentDate, { weekStartsOn: 1 })
        });
        currentDate = addWeeks(currentDate, 1);
        weekCount++;
    }
    return weeks;
}
