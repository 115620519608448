import Header from '@/modules/AdminTools/components/Header';
import CustomGroupForm from './CustomGroupForm';
import { CustomButton } from '@/components';
import { createCustomGroupTypeAsync } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/custom-group-types';

const defaultValues = {
  'name': '',
  'order': 0,
};

const CustomGroupHeader = () => (
  <Header title="Custom Groups">
    <CustomGroupForm
      defaultValues={defaultValues}
      request={createCustomGroupTypeAsync.request}
    >
      <CustomButton color={'green'}>Create Custom Group Type</CustomButton>
    </CustomGroupForm>
  </Header>
);

export default CustomGroupHeader;
