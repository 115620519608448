var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AptiveIcon } from '@aptive-env/storybook';
var ChecklistSelectModalHeader = function (_a) {
    var searchValue = _a.searchValue, onClose = _a.onClose, onChangeSearchValue = _a.onChangeSearchValue;
    return (_jsxs("div", __assign({ className: "w-full h-[60px] flex items-center justify-between border-b border-gray-200 pl-2 pr-4" }, { children: [_jsxs("div", __assign({ className: "flex items-center bg-white border border-[#E5E7EB] rounded-md h-[38px] w-[240px] overflow-hidden" }, { children: [_jsx("input", { className: "px-3 w-full focus-visible:outline-none text-[#3d3d3d] text-sm placeholder-[#A9A9A9] border-none focus:border-none focus:ring-0", value: searchValue, onChange: function (e) { return onChangeSearchValue(e.target.value); }, placeholder: "Search requirements" }), _jsx("div", __assign({ className: "px-3 border-l h-full flex items-center" }, { children: _jsx(AptiveIcon, { className: "w-[20px] h-[20px] stroke-[#9CA3AF] fill-none", icon: "search" }) }))] })), _jsx("div", __assign({ className: "cursor-pointer", onClick: onClose }, { children: _jsx(AptiveIcon, { className: "w-4 h-4 fill-[#9CA3AF] stroke-none", icon: "x", isFilled: true }) }))] })));
};
export default ChecklistSelectModalHeader;
