import { Avatar } from '@/components/common';
import PropTypes from 'prop-types';

const SidebarCard = (props) => {
  const { name, type_of_room, number_of_rooms, onSelect, id } = props;

  return (
    <div className="flex justify-between items-center py-2 cursor-pointer" onClick={() => onSelect(id)}>
      <div className="flex items-center">
        <Avatar />
        <div className="flex flex-col ml-2">
          <span className="text-gray-900 text-sm leading-4 font-normal">
            {name}
          </span>
        </div>
      </div>
      <div className="flex flex-col items-center px-3 text-gray-400 font-normal text-xs">
        <span className="text-gray-600 text-[10px] leading-3 font-normal">
          {type_of_room}
        </span>
        <span className=" text-gray-600 text-[10px] leading-3 font-normal">
          {type_of_room === 'Married' ? `${number_of_rooms} rooms` : null}
        </span>
      </div>
    </div>
  );
};

SidebarCard.propTypes = {
  name: PropTypes.string,
  type_of_room: PropTypes.string,
  number_of_rooms: PropTypes.number,
};

export default SidebarCard;
