import FilterValue from '@/modules/AdminTools/components/DataTable/FilterValue';
import PropTypes from 'prop-types';

const PlanIdsValue = ({ plan_ids }) => {
  const planIdsArray = plan_ids?.split(',') ?? [];

  return (
    <div>
      {planIdsArray.length > 0 &&
        planIdsArray.map((planId) => (
          <FilterValue key={planId} field="plan_id" value={planId} />
        ))}
      {planIdsArray.length === 0 && ''}
    </div>
  );
};

PlanIdsValue.propTypes = {
  plan_ids: PropTypes.any,
};

export default PlanIdsValue;
