var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Button, AptiveIcon } from '@aptive-env/storybook';
import { add, format, startOfToday, startOfWeek } from 'date-fns';
export var PlanningDateSelect = function (_a) {
    var startDate = _a.startDate, onChangeStartDate = _a.onChangeStartDate;
    var displayedValue = useMemo(function () {
        var start = format(startDate, 'dd MMM');
        var end = format(add(startDate, { days: 6 }), 'dd MMM yyyy');
        return "".concat(start, " - ").concat(end);
    }, [startDate]);
    var handleClickNext = function () {
        onChangeStartDate(add(startDate, { weeks: 1 }));
    };
    var handleClickPrev = function () {
        onChangeStartDate(add(startDate, { weeks: -1 }));
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "font-medium text-[32px] leading-5" }, { children: displayedValue })), _jsxs("div", __assign({ className: "flex gap-4 items-center" }, { children: [_jsx(Button, __assign({ className: "w-10 h-9", variant: "neutral", onClick: handleClickPrev }, { children: _jsx(AptiveIcon, { className: "h-5 stroke-[#9CA3AF] fill-none absolute", icon: "chevronLeft" }) })), _jsx(Button, __assign({ className: "w-10 h-9", variant: "neutral", onClick: handleClickNext }, { children: _jsx(AptiveIcon, { className: "h-5 stroke-[#9CA3AF] fill-none absolute", icon: "chevronRight" }) }))] })), _jsx("div", __assign({ className: "ml-auto" }, { children: _jsx(Button, __assign({ variant: "neutral", onClick: function () { return onChangeStartDate(startOfWeek(startOfToday(), { weekStartsOn: 0 })); } }, { children: "Today" })) }))] }));
};
