import { useState, useRef } from 'react';
import Button from '../Button';
import PropTypes from 'prop-types';
import { useOnClickOutside } from '@/hooks';
import FilterDropdownMenu from './FilterDropdownMenu';
import { DateRange } from '../DateRange';

const FilterDropdownButton = ({
  filters,
  setFilters,
  index,
  filterValueOptions,
  type,
}) => {
  const [isFilterValueOpen, setIsFilterValueOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    filters[index]?.value || [],
  );
  const [filterOptions, setFilterOptions] = useState('');

  const ChildMenu = {
    dropdown: FilterDropdownMenu,
    dropdownRadio: FilterDropdownMenu,
    daterange: DateRange,
  }[type];

  const handleOnClickDelete = () => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
    setIsFilterValueOpen(false);
  };

  const filteredOptions = filterValueOptions?.filter((option) =>
    option.name.toLowerCase().includes(filterOptions.toLowerCase()),
  );

  const handleClickOutsideDropdownButton = () => {
    setIsFilterValueOpen(false);
  };

  const ref = useRef();

  useOnClickOutside(ref, handleClickOutsideDropdownButton);

  return (
    <div className="relative" ref={ref}>
      <Button
        id="dropdownButton"
        className="border border-gray-200 rounded-lg px-4 py-2 gap-1 inline-flex items-center"
        onClick={() => setIsFilterValueOpen(!isFilterValueOpen)}
        data-dropdown-toggle="dropdown"
      >
        <div className="text-gray-600 text-based font-normal sm:text-xs leading-none">
          {`${filters[index]?.type.label}: ${filters[index]?.value[0].label !== '' ? filters[index]?.value[0].label : filters[index]?.value[1].label !== '' ? filters[index]?.value[1].label : 'All'}`}
        </div>
        <svg
          className="w-4 h-4 text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </Button>
      {isFilterValueOpen && (
        <ChildMenu
          radio={type === 'dropdownRadio'}
          setFilterOptions={setFilterOptions}
          setIsFilterValueOpen={setIsFilterValueOpen}
          onClickDelete={handleOnClickDelete}
          onCancelClick={() => setIsFilterValueOpen(false)}
          options={filteredOptions}
          setSelectedOptions={setSelectedOptions}
          selectedOptions={selectedOptions}
          filters={filters}
          setFilters={setFilters}
          index={index}
        />
      )}
    </div>
  );
};

FilterDropdownButton.propTypes = {
  filters: PropTypes.array,
  setFilters: PropTypes.func,
  index: PropTypes.number,
  filterValueOptions: PropTypes.array,
  type: PropTypes.string,
};

export default FilterDropdownButton;
