import { Provider } from 'react-redux';
import createStore from './createStore';

const StoreProvider = ({ children }) => (
  <Provider store={createStore()}>
    {children}
  </Provider>
);

export default StoreProvider;
