import { useMemo } from 'react';

import AreaOptions from './AreaOptions';
import AreaPlans from './AreaPlans';
import { useSelector } from 'react-redux';
import { planSelector } from '@/modules/AdminTools/redux/planBuilder/plans';
import { useSearchParams } from 'react-router-dom';
import AreaPlanOptions from './AreaPlanOptions';

const AreaOverride = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatePlanDefaultValues = useSelector(planSelector);

  const areaPlans = updatePlanDefaultValues.area_plans;

  const areaId = useMemo(() => {
    const areaId = searchParams.get('area_id');

    if (areaId) {
      return +areaId;
    }
    if (areaPlans.length > 0) {
      return areaPlans[0].area_id;
    }

    return null;
  }, [areaPlans, searchParams]);

  const setAreaId = (areaId) => {
    if (areaId === null) {
      searchParams.delete('area_id');
      setSearchParams(searchParams);

      return;
    }
    searchParams.set('area_id', areaId);
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <AreaPlanOptions {...{ areaId, setAreaId }} />
        <div className="flex-grow" />
        <AreaOptions {...{ setAreaId }} />
        <div className="flex-grow" />
      </div>

      <hr className="mt-4" />
      <AreaPlans {...{ areaId }} />
    </div>
  );
};

export default AreaOverride;
