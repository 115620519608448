export var generateTimeArray = function (is24H) {
    var hoursArray = [];
    if (is24H) {
        for (var i = 0; i < 24; i++) {
            hoursArray.push("".concat(i.toString().padStart(2, '0'), ":00"));
        }
    }
    else {
        for (var i = 0; i < 24; i++) {
            var hour = i % 12 === 0 ? 12 : i % 12;
            var suffix = i < 12 ? 'AM' : 'PM';
            hoursArray.push("".concat(hour, " ").concat(suffix));
        }
    }
    return hoursArray;
};
export var calculateTimeBlock = function (timeIn24Hour) {
    // Split the time string into hours, minutes, and seconds
    var timeParts = timeIn24Hour.split(':').map(Number);
    var totalMinutes = timeParts[0] * 60 + timeParts[1];
    // Calculate time block (30 minutes per block)
    var timeBlock = Math.floor(totalMinutes / 30);
    return timeBlock;
};
