import FilterValue from '@/modules/AdminTools/components/DataTable/FilterValue';
import PropTypes from 'prop-types';
import { userTypes } from '@/modules/AdminTools/redux/editHistory';

const getUserType = (editor_type) => {
  const userType = userTypes.find((type) => type.value === editor_type);

  return userType ? userType.name : editor_type;
};

const UserTypeValue = ({ editor_type }) => {
  return (
    <FilterValue field="editor_type" value={editor_type} format={getUserType} />
  );
};

UserTypeValue.propTypes = {
  editor_type: PropTypes.any,
};

export default UserTypeValue;
