import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomButton, Select } from '@/components/common';
import { selectAreasList } from '@/redux/areas-new';
import { planSelector } from '@/modules/AdminTools/redux/planBuilder/plans';
import { removeAreaPlanAsync } from '@/modules/AdminTools/redux/planBuilder/area-plans';

const AreaPlanOptions = ({ areaId, setAreaId }) => {
  const dispatch = useDispatch();
  const updatePlanDefaultValues = useSelector(planSelector);
  const areaPlans = updatePlanDefaultValues.area_plans;

  const areas = useSelector(selectAreasList);

  const areaPlanRows = useMemo(() => {
    const areaMap = areas?.reduce((map, { area_id, area_name }) => {
      map[area_id] = area_name;

      return map;
    }, {}) || {};

    return areaPlans.map(({ area_id }) => ({
      label: areaMap[area_id] ? `${area_id}: ${areaMap[area_id]}` : `Area ID: ${area_id}`,
      value: area_id,
    }));
  }, [areaPlans, areas]);

  const remove = useCallback(() => {
    if (!window.confirm('Are you sure?')) {
      return;
    }
    const areaPlanId = areaPlans.find(({ area_id }) => area_id === areaId).id;
    dispatch(removeAreaPlanAsync.request({ id: areaPlanId }));
    setAreaId(null);
  }, [areaId, areaPlans, dispatch, setAreaId]);

  if (!areaPlans.length) {
    return null;
  }

  return (
    <div className="flex flex-col mt-4">
      <div>
        Select an area override to edit
      </div>
      <div className="w-64">
        <Select
          name={'areaNames'}
          options={areaPlanRows}
          onChange={({ target: { value } }) => setAreaId(value)}
          value={areaId}
        />
        <div>
          <CustomButton onClick={remove}>Remove</CustomButton>
        </div>
      </div>
    </div>
  );
};

AreaPlanOptions.propTypes = {
  areaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setAreaId: PropTypes.func.isRequired,
};

export default AreaPlanOptions;
