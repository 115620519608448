import { CustomFormElement } from '@/components/common/Form';
import useSearchParamFilter from './useSearchParamFilter';

const ChangesFilter = () => {
  const { value, setSearchValue } = useSearchParamFilter('changes');

  return (
    <div>
      <label htmlFor="changes">Changes</label>
      <CustomFormElement
        type="text"
        value={value}
        onChange={setSearchValue}
        id="changes"
      />
    </div>
  );
};

export default ChangesFilter;
