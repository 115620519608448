import { useStableCallback } from '@/hooks';
import { useMemo } from 'react';

const useLedgerEditable = ({
  ledgerId,
  immutableFields,
}) => {
  const isEditLedger = useMemo(() => !!ledgerId, [ledgerId]);

  const canEditField = useStableCallback((fieldName) => {
    const isEditableField = !immutableFields?.includes(fieldName);

    if (!isEditLedger) {
      return true;
    }

    return isEditableField;
  });

  return {
    isEditLedger,
    canEditField,
  };
};

export default useLedgerEditable;
