var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MapType } from 'interface';
import ImageMapSatelite from 'assets/image/map-satelite.png';
import ImageMapTerrain from 'assets/image/map-terrain.png';
import ImageMapTraffic from 'assets/image/map-traffic.png';
var options = [
    {
        type: MapType.TRAFFIC,
        image: ImageMapTraffic,
        name: 'Traffic'
    },
    {
        type: MapType.SATELLITE,
        image: ImageMapSatelite,
        name: 'Satelite'
    },
    {
        type: MapType.TERRAIN,
        image: ImageMapTerrain,
        name: 'Terrain'
    }
];
export var MapTypePopoverContent = function (_a) {
    var type = _a.type, onChangeType = _a.onChangeType;
    return (_jsx("div", __assign({ className: "flex gap-3" }, { children: options.map(function (option) { return (_jsx("button", __assign({ className: "flex flex-col items-center cursor-pointer shadow-[0_1px_3px_0_#0000004D] rounded-full h-[40px] w-[40px]", onClick: function () { return onChangeType(option.type); } }, { children: _jsx("div", __assign({ className: "rounded-full border border-[2px] ".concat(type === option.type ? 'border-[#006ADF]' : 'border-white') }, { children: _jsx("img", { src: option.image, alt: option.name, className: "h-full w-full" }) })) }), option.type)); }) })));
};
