var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { AptiveIcon } from '@aptive-env/storybook';
var CustomErrorMessage = function (_a) {
    var text = _a.text, isicon = _a.isicon;
    return (_jsxs("div", __assign({ className: 'flex flex-row items-center gap-1' }, { children: [isicon && _jsx(AptiveIcon, { className: "w-[25px] h-[25px] stroke-[#DC2626] fill-none", icon: "exclamationCircle" }), _jsx("div", __assign({ className: "font-medium text-sm text-red-600" }, { children: text }))] })));
};
export default memo(CustomErrorMessage);
