import { differenceInHours, isValid, parse } from 'date-fns';
export var calculateTimeDifferenceInHours = function (startAt, endAt, breakHours) {
    var format = 'HH:mm:ss';
    var startTime = parse(startAt, format, new Date());
    var endTime = parse(endAt, format, new Date());
    if (!isValid(startTime) || !isValid(endTime) || endTime < startTime) {
        return 0;
    }
    return differenceInHours(endTime, startTime) - breakHours;
};
