import { dashboardConstants } from '@/lib';

export const getHeadRows = () => (
  [
    {
      value: dashboardConstants.COLUMN_CV_VALUE,
      align: 'left',
      className: 'whitespace-nowrap',
    },
    {
      value: dashboardConstants.COLUMN_PAYMENT,
      align: 'right',
      className: 'whitespace-nowrap',
    },
  ]
);
