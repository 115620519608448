import { useParams } from 'react-router-dom';

import * as planBuilderRoutes from '@/modules/AdminTools/routing/plan-builder';
import Tabs from '@/modules/AdminTools/components/Tabs';
import { useSelector } from 'react-redux';
import { planSelector } from '@/modules/AdminTools/redux/planBuilder/plans';

const PlanForm = () => {
  const urlParams = useParams();
  const planId = urlParams?.id;
  const updatePlanDefaultValues = useSelector(planSelector);

  if (!planId) {
    return null ;
  }
  if (+updatePlanDefaultValues?.id !== +planId) {
    return null;
  }
  const link = planBuilderRoutes.editPlan(planId);

  const links = [
    {
      to: link,
      text: 'Plan Details',
    },
    {
      to: planBuilderRoutes.optionalPricing(link, 'area'),
      text: 'Area Override',
    },
    {
      to: planBuilderRoutes.optionalPricing(link, 'team'),
      text: 'Team Override',
    },
    {
      to: planBuilderRoutes.optionalPricing(link, 'group'),
      text: 'Group Override',
    },
  ];

  return <Tabs links={links} />;
};

export default PlanForm;
