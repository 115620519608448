// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xfJqVtVIF5nejenJkpeb label{border-radius:.25rem;--tw-border-opacity: 1;border-color:rgb(229, 231, 235, var(--tw-border-opacity));--tw-bg-opacity: 1;background-color:rgb(255, 255, 255, var(--tw-bg-opacity))}`, "",{"version":3,"sources":["webpack://./src/pages/SalesRepresentatives/index.module.scss"],"names":[],"mappings":"AACE,4BACE,oBAAA,CACA,sBAAA,CACA,yDAAA,CACA,kBAAA,CACA,yDAAA","sourcesContent":[".checkBox {\n  label {\n    border-radius: 0.25rem;\n    --tw-border-opacity: 1;\n    border-color: rgb(229 231 235 / var(--tw-border-opacity));\n    --tw-bg-opacity: 1;\n    background-color: rgb(255 255 255 / var(--tw-bg-opacity));\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkBox": `xfJqVtVIF5nejenJkpeb`
};
export default ___CSS_LOADER_EXPORT___;
