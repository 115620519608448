var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { addMonths, addWeeks, addDays, format, isEqual, isSameMonth, isToday, parse, getDay, subDays, eachDayOfInterval, getDaysInMonth, setDate, subMonths, } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { AptiveIcon } from '@aptive-env/storybook';
import { CustomButton } from '../Button';
function changeDateMonth(date, isNextMonth) {
    return addMonths(date, isNextMonth ? 1 : -1);
}
function changeDateWeek(date, isNextMonth) {
    return addWeeks(date, isNextMonth ? 1 : -1);
}
function getActiveRow(date) {
    var unShiftAmount = getDay(date);
    var unPushAmount = 7 - getDay(date) - 1;
    var startDay = subDays(date, unShiftAmount);
    var endDay = addDays(date, unPushAmount);
    var thisWeek = eachDayOfInterval({ start: startDay, end: endDay });
    var rows = thisWeek.map(function (day) {
        return { text: String(getDay(day)), value: day };
    });
    return rows;
}
function getCalendarCells(date) {
    var _a, _b;
    var daysArray = new Array(getDaysInMonth(date)).fill(1);
    var calendarCells = [];
    var prepareCell = function (date, dayNumber) {
        return {
            text: String(dayNumber),
            value: setDate(date, dayNumber),
        };
    };
    daysArray.forEach(function (_, i) {
        calendarCells.push(prepareCell(date, i + 1));
    });
    var unShiftAmount = getDay((_a = calendarCells[0]) === null || _a === void 0 ? void 0 : _a.value);
    var unPushAmount = 7 - getDay((_b = calendarCells[calendarCells.length - 1]) === null || _b === void 0 ? void 0 : _b.value) - 1;
    var lastMonth = subMonths(date, 1);
    for (var i = 0; i < unShiftAmount; i++) {
        calendarCells.unshift(prepareCell(lastMonth, getDaysInMonth(lastMonth) - i));
    }
    var nextMonth = addMonths(date, 1);
    for (var i = 0; i < unPushAmount; i++) {
        calendarCells.push(prepareCell(nextMonth, i + 1));
    }
    return calendarCells;
}
function getCalendarRows(date) {
    var cells = getCalendarCells(date);
    var rows = [];
    for (var i = 0; i < cells.length; i += 7) {
        rows.push(cells.slice(i, i + 7));
    }
    return rows;
}
function classNames() {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(Boolean).join(' ');
}
var MiniCalendar = React.forwardRef(function (params, ref) {
    var className = params.className, view = params.view, currentDate = params.currentDate, onChange = params.onChange, handleCalendarIsOpen = params.handleCalendarIsOpen, props = __rest(params, ["className", "view", "currentDate", "onChange", "handleCalendarIsOpen"]);
    var _a = useState(currentDate || new Date()), shownDate = _a[0], setShownDate = _a[1];
    var _b = useState(format(shownDate, 'MMM-yyyy')), currentMonth = _b[0], setCurrentMonth = _b[1];
    var firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());
    var _c = useState(), rows = _c[0], setRows = _c[1];
    var _d = useState(), weekRow = _d[0], setWeekRow = _d[1];
    var _e = useState(currentDate || new Date()), selectedDay = _e[0], setSelectedDay = _e[1];
    var handleIconClick = function (isNext) {
        if (view === 'month') {
            setShownDate(changeDateMonth(shownDate, isNext));
        }
        else if (view === 'week') {
            setShownDate(changeDateWeek(shownDate, isNext));
        }
    };
    var handleSelectDay = function (date) {
        onChange(date);
    };
    var renderDay = function (value) {
        return (_jsx("button", __assign({ type: "button", onClick: function () { return setSelectedDay(value); }, className: classNames(isEqual(value, selectedDay) && 'bg-[#007AFF] text-white', !selectedDay && isToday(value) && 'bg-[#007AFF] text-white', !isEqual(value, selectedDay) && !selectedDay && isToday(value) && 'text-brand-core-pine', !isEqual(value, selectedDay) && isSameMonth(value, firstDayCurrentMonth) && 'text-gray-900', !isEqual(value, selectedDay) && !isSameMonth(value, firstDayCurrentMonth) && 'text-gray-400', isEqual(value, currentDate) && !isEqual(value, selectedDay) && 'text-gray-900', (isEqual(value, currentDate) || isToday(value)) && 'font-semibold', view === 'week' ? 'rounded-full' : 'rounded-lg', 'text-paragraph mx-auto flex h-full w-full items-center justify-center', !isEqual(value, selectedDay) && 'hover:bg-gray-200') }, { children: _jsx("time", __assign({ dateTime: format(value, 'yyyy-MM-dd') }, { children: format(value, 'd') })) })));
    };
    useEffect(function () {
        if (view === 'month') {
            setRows(getCalendarRows(shownDate));
        }
        else if (view === 'week') {
            setWeekRow(getActiveRow(shownDate));
        }
        setCurrentMonth(format(shownDate, 'MMM-yyyy'));
    }, [shownDate, view]);
    return (_jsxs("div", __assign({ ref: ref, className: className }, props, { children: [_jsxs("div", __assign({ className: "flex justify-between items-center ".concat(view === 'month' ? 'mb-2' : '') }, { children: [_jsxs("button", __assign({ type: "button", "aria-label": "prev-btn", onClick: function () { return handleIconClick(false); }, className: "flex items-center justify-center ".concat(view === 'week' ? 'w-[44px] h-[59px]' : 'w-[20px] h-[20px]') }, { children: [_jsx("span", __assign({ className: "sr-only" }, { children: "Previous month" })), _jsx(AptiveIcon, { className: "fill-gray-400 hover:fill-gray-900 ".concat(view === 'week' ? 'h-[24px] w-[24px]' : 'h-[16px] w-[16px]'), icon: view === 'week' ? 'chevronLeft' : 'arrowLeft', isFilled: true })] })), _jsx("div", __assign({ className: "px-4 ".concat(view === 'week' ? 'text-sm font-medium text-text-default' : 'text-xs font-semibold text-gray-900') }, { children: format(shownDate, 'MMMM yyyy') })), _jsxs("button", __assign({ type: "button", "aria-label": "next-btn", onClick: function () { return handleIconClick(true); }, className: "flex items-center justify-center ".concat(view === 'week' ? 'w-[44px] h-[59px]' : 'w-[20px] h-[20px]') }, { children: [_jsx("span", __assign({ className: "sr-only" }, { children: "Next month" })), _jsx(AptiveIcon, { className: "fill-gray-400 hover:fill-gray-900 ".concat(view === 'week' ? 'h-[24px] w-[24px]' : 'h-[16px] w-[16px]'), icon: view === 'week' ? 'chevronRight' : 'arrowRight', isFilled: true })] }))] })), _jsx("div", __assign({ className: "flex justify-center text-center font-semibold ".concat(view === 'week' ? 'text-[13px] text-gray-600 mb-[3px] gap-3' : 'text-[12px] text-text-default') }, { children: view === 'month' ?
                    rows && rows[0].map(function (_a, index) {
                        var text = _a.text, value = _a.value;
                        return _jsx("div", __assign({ className: "w-10 h-8 flex items-center justify-center" }, { children: format(value, 'eeee').slice(0, 3) }), index);
                    })
                    : view === 'week' &&
                        weekRow && weekRow.map(function (_a, index) {
                        var text = _a.text, value = _a.value;
                        return _jsx("div", __assign({ className: "w-10 items-center uppercase" }, { children: format(value, 'eeee').slice(0, 3) }), index);
                    }) })), view === 'week' && weekRow &&
                _jsx("div", __assign({ className: "flex justify-center gap-3 text-sm mb-1" }, { children: weekRow.map(function (_a, i) {
                        var text = _a.text, value = _a.value;
                        return (_jsx("div", __assign({ className: classNames('flex items-center my-1 w-[40px] h-[40px]') }, { children: renderDay(value) }), "".concat(text, " - ").concat(i)));
                    }) }), "week"), view === 'month' && (rows === null || rows === void 0 ? void 0 : rows.map(function (cells, rowIndex) { return (_jsx("div", __assign({ className: "flex justify-center text-sm mb-1" }, { children: cells.map(function (_a, i) {
                    var text = _a.text, value = _a.value;
                    return (_jsx("div", __assign({ className: classNames('w-10 h-8 flex items-center justify-center') }, { children: renderDay(value) }), "".concat(text, " - ").concat(i)));
                }) }), rowIndex)); })), _jsxs("div", __assign({ className: 'flex gap-2' }, { children: [_jsx(CustomButton, { text: "Ok", onClick: function () { return handleSelectDay(selectedDay); }, className: "w-full bg-blue-500 text-white items-center" }), _jsx(CustomButton, { text: "Cancel", color: "white", onClick: function () { return handleCalendarIsOpen(false); }, className: "w-full" })] }))] })));
});
export default MiniCalendar;
