import { useFeatureFlag } from 'configcat-react';
import { dashboardConstants } from '@/lib/constants';
import { datadogRum } from '@datadog/browser-rum';

const { DATA_DOG_RUM_LOGGING_ENABLED } = dashboardConstants;

const DataDogRum = () => {
  const { value: isLoggingEnabled } = useFeatureFlag(
    DATA_DOG_RUM_LOGGING_ENABLED,
    false,
  );

  if (!isLoggingEnabled) {
    return null;
  }

  const isDataDogInitiated = window.DD_RUM.getInternalContext() !== undefined;

  if (isDataDogInitiated) {
    return null;
  }

  datadogRum.onReady(() => {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATA_DOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
      site: 'us5.datadoghq.com',
      service: process.env.REACT_APP_DATA_DOC_SERVICE_NAME,
      env: process.env.REACT_APP_DATA_DOG_ENV,
      version: process.env.REACT_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  });

  return null;
};

export default DataDogRum;
