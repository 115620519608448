import PropTypes from 'prop-types';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomButton } from '@/components';
import { Select } from '@/components/common';
import { selectAreasList } from '@/redux/areas-new';
import { planSelector } from '@/modules/AdminTools/redux/planBuilder/plans';
import { createAreaPlanAsync } from '@/modules/AdminTools/redux/planBuilder/area-plans';

const AreaOptions = ({ setAreaId }) => {
  const dispatch = useDispatch();
  const updatePlanDefaultValues = useSelector(planSelector);
  const areaPlans = updatePlanDefaultValues.area_plans;
  const defaultAreaPlan = updatePlanDefaultValues.default_area_plan;

  const [newAreaId, setNewAreaId] = useState(null);
  const areas = useSelector(selectAreasList);

  const areaIds = useMemo(
    () => areaPlans.map(({ area_id }) => area_id),
    [areaPlans],
  );

  const areaOptions = useMemo(() => {
    const areaMap = (areas || []).map(({ area_id, area_name }) => ({
      label: `${area_id}: ${area_name}`,
      value: area_id,
    }));

    return areaMap.filter(({ value }) => !areaIds.includes(value));
  }, [areas, areaIds]);

  useEffect(() => {
    if (areaOptions.length > 0) {
      setNewAreaId(areaOptions[0].value);
    }
  }, [areaOptions]);

  const createNewAreaPlan = useCallback(
    (newAreaId) => {
      if (!newAreaId) {
        return;
      }
      const newAreaPlan = {
        ...defaultAreaPlan,
        area_id: newAreaId,
      };

      dispatch(createAreaPlanAsync.request({ areaPlan: newAreaPlan }));
      setAreaId(newAreaId);
    },
    [defaultAreaPlan, dispatch, setAreaId],
  );

  const addAreaPricing = useCallback(() => {
    if (!areaIds.includes(newAreaId)) {
      createNewAreaPlan(newAreaId);
    }
  }, [areaIds, createNewAreaPlan, newAreaId]);

  return (
    <div className="flex flex-col mt-4">
      <div>
        Select an area to add
      </div>
      <div className="w-64">
        <Select
          name={'areaNames'}
          onChange={({ target: { value } }) => setNewAreaId(value)}
          value={newAreaId}
          options={areaOptions}
        />
      </div>
      <div>
        <CustomButton onClick={addAreaPricing}>
          Add
        </CustomButton>
      </div>
    </div>
  );
};

AreaOptions.propTypes = {
  setAreaId: PropTypes.func.isRequired,
};

export default AreaOptions;
