import PropTypes from 'prop-types';

const Select = ({
  id,
  name,
  value,
  options,
  onChange,
  disabled,
}) => (
  <select
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    disabled={disabled}
    className="shadow-sm block w-full sm:text-sm rounded-md focus:ring-aptivegreen focus:border-aptivegreen border-gray-300 placeholder-gray-300"
    autoComplete="off"
  >
    {options.map(({
      name,
      value,
    }) => (
      <option
        key={value}
        value={value}
      >
        {name}
      </option>
    ))}
  </select>
);

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Select;
