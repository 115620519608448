import SubFields from '@/modules/AdminTools/components/Form/SubFields';
import Name from './Field/Name';
import Priority from './Field/Priority';

const fields = [
  [Name, Priority],
];

const Fields = () => {
  return (
    <div className="flex flex-col flex-grow">
      {fields.map((subFields, index) => (
        <SubFields {...{ subFields }} key={index} />
      ))}
    </div>
  );
};

export default Fields;
