import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  onboardingConstants, onboardingDataValues,
} from '@/lib';
import { CustomFormElement } from '@/components/common';
import { connect } from 'react-redux';
import { addFsExcludeClass } from '@/lib/utils';
import { isAdminSelector } from "@/redux/auth";
import { apartmentStatusesSelector } from '@/redux/reps';
import { optInSelectOptions, rentTierSelectOptions } from '@/lib/configs';
import {
  requestApartmentStatusesAsync,
} from '@/redux/reps';
import { apartmentStatusesLoadingSelector } from '@/redux/loading';
import { Loader } from '@/components';

const {
  HOUSING_TYPE,
  HOUSING_TYPE_LABEL,
  NUM_OF_ROOMS,
  NUM_OF_ROOMS_LABEL,
  ROOMMATE_REQUEST,
  REQUEST_ROOMMATE_LABEL,
  TENTATIVE_KNOCKING_START_DATE,
  TENTATIVE_KNOCKING_START_LABEL,
  TENTATIVE_KNOCKING_END_DATE,
  TENTATIVE_KNOCKING_END_LABEL,
  ACTUAL_START_DATE,
  ACTUAL_START_DATE_LABEL,
  ACTUAL_END_DATE,
  ACTUAL_END_DATE_LABEL,
  RENT_TIER,
  RENT_TIER_LABEL,
  OPT_IN,
  OPT_IN_LABEL,
  PRESEASON_HOUSING_TYPE,
  PRESEASON_NUM_OF_ROOMS,
  PRESEASON_TENTATIVE_KNOCKING_START_DATE,
  PRESEASON_TENTATIVE_KNOCKING_END_DATE,
  PRESEASON_ACTUAL_START_DATE,
  PRESEASON_ACTUAL_END_DATE,
  POSTSEASON_HOUSING_TYPE,
  POSTSEASON_NUM_OF_ROOMS,
  POSTSEASON_TENTATIVE_KNOCKING_START_DATE,
  POSTSEASON_TENTATIVE_KNOCKING_END_DATE,
  POSTSEASON_ACTUAL_START_DATE,
  POSTSEASON_ACTUAL_END_DATE,
} = onboardingConstants;

const HousingInfo = ({
  // Own Props
  onChangeHandler,
  isAdmin,
  apartmentStatuses,
  requestApartmentStatuses,
  isApartmentStatusesLoading,
}) => {
  const { register, getValues, setValue, formState: { errors } } = useFormContext();

  const [roomsSelectOptions, setRoomsSelectOptions] = useState([]);
  const { housingType, preseasonHousingType, postseasonHousingType } = getValues();
  const [isNeededToPopulateRooms, setIsNeededToPopulateRooms] = useState(false);

  const [preseasonRoomsSelectOptions, setPreseasonRoomsSelectOptions] = useState([]);
  const [isPreseasonNeededToPopulateRooms, setIsPreseasonNeededToPopulateRooms] = useState(false);

  const [postseasonRoomsSelectOptions, setPostseasonRoomsSelectOptions] = useState([]);
  const [isPostseasonNeededToPopulateRooms, setIsPostseasonNeededToPopulateRooms] = useState(false);

  useEffect(() => {
    requestApartmentStatuses();
  }, [requestApartmentStatuses]);

  const apartmentStatusSelectOptions = useMemo(() => {
    return [{ value: '', name: onboardingDataValues.SELECT_VALUE },
    ...apartmentStatuses.map(({ id, status_label }) => ({
      name: status_label,
      value: String(id),
    }))];
  }, [apartmentStatuses]);

  const getRoomsOption = (housingType, numOfRoomsName) => {
    if (!housingType) {
      setValue(numOfRoomsName, null);
      return {};
    }

    let isNeededToPopulateRoomsTemp = false;
    let maxRooms = 1;

    apartmentStatuses.forEach((apartmentStatus) => {
      if (parseInt(apartmentStatus.id) === parseInt(housingType) && apartmentStatus.max_rooms_per_status > 1) {
        isNeededToPopulateRoomsTemp = true;
        maxRooms = apartmentStatus.max_rooms_per_status;
      }
    });

    if (!isNeededToPopulateRoomsTemp) {
      setValue(numOfRoomsName, 1);
    }

    const roomsOptions = [];

    for (let i = 1; i <= maxRooms; i++) {
      roomsOptions.push({ value: i, name: `${i} Room${i > 1 ? 's' : ''}` });
    }

    return { roomsOptions: roomsOptions, isNeeded: isNeededToPopulateRoomsTemp };
  }

  useEffect(() => {
    const { roomsOptions, isNeeded } = getRoomsOption(housingType, NUM_OF_ROOMS);
    setIsNeededToPopulateRooms(isNeeded);
    setRoomsSelectOptions(roomsOptions);
  }, [apartmentStatuses, housingType, onChangeHandler, setValue]);

  useEffect(() => {
    const { roomsOptions, isNeeded } = getRoomsOption(preseasonHousingType, PRESEASON_NUM_OF_ROOMS);
    setPreseasonRoomsSelectOptions(roomsOptions);
    setIsPreseasonNeededToPopulateRooms(isNeeded);
  }, [apartmentStatuses, preseasonHousingType, onChangeHandler, setValue]);

  useEffect(() => {
    const { roomsOptions, isNeeded } = getRoomsOption(postseasonHousingType, POSTSEASON_NUM_OF_ROOMS);
    setPostseasonRoomsSelectOptions(roomsOptions);
    setIsPostseasonNeededToPopulateRooms(isNeeded);
  }, [apartmentStatuses, postseasonHousingType, onChangeHandler, setValue]);

  const rentTierSelectOptionsList = useMemo(() => {
    return [
      { value: '', name: onboardingDataValues.SELECT_VALUE }, ...rentTierSelectOptions];
  }, []);

  const optInSelectOptionsList = useMemo(() => {
    return [{ value: '', name: onboardingDataValues.SELECT_VALUE }, ...optInSelectOptions];
  }, []);

  return (
    <>
      <div className="px-2 py-2 border-b border-gray-200">
        <div className="flex">
          <h2 className="text-lg font-bold text-aptivegreen">{'Regular Season Info'}</h2>
        </div>
        <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
          {
            isApartmentStatusesLoading ? <div className="col-span-3"><Loader /></div> :
              <CustomFormElement
                colSpan={3}
                id={HOUSING_TYPE}
                name={HOUSING_TYPE}
                label={HOUSING_TYPE_LABEL}
                type="select"
                selectOptions={apartmentStatusSelectOptions}
                onChange={onChangeHandler}
                register={register}
                error={errors?.housingType}
                disabled={!isAdmin}
              />
          }
          {
            isApartmentStatusesLoading ? <div className="col-span-3"><Loader /></div> :
              isNeededToPopulateRooms && (
                <CustomFormElement
                  colSpan={3}
                  id={NUM_OF_ROOMS}
                  name={NUM_OF_ROOMS}
                  label={NUM_OF_ROOMS_LABEL}
                  type="select"
                  selectOptions={roomsSelectOptions}
                  onChange={onChangeHandler}
                  register={register}
                  error={errors?.housingType}
                  disabled={!isAdmin}
                />
              )
          }
          <CustomFormElement
            colSpan={3}
            id={ROOMMATE_REQUEST}
            name={ROOMMATE_REQUEST}
            label={REQUEST_ROOMMATE_LABEL}
            type="text"
            onChange={onChangeHandler}
            register={register}
            error={errors?.[ROOMMATE_REQUEST]}
            className={addFsExcludeClass()}
          />
          <CustomFormElement
            colSpan={3}
            id={OPT_IN}
            name={OPT_IN}
            label={OPT_IN_LABEL}
            type="select"
            onChange={onChangeHandler}
            selectOptions={optInSelectOptionsList}
            register={register}
            error={errors?.[OPT_IN]}
            className={addFsExcludeClass()}
          />
          <CustomFormElement
            label={RENT_TIER_LABEL}
            id={RENT_TIER}
            name={RENT_TIER}
            onChange={onChangeHandler}
            colSpan={3}
            type="select"
            selectOptions={rentTierSelectOptionsList}
            error={errors?.[RENT_TIER]}
            register={register}
            className={addFsExcludeClass()}
          />
          <CustomFormElement
            colSpan={3}
            id={TENTATIVE_KNOCKING_START_DATE}
            name={TENTATIVE_KNOCKING_START_DATE}
            label={TENTATIVE_KNOCKING_START_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[TENTATIVE_KNOCKING_START_DATE]}
          />
          <CustomFormElement
            colSpan={3}
            id={TENTATIVE_KNOCKING_END_DATE}
            name={TENTATIVE_KNOCKING_END_DATE}
            label={TENTATIVE_KNOCKING_END_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[TENTATIVE_KNOCKING_END_DATE]}
          />
          <CustomFormElement
            colSpan={3}
            id={ACTUAL_START_DATE}
            name={ACTUAL_START_DATE}
            label={ACTUAL_START_DATE_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[ACTUAL_START_DATE]}
          />
          <CustomFormElement
            colSpan={3}
            id={ACTUAL_END_DATE}
            name={ACTUAL_END_DATE}
            label={ACTUAL_END_DATE_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[ACTUAL_END_DATE]}
          />
        </div>
      </div>
      <div className="px-2 py-2 border-b border-gray-200">
        <div className="flex">
          <h2 className="text-lg font-bold text-aptivegreen">{'Pre Season Info'}</h2>
        </div>
        <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
          {
            isApartmentStatusesLoading ? <div className="col-span-3"><Loader /></div> :
            <CustomFormElement
              colSpan={3}
              id={PRESEASON_HOUSING_TYPE}
              name={PRESEASON_HOUSING_TYPE}
              label={HOUSING_TYPE_LABEL}
              type="select"
              selectOptions={apartmentStatusSelectOptions}
              onChange={onChangeHandler}
              register={register}
              error={errors?.preseasonHousingType}
              disabled={!isAdmin}
            />
          }
          {
            isApartmentStatusesLoading ? <div className="col-span-3"><Loader /></div> :
            isPreseasonNeededToPopulateRooms && (
              <CustomFormElement
                colSpan={3}
                id={PRESEASON_NUM_OF_ROOMS}
                name={PRESEASON_NUM_OF_ROOMS}
                label={NUM_OF_ROOMS_LABEL}
                type="select"
                selectOptions={preseasonRoomsSelectOptions}
                onChange={onChangeHandler}
                register={register}
                error={errors?.preseasonNumOfRooms}
                disabled={!isAdmin}
              />)
          }
          <CustomFormElement
            colSpan={3}
            id={PRESEASON_TENTATIVE_KNOCKING_START_DATE}
            name={PRESEASON_TENTATIVE_KNOCKING_START_DATE}
            label={TENTATIVE_KNOCKING_START_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[PRESEASON_TENTATIVE_KNOCKING_START_DATE]}
            disabled={!isAdmin}
          />
          <CustomFormElement
            colSpan={3}
            id={PRESEASON_TENTATIVE_KNOCKING_END_DATE}
            name={PRESEASON_TENTATIVE_KNOCKING_END_DATE}
            label={TENTATIVE_KNOCKING_END_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[PRESEASON_TENTATIVE_KNOCKING_END_DATE]}
            disabled={!isAdmin}
          />
          <CustomFormElement
            colSpan={3}
            id={PRESEASON_ACTUAL_START_DATE}
            name={PRESEASON_ACTUAL_START_DATE}
            label={ACTUAL_START_DATE_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[PRESEASON_ACTUAL_START_DATE]}
            disabled={!isAdmin}
          />
          <CustomFormElement
            colSpan={3}
            id={PRESEASON_ACTUAL_END_DATE}
            name={PRESEASON_ACTUAL_END_DATE}
            label={ACTUAL_END_DATE_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[PRESEASON_ACTUAL_END_DATE]}
            disabled={!isAdmin}
          />
        </div>
      </div>
      <div className="px-2 py-2 border-b border-gray-200">
        <div className="flex">
          <h2 className="text-lg font-bold text-aptivegreen">{'Post Season Info'}</h2>
        </div>
        <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
          {
            isApartmentStatusesLoading ? <div className="col-span-3"><Loader /></div> :
              <CustomFormElement
                colSpan={3}
                id={POSTSEASON_HOUSING_TYPE}
                name={POSTSEASON_HOUSING_TYPE}
                label={HOUSING_TYPE_LABEL}
                type="select"
                selectOptions={apartmentStatusSelectOptions}
                onChange={onChangeHandler}
                register={register}
                error={errors?.postseasonHousingType}
                disabled={!isAdmin}
              />
          }
          {
            isApartmentStatusesLoading ? <div className="col-span-3"><Loader /></div> :
            isPostseasonNeededToPopulateRooms && (<CustomFormElement
                colSpan={3}
                id={POSTSEASON_NUM_OF_ROOMS}
                name={POSTSEASON_NUM_OF_ROOMS}
                label={NUM_OF_ROOMS_LABEL}
                type="select"
                selectOptions={postseasonRoomsSelectOptions}
                onChange={onChangeHandler}
                register={register}
                error={errors?.postseasonNumOfRooms}
                disabled={!isAdmin}
              />)
          }
          <CustomFormElement
            colSpan={3}
            id={POSTSEASON_TENTATIVE_KNOCKING_START_DATE}
            name={POSTSEASON_TENTATIVE_KNOCKING_START_DATE}
            label={TENTATIVE_KNOCKING_START_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[POSTSEASON_TENTATIVE_KNOCKING_START_DATE]}
            disabled={!isAdmin}
          />
          <CustomFormElement
            colSpan={3}
            id={POSTSEASON_TENTATIVE_KNOCKING_END_DATE}
            name={POSTSEASON_TENTATIVE_KNOCKING_END_DATE}
            label={TENTATIVE_KNOCKING_END_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[POSTSEASON_TENTATIVE_KNOCKING_END_DATE]}
          />
          <CustomFormElement
            colSpan={3}
            id={POSTSEASON_ACTUAL_START_DATE}
            name={POSTSEASON_ACTUAL_START_DATE}
            label={ACTUAL_START_DATE_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[POSTSEASON_ACTUAL_START_DATE]}
          />
          <CustomFormElement
            colSpan={3}
            id={POSTSEASON_ACTUAL_END_DATE}
            name={POSTSEASON_ACTUAL_END_DATE}
            label={ACTUAL_END_DATE_LABEL}
            type="date"
            showYearDropdown
            onChange={onChangeHandler}
            register={register}
            error={errors?.[POSTSEASON_ACTUAL_END_DATE]}
          />
        </div>
      </div>
    </>
  );
};

HousingInfo.propTypes = {
  isAdmin: PropTypes.bool,
  apartmentStatuses: PropTypes.array,
  requestApartmentStatuses: PropTypes.func,
  isApartmentStatusesLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAdmin: isAdminSelector(state),
  apartmentStatuses: apartmentStatusesSelector(state),
  isApartmentStatusesLoading: apartmentStatusesLoadingSelector(state),
});

const mapDispatchToProps = {
  requestApartmentStatuses: requestApartmentStatusesAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(HousingInfo);
