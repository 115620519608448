import { createSelector } from 'reselect';

export const NAME = 'planBuilderGroupPlans';

export const groupPlansSelector = createSelector(
  (state) => state[NAME],
  (state) => state?.groupPlans,
);

export const groupPlansArraySelector = createSelector(
  (state) => state[NAME],
  (state) => state?.groupPlansArray,
);
