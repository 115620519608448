import { createAsyncAction } from '@/redux/root';
import { nameSpace } from './nameSpace';
import { settingsReducer } from './settings-reducer';
import { settingsWatcher } from './settings-saga';
import { settingsSelector } from './settings-selector';

export const customGroupNameSpace = `${nameSpace}/custom-groups`;

export const updateCustomGroupAsync = createAsyncAction(
  `${customGroupNameSpace}/UPDATE_CUSTOM_GROUP`,
);

export const createCustomGroupAsync = createAsyncAction(
  `${customGroupNameSpace}/CREATE_CUSTOM_GROUP`,
);

export const removeCustomGroupAsync = createAsyncAction(
  `${customGroupNameSpace}/REMOVE_CUSTOM_GROUP`,
);

export const requestCustomGroupsAsync = createAsyncAction(
  `${customGroupNameSpace}/REQUEST_CUSTOM_GROUPS`,
);

const NAME = 'customGroup';

export const customGroupsReducer = {
  [NAME]: settingsReducer(customGroupNameSpace, {
    updateAsync: updateCustomGroupAsync,
    createAsync: createCustomGroupAsync,
    removeAsync: removeCustomGroupAsync,
    requestsAsync: requestCustomGroupsAsync,
  }),
};

export function* customGroupWatcher() {
  yield settingsWatcher('custom-groups', {
    removeAsync: removeCustomGroupAsync,
    updateAsync: updateCustomGroupAsync,
    createAsync: createCustomGroupAsync,
    requestsAsync: requestCustomGroupsAsync,
  });
}

export const customGroupsSelector = settingsSelector(NAME);
