import { useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import { CustomFormElement, Loader, FormSection } from '@/components/common';
import { ProfileWizardContext } from '@/modules/recruits/components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { onboardingConstants } from '@/lib/constants';
import { updateProntoUserIdAsync } from '@/redux/reps';
import { prontoValidationSchema } from '../../lib';
import { prontoUserIdIsLoadingSelector } from '@/redux/loading';
import PropTypes from 'prop-types';

const {
  PRONTO_USER_ID,
  PRONTO_USER_ID_LABEL,
  PRONTO_SECTION_NAME,
} = onboardingConstants;

const ProntoUserId = ({
  userId,
  prontoUserId,
  updateProntoUserId,
  loading,
}) => {
  const { showViewHistory } = useContext(ProfileWizardContext);

  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      [PRONTO_USER_ID]: prontoUserId ?? '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(prontoValidationSchema),
  });

  const onChangeHandler = useCallback((event) => {
    const { name, value } = event.target;

    setValue(name, value, { shouldValidate: true });
  }, [setValue]);

  const handleSubmitForm = useCallback(() => {
    if (!errors?.[PRONTO_USER_ID]) {
      updateProntoUserId({ userId, prontoUserId: watch(PRONTO_USER_ID) });
    }
  }, [
    errors,
    updateProntoUserId,
    userId,
    watch,
  ]);

  return (
    <div className={loading ? 'p-6' : undefined}>
      {loading
        ? <Loader />
        : (
          <form>
            <FormSection
              title={PRONTO_SECTION_NAME}
              viewHistory
              onViewHistory={() => {
                showViewHistory(PRONTO_SECTION_NAME);
              }}
            >
              <CustomFormElement
                id={PRONTO_USER_ID}
                name={PRONTO_USER_ID}
                label={PRONTO_USER_ID_LABEL}
                type="text"
                onChange={onChangeHandler}
                onBlur={handleSubmitForm}
                register={register}
                error={errors?.[PRONTO_USER_ID]}
              />
            </FormSection>
          </form>
        )}
    </div>
  );
};

ProntoUserId.propTypes = {
  userId: PropTypes.number,
  prontoUserId: PropTypes.string,
  loading: PropTypes.bool,
  updateProntoUserId: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loading: prontoUserIdIsLoadingSelector(state),
});

const mapDispatchToProps = {
  updateProntoUserId: updateProntoUserIdAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProntoUserId);
