import { createSelector } from 'reselect';
import { isAdminSelector } from '@/redux/auth';

const contractsSelector = (state) => state.contracts;

const contractsCountsSelector = createSelector(
  contractsSelector,
  (state) => state?.counts || {},
);

export const contractsRecruitsDataSelector = createSelector(
  contractsCountsSelector,
  (state) => state?.recruits || {},
);

export const contractsFilteredTotalSelector = createSelector(
  contractsCountsSelector,
  (state) => state?.filteredTotal || 0,
);

export const allLeadsStatsHeaderDataSelector = createSelector(
  [
    isAdminSelector,
    contractsCountsSelector,
  ],
  (isAdmin, contracts) => {
    const pendingSignature = isAdmin ? contracts?.admin : contracts?.regional;

    return {
      pendingSignature: pendingSignature || 0,
      agreementsSent: contracts?.sent || 0,
      agreementsSigned: contracts?.signed || 0,
      downlineCount: contracts?.total || 0,
    };
  },
);

export const repContractsSelector = createSelector(
  [
    contractsSelector,
    (_state, id) => id,
    (state) => state.onboarding.selected?.userId,
  ],
  (state, id, onboardingId) => {
    const userId = id ?? onboardingId;

    return state?.[userId]?.contractsPerYears || {};
  },
);
