var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import aroAPI from './config';
import { addDays, format, startOfToday } from 'date-fns';
var weatherIconsMap = {
    'skc': ['skc', 'wind_skc', 'hot'],
    'few': ['few', 'wind_few'],
    'sct': ['sct', 'wind_sct'],
    'bkn': ['bkn', 'wind_bkn'],
    'ovc': ['ovc', 'wind_ovc'],
    'snow': ['snow', 'snow_sleet', 'cold'],
    'rain_snow': ['rain_snow'],
    'sleet': ['sleet', 'rain_sleet'],
    'fzra': ['fzra', 'rain_fzra'],
    'rain': ['rain'],
    'rain_showers': ['rain_showers', 'rain_showers_hi'],
    'tsra': ['tsra'],
    'tsra_sct': ['tsra_sct', 'tsra_hi'],
    'wind': ['wind', 'tornado', 'hurricane'],
    'tropical_storm': ['tropical_storm'],
    'fog': ['fog', 'dust', 'smoke', 'haze'],
    'blizzard': ['blizzard']
};
var alterWeatherIcon = function (originalIcon) {
    var suffix = originalIcon.includes('day') ? 'day' : 'night';
    var key = Object.keys(weatherIconsMap).find(function (key) { return weatherIconsMap[key].some(function (value) { return originalIcon.includes(value); }); });
    return "".concat(key, "_").concat(suffix);
};
var fetchCurrentWeekCalendarEventsWithWeatherForecasts = function (office) { return __awaiter(void 0, void 0, void 0, function () {
    var currentDate, currentWeekEventsResponse, error, currentWeekWeather, e_1, data, i, date, dateEvents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                currentDate = startOfToday();
                return [4 /*yield*/, fetchCalendarEvents({
                        office_id: office.id,
                        start_date: format(currentDate, 'yyyy-MM-dd'),
                        end_date: format(addDays(currentDate, 7), 'yyyy-MM-dd'),
                    })];
            case 1:
                currentWeekEventsResponse = _a.sent();
                error = null;
                currentWeekWeather = null;
                if (!office.location) return [3 /*break*/, 5];
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, fetchCurrentWeekWeather({
                        coordinate: {
                            lat: office.location.lat,
                            lng: office.location.lng
                        }
                    })];
            case 3:
                currentWeekWeather = _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_1 = _a.sent();
                error = "Error fetching weather forecast data for ".concat(office.name);
                return [3 /*break*/, 5];
            case 5:
                data = [];
                for (i = 0; i < 7; i++) {
                    date = addDays(currentDate, i);
                    dateEvents = currentWeekEventsResponse.result.events[format(date, 'yyyy-MM-dd')];
                    data.push({
                        date: date,
                        name: i === 0 ? 'Today' : i === 1 ? 'Tomorrow' : format(date, 'EEEE'),
                        events: dateEvents ? dateEvents.sort(function (a, b) {
                            if (a.start_at < b.start_at) {
                                return -1;
                            }
                            if (a.start_at > b.start_at) {
                                return 1;
                            }
                            return 0;
                        }) : [],
                        weather: currentWeekWeather ? {
                            icon: alterWeatherIcon(currentWeekWeather[i * 2].icon),
                            forecast: currentWeekWeather[i * 2].shortForecast,
                            temperature: {
                                day: currentWeekWeather[i * 2].temperature,
                                night: currentWeekWeather[i * 2 + 1].temperature,
                            }
                        } : null
                    });
                }
                return [2 /*return*/, { data: data, error: error }];
        }
    });
}); };
var fetchCalendarEvents = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, aroAPI.get('/calendar/events', {
                    params: params
                })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
        }
    });
}); };
var fetchCurrentWeekWeather = function (_a) {
    var coordinate = _a.coordinate;
    return __awaiter(void 0, void 0, void 0, function () {
        var weatherPointResponse, weatherGridPointResponse;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axios.get("https://api.weather.gov/points/".concat(coordinate.lat, ",").concat(coordinate.lng))];
                case 1:
                    weatherPointResponse = _b.sent();
                    return [4 /*yield*/, axios.get(weatherPointResponse.data.properties.forecast)];
                case 2:
                    weatherGridPointResponse = _b.sent();
                    return [2 /*return*/, weatherGridPointResponse.data.properties.periods];
            }
        });
    });
};
var createCalendarEvent = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, exception_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, aroAPI.post('/calendar/events', params)];
            case 1:
                response = _b.sent();
                return [2 /*return*/, response.data];
            case 2:
                exception_1 = _b.sent();
                if ((_a = exception_1.response) === null || _a === void 0 ? void 0 : _a.data) {
                    return [2 /*return*/, exception_1.response.data];
                }
                throw exception_1;
            case 3: return [2 /*return*/];
        }
    });
}); };
var fetchCalendarEventTypes = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, aroAPI.get('/calendar/event-types')];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data.result];
        }
    });
}); };
var fetchUserAvatarResponseCache = {};
var fetchUserAvatar = function (user_id, options) { return __awaiter(void 0, void 0, void 0, function () {
    var response, exception_2;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if ((options === null || options === void 0 ? void 0 : options.useCache) && fetchUserAvatarResponseCache[user_id]) {
                    return [2 /*return*/, fetchUserAvatarResponseCache[user_id]];
                }
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                return [4 /*yield*/, aroAPI.get("/calendar/employees/".concat(user_id, "/avatar"), {
                        signal: options === null || options === void 0 ? void 0 : options.signal,
                    })];
            case 2:
                response = _b.sent();
                fetchUserAvatarResponseCache[user_id] = response.data;
                return [2 /*return*/, response.data];
            case 3:
                exception_2 = _b.sent();
                if ((_a = exception_2.response) === null || _a === void 0 ? void 0 : _a.data) {
                    fetchUserAvatarResponseCache[user_id] = exception_2.response.data;
                    return [2 /*return*/, exception_2.response.data];
                }
                throw exception_2;
            case 4: return [2 /*return*/];
        }
    });
}); };
var fetchOfficeEmployees = function (officeId) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, aroAPI.get("/calendar/office/".concat(officeId, "/employees"))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data.result.employees];
        }
    });
}); };
var addCalendarEventParticipants = function (eventId, participantIds) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, aroAPI.put("/calendar/events/".concat(eventId, "/participants"), {
                    participant_ids: participantIds
                })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
        }
    });
}); };
var fetchOfficeEmployeesWithAvatars = function (officeId) { return __awaiter(void 0, void 0, void 0, function () {
    var employees, _i, employees_1, employee, avatarResponse, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchOfficeEmployees(officeId)];
            case 1:
                employees = _a.sent();
                _i = 0, employees_1 = employees;
                _a.label = 2;
            case 2:
                if (!(_i < employees_1.length)) return [3 /*break*/, 7];
                employee = employees_1[_i];
                _a.label = 3;
            case 3:
                _a.trys.push([3, 5, , 6]);
                return [4 /*yield*/, fetchUserAvatar(employee.external_id)];
            case 4:
                avatarResponse = _a.sent();
                employee.avatar = avatarResponse.result.avatarBase64;
                return [3 /*break*/, 6];
            case 5:
                e_2 = _a.sent();
                return [3 /*break*/, 6];
            case 6:
                _i++;
                return [3 /*break*/, 2];
            case 7: return [2 /*return*/, employees];
        }
    });
}); };
var fetchCalendarEventParticipants = function (eventId) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, aroAPI.get("/calendar/events/".concat(eventId, "/participants"))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data.result.participants.filter(function (item) { return item.is_invited; })];
        }
    });
}); };
var deleteCalendarEvent = function (eventId) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, aroAPI.delete("/calendar/events/".concat(eventId))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
        }
    });
}); };
var updateCalendarEvent = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, aroAPI.patch("/calendar/events/".concat(params.eventId), params)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
        }
    });
}); };
var overrideCalendarEvent = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, aroAPI.put("/calendar/events/".concat(params.eventId, "/overrides"), params)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
        }
    });
}); };
export { fetchCurrentWeekCalendarEventsWithWeatherForecasts, fetchCalendarEvents, fetchCalendarEventTypes, createCalendarEvent, fetchUserAvatar, fetchOfficeEmployees, fetchOfficeEmployeesWithAvatars, addCalendarEventParticipants, fetchCalendarEventParticipants, deleteCalendarEvent, updateCalendarEvent, overrideCalendarEvent };
