import { NUMBERS_ONLY, PRONTO_USER_ID_VALIDATION_MESSAGE } from '@/lib/validations';
import { onboardingConstants } from '@/lib/constants';
import * as yup from 'yup';

const { PRONTO_USER_ID } = onboardingConstants;

export const prontoValidationSchema = yup.object({
  [PRONTO_USER_ID]: yup
    .string()
    .notRequired()
    .test(
      'digits-only',
      PRONTO_USER_ID_VALIDATION_MESSAGE,
      (value) => (value === null || value === '') || value.toString().match(NUMBERS_ONLY),
    ),
});
