import PropTypes from 'prop-types';

import { useMemo } from 'react';

import GroupOptions from './GroupOptions';
import GroupPlans from './GroupPlans';
import { useSearchParams } from 'react-router-dom';
import GroupPlanOptions from './GroupPlanOptions';

const GroupPlansOverride = ({ groupIdName, groupType, groupOptions, groupPlans }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const groupId = useMemo(() => {
    const groupId = searchParams.get(groupIdName);

    if (groupId) {
      return +groupId;
    }
    if (groupPlans.length > 0) {
      return groupPlans[0].group_id;
    }

    return null;
  }, [groupPlans, searchParams, groupIdName]);

  const setGroupId = (groupId) => {
    if (groupId === null) {
      searchParams.delete(groupIdName);
      setSearchParams(searchParams);

      return;
    }
    searchParams.set(groupIdName, groupId);
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <GroupPlanOptions {...{ groupId, setGroupId, groupPlans, groupOptions }} />
        <div className="flex-grow" />
        <GroupOptions {...{ setGroupId, groupPlans, groupType, groupOptions }} />
        <div className="flex-grow" />
      </div>

      <hr className="mt-4" />
      <GroupPlans {...{ groupId, groupPlans }} />
    </div>
  );
};

GroupPlansOverride.propTypes = {
  groupIdName: PropTypes.string,
  groupType: PropTypes.string,
  groupOptions: PropTypes.array,
  groupPlans: PropTypes.array,
};

export default GroupPlansOverride;
