var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AptiveIcon } from '@aptive-env/storybook';
import FileUpload from './FileUpload';
var FILE_TYPES = {
    'application/pdf': 'PDF',
    'image/jpeg': 'JPG',
    'image/png': 'PNG'
};
var DropZone = function (_a) {
    var isDragging = _a.isDragging, handleDragEnter = _a.handleDragEnter, handleDragLeave = _a.handleDragLeave, handleDragOver = _a.handleDragOver, handleDrop = _a.handleDrop, handleFileUpload = _a.handleFileUpload, acceptedFileTypes = _a.acceptedFileTypes, maxFileSize = _a.maxFileSize;
    return (_jsxs("div", __assign({ onDragEnter: handleDragEnter, onDragLeave: handleDragLeave, onDragOver: handleDragOver, onDrop: handleDrop, className: "grid gap-2 border-2 border-dashed p-10 text-center rounded-lg bg-gray-50 h-[228px] ".concat(isDragging ? 'border-blue-500 bg-gray-300' : 'border-gray-300') }, { children: [_jsx(FileUpload, { onFileUpload: handleFileUpload, acceptedFileTypes: acceptedFileTypes }), _jsx("label", __assign({ htmlFor: "file-upload", className: "block cursor-pointer" }, { children: _jsxs("div", __assign({ className: 'grid gap-2' }, { children: [_jsx(AptiveIcon, { className: "w-5 h-5 stroke-[#9CA3AF] fill-none m-auto cursor-pointer", icon: "upload" }), _jsxs("div", __assign({ className: "flex m-auto" }, { children: [_jsx("span", __assign({ className: 'font-semibold text-sm text-gray-500' }, { children: "Click to upload" })), "\u00A0", _jsx("span", __assign({ className: 'font-normal text-sm text-gray-500' }, { children: "or drag and drop" }))] })), _jsxs("p", __assign({ className: "text-xs font-semibold text-gray-500" }, { children: ["Upload a file ", acceptedFileTypes.map(function (fileType) { return FILE_TYPES[fileType]; }).join(', '), " up to ", maxFileSize / (1024 * 1024), "MB"] })), _jsxs("div", __assign({ className: "flex gap-[3px] items-center px-4 py-2 mt-2 bg-blue-500 text-white rounded-lg w-[110px] m-auto hover:bg-blue-600" }, { children: [_jsx(AptiveIcon, { className: "w-3 h-3 stroke-white fill-none m-auto cursor-pointer", icon: "search" }), _jsx("span", __assign({ className: 'text-xs whitespace-nowrap' }, { children: "Browse File" }))] }))] })) }))] })));
};
export default DropZone;
