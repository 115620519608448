import PropTypes from 'prop-types';
import { createContext } from 'react';

export const RadioGroupContext = createContext({});

const RadioGroup = ({
  name,
  checked,
  onChange,
  children,
}) => (
  <RadioGroupContext.Provider value={{ name, checked, onChange }}>
    {children}
  </RadioGroupContext.Provider>
);

RadioGroup.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

export default RadioGroup;
