export var requirementModalConstants = {
    REQUIREMENTS: 'Requirements',
    NEW_REQUIREMENTS: 'New Requirements',
    STATE_LABEL: 'State',
    COUNTY_LABEL: 'County',
    MUNICIPALITY_LABEL: 'Municipality',
    CITY_CONTACT_NAME_LABEL: 'City Contact Name',
    CITY_CONTACT_EMAIL_LABEL: 'City Contact Email',
    CITY_CONTACT_PHONE_LABEL: 'City Contact Phone',
    CITY_CONTACT_NAME_PLACEHOLDER: 'Add city contact name',
    CITY_CONTACT_EMAIL_PLACEHOLDER: 'Add city contact email',
    CITY_CONTACT_PHONE_PLACEHOLDER: 'Add city contact phone',
    ATTACHMENTS_LABEL: 'Application PDF',
    ATTACHMENTS_NAME: 'attachments',
    PRIORITY_LABEL: 'Priority',
    PRIORITY_NAME: 'priority_id',
    SUBMISSION_TYPE_LABEL: 'Submission Type',
    SUBMISSION_TYPE_NAME: 'submission_type_id',
    NOTES_TYPE_NAME: 'notes_id',
    NOTES_TYPE_LABEL: 'Notes',
    NOTES_TYPE_PLACEHOLDER: 'Add notes here',
    SUBMISSION_URL_LABEL: 'Submission URL',
    SUBMISSION_URL_NAME: 'submission_url',
    SUBMISSION_URL_PLACEHOLDER: 'www.example.com',
    SUBMISSION_URL_MARK: 'https://',
    SUBMISSION_EMAIL_ADDRESS_LABEL: 'Submission Email Address',
    SUBMISSION_EMAIL_ADDRESS_NAME: 'submission_email_address',
    SUBMISSION_EMAIL_ADDRESS_PLACEHOLDER: 'someone@email.com'
};
