import { jsx as _jsx } from "react/jsx-runtime";
var FileUpload = function (_a) {
    var onFileUpload = _a.onFileUpload, acceptedFileTypes = _a.acceptedFileTypes;
    var handleFileChange = function (event) {
        if (event.target.files) {
            onFileUpload(event.target.files);
            event.target.value = '';
        }
    };
    return (_jsx("input", { type: "file", id: "file-upload", className: "hidden", onChange: handleFileChange, multiple: true, accept: acceptedFileTypes.join(',') }));
};
export default FileUpload;
