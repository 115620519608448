export const archivedLeadsConstants = {
  LEAD_MANAGEMENT_SEARCH_NAME: 'lead',
  SEARCH_LEADS: 'Search Leads',

  ARCHIVED_LEAD_TYPE: 'lead',

  VIEW_USER_BUTTON: 'View User',
  RESTORE_LEAD_BUTTON: 'Restore Lead',
  RESTORE_LEAD_CONFIRMATION_TITLE: 'Are you sure you want to restore this lead?',
};
