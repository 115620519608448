var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
function formatPhoneNumber(value) {
    var digits = value.replace(/\D/g, '');
    if (digits.length <= 3) {
        return digits;
    }
    if (digits.length <= 6) {
        return "(".concat(digits.slice(0, 3), ") ").concat(digits.slice(3));
    }
    return "(".concat(digits.slice(0, 3), ") ").concat(digits.slice(3, 6), "-").concat(digits.slice(6, 10));
}
var CustomPhone = function (_a) {
    var id = _a.id, name = _a.name, disabled = _a.disabled, formValue = _a.formValue, value = _a.value, hasError = _a.hasError, className = _a.className, register = _a.register, childOrientation = _a.childOrientation, ariaDescribedBy = _a.ariaDescribedBy, placeholder = _a.placeholder, autoComplete = _a.autoComplete, baseClasses = _a.baseClasses, children = _a.children, required = _a.required, onBlur = _a.onBlur, onChange = _a.onChange;
    var classes = useMemo(function () {
        return classNames(className, 'shadow-sm block w-full sm:text-sm rounded-md [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield]', "".concat(hasError && baseClasses
            ? baseClasses === null || baseClasses === void 0 ? void 0 : baseClasses.errorClasses
            : baseClasses === null || baseClasses === void 0 ? void 0 : baseClasses.standardClasses));
    }, [hasError, className, baseClasses]);
    var registerdInputProps = useMemo(function () {
        return register === null || register === void 0 ? void 0 : register(name);
    }, [name, register]);
    var handleChange = function (event) {
        var digits = event.target.value.replace(/\D/g, '');
        var phone = formatPhoneNumber(digits);
        var newEvent = __assign(__assign({}, event), { target: __assign(__assign({}, event.target), { name: name, value: phone }) });
        registerdInputProps === null || registerdInputProps === void 0 ? void 0 : registerdInputProps.onChange(newEvent);
        onChange === null || onChange === void 0 ? void 0 : onChange(newEvent);
    };
    var handleBlur = function (event) {
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
    };
    return (_jsxs("div", __assign({ className: "flex" }, { children: [childOrientation === 'left' && children, _jsx("input", __assign({}, (registerdInputProps && __assign({}, registerdInputProps)), { id: id, name: name, type: "text", value: register ? formValue : value, className: classes, disabled: disabled, placeholder: placeholder, "aria-describedby": ariaDescribedBy, "aria-invalid": hasError, autoComplete: autoComplete, required: required, onChange: handleChange, onBlur: handleBlur })), childOrientation === 'right' && children] })));
};
export default CustomPhone;
