import * as Api from '@/api/api';

const api = process.env.REACT_APP_PLAN_BUILDER_API;

const path = '/api/v1/plan-builder/custom-groups';

export const getCustomGroups = Api.get({ path, api });

export const createCustomGroup = Api.post({ path, api });

export const updateCustomGroup = (id) => Api.patch({ path: `${path}/${id}`, api });

export const removeCustomGroup = (id) => Api.remove({ path: `${path}/${id}`, api });
