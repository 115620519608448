var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { AptiveIcon, Button } from '@aptive-env/storybook';
import debounce from 'lodash.debounce';
var TableHeader = function (_a) {
    var search = _a.search, setSearch = _a.setSearch, addLabel = _a.addLabel, searchPlaceholder = _a.searchPlaceholder, addNewModalOpen = _a.addNewModalOpen;
    var _b = useState(search), searchValue = _b[0], setSearchValue = _b[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var debouncedSearchHandler = useCallback(debounce(setSearch, 500), []);
    var onInputChange = function (event) {
        setSearchValue(event.target.value);
        debouncedSearchHandler(event.target.value);
    };
    return (_jsxs("div", __assign({ className: "flex justify-between px-4 py-3 bg-white rounded-t-lg border border-gray-200" }, { children: [_jsxs("div", __assign({ className: "flex items-center bg-white border border-[#E5E7EB] rounded-md h-[38px] w-[240px] overflow-hidden" }, { children: [_jsx("input", { className: "px-3 w-full focus-visible:outline-none text-[#3d3d3d] text-sm placeholder-[#A9A9A9] border-none focus:border-none focus:ring-0", value: searchValue, onChange: onInputChange, placeholder: searchPlaceholder ? searchPlaceholder : 'Search' }), _jsx("div", __assign({ className: "px-3 border-l h-full flex items-center" }, { children: _jsx(AptiveIcon, { className: "w-[20px] h-[20px] stroke-[#9CA3AF] fill-none", icon: "search" }) }))] })), _jsx(Button, __assign({ icon: "plusCircle", size: "default", variant: "default", onClick: addNewModalOpen }, { children: addLabel }))] })));
};
export default TableHeader;
