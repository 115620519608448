var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { mergeClassName } from 'lib/utils';
import { useMemo, memo } from 'react';
var colorMap = {
    white: 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:ring-blue-600',
    red: 'border-transparent bg-red-600 text-white focus:ring-red-600',
    active: 'border-transparent bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-600',
    disabled: 'text-gray-300 cursor-default',
    default: 'text-gray-600 bg-white font-normal sm:text-xs leading-none border-none shadow-none',
};
var CustomButton = function (_a) {
    var onClick = _a.onClick, _b = _a.color, color = _b === void 0 ? 'default' : _b, className = _a.className, disabled = _a.disabled, name = _a.name, _c = _a.type, type = _c === void 0 ? 'button' : _c, text = _a.text, children = _a.children;
    var buttonClasses = useMemo(function () { return mergeClassName('inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm', colorMap[disabled ? 'disabled' : color], className); }, [color, disabled, className]);
    return (_jsx("button", __assign({ name: name, type: type ? type : 'button', disabled: disabled, className: mergeClassName(buttonClasses), onClick: onClick }, { children: children ? children : text })));
};
export default memo(CustomButton);
