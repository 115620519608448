var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { add, format, isEqual, startOfToday } from 'date-fns';
var WEEK_DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export var PlanningCalendarHeader = function (_a) {
    var startDate = _a.startDate, employees = _a.employees;
    return (_jsxs("div", __assign({ className: "flex items-center bg-gray-50 border-b border-gray-200 h-[80px] overflow-scroll overflow-x-hidden" }, { children: [_jsxs("div", __assign({ className: "flex border-r px-5 border-gray-200 bg-white w-[356px] h-full items-center shadow-[7px_0px_16px_0px_#0000000F]" }, { children: [_jsx("div", __assign({ className: "text-base leading-[14px] font-medium" }, { children: "Service Pros" })), _jsx("div", __assign({ className: "ml-3 p-1 flex items-center justify-center text-[#4B5563] text-xs rounded-md bg-[#F3F4F6]" }, { children: employees.length }))] })), WEEK_DAYS.map(function (weekday, index) { return (_jsxs("div", __assign({ className: "flex-1 h-full flex flex-col items-center justify-center border-gray-200 text-center ".concat(index === 6 ? '' : 'border-r') }, { children: [_jsx("div", __assign({ className: "text-sm\tfont-light leading-4 text-text-default" }, { children: weekday })), _jsx("div", __assign({ className: "text-sm font-semibold leading-5 h-8 flex items-center justify-center mt-1 ".concat(isEqual(add(startDate, { days: index }), startOfToday())
                            ? 'w-[74px] bg-[#007AFF] text-white rounded-lg ml-auto mr-auto'
                            : 'text-text-default') }, { children: format(add(startDate, { days: index }), 'MMM d') }))] }), index)); })] })));
};
