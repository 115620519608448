import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  onboardingConstants, onboardingDataValues,
} from '@/lib';
import { CustomFormElement, DateOfBirth, PostalCode } from '@/components/common';
import { connect } from 'react-redux';
import {
  experienceOptionsSelector,
  requestExperienceOptionsAsync
} from '@/redux/reps';
import { addFsExcludeClass } from '@/lib/utils';
import { selectCountries, selectStates, getAddressesAsync } from '@/redux/addresses';
import { defaultSelectOption } from '@/lib/configs';
import { isAdminSelector } from "@/redux/auth";

const {
  FIRST_NAME,
  FIRST_NAME_LABEL,
  LAST_NAME,
  LAST_NAME_LABEL,
  FULL_NAME,
  FULL_LEGAL_NAME_LABEL,
  DATE_OF_BIRTH,
  DATE_OF_BIRTH_LABEL,
  ADDRESS_CITY,
  CITY_LABEL,
  ADDRESS_STATE,
  STATE_PROVINCE_LABEL,
  ADDRESS_ZIP,
  ZIP_POSTAL_LABEL,
  EXPERIENCE,
  EXPERIENCE_LABEL,
  ADDRESS_COUNTRY,
  COUNTRY_LABEL,
} = onboardingConstants;

const PersonalInfo = ({
  // Own Props
  onChangeHandler,
  // State
  countries,
  states,
  isAdmin,
  // Dispatch
  getAddresses,
  getExperiences,
  experienceOptions,
}) => {
  const {
    getValues,
    register,
    formState: { errors },
  } = useFormContext();

  const {
    addressCountry,
  } = getValues();

  useEffect(() => {
    getAddresses();
    getExperiences();
  }, [
    getAddresses,
    getExperiences,
  ]);

  const countriesOptions = useMemo(() => ([
    ...defaultSelectOption,
    ...countries,
  ]), [countries]);

  const addressStates = useMemo(() => {
    const countryStates = states?.[addressCountry] ?? [];

    return [...defaultSelectOption, ...countryStates];
  }, [addressCountry, states]);

  const experienceSelectOptions = useMemo(() => {
    return [
      { value: '', name: onboardingDataValues.SELECT_VALUE },
      ...experienceOptions.map(({ id, name }) => ({
        value: String(id),
        name,
      })),
    ];
  }, [experienceOptions]);

  return (
    <div className='grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6'>
      <CustomFormElement
        colSpan={3}
        id={FIRST_NAME}
        name={FIRST_NAME}
        label={FIRST_NAME_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[FIRST_NAME]}
        className={addFsExcludeClass()}
        disabled={!isAdmin}
      />
      <CustomFormElement
        colSpan={3}
        id={LAST_NAME}
        name={LAST_NAME}
        label={LAST_NAME_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[LAST_NAME]}
        className={addFsExcludeClass()}
        disabled={!isAdmin}
      />
      <CustomFormElement
        colSpan={3}
        id={FULL_NAME}
        name={FULL_NAME}
        label={FULL_LEGAL_NAME_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[FULL_NAME]}
        className={addFsExcludeClass()}
        disabled={!isAdmin}
      />
      <DateOfBirth
        colSpan={3}
        id={DATE_OF_BIRTH}
        name={DATE_OF_BIRTH}
        label={DATE_OF_BIRTH_LABEL}
        type="date"
        showYearDropdown
        onChange={onChangeHandler}
        register={register}
        error={errors?.[DATE_OF_BIRTH]}
        disabled={!isAdmin}
      />
      <CustomFormElement
        colSpan={3}
        id={ADDRESS_CITY}
        name={ADDRESS_CITY}
        label={CITY_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[ADDRESS_CITY]}
        className={addFsExcludeClass()}
        disabled={!isAdmin}
      />
      <CustomFormElement
        colSpan={3}
        id={ADDRESS_COUNTRY}
        name={ADDRESS_COUNTRY}
        label={COUNTRY_LABEL}
        type="select"
        selectOptions={countriesOptions}
        onChange={onChangeHandler}
        register={register}
        error={errors?.addressCountry}
        disabled={!isAdmin}
      />
      <PostalCode
        colSpan={3}
        id={ADDRESS_ZIP}
        name={ADDRESS_ZIP}
        label={ZIP_POSTAL_LABEL}
        onChange={onChangeHandler}
        register={register}
        error={errors?.[ADDRESS_ZIP]}
        className={addFsExcludeClass()}
        disabled={!isAdmin}
      />
      <CustomFormElement
        colSpan={3}
        id={ADDRESS_STATE}
        name={ADDRESS_STATE}
        label={STATE_PROVINCE_LABEL}
        type="select"
        selectOptions={addressStates}
        onChange={onChangeHandler}
        register={register}
        error={errors?.[ADDRESS_STATE]}
        disabled={!isAdmin}
      />
      <CustomFormElement
        label={EXPERIENCE_LABEL}
        id={EXPERIENCE}
        name={EXPERIENCE}
        onChange={onChangeHandler}
        colSpan={3}
        type="select"
        selectOptions={experienceSelectOptions}
        error={errors?.[EXPERIENCE]}
        register={register}
        className={addFsExcludeClass()}
        disabled={!isAdmin}
      />
    </div>
  );
};

PersonalInfo.propTypes = {
  experienceOptions: PropTypes.array,
  countries: PropTypes.array,
  states: PropTypes.object,
  isAdmin: PropTypes.bool,
  onChangeHandler: PropTypes.func.isRequired,
  getAddresses: PropTypes.func,
  getExperiences: PropTypes.func,
};

const mapStateToProps = (state) => ({
  experienceOptions: experienceOptionsSelector(state),
  countries: selectCountries(state),
  states: selectStates(state),
  isAdmin: isAdminSelector(state),
});

const mapDispatchToProps = {
  getAddresses: getAddressesAsync.request,
  getExperiences: requestExperienceOptionsAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
