import { createSelector } from 'reselect'

const manageUsersSlice = state => state.manageUsers

const manageUserGroupsSlice = state => state.manageUserGroups

export const manageUsersSelector = createSelector(
  manageUsersSlice,
  state => state?.users || []
)

export const manageUserGroupsSelector = createSelector(
  manageUserGroupsSlice,
  state => state?.userGroups || []
)

export const manageUsersTotalSelector = createSelector(
  manageUsersSlice,
  state => state.total
)
