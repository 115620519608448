var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useCallback, useContext } from 'react';
import MultiSelect from 'components/shared/MultiSelect';
import { RenderParticipant } from './RenderParticipant';
import { CalendarStateContext } from 'context/CalendarContext';
export var EventGuestSelect = function (_a) {
    var eventData = _a.eventData, setEventData = _a.setEventData;
    var officeEmployees = useContext(CalendarStateContext).officeEmployees;
    var guestOptions = useMemo(function () {
        if (!officeEmployees) {
            return [];
        }
        return officeEmployees.map(function (employee) { return ({
            label: employee.name,
            value: employee.id,
        }); });
    }, [officeEmployees]);
    var renderMultiSelectOptionImage = useCallback(function (option) {
        var employee = officeEmployees === null || officeEmployees === void 0 ? void 0 : officeEmployees.find(function (employee) { return employee.id === option.value; });
        return employee ? _jsx(RenderParticipant, { participant: employee }) : null;
    }, [officeEmployees]);
    return (_jsxs("div", { children: [_jsx("p", __assign({ className: "font-inter text-[14px] font-normal leading-4 text-dark-gray-100 mb-1" }, { children: "Guests" })), _jsx(MultiSelect, { icon: "search", placeholder: "Add guests", maxVisibleOptions: 3, options: guestOptions, value: eventData.participants.map(function (participant) { return ({
                    value: participant.id,
                    label: participant.name,
                }); }), onChange: function (value) {
                    var ids = value.map(function (item) { return item.value; });
                    var employees = officeEmployees.filter(function (employee) {
                        return ids.includes(employee.id);
                    });
                    setEventData(function (prevData) { return (__assign(__assign({}, prevData), { participants: employees })); });
                }, renderImage: renderMultiSelectOptionImage })] }));
};
