import { CustomFormElement } from '@/components/common/Form';
import useSearchParamFilter from './useSearchParamFilter';

const CreatedAtFilter = () => {
  const createdAtStart = useSearchParamFilter('created_at_start');
  const createdAtEnd = useSearchParamFilter('created_at_end');

  return (
    <div className="flex flex-row space-x-4">
      <div className="w-1/2">
        <label htmlFor="created_at_start">Created At Start</label>
        <CustomFormElement
          type="date"
          formValue={createdAtStart.value}
          onChange={createdAtStart.setSearchValue}
          id="created_at_start"
        />
      </div>
      <div className="w-1/2">
        <label htmlFor="created_at_end">Created At End</label>
        <CustomFormElement
          type="date"
          formValue={createdAtEnd.value}
          onChange={createdAtEnd.setSearchValue}
          id="created_at_end"
        />
      </div>
    </div>
  );
};

export default CreatedAtFilter;
