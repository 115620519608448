var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { AptiveIcon } from '@aptive-env/storybook';
var FormLabel = function (_a) {
    var _b;
    var _c = _a.label, label = _c === void 0 ? '' : _c, _d = _a.errors, errors = _d === void 0 ? [] : _d, htmlFor = _a.htmlFor, _e = _a.required, required = _e === void 0 ? false : _e, _f = _a.errorClassName, errorClassName = _f === void 0 ? '' : _f, _g = _a.successClassName, successClassName = _g === void 0 ? '' : _g, _h = _a.labelClassName, labelClassName = _h === void 0 ? '' : _h, isSuccess = _a.isSuccess;
    var labelClasses = classNames(labelClassName, 'flex text-sm font-medium text-gray-700');
    var successClasses = classNames(successClassName, 'flex text-sm font-medium text-green-700');
    var errorClasses = classNames(errorClassName, 'flex mt-2 text-sm font-medium text-red-600');
    return (_jsx(_Fragment, { children: (label.length > 0 || errors.length > 0) &&
            (((_b = errors[0]) === null || _b === void 0 ? void 0 : _b.length) > 0 ? (_jsxs("div", __assign({ className: errorClasses }, { children: [_jsx("span", { children: _jsx(AptiveIcon, { className: "w-5 h-5 stroke-red-600 fill-none", icon: "exclamationCircle" }) }), _jsx("label", __assign({ htmlFor: htmlFor }, { children: errors[0] }))] }))) : (_jsx("div", __assign({ className: isSuccess ? successClasses : labelClasses }, { children: _jsxs("label", __assign({ htmlFor: htmlFor, className: "flex flex-inline gap-1" }, { children: [_jsx("div", { children: label }), required ? _jsx("div", __assign({ className: " text-red-500" }, { children: "*" })) : null] })) })))) }));
};
export default FormLabel;
