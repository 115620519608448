import {
  EditHistories,
  EditHistory,
} from '@/modules/AdminTools/modules/EditHistory';

import { abilityConstants } from '@/lib';

const { ACCESS_PLAN_BUILDER_ABILITY } = abilityConstants;

export const PATH = '/edit-history';

export const NAME = 'Edit History';

export const routes = (protectedRoute, layout) => ({
  path: PATH,
  element: layout,
  children: [
    {
      element: protectedRoute(
        <EditHistory />,
        ACCESS_PLAN_BUILDER_ABILITY
      ),
      children: [
        {
          index: true,
          element: <EditHistories />,
        },
      ],
    },
  ],
});
