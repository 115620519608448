var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
export var EventTitleInput = function (_a) {
    var _b, _c;
    var eventData = _a.eventData, setEventData = _a.setEventData, errors = _a.errors, setErrors = _a.setErrors;
    var handleChangeTitle = function (evt) {
        setEventData(function (prevData) { return (__assign(__assign({}, prevData), { title: evt.target.value })); });
        setErrors(__assign(__assign({}, errors), { title: [] }));
    };
    return (_jsxs("div", __assign({ className: 'w-full h-10 border rounded-lg' }, { children: [_jsx("input", { type: "text", placeholder: "Add Title", className: classNames('w-full h-[38px] px-[13px] outline-none rounded-lg text-sm font-normal leading-4 placeholder-[#A9A9A9] text-[#111827] border-transparent focus:border-transparent focus:ring-0', ((_b = errors.title) === null || _b === void 0 ? void 0 : _b.length) && '!text-red-600 border-red-500'), value: eventData.title, onChange: handleChangeTitle }), (_c = errors.title) === null || _c === void 0 ? void 0 : _c.map(function (error, index) { return (_jsx("p", __assign({ className: "mt-1 text-xs text-red-600 dark:text-red-500" }, { children: error }), index)); })] })));
};
