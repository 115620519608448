import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
export var mergeClassName = function () {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return twMerge(classNames.apply(void 0, classes));
};
export var generateBaseClasses = function (type, baseWrapperClassName) {
    var _a;
    if (type === void 0) { type = 'text'; }
    if (baseWrapperClassName === void 0) { baseWrapperClassName = ''; }
    var placeholderTextColor = {
        text: '300',
        date: '900',
    };
    var standardClasses = classNames('focus:ring-blue-500 focus:outline-none focus:border-blue-500', 'bg-white px-3 py-2', 'border border-gray-300', "placeholder-gray-".concat((_a = placeholderTextColor[type]) !== null && _a !== void 0 ? _a : '300'), baseWrapperClassName);
    var errorClasses = classNames('focus:ring-red-700 focus:outline-none focus:border-red-700', 'border border-red-500', 'bg-pink-100 px-3 py-2', 'placeholder-red-300', 'focus:outline-none', baseWrapperClassName);
    return {
        standardClasses: standardClasses,
        errorClasses: errorClasses,
    };
};
