import PropTypes from 'prop-types';

const Input = ({
  id,
  name,
  value,
  onKeyUp,
  onChange,
  onBlur,
  disabled,
}) => (
  <input
    id={id}
    name={name}
    value={value}
    onKeyUp={onKeyUp}
    onChange={onChange}
    onBlur={onBlur}
    disabled={disabled}
    type="text"
    className="text-center h-10 focus:ring-aptivegreen focus:border-aptivegreen border-gray-300 placeholder-gray-300 shadow-sm block w-full sm:text-sm rounded-md"
    autoComplete="off"
  />
);

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onKeyUp: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Input;
