var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import { Modal, Button, Toast } from '@aptive-env/storybook';
import { deleteApplication } from 'services';
var DeleteLicenseApplicationModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onHideShow = _a.onHideShow, application = _a.application, refresh = _a.refresh;
    var _b = useState(false), showDeleteToast = _b[0], setShowDeleteToast = _b[1];
    var _c = useState(false), isDeleteCancelled = _c[0], setIsDeleteCancelled = _c[1];
    var isDeleteCancelledRef = useRef(isDeleteCancelled);
    isDeleteCancelledRef.current = isDeleteCancelled;
    var handleDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setShowDeleteToast(true);
            onHideShow((application === null || application === void 0 ? void 0 : application.id) || 0, true);
            setTimeout(function () {
                setShowDeleteToast(false);
                if (isDeleteCancelledRef.current) {
                    setIsDeleteCancelled(false);
                }
                else {
                    deleteApplication(application.id)
                        .then(function () {
                        setIsDeleteCancelled(false);
                        refresh();
                    })
                        .catch(function (error) {
                        console.log(error);
                    });
                }
            }, 5000);
            onClose();
            return [2 /*return*/];
        });
    }); };
    var handleUndoDelete = function () {
        setIsDeleteCancelled(true);
        setShowDeleteToast(false);
        onHideShow((application === null || application === void 0 ? void 0 : application.id) || 0, false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Modal, __assign({ open: isOpen, onClickOutside: onClose }, { children: _jsxs("div", __assign({ className: "flex flex-col w-[512px] p-6" }, { children: [_jsx("div", __assign({ className: "text-lg leading-6 font-medium text-gray-900 mb-2" }, { children: "Delete License Application?" })), _jsx("div", __assign({ className: "text-sm leading-5 text-gray-600 mb-4" }, { children: "Are you sure you want to delete this license application? This action cannot be undone." })), _jsxs("div", __assign({ className: "flex justify-end gap-2" }, { children: [_jsx(Button, __assign({ variant: "neutral", onClick: onClose }, { children: "Go back" })), _jsx(Button, __assign({ variant: "danger", onClick: handleDelete }, { children: "Delete License Application" }))] }))] })) })), _jsx("div", __assign({ className: "fixed top-3 right-3 z-[100]" }, { children: showDeleteToast && (_jsx(Toast, __assign({ dismissible: true, isIcon: true, header: "Success", isActionButton: true, actionButtonTitle: "Undo", onClickActionButton: handleUndoDelete, onDismiss: function () { return setShowDeleteToast(false); }, status: "success" }, { children: "Well done, you successfully deleted a license application." }))) }))] }));
};
export default DeleteLicenseApplicationModal;
