import { useState, useRef } from 'react';
import Button from './Button';
import PropTypes from 'prop-types';
import { useOnClickOutside } from '@/hooks';
import classNames from 'classnames';

const DropdownButton = ({
  label,
  options,
  onChange,
  dropdownPosition = 'right',
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOutsideDropdownButton = () => {
    setOpen(false);
  };

  const ref = useRef();

  useOnClickOutside(ref, handleClickOutsideDropdownButton);

  return (
    <div className="relative" ref={ref}>
      <Button
        id="dropdownButton"
        className="px-2 py-1 rounded-2xl border border-gray-200 justify-start items-center gap-1 flex"
        color="white"
        onClick={() => setOpen(!open)}
        data-dropdown-toggle="dropdown"
      >
        <div className="text-right text-xs font-normal leading-none">{label}</div>
        <svg
          className="w-3 h-3 relative"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </Button>

      {open && (
        <div
          id="dropdown"
          className={classNames(
            'absolute z-20 w-44 max-h-80 overflow-y-scroll	text-base list-none bg-white rounded divide-y shadow-lg',
            dropdownPosition === 'left' ? 'right-0' : 'left-0',
          )}
        >
          <ul className="py-1 divide-y" aria-labelledby="dropdownButton">
            {options.map(({ label, isHidden, ...option }, i) => {
              const onClickOption = (event) => {
                onChange(option);
                setOpen(false);
              };

              return isHidden ? null : (
                <li key={i}>
                  <button
                    className="w-full py-2 px-4 text-sm hover:bg-gray-100 text-left"
                    onClick={onClickOption}
                  >
                    {label}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

DropdownButton.propTypes = {
  label: PropTypes.node,
  options: PropTypes.array,
  onChange: PropTypes.func,
  dropdownPosition: PropTypes.string,
};

export default DropdownButton;
