var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import { Icon, Loader } from '..';
import { FormLabel, CustomInput, CustomErrorMessage, CustomSelect, CustomFile, CustomNumber, CustomTextArea, CustomDate, CustomPhone, } from '.';
import { Select } from 'components/common';
import { generateBaseClasses } from 'lib';
var CustomFormElement = function (props) {
    var _a;
    var id = props.id, type = props.type, label = props.label, error = props.error, loading = props.loading, _b = props.required, required = _b === void 0 ? false : _b, isFileLoading = props.isFileLoading, tooltipMessage = props.tooltipMessage, formElementWrapperClassName = props.formElementWrapperClassName, elementWrapperClassName = props.elementWrapperClassName, baseWrapperClassName = props.baseWrapperClassName;
    var elementWrapperClasses = useMemo(function () {
        return classNames('mt-0.5 block rounded-md', { 'shadow-sm': type !== 'checkbox' }, elementWrapperClassName);
    }, [elementWrapperClassName, type]);
    var baseClasses = useMemo(function () { return generateBaseClasses(type, baseWrapperClassName); }, [type, baseWrapperClassName]);
    var ChildElement = useMemo(function () {
        return {
            textArea: CustomTextArea,
            file: CustomFile,
            text: CustomInput,
            number: CustomNumber,
            phone: (CustomPhone),
            select: CustomSelect,
            multiSelect: Select,
            date: CustomDate,
        }[type] || CustomInput;
    }, [type]);
    return (_jsxs("div", __assign({ className: formElementWrapperClassName }, { children: [_jsxs("div", __assign({ className: "flex items-center" }, { children: [((_a = label === null || label === void 0 ? void 0 : label.length) !== null && _a !== void 0 ? _a : 0) > 0 && (_jsx(FormLabel, { label: label, required: required, htmlFor: id })), tooltipMessage && (_jsx("div", __assign({ className: "ml-1" }, { children: _jsx(Icon, { id: id, place: "top", icon: "info", message: tooltipMessage }) })))] })), loading ? (_jsx(Loader, { className: "mt-0.5 h-[38px] items-center justify-start px-3" })) : (_jsx("div", __assign({ className: elementWrapperClasses }, { children: _jsx(ChildElement, __assign({ baseClasses: baseClasses, hasError: !!error }, props)) }))), (error === null || error === void 0 ? void 0 : error.message) && !isFileLoading && (_jsx(CustomErrorMessage, { text: error === null || error === void 0 ? void 0 : error.message }))] })));
};
export default CustomFormElement;
