var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { AptiveIcon } from '@aptive-env/storybook';
import { useState } from 'react';
import { RenderParticipant } from 'components/EventUpdateModal/EventModalComponents';
var ParticipantExpandableList = function (_a) {
    var participants = _a.participants;
    var _b = useState(false), isParticipantsExpanded = _b[0], setIsParticipantsExpanded = _b[1];
    return (_jsxs("div", __assign({ className: "overflow-hidden" }, { children: [_jsxs("div", __assign({ className: "flex items-center justify-between cursor-pointer", onClick: function () { return setIsParticipantsExpanded(!isParticipantsExpanded); } }, { children: [_jsxs("div", __assign({ className: "text-xs text-medium-gray-100" }, { children: [participants.length, " guest", participants.length > 1 ? 's' : ''] })), _jsx(AptiveIcon, { icon: "chevronDown", className: "w-[16px] h-[16px] stroke-medium-gray-100 fill-none" })] })), isParticipantsExpanded ? (_jsx("div", __assign({ className: "ml-2 mt-3" }, { children: participants.map(function (participant) { return (_jsxs("div", __assign({ className: "flex items-center gap-2 mt-2" }, { children: [_jsx("div", __assign({ className: "h-[32px] w-[32px] flex-none rounded-full overflow-hidden bg-gray-200" }, { children: _jsx(RenderParticipant, { participant: participant }) })), _jsx("div", __assign({ className: "text-xs text-dark-gray-100" }, { children: participant.name }))] }), participant.id)); }) }))) : (_jsxs("div", { children: [_jsxs("div", __assign({ className: "text-xs text-medium-gray-100 mt-3 mb-3" }, { children: [_jsx("span", { children: participants
                                    .slice(0, Math.min(4, participants.length))
                                    .map(function (participant) { return participant.name; })
                                    .join(', ') }), participants.length > 4 && (_jsxs("span", { children: ["\u00A0and ", participants.length - 4, " other", participants.length - 4 > 1 ? 's' : ''] }))] })), _jsx("div", __assign({ className: "flex gap-[1px] overflow-auto" }, { children: participants.map(function (participant) { return (_jsx("div", __assign({ className: "h-[32px] w-[32px] flex-none rounded-full overflow-hidden bg-gray-200" }, { children: _jsx(RenderParticipant, { participant: participant }) }), participant.id)); }) }))] }))] })));
};
export default ParticipantExpandableList;
