import { createReducer } from '@/redux/root';
import {
  planNameSpace,
  requestPlansAsync,
  requestPlanAsync,
  updatePlanAsync,
  createPlanAsync,
  removePlanAsync,
  massUpdateAsync,
  removePlanBuilderAreaPlan,
  updatePlanBuilderAreaPlan,
  createPlanBuilderAreaPlan,
  removePlanBuilderGroupPlan,
  updatePlanBuilderGroupPlan,
  createPlanBuilderGroupPlan,
  requestBillingFrequenciesAsync,
} from './actions';
import { NAME } from './selectors';

export const plansReducer = {
  [NAME]: createReducer(
    planNameSpace,
    {
      massUpdate: null,
      billingFrequencies: [],
    },
    {
      [requestPlansAsync.success]: ({ state, action: { payload } }) => {
        state.plans = payload;
      },
      [requestPlanAsync.success]: ({ state, action: { payload } }) => {
        state.plan = payload;
      },
      [updatePlanAsync.success]: ({ state, action: { payload } }) => {
        state.plan = payload;
        state.plans.forEach((plan, index) => {
          if (plan.id === payload.id) {
            state.plans[index] = payload;
          }
        });
      },
      [createPlanAsync.success]: ({ state, action: { payload } }) => {
        state.plan = payload;
        state.plans.push(payload);
      },
      [removePlanAsync.success]: ({ state, action: { payload } }) => {
        state.plans = state.plans.filter(({ id }) => id !== payload);
      },
      [massUpdateAsync.success]: ({ state, action: { payload } }) => {
        const { updated } = payload;
        state.massUpdate = { updated, time: +new Date() };
      },
      [createPlanBuilderAreaPlan]: ({ state, action: { payload } }) => {
        const plan = state.plan;

        plan.area_plans.push(payload);

        state.plan = plan;
      },
      [updatePlanBuilderAreaPlan]: ({ state, action: { payload } }) => {
        const plan = state.plan;
        const areaPlan = plan.area_plans.find(({ id }) => id === payload.id);

        Object.assign(areaPlan, payload);

        state.plan = plan;
      },
      [removePlanBuilderAreaPlan]: ({ state, action: { payload } }) => {
        const plan = state.plan;
        plan.area_plans = plan.area_plans.filter(({ id }) => +id !== +payload);

        state.plan = plan;
      },
      [createPlanBuilderGroupPlan]: ({ state, action: { payload } }) => {
        const plan = state.plan;

        plan.group_plans = plan.group_plans.concat(payload);

        state.plan = plan;
      },
      [updatePlanBuilderGroupPlan]: ({ state, action: { payload } }) => {
        const plan = state.plan;

        const groupPlan = plan.group_plans.find(({ id }) => id === payload.id);

        Object.assign(groupPlan, payload);

        state.plan = plan;
      },
      [removePlanBuilderGroupPlan]: ({ state, action: { payload } }) => {
        const plan = state.plan;

        plan.group_plans = plan.group_plans.filter(({ id }) => +id !== +payload.id);

        state.plan = plan;
      },
      [requestBillingFrequenciesAsync.success]: ({
        state,
        action: { payload },
      }) => {
        state.billingFrequencies = payload;
      },
    },
  ),
};
