import { CustomFormElement } from '@/components/common';
import { useFormContext } from 'react-hook-form';

const CustomGroup = () => {
  const { register } = useFormContext();

  return (
    <div className="w-64">
      <CustomFormElement register={register} label="Custom Group" type="text" name="custom_group_identifier" />
    </div>
  );
};

export default CustomGroup;
