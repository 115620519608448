var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Button, AptiveIcon } from '@aptive-env/storybook';
var NoOffice = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    return (_jsx(Modal, __assign({ className: "rounded-lg", open: isOpen, onClickOutside: function () { return onClose(); } }, { children: _jsxs("div", __assign({ className: "flex flex-col items-center w-[512px] p-6" }, { children: [_jsx(AptiveIcon, { className: "w-[48px] h-[48px] fill-none stroke-[#991b1b] mb-3", icon: "exclamation" }), _jsx("div", __assign({ className: "text-lg leading-6 font-medium text-gray-900 mb-2" }, { children: "No Office Assigned" })), _jsx("div", __assign({ className: "text-sm leading-5 text-gray-600 mb-4" }, { children: "Please ask administrator to assign office(s) to you." })), _jsx(Button, __assign({ variant: "danger", className: "w-full", onClick: function () { return onClose(); } }, { children: "Ok" }))] })) })));
};
export default NoOffice;
