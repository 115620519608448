import Header from '@/modules/AdminTools/components/Header';
import EditHistoriesTable from './EditHistoriesTable';
import Filters from './Filters';

const EditHistories = () => {
  return (
    <div className="space-y-4">
      <Header title="Edit Histories" />
      <Filters />
      <EditHistoriesTable />
    </div>
  );
};

export default EditHistories;
