export const usersManagementConstants = {
  LEAD_MANAGEMENT_SEARCH_NAME: 'lead',
  SEARCH_USERS: 'Search Users',

  ARCHIVED_USER_TYPE: 'user',

  VIEW_USER_BUTTON: 'View User',
};

export const repTypeSelectOptions = [
  {
    label: 'Users',
    value: usersManagementConstants.ARCHIVED_USER_TYPE,
  },
];
