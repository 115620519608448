import { filterEditHistoriesAsync } from '@/modules/AdminTools/redux/editHistory';
import ActionFilter from './ActionFilter';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PlanFilter from './PlanFilter';
import CreatedAtFilter from './CreatedAtFilter';
import ModelFilter from './ModelFilter';
import UserFilter from './UserFilter';
import ChangesFilter from './ChangesFilter';

/**
 * 'created_at_start' => 'nullable|date',
 * 'created_at_end' => 'nullable|date',
 * 'model_id' => 'nullable|string',
 * 'model_type' => 'nullable|string',
 * 'action' => 'nullable|string',
 * 'editor_id' => 'nullable|string',
 * 'editor_type' => 'nullable|string',
 * 'plan_id' => 'nullable|string',
 * 'changes' => 'nullable|string',
 * 'user_name' => 'nullable|string',
 *
 * 'page' => 'nullable|integer',
 * 'per_page' => 'nullable|integer',
 * 'sort_by' => 'nullable|string|in:id,created_at,...',
 * 'sort_order' => 'nullable|string|in:asc,desc',
 */
const Filters = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [cachedFilters, setCachedFilters] = useState(null);

  const data = useMemo(
    () => ({
      created_at_start: searchParams.get('created_at_start') ?? '',
      created_at_end: searchParams.get('created_at_end') ?? '',

      model_id: searchParams.get('model_id') ?? '',
      model_type: searchParams.get('model_type') ?? '',

      action: searchParams.get('action') ?? '',

      editor_id: searchParams.get('editor_id') ?? '',
      editor_type: searchParams.get('editor_type') ?? '',

      plan_id: searchParams.get('plan_id') ?? '',

      changes: searchParams.get('changes') ?? '',

      user_name: searchParams.get('user_name') ?? '',

      page: searchParams.get('page') ?? '',
      per_page: searchParams.get('per_page') ?? '',
      sort_by: searchParams.get('sort_by') ?? '',
      sort_order: searchParams.get('sort_order') ?? '',
    }),
    [searchParams]
  );

  useEffect(() => {
    if (!cachedFilters) {
      setCachedFilters(data);

      return;
    }
    const diff = Object.keys(data).filter(
      (key) => data[key] !== cachedFilters[key]
    );
    if (diff.length === 0) {
      return;
    }
    if (!diff.includes('page') && data.page != 1 && data.page != '') {
      // if changed fields, change page to 1
      const fields = [
        'created_at_start',
        'created_at_end',
        'model_id',
        'model_type',
        'action',
        'editor_id',
        'editor_type',
        'plan_id',
        'changes',
        'user_name',
      ];
      const isChanged = fields.some((field) => diff.includes(field));
      if (isChanged) {
        searchParams.set('page', 1);
        setSearchParams(searchParams);

        return;
      }
    }
    setCachedFilters(data);
  }, [data, cachedFilters, setCachedFilters, searchParams, setSearchParams]);

  useEffect(() => {
    if (!cachedFilters) {
      return;
    }

    dispatch(filterEditHistoriesAsync.request(cachedFilters));
  }, [cachedFilters, dispatch]);

  return (
    <div className="space-y-2">
      <div className="flex flex-row space-x-4">
        <div className="w-1/3">
          <ActionFilter />
        </div>
        <div className="w-2/3">
          <PlanFilter />
        </div>
      </div>
      <CreatedAtFilter />
      <ModelFilter />
      <UserFilter />
      <ChangesFilter />
    </div>
  );
};

export default Filters;
