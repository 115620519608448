import { useCallback, useEffect, useState, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DropdownButton,
  FilterButton,
  AddFilterButton,
} from '@/modules/Housing/components/common';
import { AdjustmentsIcon } from '@heroicons/react/outline';
import { BedManagementSidebar, BedManagementTable, BedManagementStats } from '../components';
import { housingConstants, bedManagementConstants } from '@/modules/Housing/lib';
import {
  requestComplexSummariesAsync,
  complexSummariesSelector,
} from '@/modules/Housing/redux/apartment';
import {
  requestTeamsSummariesAsync,
  teamsSummariesSelector,
} from '@/modules/Housing/redux/area';
import {
  requestBedManagementAsync,
  exportBedManagementAsync,
  bedManagementDataSelector,
  bedManagementDataTotalSelector,
  requestBedManagementStatisticAsync,
} from '@/modules/Housing/redux/bedManagement';
import { selectedSeasonSelector } from '@/modules/Housing/redux/season';
import {
  isBedManagementTableLoadingSelector,
  isAssigningRepLoadingSelector,
  isUnAssigningRepLoadingSelector,
  isTeamsSummariesLoadingSelector,
  isComplexSummariesLoadingSelector,
  isExportBedManagementLoadingSelector,
} from '@/modules/Housing/redux/loading';
import { countNumberOfFilters } from '@/modules/Housing/modules/BedsHistory/lib';
import {
  parseFilters,
  parseViewFilter,
} from '@/modules/Housing/modules/BedManagement/lib';
import { Loader } from '@/components/common';
import { useFeatureFlag } from 'configcat-react';
import { Profile } from '../components/Profile';

const {
  initialPage,
  initialPageSize,
  initialSeasonPeriod,
  seasonOptions,
  FILTER_ACTION_NEEDED,
  BED_MANAGEMENT_EXPORT_FEATURE_FLAG_NAME,
  HOUSING_PROFILE_FEATURE_FLAG_NAME,
  FILTER_TYPE_LIST,
  FILTER_TYPE_DATE_RANGE,
  BED_MANAGEMENT_STATISTIC_FEATURE_FLAG_NAME,
} = housingConstants;

const {
  HOUSING_TYPE_FILTER_LABEL,
  ROOMS_FILTER_LABEL,
  BEDS_FILTER_LABEL,
  VIEW_BY_COMPLEX,
  VIEW_BY_TEAM,
  SINGLE_HOUSING_TYPE,
  FEMALE_HOUSING_TYPE,
  MIXED_HOUSING_TYPE,
  MARRIED_OFFICE_HOUSING_TYPE,
  MARRIED_HOUSING_TYPE,
  MARRIED_OFFICE_HOUSING_TYPE_ABBREVIATION,
  NOT_IN_APARTMENTS_HOUSING_TYPE,
  OFFICE_APARTMENT_HOUSING_TYPE,
  AVAILABILITY_DATE_RANGE_FILTER_LABEL,
  ONLY_COUCHES_FILTER_LABEL,
} = bedManagementConstants;

const filterOptions = [
  {
    name: HOUSING_TYPE_FILTER_LABEL,
    subItems: [
      { value: MARRIED_HOUSING_TYPE },
      { value: MARRIED_OFFICE_HOUSING_TYPE },
      { value: MARRIED_OFFICE_HOUSING_TYPE_ABBREVIATION },
      { value: NOT_IN_APARTMENTS_HOUSING_TYPE },
      { value: OFFICE_APARTMENT_HOUSING_TYPE },
      { value: SINGLE_HOUSING_TYPE },
      { value: FEMALE_HOUSING_TYPE },
      { value: MIXED_HOUSING_TYPE },
    ],
    type: FILTER_TYPE_LIST,
  },
  {
    name: ROOMS_FILTER_LABEL,
    subItems: [
      { value: '1 bedroom' },
      { value: '2 bedrooms' },
      { value: '3 bedrooms' },
      { value: '4 bedrooms' },
      { value: '5 bedrooms' },
      { value: '6 bedrooms' },
      { value: '7 bedrooms' },
      { value: '8 bedrooms' },
      { value: '9 bedrooms' },
      { value: '10 bedrooms' },
      { value: '11 bedrooms' },
      { value: '12 bedrooms' },
      { value: '13 bedrooms' },
      { value: '14 bedrooms' },
      { value: '15 bedrooms' },
    ],
    type: FILTER_TYPE_LIST,
  },
  {
    name: BEDS_FILTER_LABEL,
    subItems: [
      { value: '1 bed' },
      { value: '2 beds' },
      { value: '3 beds' },
      { value: '4 beds' },
      { value: ONLY_COUCHES_FILTER_LABEL },
    ],
    type: FILTER_TYPE_LIST,
  },
  {
    name: AVAILABILITY_DATE_RANGE_FILTER_LABEL,
    type: FILTER_TYPE_DATE_RANGE,
    props: {
      isMaxDateToday: false,
    },
  },
];

const BedManagement = ({
  getBedManagement,
  bedManagementData,
  bedManagementDataTotal,
  isTableLoading,
  isExportBedManagementLoading,
  requestComplexSummaries,
  requestTeamsSummaries,
  complexes,
  teams,
  selectedSeason,
  isViewFilterLoading,
  requestBedManagementStatistic,
}) => {
  const { value: isBedManagementExportEnabled } = useFeatureFlag(
    BED_MANAGEMENT_EXPORT_FEATURE_FLAG_NAME,
    false,
  );

  const { value: isHousingProfileEnabled } = useFeatureFlag(
    HOUSING_PROFILE_FEATURE_FLAG_NAME,
    false,
  );

  const { value: isBedManagementStatisticEnabled } = useFeatureFlag(
    BED_MANAGEMENT_STATISTIC_FEATURE_FLAG_NAME,
    false,
  );

  const dispatch = useDispatch();
  const [teamIds, setTeamIds] = useState([]);
  const [complexIds, setComplexIds] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [selectedPage, setSelectedPage] = useState(initialPage);
  const [filterValueOptions, setFilterValueOptions] = useState([]);
  const [filters, setFilters] = useState([]);
  const [seasonPeriod, setSeasonPeriod] = useState(initialSeasonPeriod);
  const [refreshSidebar, setRefreshSidebar] = useState(false);
  const [isPageInitialLoad, setIsPageInitialLoad] = useState(true);
  const [recruitProfileOpen, setRecruitProfileOpen] = useState(false);
  const [selectedRepId, setSelectedRepId] = useState();

  const count = countNumberOfFilters(selectedFilters);

  const downloadOptions = useMemo(
    () => [
      {
        format: 'csv',
        label: 'Download All - CSV',
        action: exportBedManagementAsync.request,
      },
      {
        format: 'xlsx',
        label: 'Download All - Excel',
        action: exportBedManagementAsync.request,
      },
      ...(isPageInitialLoad
        ? []
        : [
          {
            format: 'csv',
            label: 'Download Results - CSV',
            action: exportBedManagementAsync.request,
            isFiltered: true,
          },
          {
            format: 'xlsx',
            label: 'Download Results - Excel',
            action: exportBedManagementAsync.request,
            isFiltered: true,
          },
        ]),
    ],
    [isPageInitialLoad],
  );

  const viewOptions = [
    {
      onClick: () => setFilterValueOptions(complexes),
      label: VIEW_BY_COMPLEX,
      value: 'complex_ids',
      type: 'dropdown',
    },
    {
      onClick: () => {
        setFilterValueOptions(
          teams.map((team) => ({ name: team.label, value: team.value })),
        );
      },
      label: VIEW_BY_TEAM,
      value: 'team_ids',
      type: 'dropdown',
    },
  ];

  const viewOption = filters[0]?.type?.label ?? viewOptions[0].label;

  useEffect(() => {
    if (isPageInitialLoad && filters.length > 0) {
      setIsPageInitialLoad(false);
    } else if (!isPageInitialLoad && filters.length === 0) {
      setIsPageInitialLoad(true);
    }
  }, [filters]);

  useEffect(() => {
    getBedManagementData();
  }, [selectedPage, pageSize, isPageInitialLoad, getBedManagement]);

  useEffect(() => {
    if (selectedPage !== initialPage) {
      setSelectedPage(initialPage);
    } else {
      getBedManagementData();
    }
  }, [teamIds, complexIds, selectedFilters, filters, selectedSeason, selectedPage]);

  const getBedManagementData = () => {
    if (isPageInitialLoad) {
      return;
    }

    const requestOptions = {
      ...parseFilters(selectedFilters),
      //recruiting_season_id: selectedSeason.value,
      season_period: seasonPeriod,
    };

    if (teamIds.length > 0) {
      requestOptions.team_ids = teamIds;
    }

    if (complexIds.length > 0) {
      requestOptions.complex_ids = complexIds;
    }

    if (isBedManagementStatisticEnabled) {
      requestBedManagementStatistic(requestOptions);
    }

    getBedManagement({
      page: {
        number: selectedPage,
        size: pageSize,
      },
      ...requestOptions,
    });
  };

  const refreshBedManagementPage = () => {
    setRefreshSidebar(!refreshSidebar);
    getBedManagementData();
  };

  useEffect(() => {
    requestTeamsSummaries({ season_period: seasonPeriod });
  }, [requestTeamsSummaries, seasonPeriod]);

  useEffect(() => {
    requestComplexSummaries({
      season_period: seasonPeriod,
    });
    if (viewOption === VIEW_BY_TEAM) {
      setFilters([]);
      setSelectedFilters({});
    }
  }, [seasonPeriod]);

  const onPageChange = useCallback(({ selected }) => {
    setSelectedPage(selected);
  }, []);

  const handleBedManagementDownload = (option) => {
    if (option) {
      const params = {
        ...(selectedFilters && parseFilters(selectedFilters)),
        ...parseViewFilter(filters[0]),
      };
      dispatch(
        option.action({
          format: option.format,
          ...(option.isFiltered && params),
          season_period: seasonPeriod,
          //recruiting_season_id: selectedSeason.value,
        }),
      );
    }
  };

  const onProfileClose = (withReload = true) => {
    setRecruitProfileOpen(false);

    if (withReload) {
      setRefreshSidebar(!refreshSidebar);
    }
  };

  const onSelectUser = (id) => {
    if (isHousingProfileEnabled) {
      setSelectedRepId(id);
      setRecruitProfileOpen(true);
    }
  };

  return (
    <div className="grow p-6">
      <div className="mb-4 flex justify-between items-center">
        <span className="text-gray-900 text-[32px] font-normal leading-10">
          Bed management
        </span>
        <div className="justify-end items-center gap-4 inline-flex">
          <DropdownButton
            options={seasonOptions}
            label={
              seasonOptions.find((option) => option.value === seasonPeriod)
                .label
            }
            onChange={(option) => setSeasonPeriod(option.value)}
          />
          {isViewFilterLoading ? (
            <Loader />
          ) : (
            <AddFilterButton
              buttonClassName="px-2 py-1 rounded-2xl border border-gray-200 justify-start items-center gap-1 flex bg-white"
              label={viewOption}
              labelClassName="text-right text-xs font-normal font-['Inter'] leading-none"
              iconClassName="w-3 h-3 text-gray-900"
              filterTypeOptions={viewOptions}
              filterValueOptions={filterValueOptions}
              setFilters={setFilters}
              filters={filters}
              isSingleFilter={true}
            />
          )}
          {!isPageInitialLoad && (
            <FilterButton
              options={filterOptions}
              label={
                <div className="gap-1 inline-flex items-center">
                  <AdjustmentsIcon className="w-3 h-3" />
                  {count > 0 ? `Filters (${count})` : 'Filters'}
                </div>
              }
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
            />
          )}
          {isBedManagementExportEnabled &&
            (isExportBedManagementLoading ? (
              <Loader />
            ) : (
              <DropdownButton
                options={downloadOptions}
                label="Download Data"
                onChange={handleBedManagementDownload}
                dropdownPosition="left"
              />
            ))}
        </div>
      </div>
      <div className="w-full">
        {isPageInitialLoad ? (
          <div className="flex justify-center items-center h-[300px]">
            <span className="text-gray-500 text-xl">
              {FILTER_ACTION_NEEDED}
            </span>
          </div>
        ) : (
          <>
            <div className="float-left mr-4">
              <BedManagementSidebar
                viewOption={viewOption}
                viewFilters={filters[0]?.value ?? []}
                selectedSeasonPeriod={seasonPeriod}
                refreshSidebar={refreshSidebar}
                onSelect={onSelectUser}
                setTeamIds={setTeamIds}
                setComplexIds={setComplexIds}
              />
            </div>
            <div className="overflow-hidden">
              {isBedManagementStatisticEnabled && (
                <BedManagementStats />
              )}
              <BedManagementTable
                isBedManagementStatisticEnabled={isBedManagementStatisticEnabled}
                isLoading={isTableLoading}
                initialPage={initialPage}
                selectedPage={selectedPage}
                onPageChange={onPageChange}
                setPageSize={setPageSize}
                pageSize={pageSize}
                viewFilters={filters}
                setViewFilters={setFilters}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                bedDataTotal={bedManagementDataTotal}
                bedData={bedManagementData}
                seasonPeriod={seasonPeriod}
                recruitingSeasonId={23}
                refreshBedManagementPage={refreshBedManagementPage}
              />
            </div>
            <div className="clear-both" />
            {
              isHousingProfileEnabled && selectedRepId !== undefined &&
              <>
                <Profile
                  selectedRepUserId={selectedRepId}
                  isOpen={recruitProfileOpen}
                  onClose={onProfileClose}
                  recruitingSeasonId={selectedSeason?.value?.toString()}
                />
              </>
            }
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bedManagementData: bedManagementDataSelector(state),
  bedManagementDataTotal: bedManagementDataTotalSelector(state),
  isTableLoading:
    isBedManagementTableLoadingSelector(state) ||
    isAssigningRepLoadingSelector(state) ||
    isUnAssigningRepLoadingSelector(state),
  complexes: complexSummariesSelector(state),
  teams: teamsSummariesSelector(state),
  selectedSeason: selectedSeasonSelector(state),
  isViewFilterLoading:
    isTeamsSummariesLoadingSelector(state) ||
    isComplexSummariesLoadingSelector(state),
  isExportBedManagementLoading: isExportBedManagementLoadingSelector(state),
});

const mapDispatchToProps = {
  getBedManagement: requestBedManagementAsync.request,
  requestComplexSummaries: requestComplexSummariesAsync.request,
  requestTeamsSummaries: requestTeamsSummariesAsync.request,
  requestBedManagementStatistic: requestBedManagementStatisticAsync.request,
};

BedManagement.propTypes = {
  getBedManagement: PropTypes.func.isRequired,
  bedManagementData: PropTypes.array.isRequired,
  bedManagementDataTotal: PropTypes.number.isRequired,
  isTableLoading: PropTypes.bool,
  isViewFilterLoading: PropTypes.bool,
  requestComplexSummaries: PropTypes.func,
  requestTeamsSummaries: PropTypes.func,
  complexes: PropTypes.array,
  teams: PropTypes.array,
  selectedSeason: PropTypes.object,
  isExportBedManagementLoading: PropTypes.bool,
  requestBedManagementStatistic: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BedManagement);
