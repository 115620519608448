import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { normalizeRows, Props, TableRow, TableCell } from '.';

const TableHead = ({
  isStickyHeader,
  rows,
  ...props
}) => (
  <thead {...(new Props(props).withClassName('bg-gray-50 divide-y whitespace-nowrap', isStickyHeader ? 'sticky top-0' : ''))}>
    {normalizeRows(rows).map(({ cells, ...props }) => (
      <TableRow
        key={uuidv4()}
        {...(new Props(props).withClassName('text-gray-600 text-xs uppercase'))}
      >
        {cells.map(({ ...props }) => (
          <TableCell
            key={uuidv4()}
            {...(
              new Props(props)
                .withProp('type', 'th')
                .withClassName('py-3 font-normal', isStickyHeader ? 'bg-gray-50 border-b' : '')
            )}
          />
        ))}
      </TableRow>
    ))}
  </thead>
);

TableHead.propTypes = {
  isStickyHeader: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default TableHead;
