import * as yup from 'yup';
import {
  REQUIRED,
  MIN_ONE_ENTRY_LIST,
  MIN_TWO_ENTRY_LIST,
  FEET_REGEX,
  INCHES_REGEX,
  WEIGHT_REGEX,
  FEET_VALIDATION_MESSAGE,
  INCHES_VALIDATION_MESSAGE,
  WEIGHT_VALIDATION_MESSAGE,
  MIN_2_CHARS,
  MAX_255_CHARS,
  EMPLOYMENT_END_DATE_MESSAGE,
  INVALID_DATE_MESSAGE,
  PHONE_REGEX,
  PHONE_VALIDATION_MESSAGE,
  MAX_50_CHARS,
} from '@/lib/validations';
import { onboardingConstants, onboardingDataValues } from '@/lib/constants';
import { fileValidationSchema } from '@/components/common/Form/CustomFile/fileValidationSchema';
import { hasRepExperienceValidationSchema, repExperienceDataValidationSchema } from '@/modules/recruits/lib';

const {
  YES_VALUE,
  COUNTRY_OTHER_VALUE,
} = onboardingDataValues;

const {
  EMPLOYMENT_HISTORY_SECTION_FEATURE_FLAG_NAME,
  REFERENCES_SECTION_FEATURE_FLAG_NAME,

  // Licensing Personal Details Section
  RACE,
  FEET,
  INCHES,
  WEIGHT,
  HAIR_COLOR,
  EYE_COLOR,
  COUNTRY_OF_BIRTH,
  STATE_OF_BIRTH,
  STATE_OF_BIRTH_OTHER,
  CITY_OF_BIRTH,
  IS_US_CITIZEN,
  HAS_VISIBLE_MARKINGS,
  MARKINGS_DESCRIPTION_NAME,
  IS_CONVICTED_OF_CRIME,
  CRIME_DESCRIPTION_NAME,
  DRIVER_LICENSE,
  DRIVER_LICENSE_LOCAL_FILE,

  // Rep Experience Section
  REP_EXPERIENCES_DATA_NAME,
  HAS_REP_EXPERIENCE,

  // Employment History Section
  EMPLOYMENT_DATA,
  EMPLOYER_NAME,
  EMPLOYER_START_DATE,
  EMPLOYER_END_DATE,

  // References Section
  REFERENCE_DATA,
  REFERENCE_NAME,
  REFERENCE_RELATION,
  REFERENCE_PHONE_NUMBER,

  // Signature Upload Section
  SIGNATURE,
} = onboardingConstants;

export const composeValidationSchema = (featureFlags) => {
  const {
    [EMPLOYMENT_HISTORY_SECTION_FEATURE_FLAG_NAME]: isEmploymentHistorySectionEnabled,
    [REFERENCES_SECTION_FEATURE_FLAG_NAME]: isReferencesSectionEnabled,
  } = featureFlags;

  return yup.object().shape({
    // Licensing Personal Details Section
    [RACE]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      }),
    [FEET]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      })
      .matches(FEET_REGEX, {
        excludeEmptyString: true,
        message: FEET_VALIDATION_MESSAGE,
      }),
    [INCHES]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      })
      .matches(INCHES_REGEX, {
        excludeEmptyString: true,
        message: INCHES_VALIDATION_MESSAGE,
      }),
    [WEIGHT]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      })
      .matches(WEIGHT_REGEX, {
        excludeEmptyString: true,
        message: WEIGHT_VALIDATION_MESSAGE,
      }),
    [HAIR_COLOR]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      }),
    [EYE_COLOR]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      }),
    [COUNTRY_OF_BIRTH]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      }),
    [STATE_OF_BIRTH]: yup
      .string()
      .when(COUNTRY_OF_BIRTH, {
        is: (value) => Boolean(value),
        then: yup.string().required(REQUIRED),
        otherwise: yup.string().when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
        }),
      }),
    [STATE_OF_BIRTH_OTHER]: yup
      .string()
      .when(COUNTRY_OF_BIRTH, {
        is: (val) => val === COUNTRY_OTHER_VALUE,
        then: yup
          .string()
          .checkWhiteSpacesOnly(REQUIRED)
          .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
            return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
          })
          .matches(/.{2,}/, {
            excludeEmptyString: true,
            message: MIN_2_CHARS,
          })
          .max(50, MAX_50_CHARS),
        otherwise: yup.string().nullable().notRequired(),
      }),
    [CITY_OF_BIRTH]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      })
      .checkWhiteSpacesOnly(REQUIRED)
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: MIN_2_CHARS,
      })
      .max(255, MAX_255_CHARS),
    [IS_US_CITIZEN]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      }).nullable(),
    [HAS_VISIBLE_MARKINGS]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      }).nullable(),
    [MARKINGS_DESCRIPTION_NAME]: yup
      .string()
      .when(HAS_VISIBLE_MARKINGS, {
        is: (val) => val === YES_VALUE,
        then: yup
          .string()
          .checkWhiteSpacesOnly(REQUIRED)
          .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
            return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
          })
          .matches(/.{2,}/, {
            excludeEmptyString: true,
            message: MIN_2_CHARS,
          })
          .max(255, MAX_255_CHARS),
        otherwise: yup.string().nullable().notRequired(),
      }),
    [IS_CONVICTED_OF_CRIME]: yup.string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      })
      .nullable(),
    [CRIME_DESCRIPTION_NAME]: yup
      .string()
      .when(IS_CONVICTED_OF_CRIME, {
        is: (val) => val === YES_VALUE,
        then: yup
          .string()
          .checkWhiteSpacesOnly(REQUIRED)
          .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
            return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
          })
          .matches(/.{2,}/, {
            excludeEmptyString: true,
            message: MIN_2_CHARS,
          })
          .max(255, MAX_255_CHARS),
        otherwise: yup.string().nullable().notRequired(),
      }),
    [DRIVER_LICENSE]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      }),
    [DRIVER_LICENSE_LOCAL_FILE]: fileValidationSchema,

    // Rep Experience Section
    [HAS_REP_EXPERIENCE]: hasRepExperienceValidationSchema,
    [REP_EXPERIENCES_DATA_NAME]: repExperienceDataValidationSchema(false),

    // Employment History Section
    ...(isEmploymentHistorySectionEnabled && {
      [EMPLOYMENT_DATA]: yup
        .array()
        .of(
          yup.object().shape({
            [EMPLOYER_NAME]: yup
              .string()
              .checkWhiteSpacesOnly(REQUIRED)
              .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
                return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
              })
              .matches(/.{2,}/, {
                excludeEmptyString: true,
                message: MIN_2_CHARS,
              })
              .max(255, MAX_255_CHARS)
              .nullable(),
            [EMPLOYER_START_DATE]: yup
              .date()
              .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
                return !isWizard && isAdmin
                  ? schema.nullable().transform((value) => (value instanceof Date && !isNaN(value) ? value : null))
                  : schema.required(REQUIRED);
              })
              .nullable()
              .typeError(INVALID_DATE_MESSAGE),
            [EMPLOYER_END_DATE]: yup
              .date()
              .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
                return !isWizard && isAdmin
                  ? schema.nullable().transform((value) => (value instanceof Date && !isNaN(value) ? value : null))
                  : schema.required(REQUIRED);
              })
              .nullable()
              .min(
                yup.ref(EMPLOYER_START_DATE),
                EMPLOYMENT_END_DATE_MESSAGE,
              )
              .typeError(INVALID_DATE_MESSAGE),
          }),
        )
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin ? schema : schema.min(2, MIN_TWO_ENTRY_LIST);
        }),
    }),

    // References Section
    ...(isReferencesSectionEnabled && {
      [REFERENCE_DATA]: yup
        .array()
        .of(
          yup.object().shape({
            [REFERENCE_NAME]: yup
              .string()
              .checkWhiteSpacesOnly(REQUIRED)
              .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
                return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
              })
              .matches(/.{2,}/, {
                excludeEmptyString: true,
                message: MIN_2_CHARS,
              })
              .max(255, MAX_255_CHARS)
              .nullable(),
            [REFERENCE_RELATION]: yup
              .string()
              .checkWhiteSpacesOnly(REQUIRED)
              .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
                return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
              })
              .matches(/.{2,}/, {
                excludeEmptyString: true,
                message: MIN_2_CHARS,
              })
              .max(255, MAX_255_CHARS)
              .nullable(),
            [REFERENCE_PHONE_NUMBER]: yup
              .string()
              .matches(PHONE_REGEX, {
                excludeEmptyString: true,
                message: PHONE_VALIDATION_MESSAGE,
              })
              .required(REQUIRED)
              .nullable(),
          }),
        )
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin ? schema : schema.min(1, MIN_ONE_ENTRY_LIST);
        }),
    }),

    // Signature Upload Section
    [SIGNATURE]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      }),
  });
};
