import { WizardProfileModal, Table } from '@/components';
import { mergeClassName } from '@/lib/utils';
import { isAdminSelector } from '@/redux/auth';
import { selectIsUpdateStatusLoading, soRepsLoadingSelector } from '@/redux/loading';
import { soRepsSelector, soRepsTotalSelector } from '@/redux/sales-operations';
import { repStatusesSelector } from '@/redux/reps';
import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { getHeadRows, parseRepRows } from './utils';
import PropTypes from 'prop-types';
import { useFeatureFlag } from 'configcat-react';
import { onboardingConstants } from '@/lib/constants';

const {
  REP_STATUS_LOGIC_FEATURE_FLAG_NAME,
} = onboardingConstants;

const SalesOperationsRepsTable = ({
  reps,
  repsTotal,
  pageSize,
  initialPage,
  selectedPage,
  repsLoading,
  isAdmin,
  isStatusUpdating,
  getReps,
  setPageSize,
  onPageChange,
  recruitingSeasonId,
  repStatuses,
}) => {
  const isStickyHeader = true;
  const [profileOpen, setProfileOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [repsToChangeStatus, setRepsToChangeStatus] = useState([]);

  const { value: isRepStatusLogicEnabled } = useFeatureFlag(
    REP_STATUS_LOGIC_FEATURE_FLAG_NAME,
    false,
  );

  const onChangeRepsStatus = useCallback(() => {
    getReps();
    setRepsToChangeStatus([]);
  }, [getReps]);

  const onCheckAllReps = useCallback((event) => {
    const repsToCheck = event.target.checked ? [...reps?.map((rep) => {
      return {
        id: rep.id,
        admin_only: rep.admin_only,
        is_coming: rep.is_coming,
        name: rep.name,
      };
    })] : [];

    setRepsToChangeStatus(repsToCheck);
  }, [reps]);

  const onCheckRep = useCallback((rep) => {
    const isIdExist = repsToChangeStatus.some((item) => item.id === rep.id);
    const repsToCheck = isIdExist
      ? [...repsToChangeStatus.filter((item) => item.id !== rep.id)]
      : [...repsToChangeStatus, rep];

    setRepsToChangeStatus(repsToCheck);
  }, [repsToChangeStatus]);

  const onRepClick = useCallback((userId) => {
    setSelectedUserId(userId);
    setProfileOpen(true);
  }, []);

  const onProfileClose = useCallback(() => {
    setProfileOpen(false);
  }, []);

  const repRows = parseRepRows(
    reps,
    onCheckRep,
    repsToChangeStatus,
    isAdmin && onRepClick,
    isStatusUpdating,
    repStatuses,
  );

  return (
    <div
      className={mergeClassName(
        'w-full flex flex-col',
        isStickyHeader ? 'max-h-[900px]' : '',
      )}
    >
      <Table
        isStickyHeader={true}
        className={isStickyHeader ? 'relative w-full border-separate border-spacing-0' : ''}
        loading={repsLoading}
        thead={{
          rows: getHeadRows(
            onCheckAllReps,
            repsToChangeStatus,
            onChangeRepsStatus,
            reps?.length === repsToChangeStatus.length,
            Boolean(repsToChangeStatus.length) && repsToChangeStatus.length < reps?.length,
            isStatusUpdating,
            repStatuses,
            isRepStatusLogicEnabled,
            recruitingSeasonId,
          ),
        }}
        tbody={{
          rows: repRows,
        }}
        paginator={{
          pageSize,
          setPageSize,
          onPageChange,
          selectedPage,
          initialPage,
          rowCount: repsTotal,
        }}
        wrapper={{
          className: 'overflow-y-visible',
        }}
      />
      <WizardProfileModal
        isOpen={profileOpen}
        onClose={onProfileClose}
        isPersonalWizard={false}
        userId={selectedUserId}
        reloadPageInfo={getReps}
        recruitingSeasonId={recruitingSeasonId}
      />
    </div>
  );
};

SalesOperationsRepsTable.propTypes = {
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  selectedPage: PropTypes.number,
  reps: PropTypes.array,
  repsTotal: PropTypes.number,
  repsLoading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isStatusUpdating: PropTypes.bool,
  getReps: PropTypes.func,
  setPageSize: PropTypes.func,
  onPageChange: PropTypes.func,
  recruitingSeasonId: PropTypes.string,
  repStatuses: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  reps: soRepsSelector(state),
  repsTotal: soRepsTotalSelector(state),
  repsLoading: soRepsLoadingSelector(state),
  repStatuses: repStatusesSelector(state),
  isAdmin: isAdminSelector(state),
  isStatusUpdating: selectIsUpdateStatusLoading(state),
});

export default connect(mapStateToProps)(SalesOperationsRepsTable);
