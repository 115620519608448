var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
var Popover = function (_a) {
    var open = _a.open, children = _a.children, content = _a.content, position = _a.position, eventModalPosition = _a.eventModalPosition, _b = _a.className, className = _b === void 0 ? '' : _b, hasArrow = _a.hasArrow, onClickOutside = _a.onClickOutside;
    var ref = useRef(null);
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (event.target instanceof HTMLElement && ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return function () {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [
        onClickOutside
    ]);
    return (_jsxs("div", __assign({ className: "relative" }, { children: [children, open && (_jsx("div", __assign({ ref: ref, className: "bg-white rounded absolute z-10 shadow-[0px_2px_4px_1px_rgba(0,0,0,0.25)] ".concat(className, "\n            ").concat(position === 'top' ? 'bottom-[100%] mb-2' : position === 'bottom' ? 'mt-2' : position === 'right' ? 'top-0 left-[100%] ml-2' : position === 'left' ? 'top-0 right-[100%] mr-2' : '', "\n            ").concat(eventModalPosition === 'right-top' ? 'top-0 left-[100%] ml-2' : eventModalPosition === 'left-top' ? 'top-0 right-[100%] mr-2' : eventModalPosition === 'right-bottom' ? 'bottom-0 left-[100%] ml-2' : eventModalPosition === 'left-bottom' ? 'bottom-0 right-[100%] mr-2' : '', "\n            ").concat(hasArrow && "\n              before:content-[\"\"] before:bg-white before:z-[-1] before:rotate-45 before:w-10 before:h-10 before:absolute\n              ".concat((eventModalPosition === 'right-top' || eventModalPosition === 'right-bottom') && 'before:left-[-3px] before:top-[10px]', "\n              ").concat((eventModalPosition === 'left-top' || eventModalPosition === 'left-bottom') && 'before:right-[-3px] before:top-[10px]', "\n            "), "\n          }") }, { children: content })))] })));
};
export default Popover;
