// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tpr833kwzWgHX5gIx_xk svg path{stroke:#007aff}.NbLiWLe1uWRwLmjQ__eF svg path{stroke:#4b5563}.tpr833kwzWgHX5gIx_xk svg,.NbLiWLe1uWRwLmjQ__eF svg{width:17px;height:17px}`, "",{"version":3,"sources":["webpack://./src/components/MapLayersToggleButtons/index.module.scss"],"names":[],"mappings":"AAEI,+BACE,cAAA,CAOF,+BACE,cAAA,CAMJ,oDACE,UAAA,CACA,WAAA","sourcesContent":[".svg_path_stroke_black {\n  svg {\n    path {\n      stroke: #007AFF\n    }\n  }\n}\n\n.svg_path_stroke_white {\n  svg {\n    path {\n      stroke: #4B5563\n    }\n  }\n}\n\n.svg_path_stroke_black, .svg_path_stroke_white {\n  svg {\n    width: 17px;\n    height: 17px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svg_path_stroke_black": `tpr833kwzWgHX5gIx_xk`,
	"svg_path_stroke_white": `NbLiWLe1uWRwLmjQ__eF`
};
export default ___CSS_LOADER_EXPORT___;
