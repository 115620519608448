import * as Api from '@/api/api';

const api = process.env.REACT_APP_PLAN_BUILDER_API;

const path = '/api/v1/plan-builder/group-plans';

export const getGroupPlans = Api.get({ path, api });

export const createGroupPlan = Api.post({ path, api });

export const updateGroupPlan = (id) => Api.patch({ path: `${path}/${id}`, api });

export const removeGroupPlan = (id) => Api.remove({ path: `${path}/${id}`, api });
