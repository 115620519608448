import { createAsyncAction } from '@/redux/root';

export const editHistoryNameSpace = '@@/plan-builder-history';

export const filterEditHistoriesAsync = createAsyncAction(
  `${editHistoryNameSpace}/FILTER_EDIT_HISTORIES`
);

export const requestHistoryOptionsAsync = createAsyncAction(
  `${editHistoryNameSpace}/EDIT_HISTORY_OPTIONS`
);
