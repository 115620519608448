export const bedManagementConstants = {
  TEAM_LABEL: 'Team',
  TEAM_NAME: 'team_ids',
  COMPLEX_LABEL: 'Complex',
  COMPLEX_NAME: 'complex_ids',

  ONLY_COUCHES_FILTER_NAME: 'only_couches',
  AVAILABILITY_DATE_RANGE_FILTER_LABEL: 'Availability Date range',
  AVAILABILITY_DATE_FROM_FILTER_NAME: 'availability_from',
  AVAILABILITY_DATE_TO_FILTER_NAME: 'availability_to',
  SEARCH_REPS_LABEL: "Search Sales Rep's",
  SEARCH_REPS_NAME: 'reps_search_query',
  SEARCH_SPS_LABEL: "Search Service Pro's",
  SEARCH_SPS_NAME: 'sps_search_query',
  SEASON_FILTER_LABEL: 'Season',
  SEASON_FILTER_NAME: 'season_period',
  HOUSING_TYPE_FILTER_LABEL: 'Housing type',
  HOUSING_TYPE_FILTER_NAME: 'apartment_types',
  ROOMS_FILTER_LABEL: 'Rooms',
  ROOMS_FILTER_NAME: 'number_of_rooms',
  BEDS_FILTER_LABEL: 'Beds',
  BEDS_FILTER_NAME: 'number_of_available_sleeping_spots',
  VIEW_BY_COMPLEX: 'View by complex',
  VIEW_BY_TEAM: 'View by team',

  ONLY_COUCHES_FILTER_LABEL: 'Couches',
  EXPECTED_SINGLE_HOUSING_TYPES: ['Single Shared Room', 'Single Own Room'],
  EXPECTED_FEMALE_HOUSING_TYPES: ['Female Shared Room', 'Female Own Room'],
  EXPECTED_MIXED_HOUSING_TYPES: ['Single Shared Room', 'Single Own Room', 'Female Shared Room', 'Female Own Room'],
  EXPECTED_MARRIED_HOUSING_TYPES: ['Married'],
  SINGLE_HOUSING_TYPE: 'Single',
  FEMALE_HOUSING_TYPE: 'Single Female',
  MIXED_HOUSING_TYPE: 'Mixed',
  MARRIED_OFFICE_HOUSING_TYPE: 'Married-Office',
  MARRIED_HOUSING_TYPE: 'Married',
  MARRIED_OFFICE_HOUSING_TYPE_ABBREVIATION: 'M/OA',
  NOT_IN_APARTMENTS_HOUSING_TYPE: 'Not in Apartments',
  OFFICE_APARTMENT_HOUSING_TYPE: 'Office Apartment',
  EXTRA_HOUSING_TYPES: [
    'Married',
    'Married-Office',
    'M/OA',
    'Not in Apartments',
    'Office Apartment',
  ],

  BED_ASSIGNMENT_TYPES: {
    ROOM: 'room',
    BED: 'bed',
    COUCH: 'couch',
  },

  QUIT_PROFILE_CONFIRMATION_BUTTON: 'Discard changes',
  RETURN_TO_PROFILE_BUTTON: 'Return to form',

  QUIT_PROFILE_CONFIRMATION_TITLE: 'Update changes?',
  QUIT_PROFILE_CONFIRMATION_MESSAGE: 'Changed have been made to the current profile but have not been submitted. How would you like to proceed?',
};
