var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import LicenseApplicationTable from './LicenseApplicationTable';
import LicenseApplicationModal from './LicenseApplication';
import ChecklistSelectModal from './ChecklistSelectModal';
import { useToast } from 'hooks/useToast';
import { fetchApplication, fetchApplicationAttachments, fetchChecklistAttachments } from 'services';
import '../../index.scss';
var LicenseApplicationsView = function () {
    var setToast = useToast().setToast;
    var _a = useState(false), isChecklistSelectModalOpen = _a[0], setIsChecklistSelectModalOpen = _a[1];
    var _b = useState(false), isLicenseApplicationModalOpen = _b[0], setIsLicenseApplicationModalOpen = _b[1];
    var _c = useState(null), selectedApplicationDetails = _c[0], setSelectedApplicationDetails = _c[1];
    var _d = useState([]), selectedApplicationAttachments = _d[0], setSelectedApplicationAttachments = _d[1];
    var _e = useState([]), selectedChecklistAttachments = _e[0], setSelectedChecklistAttachments = _e[1];
    var _f = useState(0), listDataVersion = _f[0], setListDataVersion = _f[1];
    var handleSelectChecklist = function (checklist) { return __awaiter(void 0, void 0, void 0, function () {
        var checklistAttachmentsResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchChecklistAttachments(checklist.id)];
                case 1:
                    checklistAttachmentsResult = _a.sent();
                    setSelectedApplicationDetails({
                        checklist_id: checklist.id,
                        state: checklist.state,
                        county: checklist.county,
                        municipality: checklist.municipality,
                        requirements: checklist.requirements,
                        submission_type: checklist.submission_type,
                        submission_email: checklist.submission_email,
                        submission_url: checklist.submission_url,
                        priority: checklist.priority,
                    });
                    setSelectedApplicationAttachments([]);
                    setSelectedChecklistAttachments(checklistAttachmentsResult.result.attachments);
                    setIsChecklistSelectModalOpen(false);
                    setIsLicenseApplicationModalOpen(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCloseApplicationModal = function () {
        setSelectedApplicationDetails(null);
        setSelectedApplicationAttachments([]);
        setIsLicenseApplicationModalOpen(false);
    };
    var handleClickNewApplication = function () {
        setIsChecklistSelectModalOpen(true);
    };
    var handleClickEditApplication = function (application) { return __awaiter(void 0, void 0, void 0, function () {
        var applicationResponse, attachmentsResponse, checklistAttachmentsResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchApplication(application.id)];
                case 1:
                    applicationResponse = _a.sent();
                    return [4 /*yield*/, fetchApplicationAttachments(application.id)];
                case 2:
                    attachmentsResponse = _a.sent();
                    return [4 /*yield*/, fetchChecklistAttachments(applicationResponse.result.checklist_id)];
                case 3:
                    checklistAttachmentsResult = _a.sent();
                    setSelectedApplicationDetails(applicationResponse.result);
                    setSelectedApplicationAttachments(attachmentsResponse.result.attachments);
                    setSelectedChecklistAttachments(checklistAttachmentsResult.result.attachments);
                    setIsLicenseApplicationModalOpen(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSubmitApplicationModal = function (_a) {
        var success = _a.success, message = _a.message;
        setIsLicenseApplicationModalOpen(false);
        setSelectedApplicationDetails(null);
        setSelectedApplicationAttachments([]);
        setToast({
            type: success ? 'success' : 'danger',
            message: message,
        });
        setListDataVersion(function (prev) { return prev + 1; }); // refresh the data list
    };
    return (_jsxs("div", __assign({ className: "flex flex-col h-0 grow overflow-hidden p-3" }, { children: [_jsx(LicenseApplicationTable, { version: listDataVersion, onClickNewApplication: handleClickNewApplication, onClickEditApplication: handleClickEditApplication }), _jsx(ChecklistSelectModal, { isOpen: isChecklistSelectModalOpen, onClose: function () { return setIsChecklistSelectModalOpen(false); }, onSelectChecklist: handleSelectChecklist }), selectedApplicationDetails && (_jsx(LicenseApplicationModal, { isOpen: isLicenseApplicationModalOpen, details: selectedApplicationDetails, attachments: selectedApplicationAttachments, checklistAttachments: selectedChecklistAttachments, onClose: handleCloseApplicationModal, onSubmit: handleSubmitApplicationModal }))] })));
};
export default LicenseApplicationsView;
