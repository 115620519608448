var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import AdminView from 'components/Planning/PlanningAdminView';
import TimelineView from 'components/Planning/PlanningTimelineView';
export var AppContext = createContext({
    isGoogleMapAPILoaded: false,
    googleMapAPILoadError: undefined,
});
var libraries = ['drawing', 'places', 'marker'];
var PlanningView = function () {
    var _a, _b;
    var _c = useState(false), isShowingAdminView = _c[0], setIsShowingAdminView = _c[1];
    var _d = useJsApiLoader({
        googleMapsApiKey: (_a = process.env.REACT_APP_GOOGLE_MAPS_API_KEY) !== null && _a !== void 0 ? _a : '',
        mapIds: [(_b = process.env.REACT_APP_GOOGLE_MAPS_ID) !== null && _b !== void 0 ? _b : ''],
        libraries: libraries,
    }), isLoaded = _d.isLoaded, loadError = _d.loadError;
    return (_jsx(AppContext.Provider, __assign({ value: { isGoogleMapAPILoaded: isLoaded, googleMapAPILoadError: loadError } }, { children: _jsx("div", __assign({ className: "flex flex-col grow h-0 overflow-hidden" }, { children: isShowingAdminView ? (_jsx(AdminView, { onClose: function () { return setIsShowingAdminView(false); } })) : (_jsx(TimelineView, { onClickViewProductionDemand: function () { return setIsShowingAdminView(true); } })) })) })));
};
export default PlanningView;
