import PropTypes from 'prop-types';
import { useState } from 'react';

import Modal from '@/modules/AdminTools/components/Modal';
import Form from './Form';
import CustomGroups from './CustomGroups';

const CustomGroupForm = ({ defaultValues, request, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);

  return (
    <>
      <div className="inline-block" onClick={() => setIsOpen(true)}>
        {children}
      </div>
      <Modal {...{ onClose, isOpen }}>
        <div className="overflow-y-scroll" style={{
          maxHeight: 'calc(100vh - 180px)',
        }}
        >
          <Form {...{ defaultValues, request }} />
          {defaultValues.id && (
            <>
              <hr className="mt-2" />
              <div>
                <CustomGroups custom_group_type_id={defaultValues.id} />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

CustomGroupForm.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.any.isRequired,
  request: PropTypes.func.isRequired,
};

export default CustomGroupForm;
