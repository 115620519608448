// TODO: SPT:REMOVE
// TODO: This is copied from 'react-leaflet/esm/MapContainer.js' to fix the error during map rendering.
//       This was done due to inability to upgrade package because `leaflet` dependency is locked to `1.7.1`
//       because of bad-developed `leaflet-freedraw` plugin which bundles `leaflet` instead of importing it as external dependency.
//       See https://github.com/aptive-env/soat-portal-ui/pull/312#issuecomment-2182778038 for the detailed explanation.

import { CONTEXT_VERSION, LeafletProvider } from '@react-leaflet/core';
import { Map as LeafletMap } from 'leaflet';
import { useEffect, useMemo, useRef, useState } from 'react';

const useMapElement = (mapRef, props) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    try {
      if (mapRef.current !== null && map === null) {
        const instance = new LeafletMap(mapRef.current, props);
        if (props.center != null && props.zoom != null) {
          instance.setView(props.center, props.zoom);
        } else if (props.bounds != null) {
          instance.fitBounds(props.bounds, props.boundsOptions);
        }
        if (props.whenReady != null) {
          instance.whenReady(props.whenReady);
        }
        setMap(instance);
      }
    } catch {
      //
    }
  }, [mapRef, map, props]);

  return map;
};

const MapContainer = ({
  children,
  className,
  id,
  placeholder,
  style,
  whenCreated,
  ...options
}) => {
  const mapRef = useRef(null);

  const map = useMapElement(mapRef, options);

  const createdRef = useRef(false);
  useEffect(() => {
    if (map != null && createdRef.current === false && whenCreated != null) {
      createdRef.current = true;
      whenCreated(map);
    }
  }, [map, whenCreated]);

  useEffect(() => {
    return () => {
      map?.remove();
    };
  }, [map]);

  const [props] = useState({ className, id, style });
  const context = useMemo(
    () => (map ? { __version: CONTEXT_VERSION, map } : null),
    [map],
  );

  const contents = context ? (
    <LeafletProvider value={context}>{children}</LeafletProvider>
  ) : (
    placeholder ?? null
  );

  return (
    <div {...props} ref={mapRef}>
      {contents}
    </div>
  );
};

export default MapContainer;
