import { useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { CustomButton } from '@/components';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants';
import { removeCustomGroupTypeAsync } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/custom-group-types';

const { ID, REMOVE_CONFIRM } = planBuilderConstants;

const Controls = () => {
  const dispatch = useDispatch();
  const id = useWatch({ name: ID });

  const remove = () => {
    if (!window.confirm(REMOVE_CONFIRM)) {
      return;
    }

    dispatch(removeCustomGroupTypeAsync.request({ id }));
  };

  return (
    <div className="flex flex-row self-center">
      {id && (
        <>
          <div>
            <CustomButton color={'red'} onClick={remove}>Remove</CustomButton>
          </div>
          <div className="w-4" />
        </>
      )}
      <div>
        <CustomButton color={'green'} type="submit">Save</CustomButton>
      </div>
    </div>
  );
};

export default Controls;
