import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import { Select } from '@/components/common';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { customGroupTypesSelector } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/custom-group-types';

const CustomGroupTypes = () => {
  const { setValue } = useFormContext();

  const [searchParams] = useSearchParams();

  const customGroupTypes = useSelector(customGroupTypesSelector);

  const options = useMemo(
    () => customGroupTypes
      .map((item) => ({
        value: item.name,
        label: item.name,
      })),
    [customGroupTypes],
  );

  const name = 'custom_group_type';

  useEffect(() => {
    if (options.length === 0) {
      return;
    }

    if (!searchParams.get(name)) {
      setValue(name, options[0].value);
    }
  }, [options, searchParams, setValue]);

  return (
    <div className="w-64">
      <div className="flex items-center">
        <div className="flex text-sm font-medium text-gray-700">
          <label>
            {'Custom Group Type'}
          </label>
        </div>
      </div>
      <div className={'mt-0.5 block rounded-md shadow-sm'}>
        <Controller
          name={name}
          render={({ field: { onChange, value, name } }) => (
            <Select
              {...{
                name,
                onChange,
                value,
                options,
              }}
              defaultOption={{ value: '', label: 'Select...' }}
              required
            />
          )}
        />
      </div>
    </div>
  );
};

export default CustomGroupTypes;
