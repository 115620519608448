var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from '@aptive-env/storybook';
import { AptiveIcon } from '@aptive-env/storybook';
export var MapZoomControl = function (_a) {
    var onZoom = _a.onZoom;
    return (_jsxs(Card, __assign({ className: "flex flex-col justify-center gap-2" }, { children: [_jsx("button", __assign({ className: "w-[40px] h-[40px] shadow-[0_1px_2px_0_#0000004D] bg-white rounded-full flex items-center justify-center cursor-pointer", onClick: function () { return onZoom('in'); } }, { children: _jsx(AptiveIcon, { className: "h-[24px] stroke-gray-600 fill-none", icon: "plus" }) })), _jsx("button", __assign({ className: "w-[40px] h-[40px] shadow-[0_1px_2px_0_#0000004D] bg-white rounded-full flex items-center justify-center cursor-pointer", onClick: function () { return onZoom('out'); } }, { children: _jsx(AptiveIcon, { className: "h-[24px] stroke-gray-600 fill-none", icon: "minus" }) }))] })));
};
