import PlanDetails from './PlanForm/PlanDetails';

const CreatePlan = () => {
  return (
    <div style={{ height: 'calc(100vh - 180px)' }}>
      <div className="flex flex-col h-full">
        <div
          style={{ padding: '24px 32px 128px' }}
          className="flex-grow overflow-y-scroll pr-12"
        >
          <PlanDetails />
        </div>
      </div>
    </div>
  );
};

export default CreatePlan;
