var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AptiveIcon } from '@aptive-env/storybook';
var FilePreview = function (_a) {
    var files = _a.files, onRemove = _a.onRemove, onPreview = _a.onPreview, onDownload = _a.onDownload;
    return (_jsx(_Fragment, { children: files.map(function (file, index) {
            var _a;
            return (_jsxs("div", __assign({ className: "flex justify-between items-center border-solid border bg-gray-50 border-gray-200 rounded-lg mt-4 h-[55px]" }, { children: [_jsx("div", __assign({ className: "px-4 text-sm font-medium overflow-auto no-scrollbar" }, { children: (_a = file.name) !== null && _a !== void 0 ? _a : file.filename })), _jsxs("div", __assign({ className: "flex items-center px-4 gap-4 border-l h-full rounded-r-lg" }, { children: [onPreview && (file.preview_url || file.arrayBuffer) && (_jsx(AptiveIcon, { className: "w-5 h-5 fill-none stroke-[#9CA3AF] m-auto cursor-pointer", icon: "eye", onClick: function () { return onPreview(file); } })), onDownload && (file.source_url || file.arrayBuffer) && (_jsx(AptiveIcon, { className: "w-5 h-5 fill-none stroke-[#9CA3AF] m-auto cursor-pointer", icon: "download", onClick: function () { return onDownload(file); } })), onRemove && (_jsx(AptiveIcon, { className: "w-5 h-5 fill-none stroke-[#9CA3AF] m-auto cursor-pointer", icon: "trash", onClick: function () { return onRemove(file); } }))] }))] }), index));
        }) }));
};
export default FilePreview;
