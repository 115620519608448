import { createAsyncAction } from '@/redux/root';

import { nameSpace } from '../nameSpace';

export const groupPlansNameSpace = `${nameSpace}/group-plans`;

export const updateGroupPlanAsync = createAsyncAction(
  `${groupPlansNameSpace}/UPDATE_GROUP_PLAN`,
);

export const createGroupPlanAsync = createAsyncAction(
  `${groupPlansNameSpace}/CREATE_GROUP_PLAN`,
);

export const removeGroupPlanAsync = createAsyncAction(
  `${groupPlansNameSpace}/REMOVE_GROUP_PLAN`,
);

export const requestGroupPlansAsync = createAsyncAction(
  `${groupPlansNameSpace}/REQUEST_GROUP_PLANS`,
);
