
import PropTypes from 'prop-types';

import { Select } from '@/components/common';
import { useMemo } from 'react';

const CustomGroups = ({ customGroupType, setCustomGroupType, customGroupTypes }) => {
  const customGroupOptions = useMemo(
    () => {
      return customGroupTypes?.map(({ name }) => ({
        label: name,
        value: name,
      })) || [];
    }
    , [customGroupTypes],
  );

  return (
    <div>
      <div className="flex flex-col mt-4">
        <div>
          Select a group type
        </div>
        <div className="w-64">
          <Select
            name={'groupNames'}
            onChange={({ target: { value } }) => setCustomGroupType(value)}
            value={customGroupType}
            options={customGroupOptions}
          />
        </div>
      </div>
      <hr className="mt-4" />
    </div>
  );
};

CustomGroups.propTypes = {
  customGroupType: PropTypes.string,
  setCustomGroupType: PropTypes.func.isRequired,
  customGroupTypes: PropTypes.array,
};

export default CustomGroups;
