var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ProductionDemandDataView } from './ProductionDemandDataView';
import { ProductionDemandUploadView } from './ProductionDemandUploadView';
import { fetchPlanningProductionTargets, updatePlanningProductionTargets } from 'services/planning';
import LoadingGif from 'assets/image/loading.gif';
import { getWeeksOfYear } from 'utils/getWeeksOfYear';
import { getWeek, getYear } from 'date-fns';
export var ProductionDemandSpreadSheet = function () {
    var _a = useState(false), isUploading = _a[0], setIsUploading = _a[1];
    var _b = useState(), data = _b[0], setData = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var date = new Date();
    var weeks = getWeeksOfYear(getYear(date));
    var currentWeek = getWeek(date);
    var handleUpload = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, , 2, 3]);
                    setIsUploading(true);
                    console.log(file); // TODO: remove this when API is ready
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 3000); })]; // TODO: replace this with actual API call to upload the CSV and get the parsed data
                case 1:
                    _a.sent(); // TODO: replace this with actual API call to upload the CSV and get the parsed data
                    return [3 /*break*/, 3];
                case 2:
                    setIsUploading(false);
                    return [7 /*endfinally*/];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleUpdate = function (_a) {
        var startDate = _a.startDate, officeId = _a.officeId, demand = _a.demand;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, updatePlanningProductionTargets({
                            officeId: officeId,
                            demand: demand,
                            startDate: startDate,
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    useEffect(function () {
        setIsLoading(true);
        fetchPlanningProductionTargets({
            startDate: weeks[0].start,
            endDate: weeks[weeks.length - 1].end,
        })
            .then(function (response) {
            if (response._metadata.success && response.result.production_targets) {
                setData(response.result.production_targets);
            }
        })
            .catch(function (error) {
            console.error(error);
        })
            .finally(function () {
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (isLoading) {
        return (_jsx("div", __assign({ className: "flex items-center justify-center h-10" }, { children: _jsx("div", __assign({ className: "w-[32px] h-[32px] rounded-full bg-gray-200 flex items-center justify-center" }, { children: _jsx("img", { src: LoadingGif, alt: "Loading ...", className: "w-[10px]" }) })) })));
    }
    return (_jsx(_Fragment, { children: data ? (_jsx(ProductionDemandDataView, { weeks: weeks, currentWeek: currentWeek, data: data, onChange: handleUpdate })) : (_jsx(ProductionDemandUploadView, { isUploading: isUploading, onUpload: handleUpload })) }));
};
