var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { calculateTimeBlock } from 'utils/generateTimeArray';
import { getCalendarEventTypeColors } from 'utils/color';
import { convert24HourTo12Hour } from 'utils/convertHour';
import { format } from 'date-fns';
var TIME_BLOCK_HEIGHT = 36;
var EventCard = function (_a) {
    var event = _a.event, view = _a.view, groupTotal = _a.groupTotal, groupIndex = _a.groupIndex, selectedTypes = _a.selectedTypes, onClick = _a.onClick;
    var eventTimeBlock = calculateTimeBlock(event.end_at) - calculateTimeBlock(event.start_at);
    return (_jsx(_Fragment, { children: (event.is_canceled === 'false' && selectedTypes.filter(function (item) { return item.id === event.event_type; })[0]) &&
            _jsx("div", __assign({ className: "absolute w-full p-1 rounded overflow-hidden cursor-pointer", style: {
                    color: getCalendarEventTypeColors(event.event_type).dark,
                    background: getCalendarEventTypeColors(event.event_type).light,
                    width: "calc(".concat(100 * (groupTotal - groupIndex) / groupTotal, "% - 1px)"),
                    height: TIME_BLOCK_HEIGHT * eventTimeBlock - 1,
                    top: TIME_BLOCK_HEIGHT * calculateTimeBlock(event.start_at),
                    right: 0,
                    border: groupIndex > 0 ? '1px solid white' : '',
                }, onClick: onClick }, { children: view === 'day' ? (_jsxs(_Fragment, { children: [eventTimeBlock > 1 && (_jsxs("p", __assign({ className: "text-xs font-light" }, { children: [convert24HourTo12Hour(event.start_at), " - ", convert24HourTo12Hour(event.end_at)] }))), _jsx("p", __assign({ className: "text-sm font-medium" }, { children: event.title })), eventTimeBlock > 1 && (_jsx("p", __assign({ className: "text-sm font-normal" }, { children: event.description })))] })) : (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: "text-[12px] font-medium ".concat(eventTimeBlock === 1 ? 'truncate' : 'overflow-hidden'), style: {
                                maxHeight: eventTimeBlock === 1 ? 'unset' : TIME_BLOCK_HEIGHT * (eventTimeBlock - 1)
                            } }, { children: event.title })), eventTimeBlock > 1 && (_jsx("p", __assign({ className: "text-[12px] font-light" }, { children: format(new Date("".concat(event.start_date, " ").concat(event.start_at)), 'hh:mm a') })))] })) })) }));
};
export default EventCard;
