var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useMemo, useEffect } from 'react';
import { Popover } from 'components/common';
import MiniCalendar from 'components/common/Calendar/MiniCalendar';
import classNames from 'classnames';
import { formatDateDisplay } from 'lib/utils';
import { AptiveIcon } from '@aptive-env/storybook';
import moment from 'moment';
var CustomDate = function (_a) {
    var id = _a.id, name = _a.name, formValue = _a.formValue, value = _a.value, hasError = _a.hasError, className = _a.className, onChange = _a.onChange, baseClasses = _a.baseClasses, required = _a.required, disabled = _a.disabled, register = _a.register, watch = _a.watch, _b = _a.dropdownPosition, dropdownPosition = _b === void 0 ? 'right' : _b;
    var _c = useState(false), isCalendarOpen = _c[0], setIsCalendarOpen = _c[1];
    var _d = useState(formValue ? moment(formValue) : moment()), selectedDate = _d[0], setSelectedDate = _d[1];
    var _e = useState(false), isDateSelected = _e[0], setIsDateSelected = _e[1];
    var classes = useMemo(function () { return classNames(className, 'shadow-sm block w-full sm:text-sm rounded-md rounded-r-none', "".concat(hasError && baseClasses ? baseClasses === null || baseClasses === void 0 ? void 0 : baseClasses.errorClasses : baseClasses === null || baseClasses === void 0 ? void 0 : baseClasses.standardClasses), { 'text-gray-400': disabled }); }, [hasError, className, baseClasses, disabled]);
    var calendarIconClasses = useMemo(function () { return classNames('flex gap-2 bg-gray-50 rounded-md shadow-sm border-l-0 rounded-l-none px-4 w-20 h-[38px] border border-gray-300', { 'border-red-500 bg-pink-100': hasError }); }, [hasError]);
    var handleChangeSelectedDate = function (date) {
        setSelectedDate(moment(date));
        setIsDateSelected(true);
        setIsCalendarOpen(false);
        if (onChange) {
            onChange({
                target: {
                    name: name,
                    value: date.toISOString(),
                },
            });
        }
    };
    var handleClearDate = function () {
        setIsDateSelected(false);
        setIsCalendarOpen(false);
        if (onChange) {
            onChange({
                target: {
                    name: name,
                    value: '',
                },
            });
        }
    };
    useEffect(function () {
        var timer = setTimeout(function () {
            var watchedValue = watch(name);
            if (watchedValue) {
                setSelectedDate(moment(watchedValue));
                setIsDateSelected(true);
            }
        }, 0);
        return function () { return clearTimeout(timer); };
    }, [watch, name]);
    return (_jsxs(Popover, __assign({ open: isCalendarOpen, content: _jsx("div", __assign({ className: "bg-white p-[10px] rounded-lg z-100", style: {
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
            } }, { children: _jsx(MiniCalendar, { view: "month", currentDate: selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.toDate(), onChange: handleChangeSelectedDate, handleCalendarIsOpen: setIsCalendarOpen }) })), position: dropdownPosition, onClickOutside: function () { return setIsCalendarOpen(false); } }, { children: [_jsxs("div", __assign({ className: "flex flex-row flex-grow" }, { children: [_jsx("div", __assign({ className: classes, onClick: disabled ? function () { } : function () { return setIsCalendarOpen(function (prev) { return !prev; }); } }, { children: isDateSelected ? formatDateDisplay(selectedDate) : _jsx("span", __assign({ className: 'text-gray-400' }, { children: "Select Date" })) })), _jsxs("div", __assign({ className: calendarIconClasses }, { children: [_jsx(AptiveIcon, { className: "w-5 h-5 fill-[#9CA3AF] stroke-none m-auto hover-gray-700 cursor-pointer", icon: "x", isFilled: true, onClick: handleClearDate }), _jsx(AptiveIcon, { className: "w-5 h-5 fill-none stroke-[#9CA3AF] m-auto hover-gray-700 cursor-pointer", icon: "calendar", onClick: function () { return setIsCalendarOpen(function (prev) { return !prev; }); } })] }))] })), _jsx("input", __assign({ id: id, type: "hidden", name: name }, (register && __assign({}, register(name))), { value: watch(name) ? watch(name) : (isDateSelected && selectedDate ? selectedDate.toISOString() : null), onChange: onChange, required: required }))] })));
};
export default CustomDate;
