var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AptiveIcon, Button } from '@aptive-env/storybook';
import { capitalize } from 'lodash';
var ChecklistSelectModalTable = function (_a) {
    var checklists = _a.checklists, onSelectChecklist = _a.onSelectChecklist;
    return (_jsxs("div", __assign({ className: "flex flex-col grow overflow-hidden pb-[50px]" }, { children: [_jsxs("div", __assign({ className: "flex flex-none gap-[4px] bg-gray-50 border-b border-gray-200" }, { children: [_jsx("div", __assign({ className: "text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[160px]" }, { children: _jsx("span", { children: "State" }) })), _jsx("div", __assign({ className: "text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[160px]" }, { children: _jsx("span", { children: "Counties" }) })), _jsx("div", __assign({ className: "text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[160px]" }, { children: _jsx("span", { children: "Municipalities" }) })), _jsx("div", __assign({ className: "text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[120px]" }, { children: _jsx("span", { children: "Priorities" }) })), _jsx("div", __assign({ className: "text-xs text-gray-600 uppercase tracking-[0.03em] p-3 pr-4 flex-1 text-right" }, { children: _jsx("span", { children: "Actions" }) }))] })), _jsx("div", __assign({ className: "flex flex-col flex-auto overflow-x-hidden overflow-y-auto max-h-[500px]" }, { children: checklists.map(function (checklist) {
                    var _a, _b, _c;
                    return (_jsxs("div", __assign({ className: "flex gap-[4px] border-b border-gray-200 bg-white cursor-pointer" }, { children: [_jsx("div", __assign({ className: "flex items-center text-sm text-gray-600 p-3 flex-none w-[160px]" }, { children: (_a = checklist.state) === null || _a === void 0 ? void 0 : _a.name })), _jsx("div", __assign({ className: "flex items-center text-sm text-gray-600 p-3 flex-none w-[160px]" }, { children: (_b = checklist.county) === null || _b === void 0 ? void 0 : _b.name })), _jsx("div", __assign({ className: "flex items-center text-sm text-gray-600 p-3 flex-none w-[160px]" }, { children: (_c = checklist.municipality) === null || _c === void 0 ? void 0 : _c.name })), _jsxs("div", __assign({ className: "flex items-center text-sm text-gray-600 p-3 flex-none w-[120px]" }, { children: [checklist.priority === 'high' && (_jsx(AptiveIcon, { className: "w-[16px] h-[16px] stroke-[#F05252] fill-none ml-[-10px]", icon: "chevronUp" })), checklist.priority === 'medium' && (_jsx(AptiveIcon, { className: "w-[16px] h-[16px] stroke-[#F59E0B] fill-none ml-[-10px]", icon: "menuAlt4" })), checklist.priority === 'low' && (_jsx(AptiveIcon, { className: "w-[16px] h-[16px] stroke-[#0EA5E9] fill-none ml-[-10px]", icon: "chevronDown" })), _jsx("span", __assign({ className: "pl-3" }, { children: capitalize(checklist.priority) }))] })), _jsx("div", __assign({ className: "flex items-center text-sm text-gray-600 p-3 pr-4 flex-1 justify-end" }, { children: _jsx(Button, __assign({ variant: "default", onClick: function () { return onSelectChecklist(checklist); } }, { children: "Select" })) }))] }), checklist.id));
                }) }))] })));
};
export default ChecklistSelectModalTable;
