import { loadState } from './localStorage';

const createPersistedState = () => {
  const { user } = loadState() ?? {};

  return {
    auth: {
      user: user ? { ...user } : null,
    },
  };
};

export default createPersistedState;
