import { call, put, takeLatest } from 'redux-saga/effects';

import { filterEditHistories, getHistoryOptions } from '@/modules/AdminTools/api/editHistories';
import { createRequestSaga } from '@/redux/helpers';
import {
  filterEditHistoriesAsync,
  requestHistoryOptionsAsync,
} from './actions';

function* filterEditHistoriesSaga({ payload }) {
  const response = yield call(filterEditHistories, payload);

  yield put(filterEditHistoriesAsync.success(response));
}

function* getHistoryOptionsSaga() {
  const response = yield call(getHistoryOptions);

  yield put(requestHistoryOptionsAsync.success(response));
}

export function* editHistoriesWatcher() {
  yield takeLatest(
    filterEditHistoriesAsync.request.type,
    createRequestSaga(filterEditHistoriesSaga, {
      keyNew: 'editHistories',
      errKey: 'editHistories',
      write: false,
    }),
  );

  yield takeLatest(
    requestHistoryOptionsAsync.request.type,
    createRequestSaga(getHistoryOptionsSaga, {
      keyNew: 'editHistories',
      errKey: 'editHistories',
      write: false,
    }),
  );
}
