import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useSearchParamFilter = (field) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get(field) ?? '');

  const setSearchValue = ({ target: { value } }) => setValue(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        value == searchParams.get(field) ||
        (value === '' && searchParams.get(field) === null)
      ) {
        return;
      }
      searchParams.set(field, value);
      setSearchParams(searchParams);
    }, 300);

    return () => clearTimeout(timer);
  }, [field, searchParams, setSearchParams, value]);

  useEffect(() => {
    setValue(searchParams.get(field) ?? '');
  }, [searchParams, field]);

  return { value, setSearchValue };
};

export default useSearchParamFilter;
