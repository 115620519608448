var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AptiveIcon } from '@aptive-env/storybook';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { getCalendarEventTypeColors } from 'utils/color';
export var CalendarEventTypeSelect = function (_a) {
    var items = _a.items, selected = _a.selected, onSelect = _a.onSelect, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var ref = useRef(null);
    var _c = useState(false), isExpanded = _c[0], setIsExpanded = _c[1];
    var handleSelect = function (item) {
        setIsExpanded(false);
        onSelect(item);
    };
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (event.target instanceof HTMLElement &&
                ref.current &&
                !ref.current.contains(event.target)) {
                event.stopPropagation();
                setIsExpanded(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return function () {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    return (_jsx("div", __assign({ className: "pl-2" }, { children: _jsxs("div", __assign({ className: "w-full relative" }, { children: [_jsxs("div", __assign({ className: classNames('flex items-center justify-between', disabled ? 'text-gray-400' : 'text-dark-gray-100 cursor-pointer'), onClick: disabled ? function () { } : function () { return setIsExpanded(function (prev) { return !prev; }); } }, { children: [selected && (_jsxs("div", __assign({ className: "h-9 flex items-center whitespace-nowrap px-3" }, { children: [_jsx("div", { className: "h-4 w-4 rounded-full", style: {
                                        background: getCalendarEventTypeColors(selected.id).dark,
                                    } }), _jsx("div", __assign({ className: "ml-2 text-[14px]" }, { children: selected.name }))] }))), _jsx(AptiveIcon, { className: "w-[20px] h-[20px] stroke-aro-gray fill-none", icon: isExpanded ? 'chevronUp' : 'chevronDown' })] })), isExpanded && (_jsx("div", __assign({ ref: ref, className: "shadow rounded-lg overflow-hidden absolute z-[100] bg-white w-full" }, { children: items === null || items === void 0 ? void 0 : items.map(function (item, index) { return (_jsxs("div", __assign({ className: "h-9 flex items-center whitespace-nowrap px-3 cursor-pointer border-b hover:bg-gray-100", onClick: function () { return handleSelect(item); } }, { children: [_jsx("div", { className: "h-4 w-4 rounded-full", style: {
                                    background: getCalendarEventTypeColors(item.id).dark,
                                } }), _jsx("div", __assign({ className: "ml-2 text-[14px] text-[#4B5563]" }, { children: item.name }))] }), index)); }) })))] })) })));
};
