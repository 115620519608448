import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '@/modules/AdminTools/api/planBuilder';
import { createRequestSaga } from '@/redux/helpers';
import {
  requestAreaPlansAsync,
  updateAreaPlanAsync,
  createAreaPlanAsync,
  removeAreaPlanAsync,
} from './actions';
import {
  createPlanBuilderAreaPlan,
  removePlanBuilderAreaPlan,
  updatePlanBuilderAreaPlan,
} from '../plans';

function* requestAreaPlansSaga() {
  const response = yield call(Api.getPlanBuilderAreaPlans);

  yield put(requestAreaPlansAsync.success(response));
}

function* updateAreaPlanSaga({ payload: { areaPlan } }) {
  const response = yield call(Api.updatePlanBuilderAreaPlan(areaPlan.id), areaPlan);

  yield put(updatePlanBuilderAreaPlan(response));
}

function* createAreaPlanSaga({ payload: { areaPlan } }) {
  const response = yield call(Api.createPlanBuilderAreaPlan, areaPlan);

  yield put(createPlanBuilderAreaPlan(response));
}

function* removeAreaPlanSaga({ payload: { id } }) {
  yield call(Api.removePlanBuilderAreaPlan(id));

  yield put(removePlanBuilderAreaPlan(id));
}

export function* areaPlansWatcher() {
  yield takeLatest(
    requestAreaPlansAsync.request.type,
    createRequestSaga(requestAreaPlansSaga, {
      keyNew: 'areaPlans',
      errKey: 'areaPlans',
      write: false,
    }),
  );

  yield takeLatest(
    updateAreaPlanAsync.request.type,
    createRequestSaga(updateAreaPlanSaga, {
      keyNew: 'areaPlan',
      errKey: 'areaPlan',
      write: false,
    }),
  );

  yield takeLatest(
    createAreaPlanAsync.request.type,
    createRequestSaga(createAreaPlanSaga, {
      keyNew: 'areaPlan',
      errKey: 'areaPlan',
      write: false,
    }),
  );

  yield takeLatest(
    removeAreaPlanAsync.request.type,
    createRequestSaga(removeAreaPlanSaga, {
      keyNew: 'areaPlan',
      errKey: 'areaPlan',
      write: false,
    }),
  );
}
