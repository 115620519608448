import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AreaPlan from '../../AreaPlan';
import Headers from './Headers';
import { updatingPlanLoadingSelector } from '@/redux/loading';
import FormControls from '../../FormControls';
import { updateAreaPlanAsync } from '@/modules/AdminTools/redux/planBuilder/area-plans';

const AreaPlanUpdate = ({ areaPlan }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const planErrors = useSelector((state) => state?.errors?.errors?.updatePlan);

  const isUpdating = useSelector(updatingPlanLoadingSelector);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const methods = useForm({ defaultValues: areaPlan });

  const successfulUpdate = useMemo(
    () => isSubmitted && !isUpdating && !planErrors,
    [isUpdating, planErrors, isSubmitted],
  );

  useEffect(() => {
    if (successfulUpdate) {
      methods.reset(areaPlan);
    }
  }, [navigate, successfulUpdate, areaPlan, methods]);

  useEffect(() => {
    if (planErrors && isSubmitted) {
      setIsSubmitted(false);
    }
  }, [setIsSubmitted, planErrors, isSubmitted]);

  const handleSubmit = (data) => {
    if (isUpdating) {
      return;
    }

    dispatch(updateAreaPlanAsync.request({ areaPlan: data }));

    setIsSubmitted(true);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <div className="mt-4">
          <FormControls submitText="Update Area Override" />
          <AreaPlan {...Headers} />
        </div>
      </form>
    </FormProvider>
  );
};

AreaPlanUpdate.propTypes = {
  areaPlan: PropTypes.object.isRequired,
};

export default AreaPlanUpdate;
