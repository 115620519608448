var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import { saveButton, cancelButton } from 'lib/configs';
import { CustomButton } from '.';
var CustomButtonGroup = function (_a) {
    var onCancelClick = _a.onCancelClick, onSaveClick = _a.onSaveClick, disabledCancel = _a.disabledCancel, disabledSave = _a.disabledSave, cancelText = _a.cancelText, saveText = _a.saveText, buttonClassName = _a.buttonClassName, wrapperClassName = _a.wrapperClassName, orientation = _a.orientation, loading = _a.loading, withSubmit = _a.withSubmit;
    var wrapperClasses = useMemo(function () { return classNames(wrapperClassName ? wrapperClassName : 'pr-8 pt-6', 'flex items-center space-x-4', {
        'flex-row-reverse space-x-reverse': orientation === 'right',
    }); }, [wrapperClassName, orientation]);
    var buttonClasses = useMemo(function () {
        return classNames(buttonClassName);
    }, [buttonClassName]);
    return (_jsx("div", __assign({ className: "flex-col" }, { children: _jsxs("div", __assign({ className: wrapperClasses }, { children: [_jsx(CustomButton, { name: saveText || saveButton, text: saveText || saveButton, color: "active", disabled: disabledSave || loading, className: buttonClasses, onClick: onSaveClick, type: withSubmit ? 'submit' : 'button' }, saveText), _jsx(CustomButton, { name: cancelText || cancelButton, text: cancelText || cancelButton, color: "white", disabled: disabledCancel || loading, onClick: onCancelClick, className: buttonClasses }, cancelText)] })) })));
};
export default CustomButtonGroup;
