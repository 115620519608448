import { mergeClassName } from '@/lib'
import PropTypes from 'prop-types'
import { PulseLoader } from 'react-spinners'

const Loader = ({
  className,
  color = '#23d7c6',
  size = 10,
}) => (
  <div className={mergeClassName('flex justify-center', className)}>
    <PulseLoader color={color} size={size} loading={true} />
  </div>
)

Loader.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  color: PropTypes.string,
  size: PropTypes.number,
}
export default Loader
